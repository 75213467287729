import { Avatar, Box, Button, ButtonBase, IconButton, Slider, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { TvTransLeftPanel } from '../TvTransLeftPanel/TvTransLeftPanel';
import { StreamPlayer } from 'components/molecules/StreamPlayer/StreamPlayer';
import CTAButton from 'components/atoms/CTAButton/CTAButton';
import { useDispatch, useSelector } from 'react-redux';
import { toggleChatOpened } from 'redux/actions/tvtrans';

import FollowIcon from '@mui/icons-material/FavoriteBorderRounded';
import SubscribeIcon from '@mui/icons-material/StarBorderRounded';
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';

import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => ({
  chat: ({isChatOpened}) => ({
    // borderLeft: `2px solid ${theme.palette.primary.main}`
    height: "calc(100vh - 55px)",
    width: isChatOpened ? "max-content" : 0,
    minWidth: isChatOpened ? "340px" : 0,
    overflow: "hidden",
    transition: "0.2s ease",
    // top: "55px",
    // right: 0,
    position: "relative",
    // width: "100%",
    boxShadow: "0 0 2px black",
    background: theme.palette.background.paper,
  }),
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "scroll",

    '&::-webkit-scrollbar': {
        width: '0',
    },
    // '&::-webkit-scrollbar-track': {
    //     background: theme.palette.background.paper
    // },
    // '&::-webkit-scrollbar-thumb': {
    //     background: theme.palette.grey[500],
    //     borderRadius: "10px",
    // },
  },


  channelInfo: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
  },
  channelMainInfo: {
    display: "flex",
  },

  "@keyframes liveAnimation": {
    "0%": {
      boxShadow: `0 0 0 0 ${theme.palette.primary.dark}`,
    },
    "100%": {
      opacity: 0,
      boxShadow: `0 0 5px 15px ${theme.palette.primary.main}`,
    }
  },
  channelMainInfoAvatar: {
    width: "80px",
    height: "80px",
    background: theme.palette.primary.main,
    overflow: "visible",
    position: "relative",
    cursor: "pointer",

    "&::before": {
      content: '""',
      top: "50%",
      transform: "translateY(-50%)",
      position: "absolute",
      width: "calc(100% + 3px)",
      height: "calc(100% + 3px)",
      transition: "0.1s ease",
      // boxShadow: `0 0 0 2px ${theme.palette.primary.dark}`,
      borderRadius: "50%",
    },

    "&::after": {
      content: '"live"',
      position: "absolute",
      padding: "4px 8px",
      borderRadius: "10px",
      bottom: 0,
      transform: "translateY(30%)",
      backgroundColor: theme.palette.secondary.main
    },

    "&:hover": {
      "&::before": {
        animation: `$liveAnimation 1000ms ${theme.transitions.easing.easeInOut} infinite`,
      }
    }
  },
  channelMainInfoDetails: {
    paddingLeft: "15px",
    width: "100%",
  },
  channelMainInfoDetailsTop: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  channelMainInfoDetailsDescription: {
    fontSize: "15px",
  },
  channelMainInfoDetailsActivity: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: "17px",
    borderRadius: "3px",
    textTransform: "uppercase",

    "&:hover": {
      color: theme.palette.primary.light,
    }
  },
  channelTitle: {
    fontSize: "22px",
    fontWeight: "bold"
  },
  channelActions: {
    display: "flex",
  },

  channelInfoAbout: {
    height: "max-content",
    display: "flex",
    boxSizing: "border-box",
    margin: "30px 10px",
    borderRadius: "10px",
    background: theme.palette.primary.light,
    boxShadow: `${theme.shadows[2]}`,
    color: theme.palette.primary.contrastText,
  },
  channelInfoAboutBlock: {
    padding: "40px 30px",
  },
  channelInfoAboutLinks: {
    display: "flex",
    flexDirection: "column",
    padding: "40px 20px",
    borderLeft: `solid 1px ${theme.palette.primary.contrastText}`
  },
}))

const about = {
  description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda, officiis deleniti. Consequatur possimus consequuntur, adipisci atque beatae illo cum debitis!",
  links: [
    {
      src: "https://twitter.com/home",
      name: "twitter",
      icon: <TwitterIcon/>
    },
    {
      src: "https://twitter.com/home",
      name: "youtube",
      icon: <YouTubeIcon/>
    },
    {
      src: "https://twitter.com/home",
      name: "telegram",
      icon: <TelegramIcon/>
    },
    {
      src: "https://twitter.com/home",
      name: "instagram",
      icon: <InstagramIcon/>
    },
  ]
}

export default function TvTransContentStream({video}) {
  const isChatOpened = useSelector(state => state.tvtrans.stream.isChatOpened);
  const classes = useStyles({isChatOpened});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if(window.Tawk_API?.hideWidget) {
        window.Tawk_API?.hideWidget();
        clearInterval(interval)
      }
    }, 500)
    return () => {
      clearInterval(interval)
      window.Tawk_API?.showWidget();
    };
    // eslint-disable-next-line
  }, [window.Tawk_API]);

  return (
    <>
      <TvTransLeftPanel/>
      <Box className={classes.content}>
          <StreamPlayer video={video}/>

          <Box className={classes.channelInfo}>
            <Box className={classes.channelMainInfo}>
              <Avatar className={classes.channelMainInfoAvatar}/>
              <Box className={classes.channelMainInfoDetails}>
                <Box className={classes.channelMainInfoDetailsTop}>
                  <Typography className={classes.channelTitle}>
                    User_123
                  </Typography>
                  <Typography className={classes.channelActions}>
                    <CTAButton iconElement={<EmojiPeopleRoundedIcon/>} style={{marginRight: "3px"}} variant="outlined" color="primary" small size="small">
                      React
                    </CTAButton>
                    <CTAButton iconElement={<FollowIcon/>} style={{marginRight: "3px"}} color="primary" small size="small">
                      Follow
                    </CTAButton>
                    <CTAButton iconElement={<SubscribeIcon/>} color="primary" small size="small">
                      Subscribe
                    </CTAButton>
                  </Typography>
                </Box>
                <Typography className={classes.channelMainInfoDetailsDescription}>
                  Lorem ipsum dolor sit amet, consectetur
                </Typography>
                <ButtonBase
                  onClick={() => {
                    navigate(`/tvtrans/directory/${video.directory}/${video.category}`)
                  }}
                  className={classes.channelMainInfoDetailsActivity}
                >
                  special events
                </ButtonBase>
              </Box>
            </Box>

            <Box className={classes.channelInfoAbout}>
              <Box className={classes.channelInfoAboutBlock}>
                <Typography variant="h5">
                  About User_123
                </Typography>
                <Typography component="p">
                  {about.description}
                </Typography>
              </Box>
              <Box className={classes.channelInfoAboutLinks}>
                {about.links.map((a) => (
                  <IconButton
                    style={{
                      color: "#fff",
                      padding: "5px",
                    }}
                  >
                    {a.icon}
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Box>
      </Box>
      <div className={classes.chat}>
        <Button onClick={() => dispatch(toggleChatOpened(false))}>Close</Button>
      </div>
    </>
  )
}
