import { makeStyles } from '@material-ui/core'
import { Opacity } from '@material-ui/icons';
import React from 'react'

// @ts-ignore
const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        position: "relative",
        paddingBottom: "50%",
        marginBottom: "15px",
    },
    containerInSection: {
        width: "100%",
        position: "relative",
        paddingBottom: "50%",
        marginBottom: "15px",
        transform: "scale(1.1)",
        [theme.breakpoints.down('sm')]: {
            transform: "scale(1.2)"
        }
    },
    img: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        zIndex: "1",
        
        "&::after": {
            transition: "1s cubic-bezier(.2,1.14,.3,.95)",
            transformOrigin: "left bottom",
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: "url(https://img.fastcast4u.com/react/radio/alexa-front.png)",   
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",

            transform: "",
        }
    },
    message: {
        position: "absolute",
        background: theme.palette.primary.main,
        color: "#fff",
        padding: "5px",
        fontSize: "1em",
        borderRadius: "10px",
        left: "50%",
        transition: "0.4s ease",
        transform: "translate(50%, 200%)",
        opacity: 0,
        transformOrigin: "left bottom",
        width: "max-content !important",
        zIndex: "-1",

        "&::after": {
            content: "''",
            width: "20px",
            height: "10px",
            transform: "translateX(-100%)",
            position :"absolute",
            display: 'block',
            borderRadius: "0 0 30px 0",
            left: 0,
            bottom: "-1px",
            boxShadow: `10px 0 0 -1px ${theme.palette.primary.main}`
        }
    },
    "@global": {
        "div:has(> $container)": {
            "&:hover > $container $img::after": {
                transform: "scale(90%)",
            },
            "&:hover > $container $message": {
                transform: "scale(90%)",
                opacity: 1,
            },  
        },
        "section:has(> div $containerInSection) div:has(> div $containerInSection)": {
            background: "black",
            "&:hover $containerInSection $img::after": {
                transform: "scale(90%)",
            },
            "&:hover $containerInSection $message": {
                transform: "scale(90%)",
                opacity: 1,
                right: "5%",
            },  
        }
    }
}))

export default function AlexaFeatureAnimation({isForSection}) {
    const classes = useStyles();

    return (
        <>
            {/* <div
                className={classes.activationBlock}
            ></div> */}
            <div
                className={isForSection ? classes.containerInSection : classes.container}
            >
                <div className={classes.img}>
                    <div
                        className={classes.message}
                    >
                        Alexa, play Your Radio Name
                    </div>
                </div>
            </div>
        </>
    )
}
