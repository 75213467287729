import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import Countdown from 'react-countdown';

const useStyles = makeStyles(theme => ({
    Wrapper: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&>div': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 60px',
            [theme.breakpoints.down(600)]: {
                padding: '0 30px',
            },
            [theme.breakpoints.down(400)]: {
                padding: '0 20px',
            },
            '& $p': {
                margin: 0
            }
        },
        '&>div:not(:last-child)': {
            borderRight: '1px dotted rgba(255,255,255,.2)'
        }
    },
    TimeValue: {
        fontSize: 44,
        fontWeight: 700,
        [theme.breakpoints.down(600)]: {
            fontSize: 33,
        },
    },
    TimeLabel: {
        fontSize: 18,
        [theme.breakpoints.down(600)]: {
            fontSize: 14,
        },
    },
}));

const CyberDealsCounterDown = ({ date }) => {
    const classes = useStyles();

    const renderer = ({ days, hours, minutes, seconds }) => {
        console.log("days", { days, hours, minutes, seconds });
        hours = days * 24 + hours;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
    
        return (
            <Box className={classes.Wrapper}>
                <Box>
                    <p className={classes.TimeValue}>{hours}</p>
                    <p className={classes.TimeLabel}>Hours</p>
                </Box>
                <Box>
                    <p className={classes.TimeValue}>{minutes}</p>
                    <p className={classes.TimeLabel}>Minutes</p>
                </Box>
                <Box>
                    <p className={classes.TimeValue}>{seconds}</p>
                    <p className={classes.TimeLabel}>Seconds</p>
                </Box>
            </Box>
        );
    };

    return <Countdown date={date} renderer={renderer} />;
};

export default CyberDealsCounterDown;