// @ts-nocheck
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import FooterContainer from 'components/atoms/FooterContaier/FooterContainer';
import FooterWidget from 'components/molecules/FooterWidget/FooterWidget';
import FooterLinks from 'components/molecules/FooterLinks/FooterLinks';
import FooterThemeProvider from 'theme/FooterThemeProvider';

const Footer = ({copyrightsOnly}) => {
  const {pathname} = useLocation();
  const isHidden = pathname.includes('order') || pathname.startsWith('/account/payments/') || pathname.includes('landing');
  const isCyberDeals = pathname.startsWith(`/cyberdeals`);
  const content = useSelector(state => state.language.footer.items);

  //links in the footer
  const data = useMemo(
    () => [
      {
        title: content[0].title,
        content: [
          { heading: content[0].content[0], href: '/order/package' },
          { heading: content[0].content[1], href: '/tv' },
          { heading: content[0].content[2], href: '/app', external: true },
          { heading: content[0].content[3], href: '/alexa-skill' },
        ],
      },
      {
        title: content[1].title,
        content: [
          { heading: content[1].content[0], href: '/radio-directory' },
          { heading: content[1].content[1], href: '/help' },
          { heading: content[1].content[2], href: '/faq' },
          {
            heading: content[1].content[3],
            href: 'https://billing.fastcast4u.com/serverstatus.php',
            external: true,
          },
        ],
      },
      {
        title: content[2].title,
        content: [
          { heading: content[2].content[0], href: '/contact' },
          { heading: content[2].content[1], href: '/contact' },
          { heading: content[2].content[2], href: '/ticket' },
          { heading: content[2].content[3], href: '/login' },
        ],
      },
      {
        title: content[3].title,
        content: [
          {
            heading: content[3].content[0],
            href: '//www.facebook.com/freeshoutcast/',
            external: true,
          },
          {
            heading: content[3].content[1],
            href: 'https://www.youtube.com/user/FastCast4u/videos/',
            external: true,
          },
          {
            heading: 'LinkedIn',
            href: 'https://www.linkedin.com/company/fastcast4u',
            external: true,
          },
          {
            heading: 'Instagram',
            href: 'https://www.instagram.com/fastcast4u/',
            external: true,
          },
        ],
      },
    ],
    [content],
  );

  return !isCyberDeals && 
    <Box flex={1} display='flex' alignItems='flex-end'>
      <FooterThemeProvider>
          <FooterContainer style={isCyberDeals ? {border: 'none'} : {}} component="footer" maxWidth={false} p={0}>
            <Container maxWidth="xl">
              {(!copyrightsOnly && !isHidden) && <FooterWidget data={data} />}
              <FooterLinks copyrightsOnly={copyrightsOnly}/>
            </Container>
          </FooterContainer>
      </FooterThemeProvider>
    </Box>
};

export default Footer;
