import axios from 'axios';
import { PRICING_FETCH_FAIL_TV, SET_LOADING_TV, SET_NEW_PRICES_TV, SET_PRICES_TV, SET_PROMOCODE_TV, SET_SESSION_UID, SET_STEP } from 'redux/actionTypes/orderTvTypes';
import { isProd } from 'utils/nodeEnv';

const baseUrl = window.location.host === 'dev.fastcast4u.com' ? 
  'https://dev-api-clean.fastcast4u.com/api/' 
  :
  `${isProd() ? 'https://fastcast4u.com' : ''}/api/ordertv/api/`;

// const baseUrl = 'https://fastcast4u.com/api/ordertv/api/';

// const baseUrl = 'https://dev-api-clean.fastcast4u.com/api/';

let source = axios.CancelToken.source();

const getCategories = async () => {
  const response = await axios.get(`${baseUrl}faq.php`, {
    cancelToken: source.token,
  });
  
  return response.data.data;
};

const setStep = (stepNumber) => async (dispatch) => {
  dispatch({type: SET_STEP, payload: stepNumber});
};

//#region actions to change step of ordering tv products (setStep1, setStep2, setStep3, setStep4, setStep6)
const setStep1 = async (dispatch) => {
  const request = await axios.get(`${baseUrl}step1.php`);
  dispatch({type: SET_SESSION_UID, payload: request.data});
  dispatch(setStep(request.data.data.step))
  return request.data.data;
};

const setStep2 = ({product, addons, billingcycle, promocode, session_uid}) => async (dispatch) => {
  const request = await axios.get(`${baseUrl}step2.php`, {
    params: {
      product,
      addons,
      billingcycle,
      promocode,
      session_uid,
    },
    cancelToken: source.token
  });
  dispatch(setStep(request.data.data.step));

  return request.data.data;
};

const setStep3 = ({client_password, server_password, username, email, emailmarketing, session_uid}) => async (dispatch) => {
  const request = await axios.get(`${baseUrl}step3.php`, {
    params: {
      email,
      client_password,
      server_password,
      username,
      emailmarketing,
      session_uid,
    },
    cancelToken: source.token,
  });
  dispatch(setStep(request.data.data.step));
  return request.data.data;
};

const setStep4 = (method, session_uid) => async (dispatch) => { 
  const request = await axios.get(`${baseUrl}step4.php`, {
    params: {
      method,
      session_uid,
    },
    cancelToken: source.token,
  });
  dispatch(setStep(request.data.data.step));
  return request.data.data;
};

const setStep5 = ({firstname, lastname, company, phone, smsmarketing, session_uid}) => async (dispatch) => {
  const request = await axios.get(`${baseUrl}step5.php`, {
    params: {
      firstname,
      lastname,
      company,
      phone,
      smsmarketing,
      session_uid,
    },
    cancelToken: source.token,
  });
  dispatch(setStep(5));
  return request.data.data;
};
//#endregion

const stripePaymentVerification = async (data) => {
  const request = await axios.get(`${baseUrl}stripe-payment-verification.php`, {
    params: data
  });
  return request?.data;
}

const getMonthlyLeft = async () => {
  const response = await axios.get('https://fastcast4u.com/api/getpricing/checkPromotion.php?promocode=tvpromo1stmonth', {
    cancelToken: source.token,
  });
  
  return response.data;
};

// service to get product prices
const getPricing = (promocode, tv) => async dispatch => {
  try {
    dispatch({ type: SET_LOADING_TV, payload: true})
    const order = await axios.get(`${baseUrl}pricing.php`, {
      params: {
        promocode: !promocode ? undefined : promocode,
        tv: !tv ? undefined : tv,
      },
      headers: {
        // 'Access-Control-Expose-Headers': 'Authorization, X-Socket-ID',
        // 'Content-Type': 'application/json',
        // 'Cache-Control': 'no-cache, private',
        // 'X-Powered-By': 'PHP/8.0.19',
        'Accept-Language': 'zh-TW,pl;q=0.5'
      }
    });
    // const order = await axios.get(`https://dev.fastcast4u.com/fastcast4u-dev-api/ordertv/api/pricing.php?promocode=${promocode}${tv ? `&tv=${tv}` : ''}`);

    dispatch({
      type: `${!tv ? SET_PRICES_TV : SET_NEW_PRICES_TV}`,
      payload: {...order.data}
    });
    dispatch({type: SET_PROMOCODE_TV, payload: promocode});
    return order.data.data;
  } catch (error) {
    // dispatch({ type: SET_PRICES_TV, payload: error.response.data });
    dispatch({ type: PRICING_FETCH_FAIL_TV, payload: promocode });
    return false;
  }
};

// service to get promocodes that should be applied automatically
const getPromocodes = async () => {
  const request = await axios.get(`${baseUrl}managedPromoCodes`);
  return request.data.data;
};


const checkCompatibility = async ({session_uid}) => {
  const request = await axios.get(`${baseUrl}seemsToBeOk.php`, {
    params: {
      session_uid,
    },
    cancelToken: source.token,
  });
  return request.data;
};

const updateDetails = async data => {
  const request = await axios.post(`/api/details/details.php`, data, {
    cancelToken: source.token,
  });
  return request.data.data;
};

const checkFinalCompatibility = async ({session_uid}) => {
  const request = await axios.get(`${baseUrl}isOk.php`, {
    params: {
      session_uid,
    },
    cancelToken: source.token,
  });
  return request.data;
};

const isReady = async ({session_uid}) => {
  const request = await axios.get(`${baseUrl}isReady.php`, {
    params: {
      session_uid
    },
    cancelToken: source.token,
  });
  return request.data;
};

const getLoginURL = async url => {
  const request = await axios.get(url, {
    cancelToken: source.token,
  });
  return request.data.url;
};

// cancel the requests
const cancel = () => {
  source.cancel();
  source = axios.CancelToken.source();
};

export default {
  getCategories,
  cancel,
  getPricing,
  getPromocodes,
  setStep1,
  setStep2,
  setStep3,
  setStep4,
  checkCompatibility,
  setStep5,
  updateDetails,
  checkFinalCompatibility,
  isReady,
  getLoginURL,
  getMonthlyLeft,
  stripePaymentVerification
};
