import React from 'react';
import { Route } from 'react-router-dom';
import Radio from 'views/Radio/Radio';
import RadioPromo from 'views/Radio/RadioPromo';

//children routes displayed on /radio
const RadioRoutes = [
    <Route index element={<Radio/>} />,
    // promotion route made for ability to scadule a meeting
    <Route path={`promo`} element={<RadioPromo/>} />
];

export default RadioRoutes;
