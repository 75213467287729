import axios from 'axios';
import { isProd } from 'utils/nodeEnv';
import { PRICING_FETCH_FAIL_APP, SET_PRICES_APP, SET_SESSION_UID_APP, SET_CONTENT_APP } from 'redux/actionTypes/orderAppTypes';

const baseUrl = (window.location.host === 'dev.fastcast4u.com' || window.location.hostname === 'localhost') ? 
  'https://dev-api.fastcast4u.com/' 
  :
  `${isProd() ? 'https://order.fastcast4u.com/' : ''}`;
  
// const baseUrl = 'https://order.fastcast4u.com/';

let source = axios.CancelToken.source();

// service to get promocodes that should be applied automatically
const getPromocodes = async () => {
  const request = await axios.get(`${baseUrl}managedPromoCodes`);
  return request.data.data;
};

//#region actions to change step of ordering radio products (setStep1, setStep2, setStep3, setPaymentMethod, setStep6)
const setStep1 = async dispatch => {
  const request = await axios.get(`${baseUrl}api/session`);
  dispatch({ type: SET_SESSION_UID_APP, payload: request.data });
  return request.data;
};

const setStep2 = async (data) => {
  await axios.post(`${baseUrl}api/order`, {
    ...data
  });
};

const setStep3 = async data => {
  const request = await axios.post(`${baseUrl}api/customer/credential`, {
    ...data
  });
  return request.data;
};

const setPaymentMethod = async data => {
  const request = await axios.post(`${baseUrl}api/payment`, {...data});
  return request.data;
};

const checkPaid = async (session_uid, stripe_id) => {
  let request;
  
  if(stripe_id) {
    request = await axios.get(`${baseUrl}api/payment/${session_uid}/stripe/${stripe_id}`);
  } else {
    request = await axios.get(`${baseUrl}api/payment/${session_uid}/check-paid`)
  }

  return request;
}

const setStep5 = async data => {
  const request = await axios.post(`${baseUrl}api/customer/personal`, {
    data: {
      ...data
    }
  });
  return request.data;
};
//#endregion

const getContentWithTranslation = () => async dispatch => {
  const pricingContent = await axios.get(`${baseUrl}api/translate/site?area=pricing&lang=en`)
  const createOrSignContent = await axios.get(`${baseUrl}api/translate/site?area=create_or_sign`)
  const paymentContent = await axios.get(`${baseUrl}api/translate/site?area=payment`)
  const pendingContent = await axios.get(`${baseUrl}api/translate/site?area=pending`)
  const successContent = await axios.get(`${baseUrl}api/translate/site?area=success`)

  dispatch({type: SET_CONTENT_APP, payload: {
    pricing: pricingContent.data.data,
    createOrSign: createOrSignContent.data.data,
    payment: paymentContent.data.data,
    pending: pendingContent.data.data,
    success: successContent.data.data,
  }})
}

const getActivePromocodes = async () => {
  const result = await axios.get(`${baseUrl}api/promocode/active`)
  console.log('active', result)
  return result.data.data
}

// service to get product prices
// const order = await axios.get(`https://dev.fastcast4u.com/fastcast4u-dev-api/order/api/pricing.php`, {
const getPricing = (promocode, hiddenSections) => async dispatch => {
  try {
    const order = await axios.get(`${baseUrl}api/pricing`, {
      params: {
        promocode: !promocode ? undefined : promocode,
      },
    });
    dispatch({
      type: SET_PRICES_APP,
      payload: {
        data: order.data.data,
        hiddenSections: hiddenSections
    }});
    return true;
  } catch (error) {
    console.log(error)
    dispatch({ type: PRICING_FETCH_FAIL_APP, payload: promocode });
    try {
      const order = await axios.get(`${baseUrl}api/pricing`, {});
      dispatch({ type: SET_PRICES_APP, payload: order.data });
      return true;
    } catch (error) {
      console.log(error)
    }
    return false;
  }
};

const checkCompatibility = async () => {
  const request = await axios.get(`${baseUrl}seemsToBeOk.php`, {
    cancelToken: source.token,
  });
  return request.data;
};

const updateDetails = async data => {
  const request = await axios.post(`/api/details/details.php`, data, {
    cancelToken: source.token,
  });
  return request.data;
};

const isReady = async () => {
  const request = await axios.get(`${baseUrl}isReady.php`, {
    cancelToken: source.token,
  });
  return request.data;
};

const getLoginURL = async url => {
  const request = await axios.get(url, {
    cancelToken: source.token,
  });
  return request.data.url;
};

// cancel the requests
const cancel = () => {
  source.cancel();
  source = axios.CancelToken.source();
};

export default {
  cancel,
  getPricing,
  getPromocodes,
  setStep1,
  setStep2,
  setStep3,
  checkPaid,
  setStep5,
  setPaymentMethod,
  checkCompatibility,
  updateDetails,
  isReady,
  getLoginURL,
  getContentWithTranslation,
  getActivePromocodes
};
