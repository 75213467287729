import { Box, makeStyles } from "@material-ui/core"
import ArrowDown from "assets/svg/ArrowDown"
import Text from "components/atoms/Text/Text"
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

const useStyles = makeStyles(theme => ({
  Wrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0',
      '& >*': {
        cursor: 'pointer',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }
  },
}));

// component for landing page.
export const CheckMore = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  //function to scroll to the #more section
  const checkOutMoreHandler = () => {
    navigate({pathname: location.pathname, hash: '', search: location.search})
    const blockScrollToRef = document.getElementById('more');
    if(blockScrollToRef) {
      blockScrollToRef.scrollIntoView({ behavior: 'smooth' })
    } else {
      console.log(blockScrollToRef)
    }
  };
  
  useEffect(() => {
    if(location.hash.includes('#more')) {
      checkOutMoreHandler()
    }
  }, [])

  return (
    <Box className={classes.Wrapper}>
      <Box onClick={checkOutMoreHandler}>
        <Text component='h6' color='primary'>Check out more</Text>
        <ArrowDown />
      </Box>
    </Box>
  )
}