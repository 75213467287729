import React, { lazy, Suspense } from 'react';
import { Routes as RouterRoutes, Route } from 'react-router-dom';
import {Navigate, useLocation, useNavigate} from 'react-router';
import OrderRoutes from 'routes/orderRoutes';
import OrderAppRoutes from 'routes/orderAppRoutes';
import OrderTvRoutes from 'routes/orderTvRoutes';
import AccountRoutes from 'routes/AccountRoutes';
import DirectoryRoutes from 'routes/directoryRoutes';
import HelpRoutes from 'routes/helpRoutes';
import RadioRoutes from './radioRoutes';
import TVRoutes from './tvRoutes';
import PageLoader from 'components/molecules/PageLoader/PageLoader';
import AppRoutes from './AppRoutes';
import AppleTVRoutes from './AppleTvRoutes';
import AlexaSkillRoutes from './AlexaSkillRoutes';
import RadioPromotionRoutes from './RadioPromotionRoutes';
import RouteSuspensePageLoader from 'components/molecules/RouteSuspensePageLoader/RouteSuspensePageLoader';
import FaqArticles from 'components/molecules/FaqArticles/FaqArticles';
import { isDev, isProd, isTest } from 'utils/nodeEnv';
import { useEffect } from 'react';
import Unsubscribe from 'views/Unsubscribe/Unsubscribe';
import Join from 'views/Join/Join';
import TvTransRoutes from './TvTransRoutes';
import { TvTrans } from 'views/TvTrans/TvTrans';
import AppTVRoutes from './AppTvRoutes';
import RokuTv from 'views/RokuTV/RokuTV';
import generalActions from 'redux/actions/generalActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from 'views/Box/Box';

const Home = lazy(() => import('views/Home/Home'));
const CyberDeals = lazy(() => import('views/CyberDeals/CyberDeals'));

const WebPlayer = lazy(() => import('views/WebPlayer/WebPlayer'));
const SocialLiveStreaming = lazy(() => import('views/SocialLiveStreaming/SocialLiveStreaming'));
const AddonAutodjKey = lazy(() => import('views/AddonAutodjKey/AddonAutodjKey'));
const Contact = lazy(() => import('views/Contact/Contact'));
const Login = lazy(() => import('views/Login/Login'));
const Ticket = lazy(() => import('views/Ticket/Ticket'));
const Faq = lazy(() => import('views/Faq/Faq'));
const ToS = lazy(() => import('views/ToS/ToS'));
const Privacy = lazy(() => import('views/Privacy/Privacy'));
const UpdateDetails = lazy(() => import('views/UpdateDetails/UpdateDetails'));
const Language = lazy(() => import('views/Language/Language'));
const AppPublication = lazy(() => import('views/AppPublication/AppPublication'));
const Ceo = lazy(() => import('views/Ceo/Ceo'));
const Agata = lazy(() => import('views/Agata/Agata'));
const Emil = lazy(() => import('views/Emil/Emil'));
const NotFound = lazy(() => import('views/NotFound/NotFound'));


// list of objects with properties "from" and "to". The list is used to provide redirects in code according to list's elements
const redirectsList = [
//{ from: "/:url*(/+)", to: window.location.pathname.slice(0, -1)},
  { from: `/create*`, to: `/radio`},
  // { from: `//start`, to: `/order/package`},
  { from: `/customize*`, to: `/webplayer`},
]

const ReloadEl = (() => {document.location.reload(); return <></>})

console.log("isDev", isDev(), "isProd", isProd(), "isTest", isTest());

console.log("isDev", isDev(), "isProd", isProd(), "isTest", isTest());

// component that is responsible for all routing
const Routes = () => {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const chat = useSelector(state => state.general.chat);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (location.search === '?chat') {
      dispatch(generalActions.setChatDisplay(true));
      searchParams.delete("chat");
      navigate({search: searchParams.toString()});
    }
  }, [location.search])

  useEffect(() => {
    if (!chat.onLoaded) return;

    if (chat.isOpen) {
      window.Tawk_API.maximize();
    } else {
      window.Tawk_API.minimize();
      matches && window.Tawk_API.hideWidget();
    }
    // eslint-disable-next-line
  }, [chat.onLoaded, chat.isOpen]);
  
  return (
    <Suspense fallback={<PageLoader />}>
      <RouterRoutes>
        {[
          //#region routes with children routes
            //tvtrans
            // <Route path="tvtrans" element={<TvTrans/>}>{TvTransRoutes}</Route>,

            //#region landing pages
            <Route index element={<Home/>} />,
            <Route path={`apple-tv`} element={<RouteSuspensePageLoader/>}>{AppleTVRoutes}</Route>,
            <Route path={`roku-tv`} element={<RokuTv/>}></Route>,
            <Route path={`app-tv`} element={<RouteSuspensePageLoader/>}>{AppTVRoutes}</Route>,
            <Route path={`radio`} element={<RouteSuspensePageLoader/>}>{RadioRoutes}</Route>,
            <Route path={`join`} element={<RouteSuspensePageLoader/>}><Route index element={<Join/>} /></Route>,
            <Route path={`alexa-skill`} element={<RouteSuspensePageLoader/>}>{AlexaSkillRoutes}</Route>,
            <Route path={`tv`} element={<RouteSuspensePageLoader/>}>{TVRoutes}</Route>,
            <Route path={`app`} element={<RouteSuspensePageLoader/>}>{AppRoutes}</Route>,
            <Route path={`radio-directory`} element={<RouteSuspensePageLoader/>} >{DirectoryRoutes}</ Route>,
            //#endregion
            //#region order pages
            <Route path={`order`} element={<RouteSuspensePageLoader/>}>{OrderRoutes}</Route>,
            <Route path={`orderApp`} element={<RouteSuspensePageLoader/>}>{OrderAppRoutes}</Route>,
            <Route path={`orderTv`} element={<RouteSuspensePageLoader/>}>{OrderTvRoutes}</Route>,
            //#endregion
            <Route path={`radio-promotion`}>{RadioPromotionRoutes}</Route>,
            <Route path="unsubscribe" element={<Unsubscribe/>}></Route>,
            <Route path={`help`}  element={<RouteSuspensePageLoader/>}>{HelpRoutes}</Route>,
            (isDev() || window.location.host === 'dev.fastcast4u.com') ? <Route path={`account`} element={<RouteSuspensePageLoader/>} >{AccountRoutes}</ Route> : <></>,
            <Route path={`faq`} element={<Faq/>} >,
              <Route path={':category'} element={<FaqArticles/>}/>
              <Route index element={<FaqArticles/>}/>
            </Route>,
          //#endregion

          //#region business cards
          <Route path={`ceo`} element={<Ceo/>} />,
          <Route path={`agata`} element={<Agata/>} />,
          <Route path={`emil`} element={<Emil/>} />,
          //#endregion

          //#region other routes
          <Route path={`cyberdeals`} element={<CyberDeals/>} />,
          <Route path={`box`} element={<Box/>} />,
          <Route path={`social-live-streaming`} element={<SocialLiveStreaming/>} />,
          <Route path={`addon_autodj_key`} element={<AddonAutodjKey/>} />,
          <Route path={`ticket`} element={<Ticket/>} />,
          <Route path={`contact`} element={<Contact/>} />,
          <Route path={`faq`} element={<Faq/>} />,
          <Route path={`tos`} element={<ToS/>} />,
          <Route path={`privacy`} element={<Privacy/>} />,
          <Route path={`webplayer`} element={<WebPlayer/>} />,
          <Route path={`update-details`} element={<UpdateDetails/>} />,
          <Route path={`app-publication`} element={<AppPublication/>} />,
          <Route path={`login`} element={<Login/>} />,
            // <Route path={`update-details`} element={<UpdateDetails/>} />,
          //#endregion

          //#region here the redirects list is transformed to list of routes that lead from "from" property to "to" propety
            ...redirectsList.map(({from, to}) => <Route path={from} element={<Navigate replace to={to} />} />),
          //#endregion
          (isProd() && window.location.hostname === 'fastcast4u.com') && <Route path="/public-app-creator*" element={<ReloadEl/>} />,
          //# displayed if path does not match any of rotes paths above
          <Route path="*" element={<NotFound/>} />
        ]}
      </RouterRoutes>
    </Suspense>
  )
};

export default Routes;
