import { Backdrop, Box, Dialog, IconButton, Input, makeStyles, Typography } from '@material-ui/core'
import CTAButton from 'components/atoms/CTAButton/CTAButton';
import FormikInput from 'components/atoms/FormikInput/FormikInput';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toggleAuthMode, toggleAuthWindow } from 'redux/actions/tvtrans';
import { register } from 'services/tvtrans';

const useStyles = makeStyles((theme) => ({
    container: {
        height: "350px",
        width: "80vw",
        maxWidth: "900px",
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        background: theme.palette.primary.dark,
        borderRadius: "10px",
    },
    dialogLabel: {
        padding: "50px 60px",
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
    },
    dialogLabelLeft: {
        left: "10px",
        display: "flex",
    },
    dialogLabelRight: {
        right: "10px",
        alignItems: "end",

        "& $dialogLabelDescription": {
            textAlign: "end",
        }
    },
    dialogLabelTitle: {
        fontSize: "35px",
        color: theme.palette.primary.contrastText
    },
    dialogLabelDescription: {
        fontSize: "24px",
        color: theme.palette.primary.contrastText
    },
    dialogRect: ({mode}) => ({
        height: "500px",
        width: "420px",
        left: mode === "login" ? "40px" : "calc(100% - 40px)",
        top: "50%",
        transition: "0.5s ease",
        transform: mode === "login" ? "translateY(-50%)" : "translate(-100%, -50%)",
        position: "absolute",
        background: theme.palette.background.paper,
        boxShadow: "0 0 10px rgba(0,0,0,0.5)",
        borderRadius: "20px",
        overflow: "hidden"
    }),
    dialogRectContent: ({mode}) => ({
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        padding: "40px",
        position: "absolute",
        transition: "0.5s ease",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    dialogRectForm: ({mode}) => ({
        width: "100%",
        display: "flex",
        flexDirection: "column",
    }),
    dialogRectContentLogIn: ({mode}) => ({
        transform: mode === "login" ? "translateX(0)" : "translateX(-100%)",
    }),
    dialogRectContentSignUp: ({mode}) => ({
        transform: mode === "signup" ? "translateX(0)" : "translateX(100%)",
        alignItems: "end"
    }),
    changeModeButton: {
        borderColor: "#fff",
        color: "#fff",
        fontSize: "19px",
    },
    submitButton: {
        fontSize: "18px",
    },
    backdropController: {
        width: "100%",
        height: "100%",
        position: "absolute",
    },
    closeButton: ({mode}) => ({
        position: "absolute",
        right: mode ==="login" ? 0 : "100%",
        transition: "0.5s ease",
        transform: mode ==="login" ? "translateX(0)" : "translateX(100%)",
        top: 0,
        color: theme.palette.primary.contrastText
    }),
}))

function SignUpContent({mode}) {
    const classes = useStyles({mode});

    const formik = useFormik({
        initialValues: {
            username: '',
            // password: '',
        },
        onSubmit: async values => {
        },
    });

    return (
        <Box
            className={[classes.dialogRectContent, classes.dialogRectContentSignUp]}
        >
            <Typography variant="h4">
                Sign up
            </Typography>
            <Box className={classes.dialogRectForm}>
                <FormikInput
                    formik={formik}
                    name="username"
                    type="text"
                    color="primary"
                    label="Phone"
                />
                <FormikInput
                    formik={formik}
                    name="username"
                    type="text"
                    color="primary"
                    label="Email"
                />
                <FormikInput
                    formik={formik}
                    name="username"
                    type="text"
                    color="primary"
                    label="Password"
                />
                <FormikInput
                    formik={formik}
                    name="username"
                    type="text"
                    color="primary"
                    label="Repeat password"
                />
                <FormikInput
                    formik={formik}
                    name="emailmarketing"
                    type="checkbox"
                    color="primary"
                    labelPlacement="start"
                    label="Send information to my email"
                />

            </Box>
            <CTAButton
                color={"primary"}
                className={classes.submitButton}
                onClick={() => {
                    register();
                }}
            >
                Submit
            </CTAButton>
        </Box>
    )
} 

function LogInContent({mode}) {
    const classes = useStyles({mode});
    const formik = useFormik({
        initialValues: {
            username: '',
            // password: '',
        },
        onSubmit: async values => {
        },
    });
    return (
        <Box
            className={[classes.dialogRectContent, classes.dialogRectContentLogIn]}
        >
            <Typography variant="h4">
                Log in
            </Typography>
            <Box className={classes.dialogRectForm}>
                <FormikInput
                    formik={formik}
                    name="username"
                    type="text"
                    color="primary"
                    label="Email"
                />
                <FormikInput
                    formik={formik}
                    name="username"
                    type="text"
                    color="primary"
                    label="Password"
                />
            </Box>
            <CTAButton
                color={"primary"}
                className={classes.submitButton}
            >
                Submit
            </CTAButton>
        </Box>
    )
}

export default function TvTransAuthorization() {
    const {isOpened, mode} = useSelector(state => state.tvtrans.auth);
    console.log(isOpened, mode)
    const classes = useStyles({mode});
    // const [isOpened, setOpened] = useState(true);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(toggleAuthWindow(false))
    }

    const setLoginMode = () => {
        dispatch(toggleAuthMode("login"))
    }

    const setSignupMode = () => {
        dispatch(toggleAuthMode("signup"))
    }

    return (
        <Backdrop
            open={isOpened}
            style={{zIndex: 20}}
        >
            <div
                onClick={onClose}
                className={classes.backdropController}
            />
            <Box
               className={classes.container}
            >
                <IconButton
                    onClick={onClose}
                    className={classes.closeButton}
                >
                    <CloseRoundedIcon/>
                </IconButton>

                <Box className={[classes.dialogLabel, classes.dialogLabelLeft]}>
                    <Box className={classes.dialogLabelTitle}>
                        Log in
                    </Box>
                    <Box className={classes.dialogLabelDescription}>
                        If you already have account, you can log in
                    </Box>
                    <CTAButton
                        variant="outlined"
                        className={classes.changeModeButton}
                        onClick={setLoginMode}
                    >
                        Log in
                    </CTAButton>
                </Box>
                <Box className={[classes.dialogLabel, classes.dialogLabelRight]}>
                    <Box
                        className={classes.dialogLabelTitle}
                    >
                        Sign up
                    </Box>
                    <Box className={classes.dialogLabelDescription}>
                        If you still don't have an account, you can create it!
                    </Box>
                    <CTAButton
                        variant="outlined"
                        className={classes.changeModeButton}
                        onClick={setSignupMode}
                    >
                        Sign up
                    </CTAButton>
                </Box>

                <Box
                    className={classes.dialogRect}
                >
                    <SignUpContent mode={mode}/>
                    <LogInContent mode={mode}/>
                </Box>
            </Box>
        </Backdrop>
    )
}
