// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import Image from 'components/atoms/Image/Image';
import CTAButton from 'components/atoms/CTAButton/CTAButton';
import YtModal from 'components/organisms/Modals/YtModal';
import Skeleton from '@material-ui/lab/Skeleton';
import Picture from 'components/molecules/Picture/Picture';
import parse from "html-react-parser";
import PlaneIcon from 'assets/svg/PlaneIcon';

const useStyles = makeStyles(theme => ({
  box: {
    minHeight: ({inlineHeight}) => inlineHeight ? 'none' : '80vh',
    padding: theme.spacing(10, 2),
    display: 'flex',
    flexDirection: ({matches}) => !matches ? 'column' : 'row',
    alignItems: ({ left, matches }) => matches ? (left ? left ? 'center' : 'flex-start'  : 'center') : 'center',
    justifyContent: ({ left, matches }) => matches ? (left ? 'flex-start' : 'center') : 'center',
    position: 'relative',
    backgroundColor: 'hsl(0, 0%, 9%)',
    textAlign: 'center',
    overflow: 'hidden',
  },
  left: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '60%',
      marginLeft: '10%',
      textAlign: 'left',
    },
  },
  picture: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    objectFit: 'cover',
  },
  video: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    minWidth: '100%',
    minHeight: '100%',
    transform: 'translate(-50%, -50%)',
  },
  heading: {
    textAlign: 'inherit',
    color: 'white',
    lineHeight: 1,
    textShadow: ({hasTextShadow}) => hasTextShadow && `0 1px 25px black, 0 1px 25px black, 0 1px 25px black`,
    [theme.breakpoints.up('md')]: {
      marginLeft: ({ left }) => left && theme.spacing(1),
      fontSize: ({ headingSize }) => headingSize,
    },
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  content: ({ left }) => ({
    maxWidth: 1000,
    margin: theme.spacing(2.5, left ? 1 : 'auto', 0),
    display: 'flex',
    justifyContent: !left && 'center',
    textAlign: 'inherit',
    color: 'white',
  }),
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: ({ left }) => (left ? 'flex-start' : 'center'),
    },
  },
  skeleton: {
    backgroundColor: 'hsl(0deg 0% 100% / 20%)',
    borderRadius: theme.shape.borderRadius,
  },
  skeletonHeading: {
    width: '90vw',
    height: 150,
    margin: theme.spacing(-4, 0),
    [theme.breakpoints.up('md')]: {
      width: '80vw',
      height: 100,
      margin: 0,
    },
  },
  skeletonContent: {
    width: '90vw',
    height: 75,
    [theme.breakpoints.up('md')]: {
      width: '60vw',
      height: 60,
    },
  },
  skeletonBtn: {
    width: 100,
    height: 35,
    [theme.breakpoints.up('md')]: {
      width: 150,
      height: 50,
    },
  },
  pictureBox: {
    flex: '.5',
    minWidth: '50%',
    // width: ({matches}) => matches ? '100%' : '70%',
    paddingLeft: 15,
    paddingTop: ({matches}) => matches ? 0 : 40,
  }
}));

// section located in the top of landing pages
const HeroSection = ({ hasTextShadow, className, data, headingSize, left, youtube, largerButtons, inlineHeight, ...props }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles({ left, matches, inlineHeight, headingSize, hasTextShadow });
  const [open, setOpen] = useState(false);
  
  // video or image that will be displayed on background depending on what is provided
  const videoSrc = data.isNoStrapi ? data.video : `https://strapi.fastcast4u.com${data.video}`;
  const imageSrc = data.isNoStrapi ? data.image : `https://strapi.fastcast4u.com${data.image}`;
  
  return (
    <Box className={`${classes.box} ${className}`} component="section" {...props}>
      {data.id &&
        (data.video && matches ? (
          <video
            className={classes.video}
            src={videoSrc}
            autoPlay
            muted
            loop
          />
        ) : data.image && (
          <Image
            className={classes.picture}
            src={imageSrc}
            alt=""
          />
        ))}
      <Box className={left ? classes.left : ''} zIndex={1}>
        <Text className={classes.heading} component="h1" variant={data.contentPicture ? 'h3' : 'h2'}>
          {data.id ? (
            data.heading
          ) : (
            <Skeleton className={`${classes.skeleton} ${classes.skeletonHeading}`} variant="text" />
          )}
        </Text>
        {data.id ? (
          data.content && (
            <Text className={classes.content} variant="h4">
              {data.content}
            </Text>
          )
        ) : (
          <Text className={classes.content} variant="h4">
            <Skeleton className={`${classes.skeleton} ${classes.skeletonContent}`} variant="text" />
          </Text>
        )}
        {data.id ? (
          (data.buttons || youtube.label) && (
            <Box mt={4} display="flex" className={classes.buttonContainer}>
              {data.buttons &&
                data.buttons.map(({ label, iconElement, ...props }) => (
                  <CTAButton
                    variant="contained"
                    xlarge={!largerButtons}
                    xlarger={largerButtons}
                    {...props}
                    key={label}

                    iconElement={iconElement}
                  >
                    {label}
                  </CTAButton>
                ))}
              {youtube.label && (
                <CTAButton
                  variant="contained"
                  color="secondary"
                  xlarge
                  onClick={() => setOpen(true)}
                  {...props}
                >
                  {youtube.label}
                </CTAButton>
              )}
            </Box>
          )
        ) : (
          <Box mt={4} display="flex" className={classes.buttonContainer}>
            <Skeleton className={`${classes.skeleton} ${classes.skeletonBtn}`} variant="rect" />
          </Box>
        )}
      </Box>
      {data.contentPicture && 
        <Box className={classes.pictureBox}>
          <Picture mobile={data.contentPicture}/>
        </Box>
      }
      {youtube.url && <YtModal open={open} onClose={() => setOpen(false)} url={youtube.url} />}
    </Box>
  );
};

HeroSection.defaultProps = {
  className: '',
  left: false,
  inlineHeight: false,
  youtube: {},
};

HeroSection.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    pictures: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
      alt: PropTypes.string,
    }),
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  left: PropTypes.bool,
  inlineHeight: PropTypes.bool,
  youtube: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default HeroSection;
