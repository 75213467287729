import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const OrderAppPackage = lazy(() => import('views/OrderApp/OrderAppPackage/OrderAppPackage'));
const OrderAppLogin = lazy(() => import('views/OrderApp/OrderAppLogin/OrderAppLogin'));
const OrderAppPayment = lazy(() => import('views/OrderApp/OrderAppPayment/OrderAppPayment'));
const OrderAppDetails = lazy(() => import('views/OrderApp/OrderAppDetails/OrderAppDetails'));
const OrderAppSuccess = lazy(() => import('views/OrderApp/OrderAppSuccess/OrderAppSuccess'));
const OrderAppPending = lazy(() => import('views/OrderApp/OrderAppPending/OrderAppPending'));

//children routes displayed on /OrderApp
const OrderAppRoutes = [
  <Route index element={<Navigate to="package" />} />,
  <Route path={`package`} element={<OrderAppPackage />} />,
  <Route path={`login`} element={<OrderAppLogin />} />,
  <Route path={`payment`} element={<OrderAppPayment />} />,
  <Route path={`details`} element={<OrderAppDetails />} />,
  <Route path={`success`} element={<OrderAppSuccess />} />,
  <Route path={`pending`} element={<OrderAppPending />} />
];

export default OrderAppRoutes;
