import React from 'react';
import { Route } from 'react-router-dom';
import TV from 'views/TV/TV';
import TVPromo from 'views/TV/TVPromo';

//children routes for /app page. Used to divide /app page and its promo version /app/promo
const TvRoutes = [
  <Route index element={<TV/>} />,
  <Route path={`promo`} element={<TVPromo/>} />
]

export default TvRoutes;
