import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import generalReducer from 'redux/reducers/generalReducer';
import orderReducer from 'redux/reducers/orderReducer';
import directoryReducer from 'redux/reducers/directoryReducer';
import languageReducer from 'redux/reducers/languageReducer';
import cmsReducer from 'redux/reducers/cmsReducer';
import orderTvReducer from 'redux/reducers/orderTvReducer';
import orderAppReducer from 'redux/reducers/orderAppReducer';
import accountReducer from 'redux/reducers/accountReducer';
import tvtransReducer from 'redux/reducers/tvtrans';

const generalPersistConfig = {
  key: 'general',
  storage,
  blacklist: ['chat', 'alert', 'theme'],
};

const orderPersistConfig = {
  key: 'order',
  storage,
  blacklist: ['loading', 'promo', 'specialPopup', 'products', 'others', 'othersSummary', 'addonGroups', 'addons', 'invalidPromocode'],
};

const orderTvPersistConfig = {
  key: 'orderTv',
  storage,
  blacklist: ['loading', 'promo', 'products', 'addons', 'invalidPromocode', ],
};

const orderAppPersistConfig = {
  key: 'orderTv',
  storage,
  blacklist: ['loading', 'promo', 'selectBlock', 'products', 'addons', 'invalidPromocode', ],
};

const accountPersistConfig = {
  key: 'account',
  storage,
  blacklist: ['loading', 'products'],
};

const rootReducer = combineReducers({
  general: persistReducer(generalPersistConfig, generalReducer),
  order: persistReducer(orderPersistConfig, orderReducer),
  orderTv: persistReducer(orderTvPersistConfig, orderTvReducer),
  orderApp: persistReducer(orderAppPersistConfig, orderAppReducer),
  account: persistReducer(accountPersistConfig, accountReducer),
  directory: directoryReducer,
  language: languageReducer,
  cms: cmsReducer,
  tvtrans: tvtransReducer,
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export const persistor = persistStore(store);
export default { store, persistor };
