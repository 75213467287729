import CTAButton from "components/atoms/CTAButton/CTAButton";
import { TvTransChannelsList } from "components/molecules/TvTransChannelsList/TvTransChannelsList";

const { Paper, makeStyles, Typography, Button } = require("@material-ui/core");

const useStyles = makeStyles(theme => ({
    leftPanel: {
        width: "270px",
        display: "flex",
        height: "100%",
        borderRadius: "0",
        flexDirection: "column",
        padding: "10px",

        [theme.breakpoints.down('lg')]: {
            width: "max-content",
            padding: "0",
        },
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    header: {
        fontSize: "16px",
        fontWeight: "bold",

        [theme.breakpoints.down('lg')]: {
            display: "none",
        }
    }
}))

export const TvTransLeftPanel = () => {
    const classes = useStyles();
    return (
        <Paper
            elevation={2}
            className={classes.leftPanel}
        >
            <Typography className={classes.header}>Recomended channels</Typography>
            <TvTransChannelsList/>
        </Paper>
    )
}
