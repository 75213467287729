import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'views/Root/Root';

const storageVersion = '2.16';

if(!localStorage.getItem(`storeCleared-v${storageVersion}`)){
    localStorage.clear();
    localStorage.setItem(`storeCleared-v${storageVersion}`, 'true')
}

ReactDOM.render(<Root />, document.getElementById('root'));
