import React, { useState, useMemo, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Container, makeStyles, Grid, Chip, Box, Button, Tabs, Tab, useMediaQuery } from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';
// import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
// import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
// import SurroundSoundOutlinedIcon from '@mui/icons-material/SurroundSoundOutlined';
// import RadioOutlinedIcon from '@mui/icons-material/RadioOutlined';
// import { Row, Card, Col, Alert, Button, Badge, Media } from "react-bootstrap";
// import img from '../../../images/card/3.png'
import plusCircleIcon from '../../assets/img/plus_circle.svg';
import loginIcon from '../../assets/img/login.webp';
import monitorIcon from '../../assets/img/monitor.webp';
import { Alert, AlertTitle, Pagination } from '@material-ui/lab';
import styled, { css, useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import SurroundSoundOutlinedIcon from '@mui/icons-material/SurroundSoundOutlined';
import RadioOutlinedIcon from '@mui/icons-material/RadioOutlined';
import Text from 'components/atoms/Text/Text';
import accountActions from 'redux/actions/accountActions';
import loginServices from 'services/login'
import axios from 'axios';

import parse from 'html-react-parser'

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    overflow: hidden;
  `}
`;

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20,
        [theme.breakpoints.down(768)]: {
            padding: 10,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flex: '1 0 0',
        },
        [theme.breakpoints.down(900)]: {
            minWidth: '100%',
        },
    },
    advert: {
        position: 'relative',
        padding: '10px 15px',
        flex: '1 0 0',
        fontSize: '13px',
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            flexBasis: 300,
        },
        [theme.breakpoints.up('xl')]: {
            flexBasis: 320,
        },
        backgroundColor: '#00A2F7',
        height: 'max-content',
        borderRadius: '15px',
        color: 'white',
        margin: '5px 10px',
    },
    advertButton: {
        backgroundColor: 'white',
        margin: '3px 0',
        color: '#00A2F7',
        '&:hover': {
            background: 'white',
        },
        borderRadius: '10px',
        fontSize: '12px',
    },
    advertContent: {
        width: '100%',
        margin: '7px 0',
    },
    advertTitle: {
        fontWeight: 500,
        fontSize: '15px',
        padding: '0 0 10px 0',
        position: 'relative',
        '&::after': {
            position: 'absolute',
            opacity: 0.5,
            bottom: 0,
            content: '""',
            margin: '0 -15px',
            display: 'block',
            width: 'calc(100% + 30px)',
            height: '1px',
            backgroundColor: '#fff',
        },
    },
    advertAdditionalInfo: {
        margin: '15px 0 5px 0',
    },
    advertIframe: {
        width: '100%',
        height: '100%',
        overflow: 'visible',
        zoom: 0.2,
        '-moz-transform': 'scale(0.3)',
        '-moz-transform-origin': '0 0',
        '-o-transform': 'scale(0.2)',
        '-o-transform-origin': '0 0',
        // '-webkit-transform': 'scale(0.3)',
        '-webkit-transform-origin': '0 0',
    },
    ActiveTogglerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        [theme.breakpoints.down(426)]: {
            justifyContent: 'center',
            alingItems: 'center',
            width: 'max-content',
            margin: '0 auto',
            flexDirection: 'column',
        },
        margin: '0 15px 15px 15px'
    },
    ActiveTab: {
        minWidth: '0 !important',
        [theme.breakpoints.down(426)]: {
            width: 'max-content',
        },
    },
    Products: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    ProductWrapper: {
        width: '50%',
        minWidth: 300,
        padding: 8,
        [theme.breakpoints.down(700)]: {
            minWidth: '100%',
        },
        [theme.breakpoints.down('md')]: {
            flex: '1 0 0',
        },
    },
    Product: {
        display: 'flex',
        borderRadius: '10px',
        overflow: 'hidden',
        height: '100%',
        boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
        backgroundColor: theme.palette.background.main,
    },
    ProductIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00A2F7',
        color: 'white',
        padding: '0 10px',
        fontSize: '2.1rem',
        [theme.breakpoints.down(500)]: {
            fontSize: '1.7rem',
            padding: '0 7px',
        },
    },
    ProductInfoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flex: 1
    },
    ProductInfo: {
        flex: 1,
        padding: '10px',
        '&>div': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& $h5': {
            fontSize: '1.2rem',
            fontWeight: 500
        },
        '& $h6': {
            fontSize: '1.1rem',
            fontWeight: 400
        },
        '& $p': {
            marginTop: 'auto'
        },
    },
    ProductName: {
        padding: '3px 0',
        lineHeight: 1.1,
        fontSize: 16,
    },
    ProductButtons: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: ({ background }) => background === 'dark' ? '#f1f1f1' : '#e2e2e2',
    },
    ProductButton: {
        flex: 1,
        '& $button': {
            display: 'flex',
            justifyContent: 'center',
            padding: '.5rem',
            width: '100%',
            color: '#000000de',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: '#c9c9c9',
            },
            '&>span>span': {
                display: 'flex',
                alignItems: 'center',
                '&>svg': {
                    marginRight: '5px',
                }
            }
        },
    },
    Badge: {
        paddingLeft: '10px',
        '&>div': {
            width: '100%',
            color: 'white',
        }
    },
    Active: {
        backgroundColor: theme.palette.success.main
    },
    Terminated: {
        backgroundColor: theme.palette.text.secondary,
        color: ({ background }) => background === 'dark' ? 'black !important' : 'inherit'
    },
    Suspended: {
        backgroundColor: theme.palette.error.main
    },
    Pending: {
        backgroundColor: theme.palette.warning.dark
    },
    Canceled: {
        backgroundColor: theme.palette.text.secondary,
        color: ({ background }) => background === 'dark' ? 'black !important' : 'inherit'
    },
    AlertButton: {
        backgroundColor: '#FF4C41',
        marginTop: '5px',
    },
    Pagination: {
        margin: 10,
        '& $ul': {
            justifyContent: 'center'
        }
    },
    ServiceCardSkeletonContainer: {
        width: '50%',
        padding: '8px',
    },
    ServiceCardSkeleton: {
        // position: "absolute",
        // top: 0,
        // bottom: 0,
        // left: 0,
        width: "100%",
        height: 149,
        borderRadius: '10px',
        boxSizing: 'border-box',
    }
}));

const ServiceCardSkeleton = () => {
    const classes = useStyles();

    return (
        <div className={classes.ServiceCardSkeletonContainer}>
            <Skeleton height={149} className={classes.ServiceCardSkeleton} variant="rect"/>
        </div>
    )
}

const ServiceCard = ({product}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const products = useSelector(state => state.account.products);
    const TypeIcon = DesktopWindowsIcon
    // useMemo(() => {
    //     switch(product.groupname) {
    //         case 'Apps':
    //             return PhoneAndroidIcon;
    //         case 'TV Hosting':
    //             return DesktopWindowsIcon;
    //         case 'Radio Hosting':
    //             return RadioOutlinedIcon;
    //         case 'alexa':
    //             return SurroundSoundOutlinedIcon;
    //     }
    // }, [product])

    const setActiveProductHandler = (prodId) => {
        dispatch(accountActions.setActiveProduct(products.find(({id}) => id === prodId)));
        navigate(`/account/${prodId}`);
    }

    return (
        <div key={product.id} className={classes.ProductWrapper}>
            <Grid container className={classes.Product}>
                <Grid item className={classes.ProductIcon}>
                    <TypeIcon key={Math.random} fontSize="inherit"/>
                </Grid>
                <Grid item className={classes.ProductInfoWrapper}>
                    <Grid container className={classes.ProductInfo}>
                        <Grid item xs={8}>
                            <Text variant='h5'>{product.groupname}</Text>
                            <Text className={classes.ProductName}>{product.name}&nbsp;</Text>
                            <Text component='p'>{product.username}</Text>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.Badge}><Chip className={classes[product.status]} label={product.status} /></div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.ProductButtons}>
                        <Grid item className={classes.ProductButton} onClick={() => setActiveProductHandler(product.id)}>
                            <Button>
                                <span>
                                    <ControlPointIcon color='primary' />
                                    <span>Details</span>
                                </span>
                            </Button>
                        </Grid>
                        {!(product.groupname === 'Apps') && <Grid item style={{ borderLeft: '1px solid #887B7B' }} className={classes.ProductButton}>
                            <Button>
                                <span>
                                    <ExitToAppIcon color='primary' />
                                    <span>Login</span>
                                </span>
                            </Button>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const Dashboard = () => {
    const theme = useTheme();
    const isPaginationSmall = useMediaQuery(theme.breakpoints.down(500));
    const background = useSelector(state => state.general.theme);
    const {currentPage, token} = useSelector(state => state.account);
    const products = useSelector(state => state.account.products || []);
    const announcements = useSelector(state => state.account.announcement || []);
    const classes = useStyles({ background });
    const [showAll, setShowAll] = useState(0);
    const pageSize = 4;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(location.search);
    const [detailsLoading, setDetailsLoading] = useState(false);
    
    const productsToDisplay = useMemo(() => {
        if(products.length === 0) {
            return []
        };

        if(!showAll) {
            return products.filter(({status}) => status === 'Active')
        }
        
        return products
    }, [showAll, products])
    
    const pagesCount = useMemo(() => {
        const result = Math.ceil(productsToDisplay.length/4)
        if(currentPage > result){
            dispatch(accountActions.setCurrentPage(result));
        }
        return result
    }, [productsToDisplay]);
    const productsPage = useMemo(() => productsToDisplay.length > 0 ? productsToDisplay.slice((currentPage-1) * pageSize, currentPage * pageSize) : [],
        [products, currentPage, showAll]
    );

    console.log('prodpage', productsPage);

    // useEffect(() => {
    // //     (async () => {
    // //         setDetailsLoading(true);
    // //         const data = await dispatch(loginServices.login(location.search))
    // //         if(data.data?.oauth_url) {
    // //             window.location.href = data.data.oauth_url
    // //         } else {
    // //             searchParams.delete('code')
    // //             searchParams.delete('state')
    // //             navigate({search: searchParams.toString()})
    // //             setDetailsLoading(false);
    // //             dispatch(loginServices.getProductGroups(data.token.value))
    // //         }
    // //     })()
    // // }, [])

    const showAllChangeHandler = (e, value) => {
        setShowAll(value);
    }
    
    const changePageHandler = (e, page) => {
        dispatch(accountActions.setCurrentPage(page));
    }

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item className={classes.content}>
                        <Box m='15px'>
                            <Alert severity="info">
                                <AlertTitle>Info</AlertTitle>
                                This is an info alert — <strong>check it out!</strong><br />
                                <Button className={classes.AlertButton}>Click me</Button>
                            </Alert>
                        </Box>
                        <Box className={classes.ActiveTogglerWrapper}>
                            <div>
                                <Text variant="h4">Your Services</Text>
                            </div>
                            <div>
                                <Tabs
                                    value={showAll}
                                    onChange={showAllChangeHandler}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab key="0" label={'Active'} value={0} className={classes.ActiveTab} />
                                    <Tab key="1" label={'Show All'} velue={1} className={classes.ActiveTab} />
                                </Tabs>
                            </div>
                        </Box>
                        {/* <Box className={classes.Pagination}>
                            <Pagination 
                                size={isPaginationSmall ? 'small' : 'medium'} 
                                count={pagesCount} 
                                page={currentPage}
                                onChange={changePageHandler} 
                                color="primary" 
                            />
                        </Box> */}
                        <Box m='7px' className={classes.Products}>
                            {!detailsLoading
                                ?
                                    productsPage.map(product => (
                                        <ServiceCard key={product.id} product={product} />
                                    ))
                                :
                                    <>
                                        <ServiceCardSkeleton/>
                                        <ServiceCardSkeleton/>
                                        <ServiceCardSkeleton/>
                                        <ServiceCardSkeleton/>
                                    </>
                            }
                        </Box>
                        {!detailsLoading && (
                                <Box className={classes.Pagination}>
                                    <Pagination 
                                        size={isPaginationSmall ? 'small' : 'medium'} 
                                        count={pagesCount} 
                                        page={currentPage}
                                        onChange={changePageHandler} 
                                        color="primary" 
                                    />
                                </Box>
                            )
                        }
                    </Grid>
                    <Grid item xs={3} className={classes.advert}>
                        {/* <iframe className={classes.advertIframe} srcDoc={announcements[0].announcement}/> */}
                        <Box className="text-white bg-info">
                            <Box>
                                <Box className={classes.advertTitle} >{announcements[0]?.title}</Box>
                            </Box>
                            <Box className=" mb-0">
                                <Box className={classes.advertContent}>
                                </Box>
                                <Box
                                    as="a"
                                    variant="info "
                                    href="#"
                                    className="btn-card mt-3"
                                >
                                    <Button size="medium" disableElevation disableFocusRipple className={classes.advertButton} variant='contained'>
                                        Go somewhere
                                    </Button>
                                </Box>
                            </Box>
                            <Box className=" bg-transparent border-0 text-white">
                                <Box className={classes.advertAdditionalInfo}>
                                    Last updateed 3 min ago
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default Dashboard;