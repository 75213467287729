import React from 'react';
import { Route } from 'react-router-dom';
import AlexaSkill from 'views/AlexaSkill/AlexaSkill';
import AlexaSkillPromo from 'views/AlexaSkill/AlexaSkillPromo';

//children routes for /alexa-skill page. Used to divide /app page and its promo version /alexa-skill/promo
const AlexaSkillRoutes = [
  <Route index element={<AlexaSkill/>} />,
  <Route path={`promo`} element={<AlexaSkillPromo/>} />
];

export default AlexaSkillRoutes;
