import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

const steps = [
  {
    step: 1,
    href: '/orderTv/package',
  },
  {
    step: 2,
    href: '/orderTv/login',
  },
  {
    step: 3,
    href: '/orderTv/payment',
  },
  {
    step: 4,
    href: '/orderTv/details',
  },
  {
    step: 5,
    href: '/orderTv/pending',
  },
];

const getHref = step => steps.find(el => el.step === step).href;

const OrderTvAccessController = ({ currentStep }) => {
  const {step} = useSelector(state => state.orderTv);
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (step < currentStep) return setRedirect(true);
  }, [currentStep]);

  useEffect(() => {
    if(redirect) {
      navigate({pathname: getHref(step), search: location.search})
    }
  }, [redirect])

  return <></>
};

OrderTvAccessController.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default OrderTvAccessController;
