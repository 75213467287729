import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Chip, Box, Button, Divider, TextField, Menu, MenuItem, Fade, FormControlLabel, Checkbox } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import Text from 'components/atoms/Text/Text';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
  `}
`;

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const MenuItems = [
    {
        title: 'Account 1',
        // to: '/account/details'
    },
    {
        title: 'Account 2',
        // to: '/account/contactdetails'
    },
    {
        title: 'Account 3',
        // to: '/account/addcontact'
    },
    {
        title: 'Add Sub-Account',
        // to: '/account/changepassword'
    }
];

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20
    },
    Title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 20px 20px 20px',
    },
    ContentWrapper: {
        width: '100%',
        padding: '0 10px 10px 10px',
        '&>div': {
            '&>div': {
                padding: 10,
                [theme.breakpoints.down(768)]: {
                    minWidth: '100%',
                },
                '&>div': {
                    width: '100%',
                }
            }
        }
    },
    Checkboxes: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0 10px 10px 10px',
        '&>*:not(div)': {
            padding: 10,
        },
        '&>$label': {
            padding: '0 10px',
        },
        '&>div': {
            margin: 10,
        }
    },
    Buttons: {
        display: 'flex',
        margin: '5px !important',
        '&>*': {
            margin: 5,
        },
        [theme.breakpoints.down(768)]: {
            justifyContent: 'center',
        },
    },
    BorderTop: {
        borderTop: ({ background }) => background === 'dark' ? '1px solid white' : '1px solid hsl(0, 0%, 9%)',
    },
    Menu: {
        '& $ul': {
            padding: 0
        }
    }
}));

const textFields = [
    {
        size: 6,
        label: 'First name'
    },
    {
        size: 6,
        label: 'Last name'
    },
    {
        size: 7,
        label: 'Company name(Optional)'
    },
    {
        size: 5,
        label: 'Phone Number'
    },
    {
        size: 12,
        label: 'Email'
    },
    {
        size: 8,
        label: 'Address'
    },
    {
        size: 4,
        label: 'City'
    },
    {
        size: 12,
        label: 'Address 2(Optional)'
    },
    {
        size: 4,
        label: 'Country'
    },
    {
        size: 4,
        label: 'State'
    },
    {
        size: 4,
        label: 'Zip'
    },
]

const AddContact = () => {
    const background = useSelector(state => state.general.theme);
    const classes = useStyles({ background });
    const [menuElement, setMenuElement] = useState(null);
    const [selectedItem, setSelectedItem] = useState('Select');
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [isActiveSubAccount, setIsActiveSubAccount] = useState(false);

    // opens dropdown menu with action selector
    const openMenu = e => {
        setMenuElement(e.currentTarget);
    }

    const closeMenu = (title) => {
        setSelectedItem(typeof (title) === 'string' ? title : selectedItem);
        setMenuElement(null);
    }

    // toggles isAddingNew state variable depending on action selected in dropdown menu.
    //If 'Add Sub-Account' is selected, isAddingNew will be set to true
    useEffect(() => {
        setIsAddingNew(selectedItem === 'Add Sub-Account');
    }, [selectedItem]);

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item className={classes.Title}>
                        <Text component='h4' variant='h5'>Sub-accounts</Text>
                    </Grid>
                    <Grid item className={classes.Title}>
                        <>
                            <Button
                                variant='contained'
                                color='primary'
                                aria-controls="accounts-menu"
                                aria-haspopup="true"
                                onClick={openMenu}
                            >
                                {selectedItem}
                            </Button>
                            <Menu
                                id="accounts-menu"
                                anchorEl={menuElement}
                                keepMounted
                                open={!!menuElement}
                                onClose={closeMenu}
                                TransitionComponent={Fade}
                                className={classes.Menu}
                            >
                                {MenuItems.map((item, i, array) => (
                                    <MenuItem key={i} onClick={() => closeMenu(item.title)} className={(i === array.length-1) && classes.BorderTop}>{item.title}</MenuItem>
                                ))}
                            </Menu>
                        </>
                    </Grid>
                    <Grid item className={classes.ContentWrapper}>
                        <Grid container>
                            {textFields.map((field, i) => (
                                <Grid item xs={field.size} key={i}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label={field.label}
                                        variant="outlined"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item className={classes.Checkboxes}>
                        <Text component='h5' variant='h6'>Active Sub-account</Text>
                        <FormControlLabel
                            control={<GreenCheckbox checked={isActiveSubAccount} onChange={() => setIsActiveSubAccount(!isActiveSubAccount)} name="checkedG" />}
                            label="Tick to configure as a sub-account with client area access"
                        />
                        {isActiveSubAccount && <>
                            <TextField
                                required
                                id="outlined-required"
                                label="Password"
                                variant="outlined"
                                type='password'
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Confirm Password"
                                variant="outlined"
                                type='password'
                            />
                        </>}
                        <Text component='h5' variant='h6'>Email Contact</Text>
                        <FormControlLabel
                            control={<GreenCheckbox checked={true} onChange={() => { }} name="checkedG" />}
                            label="General Emails - General Announcements & Password Remiders"
                        />
                        <FormControlLabel
                            control={<GreenCheckbox checked={true} onChange={() => { }} name="checkedG" />}
                            label="Product  Emails - General Announcements & Password Remiders"
                        />
                        <FormControlLabel
                            control={<GreenCheckbox checked={true} onChange={() => { }} name="checkedG" />}
                            label="Domain  Emails - General Announcements & Password Remiders"
                        />
                        <FormControlLabel
                            control={<GreenCheckbox checked={true} onChange={() => { }} name="checkedG" />}
                            label="Invoice  Emails - General Announcements & Password Remiders"
                        />
                        <FormControlLabel
                            control={<GreenCheckbox checked={true} onChange={() => { }} name="checkedG" />}
                            label="Support  Emails - General Announcements & Password Remiders"
                        />
                        <Text component='h5' variant='h6'>Phone  Contact</Text>
                        <FormControlLabel
                            control={<GreenCheckbox checked={true} onChange={() => { }} name="checkedG" />}
                            label="SMS Notifications and Phone Contact"
                        />
                        <Box className={classes.Buttons}>{
                            isAddingNew ?
                                <Button variant='contained' color='primary'>Add Sub-account</Button>
                                :
                                <>
                                    <Button variant='contained' color='primary'>Update</Button>
                                    <Button variant='contained' color='secondary'>Delete</Button>
                                </>
                        }</Box>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default AddContact;