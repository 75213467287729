import { SET_ALEXA_DATA, SET_APP_DATA, SET_HOME_DATA, SET_LOGIN_DATA, SET_NOTIFICATION_DATA, SET_ORDER_DATA, SET_ORDER_TV_DATA, SET_SLS_DATA, SET_TOP_BAR_DATA } from "redux/actionTypes/cmsTypes";

const setTopBarData = data => ({
  type: SET_TOP_BAR_DATA,
  payload: data,
});

const setHomeData = data => ({
  type: SET_HOME_DATA,
  payload: data,
});

const setAppData = data => ({
  type: SET_APP_DATA,
  payload: data,
});

const setAlexaData = data => ({
  type: SET_ALEXA_DATA,
  payload: data,
});

const setLoginData = data => ({
  type: SET_LOGIN_DATA,
  payload: data,
});

const setOrderData = data => ({
  type: SET_ORDER_DATA,
  payload: data,
});

const setOrderTvData = data => ({
  type: SET_ORDER_TV_DATA,
  payload: data,
});

const setSLSData = data => ({
  type: SET_SLS_DATA,
  payload: data,
});

const setNotificationData = data => ({
  type: SET_NOTIFICATION_DATA,
  payload: data,
});

export default {
  setTopBarData,
  setHomeData,
  setAppData,
  setAlexaData,
  setLoginData,
  setOrderData,
  setOrderTvData,
  setSLSData,
  setNotificationData,
};
