import { Box, makeStyles } from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Payments from '@mui/icons-material/AddCardOutlined';
import Support from '@mui/icons-material/EmailOutlined';
import CTAButton from 'components/atoms/CTAButton/CTAButton';

const useStyles = makeStyles(theme => ({
    Wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    WrapperInner: {
        display: 'flex',
        width: '100%',
        padding: '0 24px 10px 10px',
        maxWidth: 1280,
    },
    Tab: {
        margin: 4,
        color: 'inherit',
        
        '& $a': {
            padding: '10px 16px',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                color: 'white',
            },
        },
        '& $svg': {
            fontSize: '1.7rem',
            marginRight: '10px',
        },
        '& $p': {
            fontWeight: 500,
        },
    },
    ActiveTab: {
        color: 'white',
    }
}));

const SideBarTabs = [
    {
        name: '',
        icon: <DashboardRoundedIcon/>,
        label: 'Dashboard'
    },
    {
        name: '/payments',
        icon: <Payments/>,
        label: 'Payments & Invoices'
    },
    {
        name: '/supporttickets',
        icon: <Support/>,
        label: 'Support'
    },
];

// top navigation bar for /account page
const Navbar = () => {
    const classes = useStyles();
    let path = window.location.pathname.split('/')[2];
    const [active, setActive] = useState(path ? `/${path}` : '');

    return (
        <Box className={classes.Wrapper}>
            <Box className={classes.WrapperInner}>
                {SideBarTabs.map((tab, i) => (
                    <CTAButton
                        component={Link}
                        variant={active === tab.name ? 'contained' : 'text'}
                        color='primary'
                        key={i}
                        className={`${classes.Tab} ${active === tab.name ? classes.ActiveTab : ''}`} 
                        to={`/account${tab.name}`} 
                        onClick={() => setActive(tab.name)}
                    >
                        {tab.icon}
                        <Box>
                            <Text color='inherit'>{tab.label}</Text>
                        </Box>
                    </CTAButton>
                ))}
            </Box>
        </Box>
    );
};

export default Navbar;