import produce from 'immer';
import { SET_SESSION_UID, PRICING_FETCH_FAIL, SELECT_IDS, REMOVE_ADDON, RESET_ORDER_STORE, SET_ALL_PROMOCODES, SET_CREDENTIALS, SET_CYCLE, SET_PLAN, SET_PRICES, SET_PROMOCODE, TOGGLE_ADDON, TOGGLE_ADDON_EXTRA, TOGGLE_PRODUCT, UNSELECT_PRODUCTS, SET_SPECIAL_POPUP, SET_STEP } from '../actionTypes/orderTypes'

const initialState = {
  loading: true,
  currency: '',
  cycle: 'monthly',
  plan: 'regular',
  allPromocodes: {
    monthly: '',
    annually: '',
    biennially: '',
    onetime: '',
  },
  promocode: '',
  specialPopup: 'Radio25Pop12',
  invalidPromocode: '',
  products: [],
  addons: [],
  others: {
    serverAddons: [],
    mobileApps: [],
    appAddons: [],
  },
  othersSummary: [],
  activeAddonsExtra: [],
  activeProduct: '',
  activeAddons: [],
  // "recurfor" indicates how many billing cycles the discount is running
  recurfor: {},
  email: '',
  password: '',
  addonGroups: {
    serverAddons: {array: ['479'], singleChoice: false},
    mobileApps: {array: ['496', '497', '498'], singleChoice: true},
    appAddons: {array: ['523', '549', '554'], singleChoice: false},
  },
  username: '',
  emailmarketing: false,
  sessionUid: "",
  step: 1,
};

const filterArrayByArrayOfId = (arrayToFilter, arrayOfId) => [...arrayToFilter].filter(({ id }) => arrayOfId.some(addon => +addon === +id))

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICES:
      return produce(state, draftState => {
        const { currency, products, addons, promocode, recurfor, others } = action?.payload;
        const activeProduct = draftState.activeProduct ? draftState.activeProduct : products[0].id;

        const  { serverAddons, mobileApps, appAddons } = draftState.addonGroups;

        const othersArray = others.map(el => ({
          ...el,
          onetime: el.onetime.regular,
          onetimeBasic: el.onetime.regularBasic,
          onetimeBasicOld: el.onetime.regularBasicOld === null ? el.onetime.regularBasic : el.onetime.regularBasicOld,
        }))

        const othersObject = {
          serverAddons: filterArrayByArrayOfId(othersArray, serverAddons.array),
          mobileApps: filterArrayByArrayOfId(othersArray, mobileApps.array),
          appAddons: filterArrayByArrayOfId(othersArray, appAddons.array),
        }
        
        draftState.loading = false;
        draftState.currency = currency;
        draftState.products = products;
        draftState.addons = addons;
        draftState.others = othersObject;
        draftState.othersSummary = othersArray;
        draftState.activeProduct = activeProduct;
        draftState.promocode = promocode || '';
        draftState.recurfor = recurfor;
        draftState.activeAddons = draftState.activeAddons;
        // draftState.activeAddons = activeAddons || draftState.activeAddons;
      });
    case PRICING_FETCH_FAIL:
      return produce(state, draftState => {
        draftState.invalidPromocode = action.payload;
      });
    case TOGGLE_PRODUCT:
      return produce(state, draftState => {
        draftState.activeProduct = action.payload;
      });
    case SET_STEP:
      return produce(state, draftstate => {
        if(draftstate.step < action.payload) {
          draftstate.step = action.payload;
        }
      })
    case TOGGLE_ADDON:
      return produce(state, draftState => {
        const addonGroups = draftState.addonGroups;

        const addonGroupsWithSingleChoice = (
          Object.entries(addonGroups).find(el => (
            el[1].singleChoice && el[1].array.includes(action.payload.toString())
          )) || []
        )[0]

        const activeAddons = draftState.activeAddons;

        if (draftState.activeAddons.includes(action.payload)) {
          if (addonGroupsWithSingleChoice) {
            draftState.activeAddonsExtra = [];  
          }
          const newArray = draftState.activeAddons.filter(id => id !== action.payload);
          draftState.activeAddons = newArray;
        } else if (addonGroupsWithSingleChoice) {
          const selectedGroupArray = addonGroups[addonGroupsWithSingleChoice].array;
          const newArray = activeAddons.filter(id => !selectedGroupArray.includes(id.toString()));
          newArray.push(action.payload);
          draftState.activeAddons = newArray;
        }else {
          draftState.activeAddons.push(action.payload);
          draftState.addons
            .filter(el => el.parent === action.payload)
            .forEach(el => draftState.activeAddons.push(el.relid));
        }
      });
    case TOGGLE_ADDON_EXTRA:
      return produce(state, draftState => {
        if (draftState.activeAddonsExtra.includes(action.payload)) {
          const newArray = draftState.activeAddonsExtra.filter(id => id !== action.payload);
          draftState.activeAddonsExtra = newArray;
        } else {
          draftState.activeAddonsExtra.push(action.payload);
          draftState.addons
            .filter(el => el.parent === action.payload)
            .forEach(el => draftState.activeAddonsExtra.push(el.relid));
        }
      });
    case SELECT_IDS:
      return produce(state, draftState => {
        const productToActivate = action.payload.find((id) => id.length === 4 && id[0] === '2')
        const addonsToActivate = action.payload.filter((id) => id.toString().length === 3)
        const addonsExtraToActivate = action.payload.filter((id) => id.length === 2)

        if(action.payload.includes('p')) {
          draftState.plan = 'premium'
        } else {
          draftState.plan = 'regular'
        }

        if(productToActivate) {
          draftState.activeProduct = productToActivate
        }

        if(addonsToActivate.length > 0) {
          draftState.activeAddons = addonsToActivate
        }

        if(addonsExtraToActivate.length > 0) {
          draftState.activeAddonsExtra = addonsExtraToActivate
        }
      });
    case REMOVE_ADDON:
      return produce(state, draftState => {
        const newArray = draftState.activeAddons.filter(id => id !== action.payload);
        draftState.activeAddons = newArray;
      });
    case SET_CYCLE:
      return produce(state, draftState => {
        draftState.cycle = action.payload;
      });
    case SET_PLAN:
      return produce(state, draftState => {
        draftState.plan = action.payload;
      });
    case SET_PROMOCODE:
      return produce(state, draftState => {
        draftState.promocode = action.payload || '';
      });
    case SET_SESSION_UID:
      return produce(state, draftState => {
        draftState.sessionUid = action.payload.data.session_uid;
      })
    case SET_ALL_PROMOCODES:
      return produce(state, draftState => {
        draftState.allPromocodes = action.payload;
      });
    case SET_SPECIAL_POPUP:
      return produce(state, draftState => {
        draftState.specialPopup = action.payload;
      });
    case SET_CREDENTIALS:
      return produce(state, draftState => {
        const { email, password, username, emailmarketing } = action.payload;
        draftState.email = email;
        draftState.password = password;
        draftState.username = username;
        draftState.emailmarketing = emailmarketing;
      });
    case UNSELECT_PRODUCTS:
      return produce(state, draftState => {
        draftState.activeAddonsExtra = [];
        draftState.activeAddons = [];
      });
    case RESET_ORDER_STORE:
      return initialState;
    default:
      return state;
  }
};

export default orderReducer;
