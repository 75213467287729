import React, { useEffect, useMemo, useState } from 'react';
import { useCurrentLanguage } from 'utils/customHooks';
import { Typography } from '@mui/material';
import { Box, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  errorPage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
    textAlign: 'center'
  },
  errorCode: {
    fontFamily: 'inherit',
    width: 'max-content',
    maxWidth: '90%',
    fontSize: '40px',
    marginTop: '40px !important',
    marginBottom: '20px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '170px',
    },
    fontWidth: '700',
    lineHeight: '1.2',
  },
  errorDescription: {
    width: 'max-content',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
    },
    maxWidth: '90%',
  },
  errorTimer: {
    width: 'max-content',
    paddingTop: '20px',
    fontSize: '17px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    maxWidth: '90%',
  }
}))

const Unsubscribe = () => {
  const lng = useCurrentLanguage();
  const classes = useStyles();
  const [timerValue, setTimerValue] = useState(30);

  // timer in 10 seconds untill redirection
  const downloadTimer = useMemo(() => setInterval(function(){
    setTimerValue(value => value - 1)
  }, 1000), []);

  // redirects user to the main page when the timer is ended
  useEffect(() => {
    if(timerValue <= 0){
      clearInterval(downloadTimer);
      window.location.href = `${lng}/`
    }
  }, [timerValue])

  return (
    // <HeadingBlock title="Error 404: Page not found" subtitle={<Link to={lng}>Return home</Link>} />
    <Box className={classes.errorPage}>
      <Typography component="h1" variant="" className={classes.errorCode}>Unsubscribed - Confirmation</Typography>
      <Typography component="h3" variant="" className={classes.errorDescription}>You will not receive further emails. Thank you!</Typography>
      <Typography component="h3" variant="" className={classes.errorTimer}>You will be redirected in <b>{timerValue}</b> sec to the main page.</Typography>
      <Button style={{marginTop: '10px'}} href={`${lng}/`}>redirect now</Button>
    </Box>
  );
};

export default Unsubscribe;
