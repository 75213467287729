import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        position: "relative",
        paddingBottom: "50%",
        marginBottom: "15px",  
        display: "flex",
        justifyContent: "center"
    },
    containerInSection: {
        width: "100%",
        position: "relative",
        paddingBottom: "50%",
        marginBottom: "15px",  
        display: "flex",
        justifyContent: "center",
        transform: "scale(1.1)",

        [theme.breakpoints.down('sm')]: {
            transform: "scale(1.6)",
        }
    },
    animationContainer: {
        position: "absolute" ,
        height: "100%",
        width: "19% !important",
    },
    leftMobile: {
        transformOrigin: "center",
        position: "absolute",
        height: "90%",
        width: "70px",
        left: "50%",
        top: "50%",
        transform: "translate(-100%, -50%)",
        backgroundImage: 'url(https://fastcast4u.com/images/app/tel1.png)',
        backgroundSize: "auto 100%",
        backgroundPositionX: "16%",
        backgroundRepeat: "no-repeat",
        transition: "0.3s",
    },
    centerMobile: {
        transformOrigin: "center",
        height: "80%",
        // width: "19% !important",
        width: "100% !important",
        position: "absolute",
        borderRadius: "12%",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
        backgroundImage: 'url(https://fastcast4u.com/images/app/tel1.png)',
        backgroundSize: "auto 120%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        zIndex: 2,
        transition: "0.3s",
        transitionDelay: "0.2s",
    },
    rightMobile: {
        transformOrigin: "center",
        height: "90%",
        position: "absolute",
        width: "70px",
        left: "50%",
        top: "50%",
        transform: "translate(0, -50%)",
        backgroundImage: 'url(https://fastcast4u.com/images/app/tel1.png)',
        backgroundSize: "auto 100%",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "84%",
        transition: "0.3s",
    },
    "@global": {
        "div:has(> $container), section:has(> $container)": {
            "&:hover > $container $leftMobile": {
                transform: "scale(110%) translate(-140%, -50%)",
                transitionDelay: "0.1s",
            },
            "&:hover > $container $centerMobile": {
                transform: "scale(120%) translate(-50%, -50%)",
            },
            "&:hover > $container $rightMobile": {
                transform: "scale(110%)  translate(30%, -50%)",
                transitionDelay: "0.2s",
            },
        },
        "section:has(> div $containerInSection) div:has(> div $containerInSection)": {
            "&:hover $containerInSection $leftMobile": {
                transform: "scale(110%) translate(-140%, -50%)",
                transitionDelay: "0.1s",
            },
            "&:hover $containerInSection $centerMobile": {
                transform: "scale(120%) translate(-50%, -50%)",
            },
            "&:hover $containerInSection $rightMobile": {
                transform: "scale(110%)  translate(30%, -50%)",
                transitionDelay: "0.2s",
            },
        }
    },
}))

export default function TelFeatureAnimation({isForSection}) {
    const classes = useStyles();
    return (
        <div className={!isForSection ? classes.container : classes.containerInSection}>
            <div className={classes.animationContainer}>
                <div className={classes.leftMobile}/>
                <div className={classes.centerMobile}/>
                <div className={classes.rightMobile}/>
            </div>
        </div>
    )
}
