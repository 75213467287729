import { Fragment, useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import generalServices from 'services/general';
import { Divider, makeStyles } from '@material-ui/core';
import FullContainer from 'components/atoms/FullContainer/FullContainer';
import TitleSection from 'components/organisms/TitleSection/TitleSection';
import RowSection from 'components/organisms/RowSection/RowSection';
import HeroSection from 'components/organisms/HeroSection/HeroSection';
import PricingTable from 'components/organisms/PricingTable/PricingTable';
import Accordion from 'components/organisms/Accordion/Accordion';
import AppDownloadSection from 'components/organisms/AppDownloadSection/AppDownloadSection';
import { useCurrentLanguage } from 'utils/customHooks';
import { handleExternal } from 'utils/urls';
import ScrollableAnchor from 'react-scrollable-anchor';
import { CheckMore } from 'components/molecules/CheckMore/CheckMore';
import { useNavigate } from 'react-router';
import AppleTvFeatureAnimation from 'components/atoms/AppleTvFeatureAnimation/AppleTvFeatureAnimation';

const useStyles = makeStyles(theme => ({
  list: {
    paddingLeft: 15,
    margin: 0,
    lineHeight: 1.5,

    
    [theme.breakpoints.only('xs')]: {
      listStyle: 'none',
      padding: 0,
      lineHeight: 1.2,
    }
  }
}));

const AppleTv = () => {
  const [prices, setPrices] = useState([]);
  const content = useSelector(state => state.language.appleTV);
  const lng = useCurrentLanguage();
  const blockScrollToRef = useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();

  // requests prices of needed products, to display them in pricing cards
  useEffect(() => {
    const promocode = new URLSearchParams(location.search).get('promo');
    Promise.all([generalServices.getPrice({ pid: 549, promocode: promocode || 'appletvpromo' })]).then(res => setPrices(res));
  }, []);

  //function that scrolls page to main content
  const checkOutMoreHandler = () => blockScrollToRef.current.scrollIntoView({ behavior: 'smooth' });

  const heroData = useMemo(() => ({
    isNoStrapi: true,
    id: 'hero.id',
    heading: content.hero.heading,
    buttons: [
      {
        label: content.hero.buttons[0],
        // onClick: () => handleExternal(navigate,'https://fastcast4u.com/account/index.php?rp=/store/mobile-app/apple-ios-mobile-app-1', lng),
        onClick: () => handleExternal(navigate,'https://fastcast4u.com/orderapp/package?hidesections=0&select=549', lng),
        color: 'primary',
      },
      {
        label: content.hero.buttons[1],
        onClick: checkOutMoreHandler,
        color: 'primary',
      },
    ],
    contentPicture: 'https://img.fastcast4u.com/react/tvapp/tv1',
  }), [content]);

  const pricingData = useMemo(
    () => [
      {
        title: content.pricing[0].title,
        list: content.pricing[0].list,
        link: `/orderapp/package?hidesections=0&select=549`,
        ...prices[0],
      },
    ],
    [content, prices],
  );
  
  const sectionsData = useMemo(
    () => [
      {
        heading: content.sections[0].heading,
        content: `<ul class=${classes.list}>${content.sections[0].content}</ul>`,
        imgReplacement: <PricingTable image={'https://img.fastcast4u.com/react/tv/a1.png'} single={true} {...pricingData[0]} />,
        injectHTML: true
      },
    ],
    [content, pricingData],
  );

  return (
    <>
      <HeroSection data={heroData} inlineHeight/>
      <CheckMore />
      <FullContainer id="more" innerRef={blockScrollToRef} maxWidth="xl" overflowHidden>
        {sectionsData.map((props, i) => (
          <Fragment key={i}>
            <RowSection contentReverse short {...props} reverse={i % 2 === 0} />
            {i + 1 < sectionsData.length && <Divider variant="middle" />}
          </Fragment>
        ))}
      </FullContainer>
      <TitleSection
        primary={content.accordion.heading}
        secondary={content.accordion.description}
      >
        <Accordion data={content.accordion.data} injectHTML/>
      </TitleSection>
      <AppDownloadSection

        element={<AppleTvFeatureAnimation isForSection={true}/>}
        heading={content.download.heading}
        description={content.download.description}
        appleStore="https://apps.apple.com/us/app/fastcast4u-tv-radio/id1606542603"
      />
    </>
  );
};

export default AppleTv;
