import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { StreamItem } from "components/organisms/StreamItem/StreamItem";
import ShowMoreIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useState } from "react";

const useStyles = makeStyles(theme => ({
    ganre: {
        margin: "10px 0",
        padding: "0 30px",
        width: "100%",
        boxSizing: "border-box",
    },
    genreContent: {
        display: "flex",
        height: "max-content",
        display: "grid",
        gap: "13px",
        width: "100%",
        boxSizing: "border-box",
        marginRight: "-20px",
        gridTemplateColumns: ({isForModeration}) => isForModeration ? "1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr",
        alignItems: "center",
        marginTop: "15px",

        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
        },

        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: "1fr 1fr",
        },

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "1fr",
        },
    },
    genreTitle: {
        width :"100%",
        textAlign: "center",
        fontWeight: "bold",
    },
}))

const useShowMoreButtonStyles = makeStyles(theme => ({
    showMoreButtonContainer: {
        flex: 1,
        display: "flex",
        height: "30px",
        alignItems: "center",
        marginTop: "10px",

        "&::before": {
            content: '""',
            flex: 1,
            display: "flex",
            height: "1px",
            background: "rgba(0,0,0,0.3)",
        },

        "&::after": {
            content: '""',
            flex: 1,
            display: "flex",
            height: "1px",
            background: "rgba(0,0,0,0.3)",
        }
    },
    showMoreButton: {
        display: "flex",
    },
}))

function ShowMoreButton({isShowMoreClicked, ...props}) {
    const classes = useShowMoreButtonStyles();

    return (
        <Box
            className={classes.showMoreButtonContainer}
        >
            {!isShowMoreClicked &&
                <Button {...props} className={classes.showMoreButton} endIcon={<ShowMoreIcon/>}>
                    show more
                </Button>
            }
        </Box>
    )
}

export function TvTransGenreSection({title, content, canBePlayedWithSound, setCanBePlayedWithSound, i, itemsToShow, isForModeration}) {
    const classes = useStyles({isForModeration});
    const [isShowMoreClicked, setShowMoreClicked] = useState(false);

    return (
        <Box className={classes.ganre} sx={{order: i}}>
            <Typography variant="h5" className={classes.genreTitle}>{title}</Typography>
            <Box className={classes.genreContent}>
                {((isShowMoreClicked || !itemsToShow) ? content : content.slice(0, itemsToShow)).map((props, index) => <>
                    <StreamItem
                        isForModeration={isForModeration}
                        canBePlayedWithSound={canBePlayedWithSound}
                        setCanBePlayedWithSound={setCanBePlayedWithSound}
                        order={i} index={index} {...props}
                    />
                </>)}
            </Box>
            {itemsToShow &&
                <ShowMoreButton isShowMoreClicked={isShowMoreClicked} onClick={() => setShowMoreClicked(true)} />
            }
        </Box>
    )
}