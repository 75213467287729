// @ts-nocheck
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import generalActions from 'redux/actions/generalActions';

import styled, { css } from 'styled-components';
import { AppBar, Toolbar, Tooltip, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import Logo from 'components/atoms/Logo/Logo';
import SocialBar from 'components/molecules/SocialBar/SocialBar';
import ItemsLeftTopBar from 'components/molecules/ItemsLeftTopBar/ItemsLeftTopBar';
import { useCurrentLanguage } from 'utils/customHooks';

import LightIcon from '@material-ui/icons/Brightness4';
import DarkIcon from '@material-ui/icons/Brightness7';

const StyledAppBar = styled(AppBar)`
  ${({ theme, hidden }) => css`
    padding: 0;
    background-color: ${theme.palette.background.paper};
    display: ${hidden && 'none'};
  `}
`;

const StyledToolbar = styled(Toolbar)`
  ${({ theme }) => css`
    justify-content: flex-end;

    ${theme.breakpoints.up('md')} {
      min-height: ${theme.spacing(10)}px;
    }
  `}
`;

const Header = ({noSocialBar}) => {
  const muitheme = useTheme();
  const navbarEnabled = useMediaQuery(muitheme.breakpoints.up(768));
  const content = useSelector(state => state.language.components.header);
  const theme = useSelector(state => state.general.theme);
  const lng = useCurrentLanguage();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isHidden = pathname.startsWith(`${lng}/login`) || pathname.startsWith(`${lng}/account/payments/`);
  const landingHidden = pathname.includes('promo');
  const showNavbar = window.location.pathname.split('/')[1] === 'account';
  const isAuth = pathname.startsWith(`${lng}/account`);
  const [menuElement, setMenuElement] = useState(null);
	const navigate = useNavigate();

  const handleClick = () => {
    dispatch(generalActions.toggleTheme());
  };
  const openMenu = e => {
    setMenuElement(e.currentTarget);
  }
  
  const closeMenu = (link) => {
    setMenuElement(null);
    navigate(link);
  }

  return (
    <>
      <StyledAppBar color="default" position="static" hidden={isHidden}>
        {!noSocialBar &&
          <SocialBar promo />
        }
        <StyledToolbar>
          <Logo mr="auto" to={`/`} />
          {/* {isDev() && <LanguageButton />} */}
          <Tooltip title={content.toggle}>
            <IconButton color="primary" aria-label="toggle dark theme" onClick={handleClick}>
              {theme === 'light' ? <LightIcon /> : <DarkIcon />}
            </IconButton>
          </Tooltip>
        </StyledToolbar>
        {/* {showNavbar && navbarEnabled && <Navbar />} */}
      </StyledAppBar>
      <ItemsLeftTopBar />
    </>
  );
};

export default Header;
