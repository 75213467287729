import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Button,
  Box,
  makeStyles
} from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import { handleExternal } from 'utils/urls';
import { useNavigate } from 'react-router';
import { useCurrentLanguage } from 'utils/customHooks';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    maxWidth: 800,
    margin: theme.spacing(0, 'auto', 2.5),
    textAlign: 'center',
    fontWeight: 500,
  },
  img: {
    maxWidth: '100%',
    maxHeight: '200px',
    marginBottom: '15px'
  },
  desc: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center !important'
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  listItemIcon: {
    minWidth: theme.spacing(7),
    '& svg': {
      fontSize: 38,
    },
  },
  primary: {
    marginBottom: theme.spacing(0.75),
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  secondary: {
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  }
}));

// section that shows some number of products with their img and description of their features below
const FeatureSectionTv = ({ data, ...props }) => {
  const classes = useStyles();
  const lng = useCurrentLanguage();
  const navigate = useNavigate();

  return (
    <Container className={classes.container} maxWidth="xl" component="section" {...props}>
      <Text className={classes.heading} component="h4" variant="h4">
        {data.heading}
      </Text>
      <Text className={classes.heading} component="h4">
        {data.desc}
      </Text>
      <Grid container spacing={2}>
        {
          data.cards?.map(card => (
            <Grid item className={classes.item} xs={12} md={data.cards.length === 3 ? 4 : 6}>
              {/*product title */}
              <Text className={classes.heading} component="h5" variant="h5">
                {card.title}
              </Text>
              {/*product image*/}
              <img className={classes.img} src={card.img} alt='img'/>
              {/*product features description*/}
              <Text className={classes.desc} component="h5">
                {card.desc}
              </Text>
            </Grid>
          ))
        }
      </Grid>
      <Box className={classes.buttonsContainer}>
        {typeof data.button === 'string' ?
            <Button
              component='button'
              size="large"
              variant="contained"
              color="primary"
              onClick={() => handleExternal(navigate, '/order/package', lng)}
            >
              {data.button}
            </Button>
          :
            <Button
            component='button'
            size="large"
            variant="contained"
            color="primary"
            {...data.button}
            >
              {data.button.label}
            </Button>
        }
      </Box>
    </Container>
  );
};

export default FeatureSectionTv;
