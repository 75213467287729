const { SET_AUTH_OPENED, SET_AUTH_MODE, SET_CHAT_OPENED } = require("redux/actionTypes/tvtrans");

export const toggleAuthWindow = state => ({
    type: SET_AUTH_OPENED,
    payload: state,
});  

export const toggleAuthMode = mode => ({
    type: SET_AUTH_MODE,
    payload: mode,
});

export const toggleChatOpened = mode => ({
    type: SET_CHAT_OPENED,
    payload: mode,
});
