// toggles selected product. The product id should be provided as item argument
export const TOGGLE_PRODUCT_APP = 'ORDER_APP/TOGGLE_PRODUCT';

// toggles selected addon. The addon id should be provided as item argument
export const TOGGLE_ADDON_APP = 'ORDER_APP/TOGGLE_ADDON';

export const DISACTIVATE_BUNDLE_ELEMENTS = 'ORDER_APP/DISACTIVATE_BUNDLE_ELEMENTS';

// the same as toggleAddon, but for extra addons
export const TOGGLE_ADDON_EXTRA_APP = 'ORDER_APP/TOGGLE_ADDON_EXTRA';

export const REMOVE_ADDON_APP = 'ORDER_APP/REMOVE_ADDON';

export const SET_SESSION_UID_APP = 'ORDER_APP/SET_SESSION_UID';

// action to select billing cycle
export const SET_CYCLE_APP = 'ORDER_APP/SET_CYCLE';

// action to select premium or other package
export const SET_PLAN_APP = 'ORDER_APP/SET_PLAN';

export const SET_CONTENT_APP = 'ORDER_APP/SET_CONTENT'

export const SET_PROMOCODE_APP = 'ORDER_APP/SET_PROMOCODE';

export const SET_ALL_PROMOCODES_APP = 'ORDER_APP/SET_ALL_PROMOCODES';

export const SET_CREDENTIALS_APP = 'ORDER_APP/SET_CREDENTIALS';

export const RESET_ORDER_STORE_APP = 'ORDER_APP/RESET_ORDER_STORE';

export const SET_PRICES_APP = 'ORDER_APP/SET_PRICES_APP';

export const SELECT_IDS_APP = 'ORDER_APP/SELECT_IDS';

export const SET_NEW_PRICES_APP = 'ORDER_APP/SET_NEW_PRICES_APP';

export const SET_SPECIAL_POPUP_APP = 'ORDER_APP/SET_SPECIAL_POPUP_APP';

export const PRICING_FETCH_FAIL_APP = 'ORDER_APP/PRICING_FETCH_FAIL_APP';
