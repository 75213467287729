// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { Button, CircularProgress } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import parse from "html-react-parser";

const ButtonContainer = styled.div`
  ${spacing}

  button {
    height: 100%;
  }
`;

const useStyles = makeStyles(theme => ({
  root: ({xlarge, xlarger, hasIcon}) =>
    xlarge && {
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 4),
        paddingLeft: hasIcon ? theme.spacing(2) : theme.spacing(4),
      },
    },
  label: ({xlarge, xlarger}) =>
    xlarge ? {
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 2.8,
      },
    } : xlarger && {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.9,
      [theme.breakpoints.up('lg')]: {
        fontSize: 26,
        fontWeight: 600,
        lineHeight: 2.2,
      },
    },
  progress: {
    position: 'absolute',
  },
  iconContainer: ({xlarge, xlarger, small}) => ({
    width: small ? "20px" : xlarger ? "40px" : "30px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginRight: "10px",
  }),

  "@global": {
    "br.mobile-only": {
      display: "none",
      [theme.breakpoints.down('xs')]: {
        display: "block"
      },
    }
  }
}));

// call to action button
const CTAButton = ({ iconElement, variant, loading, disabled, onClick, children, xlarge, xlarger, small, ...props }) => {
  const classes = useStyles({xlarge, xlarger, hasIcon: !!iconElement, small});

  return (
    <ButtonContainer {...props}>
      <Button
        variant={variant || "contained"}
        classes={{
          root: classes.root,
          label: classes.label,
        }}
        // component={Link}
        disabled={loading || disabled}
        onClick={onClick}
        {...props}
      >
        {!!iconElement &&
          <div className={classes.iconContainer}>
            {React.cloneElement(iconElement, {style: {width: "100%", height: "100%"}})}
          </div>
        }
        {typeof children === "string" ? parse(children) : children}
        {loading && <CircularProgress className={classes.progress} size={32} />}
      </Button>
    </ButtonContainer>
  );
};

CTAButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  xlarge: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CTAButton.defaultProps = {
  xlarge: false,
  loading: false,
  disabled: false,
  onClick: null,
};

export default CTAButton;
