import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

const steps = [
  {
    step: 1,
    href: '/order/package',
  },
  {
    step: 2,
    href: '/order/login',
  },
  {
    step: 3,
    href: '/order/payment',
  },
  {
    step: 4,
    href: '/order/details',
  },
  {
    step: 5,
    href: '/order/pending',
  },
  {
    step: 6,
    href: '/order/success',
  },
];

const getHref = step => steps.find(el => el.step === step).href;

// component that controlls steps sequence. If current step doesn't match the page, the component redirects to the page that should be shown
const OrderAccessController = ({ currentStep }) => {
  // const [cookies] = useCookies(['Fc4uOrder_Session']);
  const step = useSelector(state => state.order.step);
  const [redirect, setRedirect] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    
    // if (!cookies.Fc4uOrder_Session) return setRedirect(true);
    step < currentStep && setRedirect(true);
  }, [step, currentStep]);

  useEffect(() => {
    if(redirect) {
      navigate({pathname: getHref(step), search: location.search})
    }
  }, [redirect])

  return <></>;
};

OrderAccessController.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default OrderAccessController;
