import produce, { current } from 'immer';
import { PRICING_FETCH_FAIL_APP, REMOVE_ADDON_APP, RESET_ORDER_STORE_APP, SELECT_IDS_APP, SET_ALL_PROMOCODES_APP, SET_CREDENTIALS_APP, SET_CYCLE_APP, SET_CONTENT_APP, SET_PLAN_APP, SET_PRICES_APP, SET_PROMOCODE_APP, SET_SESSION_UID_APP, TOGGLE_ADDON_APP, TOGGLE_ADDON_EXTRA_APP, TOGGLE_PRODUCT_APP, SET_SPECIAL_POPUP_APP, DISACTIVATE_BUNDLE_ELEMENTS } from '../actionTypes/orderAppTypes'

const initialState = {
  loading: true,
  currency: '',
  cycle: 'monthly',
  plan: 'regular',
  allPromocodes: {
    monthly: '',
    annually: '',
    biennially: '',
    onetime: '',
  },
  promocode: '',
  specialPopup: '',
  invalidPromocode: '',
  products: [],
  productsWithSections: [],
  sessionUid: '',
  contentWithTranslation: {
    pricing: {
      continue: "",
      remove: "",
      apply: "",
    },

  },
  addons: [],
  activeProducts: [],
  addonsToDisplay: [],
  activeAddons: [],
  bundles: [
    ["562", [
      "561",
      "560",
      "549",
    ]]
  ],
  order: [],
  selectBlock: false,
  // "recurfor" indicates how many billing cycles the discount is running
  recurfor: {},
  email: '',
  password: '',
  addonGroups: {
    serverAddons: {array: ['479'], singleChoice: false},
    mobileApps: {array: ['496', '497', '498'], singleChoice: true},
    appAddons: {array: ['523', '549', '554'],  singleChoice: false},
  },
  username: '',
  emailmarketing: false,
};

const filterArrayByArrayOfId = (arrayToFilter, arrayOfId) => [...arrayToFilter].filter(({ id }) => arrayOfId.some(addon => +addon === +id))

const filterCheckboxFalse = (products) => (id) => products.some((a) => a.checkbox && +a.id === +id)

const findProductWithId = (prodId) => (el) => +el.id === +prodId

const findActiveProductsObjects = (products, activeProducts) => products.filter(({id}) => activeProducts.some(el => +el === +id))

const filterOrderCheckboxFalse = (products) => ({product_id}) => products.some((a) => a.checkbox && +a.id === +product_id)

const getProductsAddonsList = (productsList) => productsList.reduce((prev, cur) => [...prev, ...cur.addons], []);

const orderAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICES_APP:
      return produce(state, draftState => {
        const { currency, products, addons, promocode, recurfor } = action.payload.data;
        // array of ids of every product from hidden sections
        const hiddenProducts = action.payload.hiddenSections.reduce((prev, cur) => [...prev, ...products[cur].map(p => +p.id)], [])
        draftState.loading = false;
        draftState.currency = currency;
        draftState.productsWithSections = products;
        draftState.products = products.reduce((prev, cur, i) => [...cur.map(a => ({...a, section: i})), ...prev], []);
        draftState.addons = addons;
        draftState.promocode = promocode || '';
        draftState.recurfor = recurfor;
        // in case there is a section hidden, every product from hidden section should be removed from activeProducts array
        draftState.activeProducts = draftState.activeProducts.filter(pid => !hiddenProducts.some(id => +id === +pid))
        draftState.activeAddons = draftState.activeAddons;
        const activeProductsObjects = findActiveProductsObjects(draftState.products, draftState.activeProducts);
        const activeProductsAddons = getProductsAddonsList(activeProductsObjects)
        draftState.addonsToDisplay = draftState.addonsToDisplay.filter(a => activeProductsAddons.some(b => +b === +a))
        draftState.order = draftState.order.filter(({product_id}) => draftState.activeProducts.some(pid => +pid === +product_id))
        // draftState.activeAddons = activeAddons || draftState.activeAddons;
      });
    case PRICING_FETCH_FAIL_APP:
      return produce(state, draftState => {
        draftState.invalidPromocode = action.payload;
      });
    case SET_SESSION_UID_APP:
      return produce(state, draftState => {
        draftState.sessionUid = action.payload.data.session_uid
      })
    case SET_CONTENT_APP: 
      return produce(state, draftState => {
        draftState.contentWithTranslation = action.payload
      })
    case TOGGLE_PRODUCT_APP:
      return produce(state, draftState => {
        if(!draftState.selectBlock) {
          draftState.order = draftState.order.filter((a) => +a.product_id !== +action.payload)

          if(draftState.activeProducts.includes(action.payload)) {
            draftState.activeProducts = draftState.activeProducts.filter((a) => a !== action.payload)

            const activeProductsObjects = findActiveProductsObjects(draftState.products, draftState.activeProducts);
            const activeProductsAddons = getProductsAddonsList(activeProductsObjects)

            draftState.addonsToDisplay = draftState.addonsToDisplay.filter(a => activeProductsAddons.some(b => +b === +a))
            draftState.activeAddons = draftState.activeAddons.filter(addonId => draftState.addonsToDisplay.includes(+addonId))
          } else {
            const currentProduct = draftState.products.find(({id}) => +action.payload === +id)
            const productAddons = currentProduct.addons;

            if(!currentProduct.checkbox) {
              draftState.activeProducts = draftState.activeProducts.filter(filterCheckboxFalse(draftState.products))
            }

            draftState.activeProducts = [...draftState.activeProducts, action.payload];

            const activeProductsObjects = findActiveProductsObjects(draftState.products, draftState.activeProducts)
            const activeProductsAddons = getProductsAddonsList(activeProductsObjects)

            draftState.addonsToDisplay = draftState.addonsToDisplay.filter(a => activeProductsAddons.some(b => +b === +a))
            draftState.addonsToDisplay = [...new Set([...draftState.addonsToDisplay, ...currentProduct.addons])]

            // order forming conditions
            // if product has checkbox property true, order element is just pushed to end of order list, else, the order list is filtered from those elements with checkbox false and element is pushed to end of order list
            if(draftState.products.find(findProductWithId(action.payload))?.checkbox) {
              draftState.order = [{product_id: +action.payload, addons: productAddons.filter(a => draftState.activeAddons.includes(a))}, ...state.order]
            } else {
              draftState.order = draftState.order.filter(filterOrderCheckboxFalse(draftState.products))
              draftState.order = [{product_id: +action.payload, addons: productAddons.filter(a => draftState.activeAddons.includes(a))}, ...draftState.order]
            }
          }
        }
      });
    case TOGGLE_ADDON_APP:
      return produce(state, draftState => {
        if(!draftState.selectBlock) {
          if(draftState.activeAddons.includes(action.payload)) {
            const activeAddons = draftState.activeAddons.filter((a) => a !== action.payload)
            draftState.activeAddons = activeAddons;
            console.log(activeAddons, draftState.order)
            draftState.order = draftState.order.map(el => ({product_id : el.product_id, addons: draftState.products.find(({id}) => +id === +el.product_id).addons.filter(a => activeAddons.includes(a))}))
          } else {
            draftState.activeAddons = draftState.activeAddons.filter(filterCheckboxFalse(draftState.addons));
            const activeAddons = [...draftState.activeAddons, action.payload]
            draftState.activeAddons = activeAddons;
            draftState.order = draftState.order.map(el => ({product_id : el.product_id, addons: draftState.products.find(({id}) => +id === +el.product_id).addons.filter(a => activeAddons.includes(a))}))
          }
        }
      });
    case DISACTIVATE_BUNDLE_ELEMENTS:
      return produce(state, draftState => {
        draftState.activeProducts = draftState.activeProducts.reduce((prev, cur) => {
          if(!action.payload.includes(cur)) {
            return [...prev, cur];
          }
          return prev;
        }, [])
      });
    case SELECT_IDS_APP:
      return produce(state, draftState => {
        draftState.selectBlock = action.payload.includes("fixed");
        const productsToActivate = action.payload.filter((id) => id.toString().length === 3);
        const addonsToActivate = action.payload.filter((id) => id.toString().length === 2).map(n => +n);

        const productsToActivateObjects = productsToActivate.map((a) => state.products.find(findProductWithId(a)))
        console.log("productsToActivateObjects", productsToActivateObjects)

        if(productsToActivate) {
          draftState.activeProducts = productsToActivate
        }

        productsToActivate.forEach(a => {
          draftState.addonsToDisplay = [...new Set([...(draftState.addonsToDisplay || []), ...(state.products.find(findProductWithId(a))?.addons || [])])];
        })
        draftState.order = [];
        productsToActivateObjects.forEach((currentProduct) => {
          const productAddons = currentProduct.addons;
          console.log("currentProduct", {product_id: +currentProduct.id, addons: productAddons.filter(a => draftState.activeAddons.includes(a))});
          draftState.order = [{product_id: +currentProduct.id, addons: productAddons.filter(a => draftState.activeAddons.includes(a))}, ...draftState.order];
        })

        if(productsToActivate) {
          draftState.activeProducts = productsToActivate
        }

        if(addonsToActivate.length > 0) {
          draftState.activeAddons = addonsToActivate
        }
      });
    case REMOVE_ADDON_APP:
      return produce(state, draftState => {
        const newArray = draftState.activeAddons.filter(id => id !== action.payload);
        draftState.activeAddons = newArray;
      });
    case SET_CYCLE_APP:
      return produce(state, draftState => {
        draftState.cycle = action.payload;
      });
    case SET_SPECIAL_POPUP_APP:
      return produce(state, draftState => {
        draftState.specialPopup = action.payload;
      });
    case SET_PLAN_APP:
      return produce(state, draftState => {
        draftState.plan = action.payload;
      });
    case SET_PROMOCODE_APP:
      return produce(state, draftState => {
        draftState.promocode = action.payload || '';
      });
    case SET_ALL_PROMOCODES_APP:
      return produce(state, draftState => {
        draftState.allPromocodes = action.payload;
      });
    case SET_CREDENTIALS_APP:
      return produce(state, draftState => {
        const { email, password, username, emailmarketing } = action.payload;
        draftState.email = email;
        draftState.password = password;
        draftState.username = username;
        draftState.emailmarketing = emailmarketing;
      });
    // case UNSELECT_PRODUCTS_APP:
    //   return produce(state, draftState => {
    //     draftState.activeAddonsExtra = [];
    //     draftState.activeAddons = [];
    //   });
    case RESET_ORDER_STORE_APP:
      return initialState;
    default:
      return state;
  }
};

export default orderAppReducer;
