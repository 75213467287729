import React from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import Image from 'components/atoms/Image/Image';
import TitleSection from 'components/organisms/TitleSection/TitleSection';
import googlePlayImg from 'assets/img/googlePlay.webp';
import amazonImg from 'assets/img/amazon.png';
import AppleTvFeatureAnimation from 'components/atoms/AppleTvFeatureAnimation/AppleTvFeatureAnimation';
import FullContainer from 'components/atoms/FullContainer/FullContainer';
import RowSection from '../RowSection/RowSection';

const AppleStore = React.lazy(() => import('assets/svg/AppleStore'))

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: "center",
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: 'black',
  },
  icon: {
    width: 200,
    height: 'unset',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      height: theme.spacing(7),
    },
  },
}));


// section with buttons to download aplication on google play or apple store
const AppDownloadSection = ({ heading, element, description, googlePlay, appleStore, amazonStore }) => {
  const classes = useStyles();

  return (
    <FullContainer id="more" maxWidth="xl" overflowHidden>
      <RowSection
        contentReverse
        short
        element={element}
        paddingRightNone
        contentElement={
          <TitleSection primary={heading} secondary={description}>
            <Box className={classes.box}>
              {googlePlay && <ButtonBase
                className={classes.button}
                component="a"
                href={googlePlay}
                target="_blank"
                focusRipple
              >
                <Image className={classes.icon} src={googlePlayImg} />
              </ButtonBase>}
              {appleStore && <ButtonBase
                className={classes.button}
                component="a"
                href={appleStore}
                target="_blank"
                focusRipple
              >
                <AppleStore className={classes.icon} />
              </ButtonBase>}
              {amazonStore && <ButtonBase
                className={classes.button}
                component="a"
                href={amazonStore}
                target="_blank"
                focusRipple
                style={{
                  borderRadius: "10px",
                  border: "solid 2px #a2a2a2",
                  overflow: "hidden",
                  padding: "0px",
                  margin: "8px 24px",
                }}
              >
                <Image
                  className={classes.icon}
                  src={amazonImg}
                />
              </ButtonBase>}
            </Box>
          </TitleSection>
        }
        // reverse={i % 2 === 0}
      />
        {/* <div style={{width: "500px", height: "125px", position: "relative"}}>
          
        </div> */}
    </FullContainer>
  );
};

AppDownloadSection.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  googlePlay: PropTypes.string.isRequired,
  appleStore: PropTypes.string.isRequired,
};

export default AppDownloadSection;
