import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Chip, Box, Button, Divider, TextField, Menu, MenuItem, Fade, FormControlLabel, Checkbox } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Text from 'components/atoms/Text/Text';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
  `}
`;

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20
    },
    Title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 20px 20px 20px',
    },
    Content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0 10px 10px 10px',
    },
    Button: {
        display: 'flex',
        '&>*': {
            margin: 10,
        },
        [theme.breakpoints.down(768)]: {
            justifyContent: 'center',
        },
    },
    Inputs: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 700,
        width: '100%',
        '&>*': {
            margin: 10,
        }
    },
}));

const ResetPassword = () => {
    const classes = useStyles();

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item className={classes.Title}>
                        <Text component='h4' variant='h5'>Reset Password</Text>
                    </Grid>
                    <Grid item className={classes.Content}>
                        <Box className={classes.Inputs}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Old Password"
                                variant="outlined"
                                type='password'
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="New Password"
                                variant="outlined"
                                type='password'
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Confirm New Password"
                                variant="outlined"
                                type='password'
                            />
                        </Box>
                        <Box className={classes.Button}>
                            <Button variant='contained' color='primary'>Change Password</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default ResetPassword;