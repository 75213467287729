import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Chip, Box, Button, Divider, TextField, Menu, MenuItem, Fade, FormControlLabel, Checkbox } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Text from 'components/atoms/Text/Text';
import EnhancedTable from 'components/organisms/EnhancedTable/EnhancedTable';
import { useNavigate } from 'react-router-dom';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
  `}
`;

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20
    },
    Title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 20px 20px 20px',
    },
    Content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0 20px 20px 20px',
    },
}));

const emails = [
    {
        id: 1,
        title: 'Ingredia Nutrisha, A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture',
        date: '11:49 AM'
    },
    {
        id: 2,
        title: 'Almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.',
        date: '11:49 AM'
    },
    {
        id: 3,
        title: 'Ingredia Nutrisha, A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture',
        date: '11:49 AM'
    },
    {
        id: 4,
        title: 'Almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.',
        date: '11:49 AM'
    },
    {
        id: 5,
        title: 'Ingredia Nutrisha, A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture',
        date: '11:49 AM'
    },
];

// page to view all emails
const Emails = () => {
    const classes = useStyles();
	const navigate = useNavigate();
    
    // function to transform email data to object wich is readable by EnhancedTable component as rows property
    function createData(id, title, date) {
        return { id: {data: id}, title: {data: title}, date: {data: date, alignRight: true}};
    }
    
    //rows with all emails
    const rows = emails.map(({id, title, date}) => createData(id, title, date));
    
    // const columns = [
    //     { id: 'id', numeric: false, disablePadding: false, label: 'Invoice' },
    //     { id: 'date', numeric: false, disablePadding: false, label: 'Due Date' },
    //     { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
    //     { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    // ];

    const handleClick = (e, row) => {
        if(e.target.type !== "checkbox") navigate(`132456`);
    }

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item className={classes.Title}>
                        <Text component='h4' variant='h5'>Emails</Text>
                    </Grid>
                    <Grid item className={classes.Content}>
                        <EnhancedTable
                            rows={rows}
                            handleClick={handleClick}
                            defaultRowsPerPage={5}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default Emails;