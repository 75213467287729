import React from 'react';
import PropTypes from 'prop-types';
import Title from 'components/atoms/Title/Title';
import SubTitle from 'components/atoms/Subtitle/Subtitle';
import styled from 'styled-components';
import { Box } from '@mui/material';

const StyledContainer = styled.div`
  margin: 0 0 40px;
`;

const HeadingBlock = ({ topPadding, notCentered, title, subtitle, ...props }) => (
  <Box style={{marginBottom: !subtitle ? "0" : "40px"}}>
    <Title style={{textAlign: notCentered ? 'right' : 'center', marginTop: topPadding ? '30px' : '0'}} {...props}>{title}</Title>
    {subtitle && <SubTitle>{subtitle}</SubTitle>}
  </Box>
);

HeadingBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

HeadingBlock.defaultProps = {
  subtitle: '',
};

export default HeadingBlock;
