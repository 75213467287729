import { RESET_ORDER_STORE_TV, SET_ALL_PROMOCODES_TV, SET_CREDENTIALS_TV, SET_CYCLE_TV, SET_LOADING_TV, SET_NEW_PRICES_TV, SET_PRICES_TV, SET_PROMOCODE_TV, TOGGLE_ADDON_EXTRA_TV, TOGGLE_ADDON_TV, TOGGLE_PRODUCT_TV, UNSELECT_PRODUCTS_TV, SELECT_IDS_TV, PRICING_FETCH_FAIL_TV, SET_SPECIAL_POPUP_TV, SET_SESSION_UID, SET_STEP } from 'redux/actionTypes/orderTvTypes';
import produce from 'immer';

const initialState = {
  loading: true,
  currency: '',
  cycle: 'monthly',
  allPromocodes: {
    monthly: '',
    annually: '',
    biennially: '',
    onetime: '',
  },
  promocode: '',
  specialPopup: '',
  invalidPromocode: '',
  sessionUid: "",
  group: [],
  products: [],
  addons: [],
  addonsExtra: [],
  step: 1,
  activeGroup: '',
  activeProduct: [],
  activeAddons: [],
  activeAddonsExtra: [],
  // "recurfor" indicates how many billing cycles the discount is running
  recurfor: {},
  productFilter: ["560","561","562"],
  email: '',
  client_password: '',
  server_password: '',
  username: '',
  emailmarketing: false,
};

const orderTvReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STEP:
      return produce(state, draftState => {
        draftState.step = action.payload;
      })
    case SET_SESSION_UID: 
      return produce(state, draftState => {
        draftState.sessionUid = action.payload.data.session_uid;
        draftState.step = action.payload.data.step;
      })
    case SET_PRICES_TV:
      return produce(state, draftState => {
        const { currency, products, group, recurfor, addons, others } = action.payload.data;
        const activeGroup = products[0].gid.toString();
        const activeProduct = draftState.activeProduct?.length > 0 ? draftState.activeProduct : products[0].pid.toString();

        const addonsFixed = [...others];

        draftState.loading = false;
        draftState.currency = currency;
        draftState.group = group;
        draftState.products = products.filter(prod => !(prod.title === null && prod.description === null));
        draftState.addons = addonsFixed.filter(prod => !(prod.title === null && prod.description === null));;
        draftState.addonsExtra = addons.filter(prod => !(prod.title === null && prod.description === null));;
        draftState.recurfor = recurfor;
        draftState.activeGroup = activeGroup;
        draftState.activeProduct = activeProduct;
      });
    case SET_NEW_PRICES_TV:
      return produce(state, draftState => {
        const { products, addons, others, recurfor } = action.payload.data;
        const activeGroup = products[0].gid.toString();
        const activeProduct = !state.activeProduct ? products[0].pid.toString() : state.activeProduct;
        // const activeAddon = draftState.activeAddon ? draftState.activeAddon : [];
        const activeAddons = !state.activeAddons ? draftState.activeAddons : state.activeAddons;
        const activeAddonsExtra = draftState.activeAddonsExtra ? draftState.activeAddonsExtra : [];

        const addonsFixed = [...others];

        draftState.loading = false;
        draftState.products = products.filter(prod => !(prod.title === null && prod.description === null));
        draftState.addons = addonsFixed.filter(prod => !(prod.title === null && prod.description === null));
        draftState.addonsExtra = addons.filter(prod => !(prod.title === null && prod.description === null));
        draftState.recurfor = recurfor || {};
        draftState.activeGroup = activeGroup;
        draftState.activeProduct = activeProduct;
        draftState.activeAddons = activeAddons;
        draftState.activeAddonsExtra = activeAddonsExtra;
      });
    case PRICING_FETCH_FAIL_TV:
      return produce(state, draftState => {
        draftState.invalidPromocode = action.payload;
      });
    case TOGGLE_PRODUCT_TV:
      return produce(state, draftState => {
        draftState.activeProduct = action.payload;
      });
    //crutches that need to be fixed in near future
    case TOGGLE_ADDON_TV:
      const additionalAddonsList = [549]
      return produce(state, draftState => {
        if(additionalAddonsList.includes(+action.payload)) {
          if(draftState.activeAddons.some(el => el.id === action.payload)) {
            draftState.activeAddons = draftState.activeAddons.filter(addon => addon.id !== action.payload)
          } else {
            draftState.activeAddons = [...draftState.activeAddons, {id: action.payload, additional: true}]
          }
        } else {
          if(draftState.activeAddons.some(el => el.id === action.payload)) {
            draftState.activeAddonsExtra = []
            draftState.activeAddons = draftState.activeAddons.filter(addon => addon.id !== action.payload)
          } else {
            draftState.activeAddons = [
              ...draftState.activeAddons.filter(el => el.additional),
              {id: action.payload, additional: false}
            ]
          }
        }
        if(action.payload === '') draftState.activeAddonsExtra = [];
      });
    case SELECT_IDS_TV:
      return produce(state, draftState => {
        const productToActivate = action.payload?.find((id) => state.products.some((product) => +product.pid === +id))
        const addonsToActivate = action.payload?.filter((id) => state.addons.some((addon) => +addon.pid === +id)).map(a => ({id: a, additional: a === '549'}))
        const addonsExtraToActivate = action.payload?.filter((id) => state.addonsExtra.some((addon) => +addon.relid === +id))
    
        console.log(productToActivate, addonsToActivate, addonsExtraToActivate)
        if(productToActivate) {
          draftState.activeProduct = productToActivate;
        }

        if(addonsToActivate?.length > 0) {
          draftState.activeAddons = addonsToActivate;
        }
  
        if(addonsExtraToActivate?.length > 0) {
          draftState.activeAddonsExtra = addonsExtraToActivate;
        }
    });
    case TOGGLE_ADDON_EXTRA_TV:
      return produce(state, draftState => {
        draftState.activeAddonsExtra = action.payload;
      });
    case SET_CYCLE_TV:
      return produce(state, draftState => {
        draftState.cycle = action.payload;
      });
    case SET_PROMOCODE_TV:
      return produce(state, draftState => {
        draftState.promocode = action.payload || '';
      });
    case SET_ALL_PROMOCODES_TV:
      return produce(state, draftState => {
        draftState.allPromocodes = action.payload;
      });
    case SET_SPECIAL_POPUP_TV:
      return produce(state, draftState => {
        draftState.specialPopup = action.payload;
      });
    case SET_CREDENTIALS_TV:
      return produce(state, draftState => {
        const { email, client_password, server_password, username, emailmarketing } = action.payload;
        draftState.email = email;
        draftState.client_password = client_password;
        draftState.server_password = server_password;
        draftState.username = username;
        draftState.emailmarketing = emailmarketing;
      });
    case SET_LOADING_TV:
      return produce(state, draftState => {
        draftState.loading = !!action.payload;
      });
    case UNSELECT_PRODUCTS_TV:
      return produce(state, draftState => {
        draftState.activeProduct = [];
        draftState.activeAddons = [];
        draftState.activeAddonsExtra = [];
      });
    case RESET_ORDER_STORE_TV:
      return initialState;
    default:
      return state;
  }
};

export default orderTvReducer;