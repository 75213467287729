import { ArrowBack, ArrowForward, ChevronLeft, ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useCallback, useState } from "react"

const { Typography, Box, makeStyles } = require("@material-ui/core")

const imgArr = [
    "https://img.fastcast4u.com/react/app/carousel/app%20(1).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(2).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(3).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(4).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(5).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(6).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(7).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(8).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(9).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(10).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(11).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(12).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(13).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(14).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(15).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(16).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(17).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(18).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(19).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(20).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(21).webp",
    "https://img.fastcast4u.com/react/app/carousel/app%20(22).webp",
]

const useStyles = makeStyles(theme => ({
    carouselContainer: {
        width: "100%",
        height: "500px",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        alignItems: "center",
        perspective: 500,
        overflow: "hidden"
    },
    arrowButton: {
        position: "absolute",
        display: "flex",
        height: "100%",
        fontSize: "70px",
        width: "max-content",
        alignItems: "center",
        transition: "0.1s ease",
        cursor: "pointer",

        [theme.breakpoints.down('xs')]: {
          display: "none",
        },
    },
    arrowButtonRight: {
        right: 0,
    },
    arrowButtonLeft: {
        left: 0,
    },
    slideContainer: {
        width: "max-content",
        height: "100%",
        // background: "red",
        display: "flex",
        position: "absolute",
        left: "50%",
        opacity: ({isPrev2, isNext2}) => ((isNext2 || isPrev2) ? 0 : 1),
        transform: ({isPrev, isPrev2, isCur, isNext, isNext2}) => (
            isPrev?
                "translateX(-150%) scale(90%) rotateY(-13deg)"
                :
            isPrev2?
                "translateX(-200%) scale(50%) rotateY(-25deg)"
                : isCur ?
                "translateX(-50%)"
                : isNext?
                "translateX(50%) scale(90%) rotateY(10deg)"
                : isNext2 ?
                "translateX(100%) scale(50%) rotateY(25deg)" : "scale(0)"
        ),
        padding: "10px",
        transition: "1s ease",
    },
    slideContent: {
        width: "max-content",
        height: "100%",
        display: "flex",
        borderRadius: "10px 10px 10px 10px",
        overflow: "hidden",
        boxShadow: "0 0 4px 0 rgb(0,0,0,0.5)",
        position: "relative",
    },
    slideImg: {
        height: "100%",
        userSelect: "none",
    },
    bluringBox: ({isCur, isPrev2, isNext2}) => ({
        top: 0,
        left: 0,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        backdropFilter: isCur ? "blur(0)" : (isPrev2 || isNext2) ? "blur(10px)" : "blur(3px)",
        height: "100%",
        width: '100%',
        transition: "0.7s ease",

        [theme.breakpoints.down('xs')]: {
            backdropFilter: "blur(0)",
            opacity: isCur ? 1 : (isPrev2 || isNext2) ? 0 : 0.3,
        },
    })
}));

const Slide = ({activeSlide, i, src, carouselLength}) => {
    const isForNextCycle = activeSlide === carouselLength - 1 && i === 0;
    const isForNextCycle2 = (activeSlide > carouselLength / 2 && i <= 2) && !isForNextCycle;
    const isForPrevCycle = activeSlide === 0 && i === carouselLength - 1;
    const isForPrevCycle2 = (activeSlide < carouselLength / 2 && i >= carouselLength - 3) && !isForPrevCycle;
    
    const isPrev = (activeSlide - i === 1 && !isForNextCycle && !isForNextCycle2) || isForPrevCycle;
    const isPrev2 = ((!isPrev && activeSlide > i) && !isForNextCycle && !isForNextCycle2) || isForPrevCycle2;
    const isCur = activeSlide - i === 0;
    const isNext = ((activeSlide - i === -1) && !isForPrevCycle && !isForPrevCycle2) || isForNextCycle ;
    const isNext2 = ((!isNext && activeSlide < i) || isForNextCycle2) && !isForPrevCycle;

    const classes = useStyles({isPrev, isPrev2, isCur, isNext, isNext2});

    return (
        <Box
            key={`img-${i}`}
            className={classes.slideContainer}
        >
            <Box
                className={classes.slideContent}
            >
                <img
                    draggable={false}
                    className={classes.slideImg}
                    src={imgArr[i]}
                />
            </Box>
            <Box className={classes.bluringBox}/>
        </Box>
    )
}

export const PhotoCarousel = () => {
    const [isActive, setActive] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const [mouseDownCoords, setMouseDownCoords] = useState(0);
    const [mouseMoveCoords, setMouseMoveCoords] = useState(0);
    const classes = useStyles();
    let timer;

    const handleNext = useCallback(() => {
        setActiveSlide(a => {
            if(a === imgArr.length - 1) {
                return 0
            }
            return a + 1
        })
    }, [])
    
    const handlePrev = useCallback(() => {
        setActiveSlide(a => {
            if(a === 0) {
                return imgArr.length - 1
            }
            return a - 1
        })
    }, [])
    
    const startTimer = useCallback(() => {
        timer = setInterval(handleNext, 5000);
    }, [])

    const stopTimer = useCallback(() => {
        clearInterval(timer)
    }, [])

    useEffect(() => {
        startTimer()

        return () => {
            clearInterval(timer)
        }
    }, [])

    
    return (
        <>
            <Box
                className={classes.carouselContainer}

                onTouchStart={(event) => {
                    setMouseDownCoords(event.touches[0].clientX)
                }}
                onTouchMove={(event) => {
                    setMouseMoveCoords(event.touches[0].clientX)
                }}
                onTouchEnd={(event) => {
                    if(mouseDownCoords - mouseMoveCoords > 15) {
                        handleNext();
                    }
                    
                    if(mouseDownCoords - mouseMoveCoords < -15) {
                        handlePrev();
                    }

                }}
            >
                {imgArr.map((src, i) => <Slide activeSlide={activeSlide} i={i} src={src} carouselLength={imgArr.length}/>)}

                <Box
                    className={[classes.arrowButton, classes.arrowButtonLeft]}
                    onClick={() => {
                        handlePrev();
                        stopTimer();
                        startTimer();
                    }}
                >
                    <ChevronLeftRounded
                        style={{fontSize: "70px"}}
                    />
                </Box>

                <Box
                    className={[classes.arrowButton, classes.arrowButtonRight]}
                    onClick={() => {
                        handleNext();
                        stopTimer();
                        startTimer();
                    }}
                >
                    <ChevronRightRounded
                        style={{fontSize: "70px"}}
                    />
                </Box>
            </Box>

        </>
    )
}