export const SET_STEP = "ORDER_TV/SET_STEP";

export const SET_SESSION_UID = "ORDER_TV/SET_SESSION_UID"

// toggles selected product. The product id should be provided as item argument
export const TOGGLE_PRODUCT_TV = 'ORDER_TV/TOGGLE_PRODUCT';

// sets promocode from special popup DialogOnTabChange (the ones that is showed when mouse leaves window borders)
export const SET_SPECIAL_POPUP_TV = "ORDER_TV/SET_SPECIAL_POPUP"

// toggles selected addon. The addon id should be provided as item argument
export const TOGGLE_ADDON_TV = 'ORDER_TV/TOGGLE_ADDON';

// the same as toggleAddon, but for extra addons
export const TOGGLE_ADDON_EXTRA_TV = 'ORDER_TV/TOGGLE_ADDON_EXTRA';

export const REMOVE_ADDON_TV = 'ORDER_TV/REMOVE_ADDON';

// action to select billing cycle
export const SET_CYCLE_TV = 'ORDER_TV/SET_CYCLE';

// action to select premium or other package
export const SET_PLAN_TV = 'ORDER_TV/SET_PLAN';

export const SET_PROMOCODE_TV = 'ORDER_TV/SET_PROMOCODE';

export const SET_ALL_PROMOCODES_TV = 'ORDER_TV/SET_ALL_PROMOCODES';

export const SET_CREDENTIALS_TV = 'ORDER_TV/SET_CREDENTIALS';

export const RESET_ORDER_STORE_TV = 'ORDER_TV/RESET_ORDER_STORE';

export const SET_PRICES_TV = 'ORDER_TV/SET_PRICES_TV';

export const SET_NEW_PRICES_TV = 'ORDER_TV/SET_NEW_PRICES_TV';

export const PRICING_FETCH_FAIL_TV = 'ORDER_TV/PRICING_FETCH_FAIL_TV';

export const SET_LOADING_TV = 'ORDER_TV/SET_LOADING';

export const SELECT_IDS_TV = 'ORDER_TV/SELECT_IDS';

export const UNSELECT_PRODUCTS_TV = "ORDER_TV/UNSELECT_PRODUCTS"