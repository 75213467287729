import React from 'react';
import { makeStyles, Grid, Box, Button, TextField } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Text from 'components/atoms/Text/Text';
import { useSelector } from 'react-redux';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
  `}
`;

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20
    },
    Title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 20px 20px 20px',
    },
    ContentWrapper: {
        width: '100%',
        padding: '0 10px 10px 10px',
        '&>div': {
            '&>div': {
                padding: 10,
                [theme.breakpoints.down(768)]: {
                    minWidth: '100%',
                },
                '&>div': {
                    width: '100%',
                }
            }
        }
    },
}));


//page to change account data such as first name or company name
const AccountDetails = () => {
    const classes = useStyles();
    // current account data
    const accountData = useSelector(state => state.account);

    // fields to edit account data
    const textFields = [
        {
            size: 6,
            label: 'First name',
            value: accountData.firstname
        },
        {
            size: 6,
            label: 'Last name',
            value: accountData.lastname
        },
        {
            size: 12,
            label: 'Company name(Optional)',
            optional: true,
            value: accountData.companyname
        },
        {
            size: 12,
            label: 'Email',
            value: accountData.email
        },
        {
            size: 8,
            label: 'Address',
            value: accountData.adress1
        },
        {
            size: 4,
            label: 'City',
            value: accountData.city
        },
        {
            size: 12,
            label: 'Address 2(Optional)',
            optional: true,
            value: accountData.adress2
        },
        {
            size: 4,
            label: 'Country',
            value: accountData.country

        },
        {
            size: 4,
            label: 'State',
            value: accountData.state
        },
        {
            size: 4,
            label: 'Zip',
            value: accountData.postcode
        },
    ]
    
    // function to attach files such as logo picture
    const attachFiles = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.click();
    }

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item className={classes.Title}>
                        <Text component='h4' variant='h5'>Account Details</Text>
                    </Grid>
                    <Grid item className={classes.ContentWrapper}>
                        <Grid container>
                            {textFields.map((field, i) => (
                                <Grid item xs={field.size} key={i}>
                                    <TextField
                                        required={!field.optional}
                                        id="outlined-required"
                                        value={field.value}
                                        label={field.label}
                                        variant="outlined"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item className={classes.Title}>
                        <Button variant='contained' color='primary'>Update Details</Button>
                        <Button variant='contained' color='secondary'>Remove Account</Button>
                    </Grid>
                    <Grid item className={classes.Title}>
                        <Text component='h4' variant='h5'>Profile Logo or Picture</Text>
                    </Grid>
                    <Grid item className={classes.Title}>
                        <Button onClick={attachFiles} variant='contained' color='primary'>Add Own Profile</Button>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default AccountDetails;