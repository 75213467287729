export const SET_ACTIVE_PRODUCT = 'ACCOUNT/SET_ACTIVE_PRODUCT';

export const SET_CURRENT_PAGE = 'ACCOUNT/SET_CURRENT_PAGE';

export const SET_ACCOUNT_DATA = 'ACCOUNT/SET_ACCOUNT_DATA';

export const SET_PRODUCT_GROUPS = 'ACCOUNT/SET_PRODUCT_GROUPS';

export const SET_TOKEN = 'ACCOUNT/SET_TOKEN';

export const RESET_ACCOUNT_STORE = 'ACCOUNT/RESET_ACCOUNT_STORE';