import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoFile from 'assets/img/logo.webp';
import CTAButton from 'components/atoms/CTAButton/CTAButton';
import { makeStyles } from '@material-ui/core';
import { toggleAuthMode, toggleAuthWindow } from 'redux/actions/tvtrans';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ChevronLeftRounded';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

// @ts-ignore
const useStyles = makeStyles(theme => ({
  searchBar: {
    color: theme.palette.text.primary,
    background: theme.palette.grey[200],
    // transition: "0.1s ease",
    flex: 1,
    boxShadow: `0 0 3px rgba(0,0,0,0.5)`,
    paddingLeft: "35px",
    position: "relative",
    height :"37px",

    "&:hover": {
        background: theme.palette.grey[300],
    },

    [theme.breakpoints.down('sm')]: {
      flex: "none",
      width: "20px !important",
      order: 10,
      marginRight: "0px !important",
      background: "none !important",
      height: "100%",
    },
  },
  searchBarActive: {
    position: "fixed !important",
    width: "100% !important",
    height: "50px !important",
    background: `${theme.palette.background.paper} !important`,
    zIndex: 100,
    top: 0,
    left: 0,
    "& $searchBarBase": {
      display: "flex !important",
      width: "100%",
    },

    "& $searchBarIcon": {
      left: "100%",
      transform: "translateX(-100%)",
    },
    "& $searchBarBack": {
      display: "flex !important"
    }
  },
  searchBarBack: {
    position: "absolute",
    left: 0,
    height: "100% !important",
    width: "auto !important",
    boxSizing: "borderBox",
    padding: "8px !important",
    paddingLeft: "3px !important",
    display: "none !important",
  },
  searchBarIcon: {
    position: "absolute",
    left: 0,
    height: "100% !important",
    width: "auto !important",
    boxSizing: "borderBox",
    padding: "5px !important",
    [theme.breakpoints.down('sm')]: {
      padding: "10px !important",
    },
  },
  searchBarBase: {
    color: theme.palette.primary.contrastText,
    width: "100%",
    height: "100%",
    background: "transparent",
    border: 0,
    outline: "none",
    [theme.breakpoints.down('sm')]: {
      width: "0px"
    },
  },

  browseLink: {
    position: "relative",
    color: theme.palette.text.primary,
    textDecoration: "none",
    height: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    transition: "0.1s ease",
    "&:hover": {
      backdropFilter: "invert(0.1)",
    },

    "&::after": {
      content: '""',
      height: "3px",
      width: "50%",
      position: "absolute",
      left: "50%",
      bottom: "0",
      background: theme.palette.primary.main,
      transform: "translate(-50%, 105%)",
      transition: "0.1s ease",
    },

    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  browseLinkActive: {
    color: theme.palette.primary.main,

    "&::after": {
      transform: "translate(-50%, 0%)",
      width: "100%",
    },

    "&:hover": {
      backdropFilter: "none",
    },
  },
  logo: {
    padding: "15px 0",
    boxSizing: "border-box",
    height: "100%",
    marginRight: "20px",
    objectFit: "contain",
    objectPosition: "left",
    cursor: "pointer"
  },
}))

const Logo = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    return (
        <img
            src={LogoFile}
            className={classes.logo}
            onClick={() => {
              navigate("/tvtrans")
            }}
        />
    )
}

const SearchBar = () => {
  const classes = useStyles();
  const [isActive, setActive] = React.useState();
  const ref = React.useRef(null);

  return (
    <Search
      className={[classes.searchBar, isActive && classes.searchBarActive].join(" ")}
    >
      <BackIcon
        onClick={() => {setActive(false)}}
        className={classes.searchBarBack}
      />
      <SearchIcon
        onClick={() => {
          setActive(true);
          ref.current.focus();
        }}
        className={classes.searchBarIcon}
      />
      <input
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        sx={{
          width: "100%",
        }}
        ref={ref}
        className={classes.searchBarBase}
      />
    </Search>
  )
}

export default function TvTransAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const classes = useStyles();
  const isMenuOpen = anchorEl;
  const isMobileMenuOpen = mobileMoreAnchorEl;
  const dispatch = useDispatch();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
        sx={{
            background: "transparent",
            color: "black",
            height: "55px",
            zIndex: "10",
        }}
        position="static"
    >
        <Toolbar sx={{display: "flex", minHeight: "0px !important"}}>
            <Box sx={{flex: 1, display: "flex", height: "100%", overflow: "hidden"}}>
              <Logo />
              <NavLink
                to="/tvtrans/directory"               
                className={({ isActive, isPending }) => (
                  [
                    isPending && "pending",
                    isActive && classes.browseLinkActive,
                    classes.browseLink
                  ].join(" ")
                )}
              >
                Browse
              </NavLink>
            </Box>
            <SearchBar/>
            <Box sx={{flex: 1, display: "flex", justifyContent: "end"}}>
                <CTAButton
                  style={{marginRight: "2px"}}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    dispatch(toggleAuthWindow(true))
                    dispatch(toggleAuthMode("login"))
                  }}
                >
                  Log in
                </CTAButton>
                <CTAButton
                  color="primary"
                  onClick={() => {
                    dispatch(toggleAuthWindow(true))
                    dispatch(toggleAuthMode("signup"))
                  }}
                >
                  Sign up
                </CTAButton>
            </Box>
        </Toolbar>
    </AppBar>
  );
}