import React from 'react';
import { Dialog, DialogTitle, makeStyles, Slide, CardMedia, Box, Typography, DialogContent, Grid, Button, Input, OutlinedInput } from "@material-ui/core";
import { IconButton } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { Close } from "@material-ui/icons";
import Image from 'components/atoms/Image/Image';
import CTAButton from 'components/atoms/CTAButton/CTAButton';
import { useLocation, useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { borderRadius } from '@mui/system';
import {FileCopyOutlined} from "@material-ui/icons";
import {FileCopy} from "@material-ui/icons";
import Tooltip from '@mui/material/Tooltip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: '10px',
  },
  windowHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
    paddingBottom: '7px',
    paddingTop: '10px',
    [theme.breakpoints.down('xs')]: {
      height: '50px',
      paddingRight: '5px',
    },
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '17px',
  },
  token: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'ease 0.1s',
    padding: '6px 9px',
    marginBottom: '13px',
    "&:hover": {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    "&:active": {
      transform: 'scale(0.95)',
      color: 'rgba(0, 0, 0, 0.4)',
    },
  },
  windowContent: {
    display: 'flex',
    // [theme.breakpoints.down('xs')]: {
    //   flexDirection: 'column-reverse'
    // },
    justifyContent: 'space-between',
  },
  windowMainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    textAlign: 'center',
    paddingRight: 40,
    marginBottom: 30,

    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
  windowMainContentText: {
    fontSize: '20px',
  },
  windowMainContentTextBold: {
    whiteSpace: 'nowrap',
  },
  promocode: {
    fontWeight: 'bold',
    fontSize: '23px',
    margin: '9px 0'
  },
  windowMainContentSecondary: {
    fontSize: '15px'
  },
  windowMainContentHeader: {
    display: 'block',
    fontSize: '22px',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
    paddingTop: 0,
    fontSize: '25px',
  },
  windowMainContentHeaderContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: -10,
    },
  },
  windowPicture: {
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      padding: '0 40px',
      marginBottom: '10px'
    },
  },
  actionButton: {
    display: 'flex',
  }
}))

export const DialogOnAppSuccess = () => {
  const [open, setOpen] = useState(true)
  const classes = useStyles();
  const [email] = useCookies(['customer_email']);
  const [token] = useCookies(['app_creator_token']);
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const onClose = () => {
    searchParams.delete('success')
    navigate({search: searchParams.toString()})
  };

  useEffect(() => {
    setOpen(searchParams.get('success') === '')
  }, [searchParams])
  
  return (
    <Dialog
      TransitionComponent={Transition}
      scroll="body"
      open={open}
      classes={{ paper: classes.paper }}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.windowHeader}>
        App Project Submitted Successfully!
      </DialogTitle>
      <DialogContent className={classes.mainContent}>
        Our Developers will keep you updated about the progress of App delivery and publication
        <CTAButton
            onClick={onClose}
            size="medium"
            style={{marginTop: '10px'}}
            variant="contained"
            color="primary"
          >
          Close
        </CTAButton>
      </DialogContent>
    </Dialog>
  )
}