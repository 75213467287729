import ReactHlsPlayer from 'react-hls-player/dist'
import FullscreenIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExitRounded';
import PlayIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/PauseRounded';
import VolumeMuteIcon from '@mui/icons-material/VolumeMuteRounded';
import VolumeHeighIcon from '@mui/icons-material/VolumeUpRounded';
import VolumeMiddleIcon from '@mui/icons-material/VolumeDownRounded';
import VolumeOffIcon from '@mui/icons-material/VolumeOffRounded';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleChatOpened } from 'redux/actions/tvtrans';
import CTAButton from 'components/atoms/CTAButton/CTAButton';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';

const { makeStyles, IconButton, Slider, Button } = require("@material-ui/core");
const { useState, useEffect } = require("react");

const useStyles = makeStyles(theme => ({
    videoPlayer: ({isFullscreen}) => ({
        width: "100%",
        height: "100%",
        maxHeight: isFullscreen ? "100vh" : "75vh",
        background: "black",
        backdropFilter: "blur(10px)",
        objectPosition: "center",
        objectFit: "contain",
        display: "block",
    }),
    videoPlayerContainer: {
        position: "relative",
        width: "100%",
        height: "max-content",
    
        "&:hover $controls $controlsBottom": {
        },
        
        // "&:hover $controls": {
        //   opacity: 1,
        //   transition: "0.2s ease",
        //   transitionDelay: "0",
        // }
    },
    controls: {
        position: "absolute",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        left: 0,
        top: 0,
        opacity: 0,
        transition: "0.2s ease",
    },
    controlsBottom: {
        position: "absolute",
        bottom: "0",
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "space-between",
        backgroundImage: "linear-gradient(0deg, rgba(0,0,0,0.7), transparent)",
    },
    controlButton: {
        width: "60px",
        display: "flex",
        height: "60px",
        fontSize: "100px !important",
        position: "relative",
        color: theme.palette.primary.contrastText,
    },
    controlsTop: {
        position: "absolute",
        top: "0",
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row-reverse",
        padding: "10px",
    },
    controlButtonBig: ({isPaused}) => ({
        width: "150px",
        display: "flex",
        height: "150px",
        // fontSize: "100px !important",
        position: "relative",
        color: theme.palette.primary.light,
        borderRadius: "50%",
        positon: "relative",
        left: "50%",
        top: "50%",
        transition: "0.2s ease",
        transform: `translate(-50%, -50%) ${isPaused ? "scale(1)" : "scale(0)"}`,
        
        "&::before": {
            content: '""',
            background: "rgba(255,255,255,0.2)",
            width: "100%",
            height: "100%",
            position: "absolute",
            borderRadius: "50%",
        }
    }),
    volumeSlider: ({showVolume}) => ({
        position: "absolute",
        transform: "translateY(-50%)",
        transformOrigin: "center",
        left: "25%",
        bottom: "20px",
        transition: "0.1s ease",
    
        opacity: +showVolume,
    
        // "&::-webkit-slider-runnable-track": {
        //   background: "yellow",
        // }
    }),
}))  

const VolumeButton = ({ videoRef, setMuted, muted }) => {
    const [showVolume, setShowVolume] = useState(false);
    const classes = useStyles({showVolume});
    const [volume, setVolume] = useState(0);
  
    useEffect(() => {
      setVolume(videoRef.current.volume / 100)
    }, [videoRef])
  
    const handleChange = (e, newValue) => {
      setVolume(newValue / 100)
      videoRef.current.volume = newValue / 100;
    };
  
    const toggleMute = () => {
      setMuted(!muted);
      videoRef.current.muted = !muted;
    };
  
    return (
      <div
        className={classes.controlButton}
        onMouseEnter={() => setShowVolume(true)}
        onMouseLeave={() => setShowVolume(false)}
      >
        <IconButton
          className={classes.controlButton}
          onClick={toggleMute}
        >
          {muted ?
              <VolumeOffIcon sx={{width: "30px", height: "30px"}}/>
            : volume > 0.6 ?
              <VolumeHeighIcon sx={{width: "30px", height: "30px"}}/>
            : volume > 0 ?
              <VolumeMiddleIcon sx={{width: "30px", height: "30px"}}/>
            :
              <VolumeMuteIcon sx={{width: "30px", height: "30px"}}/>
          }
        </IconButton>
        <Slider
          aria-label="Temperature"
          defaultValue={50}
          min={0}
          max={100}
          className={classes.volumeSlider}
          orientation='vertical'
          onChange={handleChange}
          color="primary"
        />
      </div>
    );
  };
  
  const PlayButton = ({playerRef, setPaused, isPaused}) => {
    const classes = useStyles();
  
    return (
      <IconButton
        id="playpause"
        data-state="play"
        className={classes.controlButton}
        onClick={() => {
          if(playerRef.current.paused) {
            playerRef.current.play()
          } else {
            playerRef.current.pause()
          }
  
          setPaused(playerRef.current.paused)
        }}
      >
        {isPaused ?
            <PlayIcon sx={{width: "30px", height: "30px"}}/>
          :
            <PauseIcon sx={{width: "30px", height: "30px"}}/>
        }
      </IconButton>
    )
  }
  
  const BigPlayButton = ({playerRef, setPaused, isPaused}) => {
    const classes = useStyles({isPaused});
  
    return (
      <IconButton
        id="playpause"
        data-state="play"
        className={classes.controlButtonBig}
        onClick={() => {
          if(playerRef.current.paused) {
            playerRef.current.play()
          } else {
            playerRef.current.pause()
          }
  
          setPaused(playerRef.current.paused)
        }}
      >
        {isPaused ?
            <PlayIcon sx={{width: "100px", height: "100px"}}/>
          :
            <PauseIcon sx={{width: "100px", height: "100px"}}/>
        }
      </IconButton>
    )
  }
  
export const StreamPlayer = ({video}) => {
    const playerRef = useRef(null);
    const ref = useRef(null);
    const [isPaused, setPaused] = useState(false);
    const [muted, setMuted] = useState(false);
    const [isFullscreen, setFullscreen] = useState(false);
    const [timeoutID, setTimeoutID] = useState(null);
    const [showControls, setShowControls] = useState(false);
    const classes = useStyles({isFullscreen});

    const dispatch = useDispatch();
    const isChatOpened = useSelector(state => state.tvtrans.stream.isChatOpened);
  
    const handleMouseMove = () => {
      setShowControls(true);
  
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
  
      setTimeoutID(setTimeout(() => setShowControls(false), 2000));
    };
  
    useEffect(() => {
      var promise = playerRef.current.play();
  
      if (promise !== undefined) {
        promise.then(_ => {
          playerRef.current.play();
        }).catch(error => {
          playerRef.current.muted = true;
          setMuted(true);
          playerRef.current.play();
        });
      }
    }, [])
  
    return (
      <div onMouseMove={handleMouseMove} ref={ref} className={classes.videoPlayerContainer}>
        <ReactHlsPlayer
            className={classes.videoPlayer}
            src={video.src}
            playerRef={playerRef}
            controls={false}
        >
        </ReactHlsPlayer>
        <div
            id="video-controls"
            className={classes.controls}
            style={{
                opacity: +showControls,
                cursor: showControls ? "default" : "none"
            }}
            data-state="hidden"
        >
            <div id="video-controls" className={classes.controlsTop} data-state="hidden">
                {!isChatOpened &&
                    <CTAButton
                        onClick={() => {
                            dispatch(toggleChatOpened(true))
                        }}
                        color="primary"
                        small
                        iconElement={<ChatRoundedIcon/>}
                    >
                        open
                    </CTAButton>
                }
            </div>
            <BigPlayButton
                playerRef={playerRef}
                setPaused={setPaused}
                isPaused={isPaused}
            />
            <div id="video-controls" className={classes.controlsBottom} data-state="hidden">
                <div>
                    <PlayButton
                        playerRef={playerRef}
                        setPaused={setPaused}
                        isPaused={isPaused}
                    />
                </div>
                <div style={{display: "flex"}}>
                    <VolumeButton
                        videoRef={playerRef}
                        setMuted={setMuted}
                        muted={muted}
                    />
                    <IconButton
                        id="fs"
                        type="button"
                        data-state="go-fullscreen"
                        className={classes.controlButton}
                        onClick={() => {
                          if(document.fullscreenElement !== null) {
                              document.exitFullscreen();
                          } else {
                              ref.current.requestFullscreen();
                          };
          
                          setFullscreen(document.fullscreenElement === null);
                        }}
                    >
                        {isFullscreen ?
                            <FullscreenIcon sx={{width: "30px", height: "30px"}}/>
                        :
                            <FullscreenExitIcon sx={{width: "30px", height: "30px"}}/>
                        }
                    </IconButton>
                </div>
            </div>
        </div>
      </div>
    )
  }
  