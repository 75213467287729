import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Text from 'components/atoms/Text/Text';
import AlexaFormVisitWebsite from 'components/molecules/AlexaFormVisitWebsite/AlexaFormVisitWebsite';
import React, { useState } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles(theme => ({
    Inputs: {
        '&>*': {
            padding: 10,
            [theme.breakpoints.down(1100)]: {
                minWidth: '100%',
            },
        },
        '& $button': {
            margin: '0 !important'
        },
    },
    TextFieldsExaple: {
        '& $input': {
            color: theme.palette.text.primary
        },
    },
    TextFieldMode1: {
        '& $fieldset': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }
    },
    TextFieldMode2: {
        '& $fieldset': {
            borderBottomWidth: 0,
            borderTopWidth: 0,
            borderRadius: 0,
        }
    },
    TextFieldMode3: {
        '& $fieldset': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        }
    },
    SubmitButton: {
        display: 'flex',
        justifyContent: 'center'
    },
}));

const languages = [
    'English (US)',
    'English (AU)',
    'English (CA)',
    'English (IN)',
    'English (UK)',
    'German (DE)',
    'Spanish (ES)',
    'Spanish (US)',
    'Spanish (MX)',
    'French (FR)',
    'Italian (IT)',
    'Japanese (JP)',
    'French (CA)',
    'Portuguese (BR)',
    'Hindi (IN)',
]

const AlexaForm2 = ({ withWebsiteVisit }) => {
    const classes = useStyles();
    const [iconName, setIconName] = useState('Choose Image');
    const [selectedLang, setSelectedLang] = useState(0);

    const attachFiles = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = "image/jpeg,image/png";
        input.onchange = e => {
            setIconName(e.target.files[0].name);
            input.remove();
        }
        input.click();
    }

    return (
        <Grid item xs={12}>
            {withWebsiteVisit && <AlexaFormVisitWebsite />}
            <Grid container className={classes.Inputs}>
                <Grid item xs={12}>
                    <Text component='h5' variant='h5'>Choose username</Text>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        id="outlined-required"
                        label='Stream URL (HTTPS required)'
                        placeholder='Stream URL'
                        variant="outlined"
                        size='small'
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="error">Invocation can not be changed after Skill is Published</Alert>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="outlined-required"
                        label='Invocation'
                        variant="outlined"
                        size='small'
                        fullWidth
                        helperText={'Your invocation name should be two or more words, and can contain only lower-case alphabetic characters, spaces between words, possessive apostrophes (for example, "sam`s science trivia"), or periods used in abbreviations (for example, "a. b. c."). Other characters like numbers must be spelled out. For example, "twenty one". Read more on requirements'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Text component='h5' variant='h5'>Example phrases</Text>
                </Grid>
                <Grid item xs={12} className={classes.TextFieldsExaple}>
                    <TextField
                        className={classes.TextFieldMode1}
                        id="outlined-required"
                        variant="outlined"
                        size='small'
                        value='Alexa, launch'
                        disabled
                        fullWidth
                    />
                    <TextField
                        className={classes.TextFieldMode2}
                        id="outlined-required"
                        variant="outlined"
                        size='small'
                        value='Alexa, play'
                        disabled
                        fullWidth
                    />
                    <TextField
                        className={classes.TextFieldMode3}
                        id="outlined-required"
                        variant="outlined"
                        size='small'
                        value='Alexa, ask what is current title'
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        id="outlined-required"
                        label='Public Name'
                        variant="outlined"
                        size='small'
                        fullWidth
                        helperText={'The name of the skill that will be displayed to customers in the Alexa app. It can be different from your invocation name and must be between 2-50 characters.'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl size='small' fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedLang}
                            onChange={e => setSelectedLang(e.target.value)}
                            label="Language"
                        >
                            {languages.map((lang, i) => (
                                <MenuItem key={i} value={i}>{lang}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Language selected determines the Amazon Store version where your Skill will be Published, e.g. English UK = Amazon UK</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="info">Notice: Create descriptions in the same language as selected for Alexa Skill. Your decriptions can be modified and supplemented in order to meet Amazon requirements.</Alert>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="outlined-required"
                        label='One Sentence Description'
                        variant="outlined"
                        size='small'
                        fullWidth
                        minRows={2}
                        multiline
                        helperText={'A quick at-a-glance sentence that describes the skill or what customers can do with it. This will display in the skill list in the Alexa App.'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="outlined-required"
                        label='Detailed Description'
                        variant="outlined"
                        size='small'
                        fullWidth
                        minRows={3}
                        multiline
                        helperText={'We recommend including information about the radio station, broadcast type and content (music genres, language etc).'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="info">Images should not contain other logos and advertisement</Alert>
                </Grid>
                <Grid item xs={12}>
                    <Text component='p' mb={1}>Icon</Text>
                    <Button variant='outlined' onClick={attachFiles}>{iconName}</Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="outlined-required"
                        label='Keywords'
                        variant="outlined"
                        size='small'
                        fullWidth
                        helperText={'Simple search words that relate to or describe this skill. This helps customers find the skill quickly and easily. Use spaces between each search term'}
                    />
                </Grid>
                <Grid item xs={12}className={classes.SubmitButton}>
                    <Button variant='contained' color='primary'>Submit</Button>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default AlexaForm2;