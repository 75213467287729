import { SET_ALEXA_DATA, SET_APP_DATA, SET_HOME_DATA, SET_LOGIN_DATA, SET_NOTIFICATION_DATA, SET_ORDER_DATA, SET_ORDER_TV_DATA, SET_SLS_DATA, SET_TOP_BAR_DATA } from 'redux/actionTypes/cmsTypes';
import produce from 'immer';

const initialState = {
  topBar: {
    bar: {},
  },
  home: {
    hero: {
      title: '',
      content: '',
      buttons: [],
      cover: {},
      video: {},
    },
    bar: {},
  },
  app: {
    hero: {
      title: '',
      content: '',
      buttons: [],
    },
    bar: {},
  },
  alexa: {
    hero: {
      title: '',
      content: '',
      buttons: [],
    },
    bar: {},
  },
  login: {
    heading: '',
    button: {},
  },
  order: {
    promocode: '',
    cycle: '',
  },
  orderTv: {
    promocode: '',
    cycle: '',
  },
  sls: {
    hero: {
      title: '',
      content: '',
      buttons: [],
    },
    bar: {},
  },
  notification: {
    active: false,
    content: '',
    date: '',
    image: '',
    mobile: '',
    link: '',
  },
};

// reducer to access to the data from strapi and use it on site
const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOP_BAR_DATA:
      return produce(state, draftState => {
        draftState.topBar = { ...state.topBar, ...action.payload };
      });
    case SET_HOME_DATA:
      return produce(state, draftState => {
        draftState.home = { ...state.home, ...action.payload };
      });
    case SET_APP_DATA:
      return produce(state, draftState => {
        draftState.app = action.payload;
      });
    case SET_ALEXA_DATA:
      return produce(state, draftState => {
        draftState.alexa = action.payload;
      });
    case SET_LOGIN_DATA:
      return produce(state, draftState => {
        draftState.login = action.payload;
      });
    case SET_ORDER_DATA:
      return produce(state, draftState => {
        draftState.order = action.payload;
      });
    case SET_ORDER_TV_DATA:
      return produce(state, draftState => {
        draftState.orderTv = action.payload;
      });
    case SET_SLS_DATA:
      return produce(state, draftState => {
        draftState.sls = action.payload;
      });
    case SET_NOTIFICATION_DATA:
      return produce(state, draftState => {
        draftState.notification = action.payload;
      });
    default:
      return state;
  }
};

export default generalReducer;
