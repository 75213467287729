import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import VisaIcon from '../../assets/svg/VisaIcon';
import MastercardIcon from '../../assets/svg/MastercardIcon';
import PaypalIcon from '../../assets/svg/PaypalIcon';
import bankTransfer from '../../assets/img/bankTransfer.webp';
import idealLogo from '../../assets/img/idealLogo.webp';
import pscLogo from '../../assets/img/pscLogo.webp';
import BoxTitle from 'components/atoms/BoxTitle/BoxTitle';
import SecureMessage from 'components/atoms/SecureMessage/SecureMessage';
import PaymentButton from 'components/atoms/PaymentButton/PaymentButton';
import Image from 'components/atoms/Image/Image';
import Text from 'components/atoms/Text/Text';
import styled, { css } from 'styled-components';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    overflow: hidden;
  `}
`;

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20,
        '&>div': {
            maxWidth: 1250,
            margin: '0 auto'
        },
        [theme.breakpoints.down(768)]: {
            padding: 0,
        },
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: 20,
        '& $a': {
            textDecoration: 'none'
        }
    },
    content: {
        width: '100%',
        padding: '0 20px 20px 20px',
        '&>div:not(:last-child)': {
            marginBottom: 20
        }
    },
    AlertButton: {
        height: '100%',
    },
    Status: {
        textTransform: 'uppercase',
        padding: '10px 20px',
        border: '2px solid',
        fontWeight: 800,
        fontSize: '1rem'
    },
    Paid: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main
    },
    Unpaid: {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main
    },
    Cancelled: {
        color: theme.palette.grey[600],
        borderColor: theme.palette.grey[600]
    },
    PaymentWrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: 15,
        '&>div': {
            width: '100%',
            maxWidth: '400px'
        }
    },
    InvoiceWrapper: {
        width: '100%',
        margin: '5px 0',
        '&>div': {
            width: '100%',
            margin: '15px 0',
        }
    },
    InvoiceHeader: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 20,
        '&>div': {
            [theme.breakpoints.down(768)]: {
                minWidth: '100%',
            },
        }
    },
    InvoiceHeaderLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    InvoiceHeaderInfo: {
        display: 'flex',
        flexDirection: 'column',
        '&>div:nth-child(1)': {
            backgroundColor: theme.palette.primary.main,
            fontSize: '1.875rem',
            color: 'white',
            padding: '1.5rem',
            fontWeight: 500,
            [theme.breakpoints.down(768)]: {
                textAlign: 'center',
            },
        },
        '&>div:nth-child(2)': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5rem 1.5rem'
        },
        '&>div:nth-child(3)': {
            alignSelf: 'flex-end',
            '&>img': {
                maxWidth: '100%'
            }
        },
    },
    InvoiceContactInfo: {
        display: 'flex',
        padding: '0 15px',
        '&>div': {
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            marginBottom: 15,
            [theme.breakpoints.down(768)]: {
                minWidth: '100%',
            },
        }
    },
    InvoiceProductTable: {
        '&>div': {
            '&>div': {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                padding: '0 15px',
                width: '100%',
                '&>div': {
                    padding: '10px 0',
                }
            }
        }
    },
    InvoiceSummary: {
        display: 'flex',
        justifyContent: 'flex-end',
        '&>div': {
            display: 'inline-flex',
            flexDirection: 'column',
            '&>div': {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 15px',
                width: '100%',
                '&>div:first-child': {
                    paddingRight: 15
                },
                '&>div': {
                    padding: '10px 0'
                }
            }
        }
    },
    InvoiceSummaryTotal: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
    },
    paymethod: {
        marginLeft: theme.spacing(0.5),
        backgroundColor: ({ background }) => background === 'dark' ? 'transparent' : theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
    },
    icon: {
        width: theme.spacing(6),
        padding: theme.spacing(0, 0.5),
        border: 'solid 1px',
        borderColor: theme.palette.grey[300],
    },
    paypal: {
        width: theme.spacing(3),
        height: theme.spacing(3.5),
        padding: theme.spacing(0.5),
    },
    image: {
        height: theme.spacing(3),
        padding: theme.spacing(0.5),
        '& img': {
            height: '100%',
        },
    },
    lightMode: {
        backgroundColor: ({ background }) => background === 'dark' ? 'rgba(0,0,0, .2)' : 'rgba(0,0,0, .1)'
    },
    darkMode: {
        backgroundColor: ({ background }) => background === 'dark' ? 'rgba(0,0,0, .3)' : 'rgba(0,0,0, .2)'
    },
    TextAlignContainer: {
        display: 'flex',
        alignItems: 'center',
        '&>*': {
            width: '100%'
        }
    }
}));

const Payment = () => {
    const dispatch = useDispatch();
    // const details = useSelector(state => state.client.details);
    const background = useSelector(state => state.general.theme);
    const classes = useStyles({ background });
    const [data, setData] = useState({});
    const splitted = window.location.pathname.split('/');
    const paymentId = splitted[splitted.length - 1];

    const handleClick = () => {

    }

    return (
        <Box className={classes.Wrapper}>
            <Grid container>
                <Grid item className={classes.title}>
                    <Link to={"/account/payments"}>
                        <Button variant='contained' color='primary' className={classes.AlertButton}>Back</Button>
                    </Link>
                    <div className={`${classes.Status} ${classes['Unpaid']}`}>Unpaid</div>
                </Grid>
                <Grid item className={classes.content}>
                    <Card>
                        <Grid container className={classes.PaymentWrapper}>
                            <Grid item>
                                <BoxTitle variant="h5" component="h1" mb={2}>
                                    PAY NOW
                                </BoxTitle>
                                <SecureMessage />
                                <Box>
                                    <PaymentButton onClick={() => handleClick('fasterpay')}>
                                        Debit / Credit Card
                                        <Box display="flex" alignItems="center" ml={1}>
                                            <VisaIcon className={`${classes.paymethod} ${classes.icon}`} />
                                            <MastercardIcon className={`${classes.paymethod} ${classes.icon}`} />
                                        </Box>
                                    </PaymentButton>
                                    <PaymentButton onClick={() => handleClick('paypal')}>
                                        PayPal
                                        <Box display="flex" alignItems="center" ml={1}>
                                            <PaypalIcon className={`${classes.paymethod} ${classes.paypal}`} />
                                        </Box>
                                    </PaymentButton>
                                    <PaymentButton onClick={() => handleClick('paymentwall')}>
                                        +120 methods
                                        <Box display="flex" alignItems="center" ml={1}>
                                            <Box className={`${classes.paymethod} ${classes.image}`}>
                                                <Image src={bankTransfer} />
                                            </Box>
                                            <Box className={`${classes.paymethod} ${classes.image}`}>
                                                <Image src={idealLogo} />
                                            </Box>
                                            <Box className={`${classes.paymethod} ${classes.image}`}>
                                                <Image src={pscLogo} />
                                            </Box>
                                        </Box>
                                    </PaymentButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                    <Card>
                        <Grid container className={classes.InvoiceWrapper}>
                            <Grid item>
                                <Grid container className={classes.InvoiceHeader}>
                                    <Grid item xs={5} className={classes.InvoiceHeaderLogo}>
                                        <div>
                                            <img src="https://img.fastcast4u.com/logo.png" alt="logo" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={7} className="col-md-7 p-0">
                                        <div className={classes.InvoiceHeaderInfo}>
                                            <div>INVOICE #{'data.id'}</div>
                                            <div className={classes.lightMode}><span>Invoice Date</span><span>{'data.duedate'}</span></div>
                                            <div><img className='mw-100' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASIAAAAUCAYAAAAp6jCyAAAAAXNSR0IArs4c6QAAAkBJREFUeF7tm+1ugzAMReH9H5ppm9YhSqJzcdJS6fS3m7nO9fEHbN22bVvAZ13XU6v91/c2rWOJTcud1IfWORXfWj7s/1Z6Pvld+/Pv5gP5veQuRsWB+ENs7uZzS2Np3CpaauVvxYdvf1ZB9H8tBJJ3gwBJlpbwWkWkAtVRIk99SO0F0W/EUj0Lok7QSEK9S6izISCIniNAIENsSGzTTmCGfXrmqGJB8g4OXHZEBBSViyPnp0JKq9hsHz4xqT/RZ0ezC90IuWhKy78LIAk7I0kF0XNvMGOMJfdL7qKivVQ/r/RZEAmihwZSoc7uRsj4MNsHkvjEz1cm9Sf6LIgEkSA6kMSOqL/sTUFXsU8BTrpKAj13RIekIAFxWf2cOCRuJEGIjR3RPHAJomVZfI+o/tQmFdLdxkNBdE0DaXEkXQo5047oMOK5rB5bJYkIR70H4mg29u5SmFe6WUEkiB4aqAjJjqgOgTTxR3Ujo7rfin4EkSASRBf2eyk0iD2xudteaxQMBZEgEkSC6JRvKRjtiKCQSGDdEdVHDFK1WxWwImZ3RGPvjuSLHZHvET00cLf9jCC69sQqTfxREHBHdK5Y//t+F5cZVX52NyKIBFEKN3dE7ojcEcHRvtWBkCQi3Q6xIZBPITDDPj2TxJB0gJXR/hhbOyI7op8IpGKe0T1WfEjBJYj6Oy5B1CpDMFlm7HAqFcTRrHOhnTfzU7Ck9oJIEJ2OJ3259oNGWsR3CVUQCSJSyFIwEs2Tp9Jp4R71Zv5RFV9UBiwfiXVjcAAAAABJRU5ErkJggg==" /></div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container className={classes.InvoiceContactInfo}>
                                    <Grid item xs={7} className="col-md-7 d-flex flex-column justify-content-start mb-2">
                                        <Text component='p'><strong>PAY TO</strong></Text>
                                        <Text component='p'>FastCast DWC-LLC</Text>
                                        <Text component='p'>Business Center, Dubai World Central,</Text>
                                        <Text component='p'>P.O. Box 390667, Dubai, U.A.E.</Text>
                                    </Grid>
                                    <Grid item xs={5} className="col-md-5 d-flex flex-column justify-content-start mb-2">
                                        <Text component='p'><strong>INVOICED TO</strong></Text>
                                        <Text component='p'>{`${'data.firstname'} ${'data.lastname'}`}</Text>
                                        <Text component='p'>{`${'details.address1'}${!'details.address1' || !'details.city' ? '' : ', '}${'details.city'}`}</Text>
                                        <Text component='p'>{'details.countryname'}</Text>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.InvoiceProductTable}>
                                <Grid container>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <Text component='p'><strong>DESCRIPTION</strong></Text>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Text component='p' align='right'><strong>AMOUNT</strong></Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.lightMode}>
                                        <Grid container>
                                            <Grid item xs={9} className={classes.TextAlignContainer}>
                                                <Text component='p'>2021 | Live & Web TV + Social Media Streaming - Live & Web TV + Social Media Streaming | 100 viewers HD (2021-09-22 - 2021-10-21)</Text>
                                            </Grid>
                                            <Grid item xs={3} className={classes.TextAlignContainer}>
                                                <Text component='p' align='right'>1.00 USD</Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.darkMode}>
                                        <Grid container>
                                            <Grid item xs={9} className={classes.TextAlignContainer}>
                                                <Text component='p'>Mobile App - Apple iOS Mobile App</Text>
                                            </Grid>
                                            <Grid item xs={3} className={classes.TextAlignContainer}>
                                                <Text component='p' align='right'>59.00 USD</Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.lightMode}>
                                        <Grid container>
                                            <Grid item xs={9} className={classes.TextAlignContainer}>
                                                <Text component='p'>Addon - AdMob Advertising</Text>
                                            </Grid>
                                            <Grid item xs={3} className={classes.TextAlignContainer}>
                                                <Text component='p' align='right'>20.00 USD</Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.darkMode}>
                                        <Grid container>
                                            <Grid item xs={9} className={classes.TextAlignContainer}>
                                                <Text component='p'>Addon - Push Notifications</Text>
                                            </Grid>
                                            <Grid item xs={3} className={classes.TextAlignContainer}>
                                                <Text component='p' align='right'>10.00 USD</Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container className={classes.InvoiceSummary}>
                                    <Grid item>
                                        <Grid container className={classes.darkMode}>
                                            <Grid item>
                                                <Text component='p'>Sub Total</Text>
                                            </Grid>
                                            <Grid item>
                                                <Text component='p'>10.00 USD</Text>
                                                {/* <Text component='p'>{`${'data.subtotal'} ${'data.currencycode'}`}</Text> */}
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.darkMode}>
                                            <Grid item>
                                                <Text component='p'>Credit</Text>
                                            </Grid>
                                            <Grid item>
                                                <Text component='p'>0.00 USD</Text>
                                                {/* <Text component='p'>{`${'data.credit'} ${'data.currencycode'}`}</Text> */}
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.InvoiceSummaryTotal}>
                                            <Grid item>
                                                <Text component='p' variant='h6'>TOTAL</Text>
                                            </Grid>
                                            <Grid item>
                                                <Text component='p' variant='h6'>10.00 USD</Text>
                                                {/* <Text component='p' variant='h6'>{`${'data.total'} ${'data.currencycode'}`}</Text> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
export default Payment;