import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import generalServices from 'services/general';
import { Container, Grid, List, ListItem, makeStyles, Box } from '@material-ui/core';
import Accordion from 'components/organisms/Accordion/Accordion';
import faqServices from 'services/faq';
import Text from 'components/atoms/Text/Text';
import HeadingBlock from 'components/molecules/HeadingBlock/HeadingBlock';
import PricingTable from 'components/organisms/PricingTable/PricingTable';
import { useSelector } from 'react-redux';
import { openScaduleWidget } from 'utils/openScaduleWidget';

const useStyles = makeStyles(theme => ({
  pricing: {
    margin: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    whiteSpace: 'pre-wrap',
  },
  sectionTitle: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    textAlign: 'center',
  },
  list: {
    paddingLeft: 15,
    margin: 0,
    lineHeight: 1.5,
    listStyleType: 'disc',

    [theme.breakpoints.only('xs')]: {
      listStyle: 'none',
      padding: 0,
      lineHeight: 1.2,
    }
  },
  listItem: {
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      textAlign: 'left',
    },
    textAlign: 'center',
    display: 'list-item',
    position: 'relative',
  },
  listItemContent: {
    position: 'relative',
    left: "-10px"
  }
}));

// radio promotion page but with ability to schedule meeting
const RadioPromotion = () => {
  const location = useLocation();
  const [promocode, setPromocode] = useState(null);
  const [price, setPrice] = useState(null);
  const classes = useStyles();
  const content = useSelector(state => state.language.start.radioPromotion)
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const urlPromocode = new URLSearchParams(location.search).get('promo');
    const promocode = 'directoryRegPromo';
    setPromocode(urlPromocode || promocode);
    faqServices.getArticles('88').then(({ articles }) => {
      setArticles(articles || []);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (promocode === null) return;
    // if promocode is provided, procut price is required and seted to state variable to be used in PricingTable
    generalServices.getPrice({ pid: 479, promocode }).then(res =>
      setPrice({
        current: res.current,
        regular: res.regular,
      }),
    );
  }, [promocode]);

  const pricingData = {
    title: 'Radio Directory Package',
    // list: [],
    list: ['Online Radio Directory Submission Premium Package'],
    //opens widget for scheduling
    onClick: openScaduleWidget,
    // link: `https://billing.fastcast4u.com/cart.php?a=add&pid=479&promocode=${promocode}`,
    ...price,
  };

  return (
    <Container maxWidth="xl">
      <HeadingBlock
        topPadding
        title={content.heading.title}
        subtitle={content.heading.subtitle}
      />
      <Grid container spacing={3} justify="center">
        <Grid className={classes.content} item xs={12} lg={7} xl={8}>
          <Text component="h2" variant="h4" className={classes.sectionTitle} fontWeight={500} mb={4}>
            {content.section.title}
          </Text>
          <List className={classes.list} mb={3}>
            {content.section.list.map(element => (
              <ListItem className={classes.listItem} key={content}>
                {/* {element} */}
                <span className={classes.listItemContent}>{element}</span>
              </ListItem>
            ))}
          </List>
        </Grid>
        <PricingTable
          className={classes.pricing}
          {...pricingData}
          xs={12}
          sm={9}
          md={7}
          lg={5}
          xl={4}
        />
      </Grid>
      <Box>
        <HeadingBlock
          topPadding
          title={'Frequently Asked Questions'}
        />
        <Accordion
            data={articles}
            summaryProps={{
              component: 'h3',
              variant: 'subtitle1',
              fontWeight: 500,
            }}
            injectHTML
            start={null}
          />
      </Box>
      {/* <PricingBlock data={pricingData} /> */}
    </Container>
  );
};

export default RadioPromotion;
