import React from 'react';
import { Route } from 'react-router-dom';
import RadioPromotion from 'views/RadioPromotion/RadioPromotion';
import RadioPromotionPromo from 'views/RadioPromotion/RadioPromotionPromo';

//children routes displayed on /radio-promotion
const RadioPromotionRoutes = [  
  <Route index element={<RadioPromotion/>} />,
  // promotion route made for ability to scadule a meeting
  <Route path={`promo`} element={<RadioPromotionPromo/>} />
];

export default RadioPromotionRoutes;
