import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy].data < a[orderBy].data) {
        return -1;
    }
    if (b[orderBy].data > a[orderBy].data) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, showId, notCheckboxes } = props;

    // "property" argument should be column id the table should be sorted by
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {!notCheckboxes && <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>}
                {columns.map((column, i) => (
                    (showId || i !== 0) && 
                        <TableCell
                            key={column.id}
                            align={column.numeric ? 'right' : 'left'}
                            padding={column.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === column.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'asc'}
                                onClick={createSortHandler(column.id)}
                            >
                                {column.label}
                                {orderBy === column.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
    showId: PropTypes.bool,
    notCheckboxes: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    PaginationWrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    ButtonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px'
    },
    PaginationRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    TableRow: {
        cursor: 'pointer'
    },
    SelectedRow: {
        backgroundColor: ({background}) => background === 'dark' ? 'hsl(0deg 0% 21%)' : 'hsl(0deg 0% 96%)'
    },
	Paid: {
        color: theme.palette.success.main
    },
	Unpaid: {
        color: theme.palette.secondary.main
    },
	Cancelled: {
        color: theme.palette.grey[500]
    },
}));

export default function EnhancedTable({ extraPaginationButton, handleClick, rows, columns, showId, defaultRowsPerPage, notCheckboxes, selectedRow, rowsPerPageOptions }) {
    const background = useSelector(state => state.general.theme);
    const classes = useStyles({background});
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

    // handles column to be sort by, and sort order (asc or desc). "Property" argument should be id of column the table should be sorted by.
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // function for tableheading checkboxsets all rows selected or unselected depending on if they are all selected allready
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n[Object.keys(n)[0]].data);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // sets row with given name (id) selected
    const setSelectedHandleClick = (name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) { //if given name (id) is not selected
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) { //if given name (id) is selected, and it is the name (id) of the first row
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) { //if given name (id) is selected, and it is the name (id) of the last row
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) { //if given name (id) is the name (id) of the row located some
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //changes rows per page count
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const isSelected = (name) => selected.indexOf(name) !== -1; //checks if given name (id) is selected (returns true if it is)

    // count of rows that could be placed on the page, but there is no rows left
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        {columns && <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            columns={columns}
                            showId={showId}
                            notCheckboxes={notCheckboxes}
                        />}
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const rowKeys = Object.keys(row);
                                    const id = row[rowKeys[0]].data;
                                    const isItemSelected = isSelected(id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={e => handleClick(e, id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={id}
                                            selected={isItemSelected}
                                            className={`${classes.TableRow} ${selectedRow === id ? classes.SelectedRow : ''}`}
                                        >
                                            {/* checkbox to select row */}
                                            {!notCheckboxes && <TableCell padding="checkbox">
                                                <Checkbox
                                                    onChange={() => setSelectedHandleClick(id)}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>}
                                            {/* table cells list */}
                                            {rowKeys.map((key, i) => (
                                                (showId || i !== 0) && <TableCell key={i} align={(row[key] || {}).alignRight ? "right" : "left"}>{(row[key] || {}).data}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                // empty place to prevent table to change it's size
                                <TableRow style={{ height: 57 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className={classes.PaginationWrapper}>
                    {extraPaginationButton ?
                        <Box className={classes.ButtonWrapper}>{extraPaginationButton}</Box>
                        :
                        <Box></Box>
                    }
                    <TablePagination
                        className={classes.PaginationRoot}
                        rowsPerPageOptions={rowsPerPageOptions ? [...rowsPerPageOptions] : [5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage='Rows:'
                    />
                </Box>
            </Paper>
        </div>
    );
}
