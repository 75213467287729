import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        position: "relative",
        paddingBottom: "60%",
        marginBottom: "15px",  
        display: "flex",
        justifyContent: "center"
    },
    containerInSection: {
        width: "100%",
        position: "relative",
        paddingBottom: "60%",
        marginBottom: "15px",  
        display: "flex",
        justifyContent: "center"
    },
    animationContainer: {
        position: "absolute" ,
        height: "100%",
        width: "100% !important",
        perspective: "1000px",
    },
    laptopBorder: {
        transformOrigin: "bottom",
        position: "absolute",
        height: "90%",
        width: "89% !important",
        backgroundSize: "113%",
        backgroundPosition: "50% 15%",
        backgroundImage: 'url(https://img.fastcast4u.com/react/home/tv.png)',
        backgroundRepeat: "no-repeat",
        transition: "0.3s",
        bottom: "5.3%",
        transform: "rotateX(-15deg) scale(1.02)",
        perspective: "1000px",
    },
    laptopKeyboard: {
        transformOrigin: "center",
        position: "absolute",
        height: "5.9%",
        bottom: 0,
        width: "100%",
        backgroundImage: 'url(https://img.fastcast4u.com/react/home/tv.png)',
        backgroundSize: "102%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "52% 97%",
        transition: "0.3s",
    },
    screen: {
        boxShadow: "inset 0 0 4px black",
        position: "absolute",
        top: "5%",
        left: "3%",
        bottom: "5%",
        right: "3%",
        borderRadius: "1px",
        width: "auto !important",

        "&::before": {
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: `url(https://img.fastcast4u.com/react/tv/4.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            content: '""',
            transition: "ease 0.1s",
            transitionDelay: "0.2s",
        },
        
        "&::after": {
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0.2)",
            width: "100%",
            height: "100%",
            background: "linear-gradient(53deg, transparent 68%, rgb(255, 255, 255, 1) 68.5%, transparent)",
            transition: "0.3s ease",
            opacity: 0.2,
            content: '""',
        }
    },
    "@global": {
        "div:has(> $container), section:has(> $container)": {
            "&:hover > $container $leftMobile": {
                transform: "scale(110%) translate(-140%, -50%)",
                transitionDelay: "0.1s",
            },
            "&:hover > $container $centerMobile": {
                transform: "scale(120%) translate(-50%, -50%)",
            },
            "&:hover > $container $rightMobile": {
                transform: "scale(110%)  translate(30%, -50%)",
                transitionDelay: "0.2s",
            },
        },
        "section:has(> div $containerInSection) div:has(> div $containerInSection)": {
            "&:hover $containerInSection $laptopBorder": {
                transform: "translateY(0) scale(1.01)",
            },
            "&:hover $containerInSection $laptopBorder $screen": {
                "&::after": {
                    backgroundColor: "transparent",
                    opacity: 0.3,
                },
                "&::before": {
                    backgroundImage: `url(https://img.fastcast4u.com/react/tv/10.png)`,
                }
            },

            "&:hover $containerInSection $rightMobile": {
                transform: "scale(110%)  translate(30%, -50%)",
                transitionDelay: "0.2s",
            },
        }
    },
}))

export default function OnlineTvHostingAnimation({isForSection}) {
    const classes = useStyles();
    return (
        <div className={!isForSection ? classes.container : classes.containerInSection}>
            <div className={classes.animationContainer}>
                <div className={classes.laptopBorder}>
                    <div className={classes.screen}>

                    </div>
                </div>
                <div className={classes.laptopKeyboard}/>
            </div>
        </div>
    )
}
