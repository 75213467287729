import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MastercardIcon = props => (
  <SvgIcon viewBox="0 0 24 16" {...props}>
    <path
      style={{ fill: 'rgb(100%,37.254902%,0%)' }}
      d="M 8.835938 2.808594 L 15.167969 2.808594 L 15.167969 13.1875 L 8.835938 13.1875 Z M 8.835938 2.808594 "
    />
    <path
      style={{ fill: 'rgb(92.156863%,0%,10.588235%)' }}
      d="M 9.488281 8 C 9.484375 5.976562 10.410156 4.0625 12 2.8125 C 9.304688 0.683594 5.429688 0.992188 3.105469 3.523438 C 0.777344 6.050781 0.777344 9.949219 3.105469 12.480469 C 5.429688 15.007812 9.304688 15.316406 12 13.191406 C 10.410156 11.941406 9.484375 10.027344 9.488281 8 Z M 9.488281 8 "
    />
    <path
      style={{ fill: 'rgb(96.862745%,61.960784%,10.588235%)' }}
      d="M 22.011719 12.089844 L 22.011719 11.878906 L 22.101562 11.878906 L 22.101562 11.835938 L 21.886719 11.835938 L 21.886719 11.878906 L 21.96875 11.878906 L 21.96875 12.089844 Z M 22.433594 12.089844 L 22.433594 11.835938 L 22.367188 11.835938 L 22.289062 12.015625 L 22.214844 11.835938 L 22.148438 11.835938 L 22.148438 12.089844 L 22.195312 12.089844 L 22.195312 11.894531 L 22.265625 12.0625 L 22.316406 12.0625 L 22.386719 11.894531 L 22.386719 12.089844 Z M 22.433594 12.089844 "
    />
    <path
      style={{ fill: 'rgb(96.862745%,61.960784%,10.588235%)' }}
      d="M 22.640625 8 C 22.640625 10.527344 21.203125 12.832031 18.9375 13.9375 C 16.671875 15.042969 13.976562 14.75 12 13.1875 C 13.585938 11.9375 14.511719 10.023438 14.511719 8 C 14.511719 5.972656 13.585938 4.058594 12 2.808594 C 13.976562 1.246094 16.671875 0.957031 18.9375 2.0625 C 21.203125 3.164062 22.640625 5.472656 22.640625 8 Z M 22.640625 8 "
    />
  </SvgIcon>
);

export default MastercardIcon;
