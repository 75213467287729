import React from 'react';
import { Route } from 'react-router-dom';
import AppTv from 'views/AppTV/AppTV';

import AppleTV from 'views/AppleTV/AppleTV';
import AppleTVPromo from 'views/AppleTV/AppleTVPromo';

//children routes for /apple-tv page. Used to divide /app page and its promo version /apple-tv/promo
const AppTVRoutes = [  
  <Route index element={<AppTv/>} />,
  // <Route path={`promo`} element={<AppleTVPromo/>} />
];

export default AppTVRoutes;
