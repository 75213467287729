import { Fragment, useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import generalServices from 'services/general';
import { Divider, makeStyles } from '@material-ui/core';
import FullContainer from 'components/atoms/FullContainer/FullContainer';
import TitleSection from 'components/organisms/TitleSection/TitleSection';
import RowSection from 'components/organisms/RowSection/RowSection';
import HeroSection from 'components/organisms/HeroSection/HeroSection';
import PricingTable from 'components/organisms/PricingTable/PricingTable';
import Accordion from 'components/organisms/Accordion/Accordion';
import AppDownloadSection from 'components/organisms/AppDownloadSection/AppDownloadSection';
import { useCurrentLanguage } from 'utils/customHooks';
import { handleExternal } from 'utils/urls';
import ScrollableAnchor from 'react-scrollable-anchor';
import { CheckMore } from 'components/molecules/CheckMore/CheckMore';
import { useNavigate } from 'react-router';
import AppleTvFeatureAnimation from 'components/atoms/AppleTvFeatureAnimation/AppleTvFeatureAnimation';
import PricingGrid from 'templates/PricingGrid';
import PricingBlock from 'components/organisms/PricingBlock/PricingBlock';

const useStyles = makeStyles(theme => ({
  list: {
    paddingLeft: 15,
    margin: 0,
    lineHeight: 1.5,

    
    [theme.breakpoints.only('xs')]: {
      listStyle: 'none',
      padding: 0,
      lineHeight: 1.2,
    }
  }
}));

const AppTv = () => {
  const [prices, setPrices] = useState([]);
  const content = useSelector(state => state.language.appTV);
  const lng = useCurrentLanguage();
  const blockScrollToRef = useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const [promo, setPromo] = useState(""); 

  // requests prices of needed products, to display them in pricing cards
  useEffect(() => {
    const promocode = new URLSearchParams(location.search).get('promo');
    setPromo(promocode);
    Promise.all([
      generalServices.getPrice({ pid: 560, promocode: promocode ? promocode : 'appletvpromo' }),
      generalServices.getPrice({ pid: 561, promocode: promocode ? promocode : 'appletvpromo' }),
      generalServices.getPrice({ pid: 549, promocode: promocode ? promocode : 'appletvpromo' }),
      generalServices.getPrice({ pid: 562, promocode: promocode ? promocode : 'appletvpromo' })
    ]).then(res => {
      console.log("res", res)
      setPrices(res)
    });
  }, []);

  //function that scrolls page to main content
  const checkOutMoreHandler = () => blockScrollToRef.current.scrollIntoView({ behavior: 'smooth' });

  const heroData = useMemo(() => ({
    isNoStrapi: true,
    id: 'hero.id',
    heading: content.hero.heading,
    buttons: [
      {
        label: content.hero.buttons[0],
        onClick: () => handleExternal(navigate,`/orderapp/package?hidesections=0,2&promo=${promo ? promo : 'appletvpromo'}`, lng),
        color: 'primary',
      },
      {
        label: content.hero.buttons[1],
        onClick: checkOutMoreHandler,
        color: 'primary',
      },
    ],
    contentPicture: 'https://img.fastcast4u.com/react/tvapp/tv1',
  }), [content]);

  const pricingData = useMemo(
    () => [
      {
        title: content.pricing[0].title,
        list: content.pricing[0].list,
        link: `/orderapp/package?hidesections=0,2&select=560&promo=${promo ? promo : 'appletvpromo'}`,
        image: "https://img.fastcast4u.com/react/icon/icon_android.png",
        novelty: true,
        ...prices[0],
      },
      {
        title: content.pricing[1].title,
        list: content.pricing[1].list,
        link: `/orderapp/package?hidesections=0,2&select=561&promo=${promo ? promo : 'appletvpromo'}`,
        image: "https://img.fastcast4u.com/react/icon/icon_amazon.png",
        novelty: true,
        ...prices[1],
      },
      {
        title: content.pricing[2].title,
        list: content.pricing[2].list,
        link: `/orderapp/package?hidesections=0,2&select=549&promo=${promo ? promo : 'appletvpromo'}`,
        image: 'https://img.fastcast4u.com/react/icon/apple-tv.png',
        ...prices[2],
      },
      {
        title: content.pricing[3].title,
        list: content.pricing[3].list,
        link: `/orderapp/package?hidesections=0,2&select=562&promo=${promo ? promo : 'appletvpromo'}`,
        dark: true,
        deal: true,
        ...prices[3],
      },
    ],
    [content, prices],
  );

  const sectionsData = useMemo(
    () => [
      {
        heading: content.sections[0].heading,
        content: `<ul class=${classes.list}>${content.sections[0].content}</ul>`,
        // imgReplacement: <PricingTable image={'https://img.fastcast4u.com/react/tv/a1.png '} single={true} {...pricingData[0]} />,
        injectHTML: true,
        element: <AppleTvFeatureAnimation isForSection={true}/>
      },
    ],
    [content, pricingData],
  );

  return (
    <>
      <HeroSection data={heroData} inlineHeight/>
      <CheckMore />
      <FullContainer id="more" innerRef={blockScrollToRef} maxWidth="xl" overflowHidden>
        {sectionsData.map((props, i) => (
          <Fragment key={i}>
            <RowSection contentReverse short {...props} reverse={i % 2 === 0} />
            {i + 1 < sectionsData.length && <Divider variant="middle" />}
          </Fragment>
        ))}
      </FullContainer>
      <PricingGrid id="pricing">
        {pricingData.map((props, i) => (
          <PricingTable small key={i} {...props} />
        ))}
      </PricingGrid>
      <TitleSection
        primary={content.accordion.heading}
        secondary={content.accordion.description}
      >
        <Accordion data={content.accordion.data} injectHTML/>
      </TitleSection>
      <AppDownloadSection
        heading={content.download.heading}
        // description={content.download.description}
        appleStore="https://account.fastcast4u.com/link.php?id=1225"
        googlePlay='https://account.fastcast4u.com/link.php?id=1224'
        amazonStore='https://www.amazon.com/FastCast-DWC-LLC-FastCast4u-Online-Radio/dp/B0C4YQZJKT'
      />
    </>
  );
};

export default AppTv;
