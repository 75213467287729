const { Suspense } = require("react")
const { Outlet } = require("react-router")
const { default: PageLoader } = require("../PageLoader/PageLoader")

const RouteSuspensePageLoader = () => (
  <Suspense fallback={<PageLoader />}>
    <Outlet/>
  </Suspense>
)

export default RouteSuspensePageLoader