import { RESET_ACCOUNT_STORE, SET_ACTIVE_PRODUCT, SET_CURRENT_PAGE } from "redux/actionTypes/accountTypes";

// selects product to watch details about
const setActiveProduct = product => ({
  type: SET_ACTIVE_PRODUCT,
  payload: product,
});

// to change the page of product list
const setCurrentPage = page => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});

// resets account store
const resetStore = () => ({
  type: RESET_ACCOUNT_STORE,
});

export default {
  resetStore,
  setActiveProduct,
  setCurrentPage
};
