import { makeStyles } from '@material-ui/core'
import React from 'react'

// @ts-ignore
const useStyles = makeStyles(theme => ({
    "@keyframes wednesdayAnimationAfter": {
        "0%": {
        },

        "10, 20%": {
            flex: 7
        },

        "23, 40%": {
            flex: 8
            
        },

        "50%, 60%": {
            flex: 2
        },

        "75%, 85%": {
            flex: 7
        },

        "100%": {
        },
    },
    "@keyframes wednesdayAnimationCenter": {
        "0%": {
        },

        "10%, 20%": {
            flex: 5
            
        },

        "23%, 40%": {
            flex: 6
        },

        "50%, 60%": {
            flex: 3
        },

        "75%, 85%": {
            flex: 7
        },

        "100%": {
        },
    },
    "@keyframes fridayAnimationAfter": {
        "0%": {
        },

        "10, 20%": {
            flex: 10
        },

        "23, 40%": {
            flex: 1
        },

        "50%, 60%": {
            flex: 6
        },

        "75%, 85%": {
            flex: 7
        },

        "100%": {
        },
    },
    "@keyframes fridayAnimationBefore": {
        "0%": {
        },

        "10, 20%": {
            flex: 2
        },

        "23, 40%": {
            flex: 9
            
        },

        "50%, 60%": {
            flex: 8
        },

        "75%, 85%": {
            flex: 2
        },

        "100%": {
        },
    },
    "@keyframes tuesdayAnimationBefore": {
        "0%": {
        },

        "10, 20%": {
            flex: 2
        },

        "23, 40%": {
            flex: 9
            
        },

        "50%, 60%": {
            flex: 8
        },

        "75%, 85%": {
            flex: 2
        },

        "100%": {
        },
    },
    "@keyframes tuesdayAnimationAfter": {
        "0%": {
        },

        "10, 20%": {
            flex: 2
        },

        "23, 40%": {
            flex: 9
            
        },

        "50%, 60%": {
            flex: 8
        },

        "75%, 85%": {
            flex: 2
        },

        "100%": {
        },
    },
    "@keyframes thursdayAnimationAfter": {
        "0%": {
        },

        "10, 20%": {
            flex: 10
        },

        "23, 40%": {
            flex: 2
            
        },

        "50%, 60%": {
            flex: 5
        },

        "75%, 85%": {
            flex: 2
        },

        "100%": {
        },
    },
    "@keyframes thursdayAnimationBefore": {
        "0%": {
        },

        "10, 20%": {
            flex: 2
        },

        "23, 40%": {
            flex: 9
            
        },

        "50%, 60%": {
            flex: 8
        },

        "75%, 85%": {
            flex: 2
        },

        "100%": {
        },
    },
    container: {
        width: "100%",
        position: "relative",
        height: "100%",
        boxSizing: "border-box",
    },
    columnsContainer: {
        height: "53%",
        position: "absolute",
        bottom: "9%",
        width: "auto !important",
        left: "20%",
        right: "3%",
        boxSizing: "border-box",
        background: "#fff",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        zIndex: "2",
        // background: "black",
        transition: "6s ease",
        marginLeft: "1.7%",
        "&::after": {
            transition: "6s ease",
            borderRadius: "1px 1px 0 0",
            // boxShadow: "1px 1px 3px black"
        },
        "&::before, *": {
            transition: "6s ease",
            borderRadius: "0 0 1px 1px",
        }
    },
    columnMonday: {
        marginLeft: 0,
        "&::after": {
            content: '""',
            width: "100%",
            flex: 1,
            background: theme.palette.primary.light
        },
    },
    columnTuesday: {
        "&::before": {
            content: '""',
            width: "100%",
            flex: 5,
            background: theme.palette.primary.light,
            animation: "5s $tuesdayAnimationBefore infinite paused",
        },
        "&::after": {
            content: '""',
            width: "100%",
            flex: 7,
            background: theme.palette.error.dark,
            animation: "5s $tuesdayAnimationBefore infinite paused"
        },
    },
    columnWednesday: {
        "&::before": {
            content: '""',
            width: "100%",
            flex: 5,
            background: theme.palette.error.dark,
            animation: "6s $wednesdayAnimationBefore infinite paused"
        },
        "&::after": {
            content: '""',
            width: "100%",
            flex: 18,
            background: theme.palette.primary.light,
            animation: "6s $wednesdayAnimationAfter infinite paused"
        },
    },
    columnThursday: {
        "&::before": {
            content: '""',
            width: "100%",
            flex: 16,
            background: theme.palette.primary.light,
            animation: "3s $thursdayAnimationBefore infinite paused"
        },
        "&::after": {
            content: '""',
            width: "100%",
            flex: 5,
            background: theme.palette.primary.light,
            animation: "3s $thursdayAnimationAfter infinite paused"
        },
    },
    columnFriday: {
        "&::before": {
            content: '""',
            width: "100%",
            flex: 5,
            background: theme.palette.primary.light,
            animation: "6s $fridayAnimationBefore infinite paused"
        },
        "&::after": {
            content: '""',
            width: "100%",
            flex: 7,
            background: "#4044dc",
            animation: "6s $fridayAnimationAfter infinite paused"
        },
    },
    columnSaturday: {
        "&::after": {
            content: '""',
            width: "100%",
            flex: 1,
            background: "#4044dc"
        }
    },
    columnSunday: {
        "&::after": {
            content: '""',
            width: "100%",
            flex: 1,
            background: "#4044dc"
        }
    },
    cellPrimary: {
        flex: 1,
        background: theme.palette.primary.light,
    },
    cellRed: {
        flex: 1,
        borderRadius: "1px",
        background: theme.palette.error.dark,
    },
    cellSuccess: {
        borderRadius: "1px",
        background: theme.palette.success.main,
    },
    cell3: {
        animation: "6s $wednesdayAnimationCenter infinite paused",
        flex: 1,
    },
    cell4: {
        flex: 1,
    },
    windowImg: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "65% !important",
        paddingBottom: "63%",
        backgroundSize: "115%",
        backgroundImage: "url(https://img.fastcast4u.com/react/radio/everest1.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "43%",
    },

    "@global": {
        "section:has(> div $windowImg) div:has(> div $windowImg)": {
            "&:hover $windowImg $columnsContainer $column $cell3": {
                animationPlayState: "running",
            },
            "&:hover $windowImg $columnsContainer $column $cellSuccess": {
                animationPlayState: "running",
            },
            "&:hover $windowImg $columnsContainer $column::after": {
                animationPlayState: "running",
            },
            "&:hover $windowImg $columnsContainer $column::before": {
                animationPlayState: "running",
            },
        }
    },
}))

export default function CalendarAnimation() {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.windowImg}>
                <div className={classes.columnsContainer}>
                    <div className={[classes.column, classes.columnMonday].join(" ")}></div>
                    <div className={[classes.column, classes.columnTuesday].join(" ")}></div>
                    <div className={[classes.column, classes.columnWednesday].join(" ")}>
                        <div className={[classes.cell3, classes.cellSuccess].join(" ")}></div>
                    </div>
                    <div className={[classes.column, classes.columnThursday].join(" ")}>
                        <div className={[classes.cell4, classes.cellRed].join(" ")}></div>
                    </div>
                    <div className={[classes.column, classes.columnFriday].join(" ")}></div>
                    <div className={[classes.column, classes.columnSaturday].join(" ")}></div>
                    <div className={[classes.column, classes.columnSunday].join(" ")}></div>
                </div>
            </div>
        </div>
    )
}
