import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { StreamItem } from '../StreamItem/StreamItem';
import { TvTransStreamsSlider } from '../TvTransStreamsSlider/TvTransStreamsSlider'
import { TvTransSectionsList } from '../TvTransSectionsList/TvTransSectionsList';
import { TvTransCategoriesList } from '../TvTransCategoriesList/TvTransCategoriesList';
import { TvTransLeftPanel } from '../TvTransLeftPanel/TvTransLeftPanel';
import { useParams } from 'react-router';
import { TvTransGenreSection } from 'components/molecules/TvTransGenreSection/TvTransGenreSection';

const useStyles = makeStyles(theme => ({
    mainContent: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    ganre: {
        margin: "10px 0",
        padding: "0 30px",
        width: "100%",
        boxSizing: "border-box",
    },
    genreContent: {
        display: "flex",
        height: "max-content",
        display: "grid",
        gap: "13px",
        width: "100%",
        boxSizing: "border-box",
        marginRight: "-20px",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        alignItems: "center",
        marginTop: "15px",

        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
        },

        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: "1fr 1fr",
        },

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "1fr",
        },
    },
    genreTitle: {
        width :"100%",
        textAlign: "center",
        fontWeight: "bold",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
    
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            background: theme.palette.background.paper
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.grey[500],
            borderRadius: "10px",
        },
    },
}))

export default function TvTransContentDirectory({content}) {
    const classes = useStyles();
    const {directory} = useParams();

    return (
        <>
            <TvTransLeftPanel/>
            <Box className={classes.content}>
                <Box className={classes.mainContent}>
                    <TvTransSectionsList directories={content.directories} selectedDirectorySlug={directory} hero/>
                    {directory &&
                        <TvTransCategoriesList directoryToDisplay={directory} categoriesList={content.categoriesList}/>
                    }
                    {content.main.map(({title, content}, i) => (
                        <TvTransGenreSection itemsToShow={4} i={i} title={title} content={content} />
                    ))}
                </Box>
            </Box>
        </>
    )
}
