import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const VisaIcon = props => (
  <SvgIcon viewBox="0 0 24 8" {...props}>
    <defs xmlns="http://www.w3.org/2000/svg">
      <clipPath id="clip1">
        <path d="M 11.882812 0.140625 L 10.28125 7.878906 L 8.339844 7.878906 L 9.945312 0.140625 Z M 20.042969 5.136719 L 21.0625 2.234375 L 21.652344 5.136719 Z M 22.207031 7.878906 L 24 7.878906 L 22.433594 0.140625 L 20.777344 0.140625 C 20.40625 0.140625 20.09375 0.363281 19.953125 0.710938 L 17.042969 7.878906 L 19.078125 7.878906 L 19.484375 6.722656 L 21.972656 6.722656 Z M 17.144531 5.351562 C 17.152344 3.3125 14.410156 3.199219 14.425781 2.285156 C 14.433594 2.007812 14.691406 1.714844 15.25 1.636719 C 15.527344 1.601562 16.292969 1.570312 17.160156 1.984375 L 17.503906 0.34375 C 17.035156 0.167969 16.4375 0 15.6875 0 C 13.773438 0 12.421875 1.050781 12.410156 2.558594 C 12.398438 3.671875 13.375 4.292969 14.109375 4.664062 C 14.863281 5.042969 15.117188 5.285156 15.117188 5.625 C 15.109375 6.144531 14.511719 6.375 13.953125 6.382812 C 12.980469 6.398438 12.414062 6.109375 11.960938 5.894531 L 11.613281 7.589844 C 12.0625 7.804688 12.902344 7.992188 13.769531 8 C 15.804688 8 17.136719 6.960938 17.144531 5.351562 M 9.113281 0.140625 L 5.96875 7.878906 L 3.921875 7.878906 L 2.375 1.703125 C 2.28125 1.324219 2.199219 1.183594 1.914062 1.023438 C 1.445312 0.761719 0.675781 0.519531 0 0.367188 L 0.046875 0.140625 L 3.347656 0.140625 C 3.765625 0.140625 4.144531 0.429688 4.238281 0.929688 L 5.058594 5.410156 L 7.074219 0.140625 Z M 9.113281 0.140625 " />
      </clipPath>
      <linearGradient
        id="linear0"
        gradientUnits="userSpaceOnUse"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientTransform="matrix(23.999999,0,0,24.774193,0,4.0007)"
      >
        <stop
          offset="0"
          style={{ stopColor: 'rgb(13.333333%,13.72549%,34.117647%)', stopOpacity: 1 }}
        />
        <stop
          offset="1"
          style={{ stopColor: 'rgb(14.509804%,29.019608%,64.705882%)', stopOpacity: 1 }}
        />
      </linearGradient>
    </defs>
    <path
      style={{ fill: 'url(#linear0)' }}
      d="M 11.882812 0.140625 L 10.28125 7.878906 L 8.339844 7.878906 L 9.945312 0.140625 Z M 20.042969 5.136719 L 21.0625 2.234375 L 21.652344 5.136719 Z M 22.207031 7.878906 L 24 7.878906 L 22.433594 0.140625 L 20.777344 0.140625 C 20.40625 0.140625 20.09375 0.363281 19.953125 0.710938 L 17.042969 7.878906 L 19.078125 7.878906 L 19.484375 6.722656 L 21.972656 6.722656 Z M 17.144531 5.351562 C 17.152344 3.3125 14.410156 3.199219 14.425781 2.285156 C 14.433594 2.007812 14.691406 1.714844 15.25 1.636719 C 15.527344 1.601562 16.292969 1.570312 17.160156 1.984375 L 17.503906 0.34375 C 17.035156 0.167969 16.4375 0 15.6875 0 C 13.773438 0 12.421875 1.050781 12.410156 2.558594 C 12.398438 3.671875 13.375 4.292969 14.109375 4.664062 C 14.863281 5.042969 15.117188 5.285156 15.117188 5.625 C 15.109375 6.144531 14.511719 6.375 13.953125 6.382812 C 12.980469 6.398438 12.414062 6.109375 11.960938 5.894531 L 11.613281 7.589844 C 12.0625 7.804688 12.902344 7.992188 13.769531 8 C 15.804688 8 17.136719 6.960938 17.144531 5.351562 M 9.113281 0.140625 L 5.96875 7.878906 L 3.921875 7.878906 L 2.375 1.703125 C 2.28125 1.324219 2.199219 1.183594 1.914062 1.023438 C 1.445312 0.761719 0.675781 0.519531 0 0.367188 L 0.046875 0.140625 L 3.347656 0.140625 C 3.765625 0.140625 4.144531 0.429688 4.238281 0.929688 L 5.058594 5.410156 L 7.074219 0.140625 L 9.113281 0.140625 "
    />
  </SvgIcon>
);

export default VisaIcon;
