/* eslint-disable react/no-danger */
// @ts-nocheck
import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import GlobeCanvas from 'components/atoms/Globe/Globe';
import earthImg from "../../../assets/map_transparent_blue.png"
// import earthImgGradient from "../../../assets/map_transparent_gradient.png"

const useStyles = makeStyles(theme => ({
  Wrapper: {
    position: "relative",
    background: theme.palette.primary.dark,
    backgroundImage: `radial-gradient(rgba(255, 255, 255, 0.05) 2px, transparent 4px)`,
    backgroundSize: "60px 60px",
    backgroundRepeat: "repeat",
    backgroundPosition: ({mousePos}) => `${mousePos[0]/15}px ${mousePos[1]/15}px`,
    overflow: "hidden",
    marginBottom: "30px",
    padding: "0 50px",
    [theme.breakpoints.down('sm')]: {
      padding: "0",
    }
  },
  globeWraper: ({mousePos}) => ({
    position: "absolute",
    top: "50%",
    right: "0",
    transform: `translate(calc(40% - ${mousePos[0]/25}px), -50%)`,
    maxHeight: "145%",
    height: "200%",
    width: "100%",
    transition: "max-height 0.1s ease",

    [theme.breakpoints.down('sm')]: {
      height: 0,
      top: 0,
      transform: "none",
      width: "100%",
      paddingBottom: "100%",
      marginTop: "70px",
    }
  }),
  content: {
    width: "70%",
    // height: "100%",
    minHeight: "90vh",
    position: "relative",
    boxSizing: "border-box",
    color: "white",
    borderLeft: "3px solid #fff",
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.breakpoints.down('md')]: {
      width: "100%",
      padding: "30px 20% 30px 30px",
    },
    
    [theme.breakpoints.down('sm')]: {
      borderLeft: "none",
      height: "auto",
      top: 0,
      transform: "none",
      padding: "0 30px",
    },
    // [theme.breakpoints.down('xs')]: {
    //   textAlign: "center",
    // }
  },
  title: {
    color: "white",
    marginBottom: "30px",

    [theme.breakpoints.down('xs')]: {
      paddingBottom: "100%",
      paddingTop: "20px",
      // textAlign: "center"
    }
  },
  columns: {
    display: "flex",
    width: "100%",
    zIndex: "10",

    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    }
  },
  columnTitle: {
    fontSize: "30px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  columnContent: {
    display: "flex",
    width: "70%",
    zIndex: "10",
    fontSize: "16px",

    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  },
  column: {
    flex: 1,

    [theme.breakpoints.down('md')]: {
      marginBottom: "20px",
    }
  }
}));

const GlobeSection = () => {
  const [mousePos, setMousePos] = useState([0, 0])
  const classes = useStyles({mousePos});
  
  return (
    <Box onMouseMove={(event) => {setMousePos([event.clientX, event.clientY])}} className={classes.Wrapper}>
        <Box className={classes.globeWraper}>
          <GlobeCanvas map={earthImg}/>
        </Box>
        <Box className={classes.content}>
          <Typography variant="h3" className={classes.title}>
            Unlimited Radio Server Package
          </Typography>
          <Box className={classes.columns}>
            <Box className={classes.column}>
              <Box className={classes.columnTitle}>Global</Box>
              <Box className={classes.columnContent}>
                Four Worldwide Data Centers & Network hosting over 2000 Online Radio Stations
              </Box>
            </Box>
            <Box className={classes.column}>
              <Box className={classes.columnTitle}>Performance</Box>
              <Box className={classes.columnContent}>
                Newest Shoutcast & Icecast Servers Hosted on Best Performance Machines
              </Box>
            </Box>
            <Box className={classes.column}>
              <Box className={classes.columnTitle}>
                Stability
              </Box>
              <Box className={classes.columnContent}>
                Over 99.99% uptime over the last 12 years
              </Box>
            </Box> 
          </Box>
        </Box>
    </Box>
  );
};

export default GlobeSection;
