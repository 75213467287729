export const SET_TOP_BAR_DATA = 'CMS/SET_TOP_BAR_DATA';

export const SET_HOME_DATA = 'CMS/SET_HOME_DATA';

export const SET_APP_DATA = 'CMS/SET_APP_DATA';

export const SET_ALEXA_DATA = 'CMS/SET_ALEXA_DATA';

export const SET_LOGIN_DATA = 'CMS/SET_LOGIN_DATA';

export const SET_ORDER_DATA = 'CMS/SET_ORDER_DATA';

export const SET_ORDER_TV_DATA = 'CMS/SET_ORDER_TV_DATA';

export const SET_SLS_DATA = 'CMS/SET_SLS_DATA';

export const SET_NOTIFICATION_DATA = 'CMS/SET_NOTIFICATION_DATA';
