import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  makeStyles,
} from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import Image from 'components/atoms/Image/Image';
import Price from 'components/molecules/Price/Price';
import { modeSwitch } from 'utils/theme';
import parse from 'html-react-parser';

import bow from '../../../assets/bow.png';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 0, 7),
    justifyContent: 'center',
  },
  item: {
    position: 'relative',
    zIndex: ({ best }) => best && 1,

    [theme.breakpoints.down('md')]: {
      order: ({ best }) => best && 1,
    },
    [theme.breakpoints.up('lg')]: {
      margin: ({ best }) => (best ? 0 : theme.spacing(5, 0, 2)),
    },
  },
  box: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: theme.shadows[6],
    overflow: 'hidden',
    position: 'relative',
  },
  title: {
    width: '100%',
    padding: ({ best }) => theme.spacing(best ? 2.5 : 2, 0),
    backgroundColor: ({ dark }) =>
      dark ? theme.palette.grey[modeSwitch(300, 800)] : theme.palette.grey[modeSwitch(50, 900)],
    textAlign: 'center',
    fontWeight: 500,
    fontSize: ({ isSmall }) => (isSmall ? '1.1rem' : 'auto'),
  },
  divider: {
    width: '100%',
  },
  list: ({ isSmall }) => ({
    '& *': {
      fontSize: isSmall ? '0.8rem' : 'auto',
    },
    padding: '0 10%',
    paddingTop: theme.spacing(3),
    '& li': {
      padding: theme.spacing(0.5, 2),
      textAlign: 'center',
    },
  }),
  imageContainer: {
    height: theme.spacing(7),
    display: 'flex',
    gap: '4px',
    margin: ({ best }) => best && theme.spacing(2, 0),
    padding: theme.spacing(1),
  },
  description: {
    color: theme.palette.grey[modeSwitch(500)],
  },
  image: {
    height: '100%',
    filter: modeSwitch(false, 'brightness(0) invert(1)'),
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    flex: 1,
  },
  btn: {},
  newChip: {
    width: '200px !important',
    padding: '10px',
    fontWeight: 'bold',
    position: 'absolute',
    transform: 'rotateZ(-45deg) translate(-27%, -100%)',
    left: 0,
    top: 0,
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  dealChip: {
    width: '200px',
    padding: '10px',
    fontWeight: 'bold',
    position: 'absolute',
    transform: 'rotateZ(-45deg) translate(-27%, -100%)',
    left: 0,
    top: 0,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));

const DealChip = () => {
  const classes = useStyles({});

  return <Box className={classes.dealChip}>DEAL</Box>;
};

const NewChip = () => {
  const classes = useStyles({});

  return <Box className={classes.newChip}>NEW</Box>;
};

// card to demonstrate price on certain product
const PricingTable = ({
  cycle,
  button = {},
  title,
  current,
  description,
  regular,
  list,
  image,
  link,
  best,
  single,
  small,
  novelty,
  dark,
  deal,
  gift,
  discountChipText,
  withoutNowInPrice,
  emphasisOnSave,
  ...props
}) => {
  const classes = useStyles({ best, isSmall: small, dark });

  return (
    <Grid
      className={classes.item}
      component="article"
      item
      xs={12}
      sm={single ? 12 : best ? 9 : 6}
      md={single ? 12 : best ? 6 : null}
      lg={single ? 9 : small ? 3 : 4}
      {...props}
    >
      {gift && (
        <div
          style={{
            width: 100,
            height: 100,
            position: 'absolute',
            right: -10,
            top: -15,
            transform: 'rotateZ(20deg)',
            background: `url("${bow}")`,
            backgroundSize: 'contain',
            zIndex: 10,
          }}
        />
      )}
      <Paper className={classes.box} variant="outlined">
        {title && (
          <>
            <Text className={classes.title} component="h4" variant="h6">
              {title}
            </Text>
            <Divider className={classes.divider} />
          </>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: '100%',
            flex: 1,
            overflow: 'hidden',
            alignItems: 'center',
          }}
        >
          {deal && <DealChip />}
          {novelty && <NewChip />}
          <Price
            emphasisOnSave={emphasisOnSave}
            withoutNowInPrice={true}
            small={small}
            cycle={cycle}
            discountChipText={discountChipText}
            current={current}
            regular={regular}
          />
          <List className={classes.list}>
            {list?.map((content) => (
              <ListItem key={content}>
                <ListItemText primary={parse(content)} />
              </ListItem>
            ))}
          </List>
          {description && (
            <Text className={classes.description} variant="h6">
              {description}
            </Text>
          )}
          {image && (
            <div className={classes.imageContainer}>
              {image &&
                (typeof image === 'object' ? (
                  <>
                    {image.map((a) => (
                      <Image className={classes.image} src={a} />
                    ))}
                  </>
                ) : (
                  <Image className={classes.image} src={image} />
                ))}
            </div>
          )}
          <div className={classes.btnContainer}>
            <Button
              className={classes.btn}
              component="a"
              href={link}
              color="primary"
              variant="contained"
              size={small ? 'normal' : 'large'}
              {...button}
            >
              {button.label ? button.label : 'GET NOW'}
            </Button>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

PricingTable.defaultProps = {
  current: false,
  regular: false,
  best: false,
  image: '',
};

PricingTable.propTypes = {
  title: PropTypes.string.isRequired,
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  regular: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string,
  link: PropTypes.string.isRequired,
  best: PropTypes.bool,
};

export default PricingTable;
