import { makeStyles } from '@material-ui/core'
import { Opacity } from '@material-ui/icons';
import React from 'react'
import appCreatorImg from "../../../assets/app-creator.webp"

// @ts-ignore
const useStyles = makeStyles(theme => ({
    "@keyframes colorChange": {
        "0%, 20%": {
            backgroundColor: "brown"
        },

        "25%, 45%": {
            backgroundColor: "green"
        },

        "50%, 70%": {
            backgroundColor: "rgb(200, 50, 0)"
        },

        "75%, 95%": {
            backgroundColor: "blue"
        },

        "100%": {
            backgroundColor: "brown"
        },
    },
    container: {
        width: "100%",
        position: "relative",
        paddingBottom: "60%",
        marginBottom: "15px",
        "&::before": {
            content: '""',
            width: "auto",
            top: "5%",
            bottom: "20%",
            left: "15%",
            right: "15%",
            position: "absolute",
            animation: "$colorChange 3s infinite paused"
        },
        "&::after": {
            content: '""',
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundSize: "contain",
            backgroundImage: `url(${appCreatorImg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
        }
    },
    containerInSection: {

    },
    "@global": {
        "div:has(> $container)": {
            "&:hover > $container $img::after": {
                transform: "scale(90%)",
            },
            "&:hover > $container $message": {
                transform: "scale(90%)",
                opacity: 1,
            },  
        },
        "section:has(> div $containerInSection) div:has(> div $containerInSection)": {
            "&:hover $containerInSection::before": {
                animationPlayState: "running"
            }
        }
    }
}))

export default function AppCreatorAnimation({isForSection}) {
    const classes = useStyles();

    return (
        <>
            {/* <div
                className={classes.activationBlock}
            ></div> */}
            <div
                className={`${isForSection && classes.containerInSection} ${classes.container}`}
            >
            </div>
        </>
    )
}
