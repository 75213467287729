import { useState } from "react";
import ReactHlsPlayer from "react-hls-player/dist";
import PreviewImage from "../../../assets/preview.png";
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import PodcastsRoundedIcon from '@mui/icons-material/PodcastsRounded';
import TagRoundedIcon from '@mui/icons-material/TagRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import { useNavigate } from "react-router";

const { makeStyles, Box, Avatar, Chip, Typography, IconButton } = require("@material-ui/core");

const videoExample = {
    title: "Lorem ipsum dolor sit amet.",
    description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit cum sapiente est aut. Magnam, officia!",
    src: "https://tv.fastcast4u.com:3238/stream/play.m3u8",
    previewSrc: PreviewImage,
    chips: [
        "bla 1",
        "bla 2",
        "bla 3"
    ],
    author: {
        name: "someGuy",
        avatarSrc: ""
    }
}

// @ts-ignore
const useStyles = makeStyles(theme => ({
    sliderContainer: {
        width: "100%",
        boxShadow: "border-box",
        display: "flex",
        position: "relative",
        height: "350px",
        marginTop: "30px",
        overflowX: "hidden",
        alignItems: "center",
    },
    card: {
        width: "340px",
        height: "90%",
        display: "flex",
        position: "absolute",
        borderRadius: "10px",
        background: theme.palette.background.paper,
        boxShadow: "0 0 5px black",
        overflow: "hidden",
        transition: "1s ease",
        cursor: "pointer",

        [theme.breakpoints.down('md')]: {
            height: "70%",
        }
    },
    cardCur: {
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 3,
        width: "750px",

        "& $cardVideo": {
            width: "65%",
        },

        "& $cardDescription": {
            flex: 1,
            padding: "10px"
        },

        
        [theme.breakpoints.down('md')]: {
            width: "76% !important",
            maxWidth: "500px"
        }
    },
    cardPrev: {
        left: 0,
        transform: "scale(70%) translateX(-10%)",
    },
    cardNext: {
        left: "100%",
        transform: "scale(70%) translateX(-130%)",
    },
    cardPrev2: {
        left: 0,
        opacity: 0,
        transform: "scale(50%) translateX(-75%)",
    },
    cardNext2: {
        left: "100%",
        opacity: 0,
        transform: "scale(50%) translateX(-130%)",
    },
    cardPrev3: {
        // display: "none",
        left: 0,
        opacity: 0,
        transform: "scale(70%) translateX(-40%)",
    },
    cardNext3: {
        // display: "none",
        left: 0,
        opacity: 0,
        transform: "scale(70%) translateX(-70%)",
    },
    cardVideo: {
        background: "black",
        width: "100%",
        position: "relative",
        transition: "0.5s ease",

        [theme.breakpoints.down('md')]: {
            width: "100% !important"
        }
    },
    cardDescription: {
        width: 0,
        overflow: "hidden",
        position: "relative",

        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    },
    author: {
        fontSize: "20px",
        // fontWeight: "old",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        background: "rgba(0,0,0,0.05)",
        boxShadow: "0 0 2px rgba(0,0,0,0.5)",
        borderRadius: "7px",
    },
    authorData: {
        fontSize: "20px",
        // fontWeight: "old",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        background: "rgba(0,0,0,0.05)",
        boxShadow: "0 0 2px rgba(0,0,0,0.5)",
        margin: "10px",
        borderRadius: "7px",
    },
    authorImg: {
        background: theme.palette.primary.main,
        marginRight: "10px",
    },
    viewersChip: {
        position: "absolute",
        top: "5px",
        right: "5px"
    },
    liveChip: {
        position: "absolute",
        top: "5px",
        left: "5px"
    },
    tagsBlock: {
        height: "max-content",
        display: "flex",
        marginBottom: "10px",
    },
    slideButton: {
        width: "50px",
        height: "50px",
        backgroundColor: `${theme.palette.background.paper} !important`,
        zIndex: "10",
        boxShadow: "0 0 5px black",
        position: "absolute",
    },
    slideButtonLeft: {
        left: "10px",
    },
    slideButtonRight: {
        right: "10px",
    }
}))


const videosList = [
    {...videoExample, id: Math.random()}, {...videoExample, id: Math.random()},
    {...videoExample, id: Math.random()}, {...videoExample, id: Math.random()},
    {...videoExample, id: Math.random()}, {...videoExample, id: Math.random()},
    {...videoExample, id: Math.random()}, {...videoExample, id: Math.random()},
    {...videoExample, id: Math.random()}, {...videoExample, id: Math.random()},
    {...videoExample, id: Math.random()}, {...videoExample, id: Math.random()},
    {...videoExample, id: Math.random()}, {...videoExample, id: Math.random()},
    {...videoExample, id: Math.random()}, {...videoExample, id: Math.random()},
]

const TvTransStreamsSliderSlide = ({id, i, selectedId, src, previewSrc, author,description}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const numSlides = videosList.length;
    const isCur = i === selectedId;
    let isPrev = (selectedId - 1 + numSlides) % numSlides;
    let isPrev2 = (selectedId - 2 + numSlides) % numSlides;
    let isPrev3 = (selectedId - 3 + numSlides) % numSlides;
    let isNext = (selectedId + 1) % numSlides;
    let isNext2 = (selectedId + 2) % numSlides;
    let isNext3 = (selectedId + 3) % numSlides;
    

    return (
        <div
            className={[
                classes.card,
                isCur ?
                    classes.cardCur
                : i === isPrev ?
                    classes.cardPrev
                : i === isPrev2 ?
                    classes.cardPrev2
                : i === isPrev3 && i !== isNext3 ?
                    classes.cardPrev3
                : i === isNext ?
                    classes.cardNext
                : i === isNext2 ?
                    classes.cardNext2
                : classes.cardNext3
            ].join(" ")}
        >
            <div
                className={classes.cardVideo}
                onClick={() => {
                    navigate("/tvtrans/stream");
                }}
            >
                {i === selectedId &&
                    <ReactHlsPlayer
                        key={`${id}-video`}
                        autoPlay={true}
                        muted={true}
                        src={`${src}#t=0.1`}
                        width="100%"
                        height="100%"
                        style={{
                            position: "absolute",
                            objectFit: "cover",
                        }}
                    />
                }
                <img
                    key={`${id}-image`}
                    src={previewSrc}
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        objectFit: "cover",
                        transition: "1s ease",
                        opacity: i === selectedId ? 0 : 1,
                    }}
                />
                <Chip
                    color="secondary"
                    label="254"
                    className={classes.viewersChip}
                    icon={<GroupsRoundedIcon/>}
                />
                <Chip
                    color="secondary"
                    label="LIVE"
                    className={classes.liveChip}
                    icon={<PodcastsRoundedIcon/>}
                />
            </div>
            <div className={classes.cardDescription}>
                <div className={classes.author}>
                    <Avatar className={classes.authorImg} src={author.avaterSrc} color="primary" />
                    <div>{author.name} {i}</div>
                </div>
                <div className={classes.tagsBlock}>
                    {["bla 1", "bla 2", "bla 3"].map(a => (
                        <Chip
                            color="primary"
                            label={a}
                            size="small"
                            style={{marginRight: "2px"}}
                            icon={<TagRoundedIcon style={{height: "15px"}}/>}
                        />
                    ))}
                </div>
                <Typography variant="p">{description}</Typography>
            </div>
        </div>
    )
}

export const TvTransStreamsSlider = () => {
    const classes = useStyles();
    const [selectedId, selectId] = useState(0);

    return (
        <>
            <Box
                className={classes.sliderContainer}
            >
                {videosList.map((props, i) => (
                    <TvTransStreamsSliderSlide key={`${i}-container`}{...props} i={i} selectedId={selectedId}/>
                ))}
                <IconButton
                    className={[classes.slideButton, classes.slideButtonLeft]}
                    color={"primary"}
                    onClick={() => {
                        selectId(a => {
                            if(a === 0) {
                                return videosList.length - 1;
                            }
                            return (a - 1)
                        })
                    }}
                >
                    <NavigateBeforeRoundedIcon/>
                </IconButton>
                <IconButton
                    className={[classes.slideButton, classes.slideButtonRight]}
                    color={"primary"}
                    onClick={() => {
                        selectId(a => {
                            if(a === videosList.length - 1) {
                                return 0;
                            }
                            return (a + 1)
                        })
                    }}
                >
                    <NavigateNextRoundedIcon/>
                </IconButton>
            </Box>
        </>
    )
}