
export const TOGGLE_THEME = 'GENERAL/TOGGLE_THEME';

export const SET_CHAT = 'GENERAL/SET_CHAT';

export const SET_CHAT_DISPLAY = 'GENERAL/SET_CHAT_DISPLAY';

export const SET_CHAT_STATUS = 'GENERAL/SET_CHAT_STATUS';

export const SET_INITIAL_DATA = 'GENERAL/SET_INITIAL_DATA';

export const SET_INTERACTED = 'GENERAL/SET_INTERACTED';

export const SET_ALERT = 'GENERAL/SET_ALERT';
