import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AppleIcon = props => (
  <SvgIcon {...props}>
    <path d="M 8.683594 24 C 5.113281 23.980469 2.15625 16.6875 2.15625 12.972656 C 2.15625 6.910156 6.707031 5.582031 8.460938 5.582031 C 9.25 5.582031 10.09375 5.890625 10.839844 6.164062 C 11.359375 6.355469 11.898438 6.554688 12.199219 6.554688 C 12.378906 6.554688 12.800781 6.386719 13.171875 6.238281 C 13.96875 5.921875 14.957031 5.527344 16.109375 5.527344 C 16.109375 5.527344 16.113281 5.527344 16.117188 5.527344 C 16.976562 5.527344 19.585938 5.71875 21.152344 8.074219 L 21.519531 8.625 L 20.992188 9.023438 C 20.238281 9.59375 18.859375 10.632812 18.859375 12.691406 C 18.859375 15.132812 20.417969 16.070312 21.167969 16.519531 C 21.5 16.71875 21.84375 16.925781 21.84375 17.375 C 21.84375 17.667969 19.503906 23.964844 16.105469 23.964844 C 15.273438 23.964844 14.683594 23.714844 14.167969 23.492188 C 13.640625 23.269531 13.191406 23.078125 12.441406 23.078125 C 12.0625 23.078125 11.582031 23.257812 11.074219 23.449219 C 10.382812 23.707031 9.597656 24 8.707031 24 Z M 8.683594 24 " />

    <path d="M 16.46875 0 C 16.558594 3.191406 14.277344 5.40625 11.996094 5.265625 C 11.621094 2.71875 14.273438 0 16.46875 0 Z M 16.46875 0 " />
  </SvgIcon>
);

export default AppleIcon;
