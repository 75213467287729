import { Button, Grid, makeStyles } from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import React from 'react';

const useStyles = makeStyles(theme => ({
    VisitWebsite: {
        marginBottom: 15,
        '&>div': {
            display: 'flex',
            alignItems: 'center',
        },
        '& $p': {
            margin: '0 10px'
        },
        '& $h6': {
            marginLeft: 'auto'
        },
    },
    VisitWebsiteButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10
    }
}));

const AlexaFormVisitWebsite = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.VisitWebsite}>
            <Grid item xs={6}>
                <Text component='h6' variant='h6'>Domain</Text>
            </Grid>
            <Grid item xs={6}>
                <Text component='p'>Radio Marja 1</Text>
            </Grid>
            <Grid item xs={6}>
                <Text component='h6' variant='h6'>Username</Text>
            </Grid>
            <Grid item xs={6}>
                <Text component='p'>hhijmzsr</Text>
            </Grid>
            <Grid item xs={12} className={classes.VisitWebsiteButton}>
                <Button variant='outlined'>Visit Website</Button>
            </Grid>
        </Grid>
    );
};

export default AlexaFormVisitWebsite;