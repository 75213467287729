import React from 'react';
import { Route } from 'react-router-dom';

// const RadioDirectory = lazy(() => import('views/RadioDirectory/RadioDirectory'));
import RadioDirectory from 'views/RadioDirectory/RadioDirectory';

//children routes for /radio-directory page. Used to do search on page
const RadioDirectoryRoutes = [
    // in react router v6 regExp are not supported. This part of code was used in v5, and was left here in case the support will be returned
    // <Route path={`station/:id/:station?`} element={<RadioDirectory />} />,
    // <Route path={`search/:title/:page(\\d+)/:sort(name|listeners)`} element={<RadioDirectory />} />,
    // <Route path={`:page(\\d+)/:sort(name|listeners)`} element={<RadioDirectory />} />,
    // <Route path={`search/:title/:sort(name|listeners)`} element={<RadioDirectory />} />,
    // <Route path={`search/:title/:page(\\d+)`} element={<RadioDirectory />} />,
    // <Route path={`:sort(name|listeners)`} element={<RadioDirectory />} />,
    // <Route path={`search/:title`} element={<RadioDirectory />} />,
    // <Route path={`:page(\\d+)`} element={<RadioDirectory />} />,

    <Route path={`station/:id/:station`} element={<RadioDirectory />} />,
    <Route path={`search/:title/:page`} element={<RadioDirectory />} />,
    <Route path={`:page`} element={<RadioDirectory />} />,
    <Route index element={<RadioDirectory />} />
];

export default RadioDirectoryRoutes;
