// toggles selected product. The product id should be provided as item argument
export const TOGGLE_PRODUCT = 'ORDER_RADIO/TOGGLE_PRODUCT';

// sets promocode from special popup DialogOnTabChange (the ones that is showed when mouse leaves window borders)
export const SET_SPECIAL_POPUP = "ORDER_RADIO/SET_SPECIAL_POPUP"

export const SET_SESSION_UID = "ORDER_RADIO/SET_SESSION_UID"

// toggles selected addon. The addon id should be provided as item argument
export const TOGGLE_ADDON = 'ORDER_RADIO/TOGGLE_ADDON';

export const SET_STEP = 'ORDER_RADIO/SET_STEP';

// the same as toggleAddon, but for extra addons
export const TOGGLE_ADDON_EXTRA = 'ORDER_RADIO/TOGGLE_ADDON_EXTRA';

export const REMOVE_ADDON = 'ORDER_RADIO/REMOVE_ADDON';

// action to select billing cycle
export const SET_CYCLE = 'ORDER_RADIO/SET_CYCLE';

// action to select premium package
export const SET_PLAN = 'ORDER_RADIO/SET_PLAN';

// action to set promocode
export const SET_PROMOCODE = 'ORDER_RADIO/SET_PROMOCODE';

export const SET_ALL_PROMOCODES = 'ORDER_RADIO/SET_ALL_PROMOCODES';

export const SET_CREDENTIALS = 'ORDER_RADIO/SET_CREDENTIALS';

export const RESET_ORDER_STORE = 'ORDER_RADIO/RESET_ORDER_STORE';

export const SET_PRICES = 'ORDER_RADIO/SET_PRICES';

export const PRICING_FETCH_FAIL = 'ORDER_RADIO/PRICING_FETCH_FAIL';

export const UNSELECT_PRODUCTS = 'ORDER_RADIO/UNSELECT_PRODUCTS';

export const SELECT_IDS = 'ORDER_RADIO/SELECT_IDS';
