import { Fragment, useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import generalServices from 'services/general';
import { Box, Divider, makeStyles } from '@material-ui/core';
import FullContainer from 'components/atoms/FullContainer/FullContainer';
import TitleSection from 'components/organisms/TitleSection/TitleSection';
import RowSection from 'components/organisms/RowSection/RowSection';
import HeroSection from 'components/organisms/HeroSection/HeroSection';
import PricingTable from 'components/organisms/PricingTable/PricingTable';
import Accordion from 'components/organisms/Accordion/Accordion';
import AppDownloadSection from 'components/organisms/AppDownloadSection/AppDownloadSection';
import { useCurrentLanguage } from 'utils/customHooks';
import { handleExternal } from 'utils/urls';
import ScrollableAnchor from 'react-scrollable-anchor';
import { CheckMore } from 'components/molecules/CheckMore/CheckMore';
import { useNavigate } from 'react-router';
import AppleTvFeatureAnimation from 'components/atoms/AppleTvFeatureAnimation/AppleTvFeatureAnimation';
import Text from 'components/atoms/Text/Text';
import FancyCountDown from 'components/organisms/FancyCountDown/FancyCountDown';
import { ButtonBase } from '@mui/material';
import Image from 'components/atoms/Image/Image';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingLeft: 15,
    margin: 0,
    lineHeight: 1.5,

    [theme.breakpoints.only('xs')]: {
      listStyle: 'none',
      padding: 0,
      lineHeight: 1.2,
    },
  },
  icon: {
    width: '100% !important',
    height: 'unset',
    objectFit: 'cover',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      height: theme.spacing(7),
    },
  },
  button: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: 'black',
  },
}));

const RokuTv = () => {
  const [prices, setPrices] = useState([]);
  const content = useSelector((state) => state.language.rokuTV);
  console.log('Content: ', content);
  const lng = useCurrentLanguage();
  const blockScrollToRef = useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const [hoursLeft, setHoursLeft] = useState();

  var _second = 1000;
  var _minute = _second * 60;
  var _hour = _minute * 60;

  const expireUTC = Date.UTC(2023, 7, 31, 22, 0, 0);
  const now = new Date();
  const nowUTC = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
  );
  const distance = expireUTC - nowUTC;
  const [distanceSinceLastVisit, setDistanceSinceLastVisit] = useState(0);

  console.log('distance: ', distance);
  // requests prices of needed products, to display them in pricing cards
  useEffect(() => {
    if (!localStorage.getItem('oldestVisit')) {
      localStorage.setItem('oldestVisit', now.toUTCString());
      setDistanceSinceLastVisit(0);
    }
    const oldestVisit = new Date(localStorage.getItem('oldestVisit'));
    const oldestVisitUTC = Date.UTC(
      oldestVisit.getUTCFullYear(),
      oldestVisit.getUTCMonth(),
      oldestVisit.getUTCDate(),
      oldestVisit.getUTCHours(),
      oldestVisit.getUTCMinutes(),
      oldestVisit.getUTCSeconds(),
    );
    console.log(oldestVisit);
    setDistanceSinceLastVisit(nowUTC - oldestVisitUTC);

    const interval = setInterval(() => {
      const now = new Date();
      const nowUTC = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
      );
      setDistanceSinceLastVisit(nowUTC - oldestVisitUTC);
    }, 1000 * 60);
    const promocode = new URLSearchParams(location.search).get('promo');

    return () => clearInterval(interval);
  }, []);

  //function that scrolls page to main content
  const checkOutMoreHandler = () => blockScrollToRef.current.scrollIntoView({ behavior: 'smooth' });

  const heroData = useMemo(
    () => ({
      isNoStrapi: true,
      id: 'hero.id',
      heading: content.hero.heading,
      content: content.hero.content,
      buttons: [
        {
          label: content.hero.buttons[0],
          // onClick: () => handleExternal(navigate,'https://fastcast4u.com/account/index.php?rp=/store/mobile-app/apple-ios-mobile-app-1', lng),
          onClick: () =>
            handleExternal(navigate, 'https://account.fastcast4u.com/link.php?id=1220', lng),
          color: 'primary',
        },
        {
          label: content.hero.buttons[1],
          onClick: checkOutMoreHandler,
          color: 'primary',
        },
      ],
      contentPicture: 'https://img.fastcast4u.com/react/tvapp/rokutv',
    }),
    [content],
  );

  const pricingData = useMemo(
    () => [
      {
        title: content.pricing[0].title,
        list:
          distance > 0
            ? [
                '<span style="font-size: 25px"><b style="color: hsl(350, 65%, 46%)">Pre-Order Now</b></span>',
                `<span style="font-size: 25px"><b style="color: hsl(350, 65%, 46%)">${
                  distanceSinceLastVisit / _minute < 1
                    ? 4
                    : distanceSinceLastVisit / _minute < 3
                    ? 3
                    : distanceSinceLastVisit / _minute < 5
                    ? 2
                    : 1
                }</b> Left in Stock</span>`,
              ]
            : [],
        link: `https://account.fastcast4u.com/link.php?id=1220`,
        button: { label: 'Order Now' },
        ...prices[0],
        regular: 299,
        current: 75,
      },
    ],
    [content, prices, distance, distanceSinceLastVisit],
  );

  const sectionsData = useMemo(
    () => [
      {
        heading: content.sections[0].heading,
        content: `<ul class=${classes.list}>${content.sections[0].content}</ul>`,
        imgReplacement: <PricingTable novelty={true} single={true} {...pricingData[0]} />,
        injectHTML: true,
      },
    ],
    [content, pricingData],
  );

  return (
    <>
      <HeroSection data={heroData} inlineHeight />
      <CheckMore />
      <Text component="h2" variant="h3" mt={3} mb={1} align="center" fontWeight={500}>
        {'Create Your Own Roku TV Channel App'}
      </Text>
      <Text
        color="textSecondary"
        component="h3"
        variant="h4"
        mt={0}
        mb={3}
        align="center"
        fontWeight={500}
      >
        {'App for Online Radio and Online TV'}
      </Text>
      <FullContainer id="more" innerRef={blockScrollToRef} maxWidth="xl" overflowHidden>
        {sectionsData.map((props, i) => (
          <Fragment key={i}>
            <RowSection contentReverse long {...props} reverse={i % 2 === 0} />
            {i + 1 < sectionsData.length && <Divider variant="middle" />}
          </Fragment>
        ))}
      </FullContainer>
      <Text
        color="textSecondary"
        component="h4"
        variant="  p"
        mt={0}
        mb={3}
        align="center"
        fontWeight={500}
      >
        {
          'Delivery time is up to 30 days from the day we receive the project and all required details. Unpaid pre-orders will be canceled in 12 hours'
        }
      </Text>

      <Box
        style={{
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text component="h2" variant="h4" mt={10} mb={3} align="center" fontWeight={500}>
          {'Demo Roku TV Channel'}
        </Text>
        <Text
          color="textSecondary"
          component="h3"
          variant="h5"
          mt={0}
          mb={3}
          align="center"
          fontWeight={500}
        >
          {
            'Enter Vanity access code: 9G9J292 on your Roku TV to activate or Click to Add to Your Roku Channels:'
          }
        </Text>
        <ButtonBase
          className={classes.button}
          component="a"
          href={'https://channelstore.roku.com/details/287350a421360d6b45a4627a5bb443b3'}
          target="_blank"
          focusRipple
          style={{
            borderRadius: '10px',
            width: '100px',
            border: 'solid 2px #a2a2a2',
            overflow: 'hidden',
            padding: '0 10px',
            margin: '0 auto',
            fontSize: 13,
            // background: "#000",
            fontWeight: 'bold',
            gap: 10,
          }}
        >
          <Image
            className={classes.icon}
            src={'https://img.fastcast4u.com/react/tvapp/rokutv_logo.png'}
          />
        </ButtonBase>
      </Box>
    </>
  );
};

export default RokuTv;
