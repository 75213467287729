import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import PageLoader from 'components/molecules/PageLoader/PageLoader';
import lng from 'utils/languageRoute';

// const Help = lazy(() => import('views/Help/Help'));
// const HelpArticle = lazy(() => import('views/HelpArticle/HelpArticle'));
import Help from 'views/Help/Help';
import HelpArticle from 'views/HelpArticle/HelpArticle';

//children routes displayed on /help
const HelpRoutes = [
  <Route path={`:id/:slug`} element={<HelpArticle/>} />,
  <Route path={`:id`} element={<HelpArticle/>} />,
  <Route index element={<Help/>} />
];

export default HelpRoutes;

