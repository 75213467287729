import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    Modal: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0,0,0,0.4)',
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: ({active}) => active ? 1 : 0,
        pointerEvents: ({active}) => active ? 'all' : 'none',
        transition: '.5s',
        cursor: 'pointer',
        zIndex: 999999999999
    },
    ModalInner: {
        position: 'relative',
        maxHeight: '100%',
        maxWidth: '100%',
        padding: '1px',
        backgroundColor: 'black',
        transform: ({active}) => active ? 'scale(1)' : 'scale(.5)',
        transition: '.4s all'
    },
    CloseIcon: {
        display: 'inline-block',
        position: 'fixed',
        top: '0',
        right: '0',
        zIndex: 9999999999999,
        backgroundColor: 'white',
        padding: '5px',
        height: '34px',
        width: '34px',
        '&:hover': {
            backgroundColor: 'rgb(230, 230, 230)',
        },
        color: 'black !important'
    }
}));

const Modal = ({active, setActive, children}) => {
    const classes = useStyles({active});

    return (
        <Box className={classes.Modal} onClick={() => setActive(false)}>
            <Box className={classes.ModalInner} onClick={e => e.stopPropagation()}>
                <Box className={classes.CloseIcon} onClick={() => setActive(false)}>
                    <CloseIcon />
                </Box>
                {children}
            </Box>
        </Box>
    );
};

export default Modal;