import React, { useState } from 'react';
import { makeStyles, Grid, Box, Button, useMediaQuery, useTheme, InputBase } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Text from 'components/atoms/Text/Text';
import EnhancedTable from 'components/organisms/EnhancedTable/EnhancedTable';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-custom-scrollbars';
import SendRoundedIcon from '@material-ui/icons/SendRounded';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
  `}
`;

const tickets = [
    {
        id: 1,
        title: 'Topup',
        date: '06:24:45 AM',
        status: 'Completed'
    },
    {
        id: 2,
        title: 'Topup',
        date: '06:24:45 AM',
        status: 'Pending'
    },
    {
        id: 3,
        title: 'Topup',
        date: '06:24:45 AM',
        status: 'Canceled'
    },
    {
        id: 4,
        title: 'Withdraw',
        date: '06:24:45 AM',
        status: 'Canceled'
    },
    {
        id: 5,
        title: 'Withdraw',
        date: '06:24:45 AM',
        status: 'Pending'
    },
    {
        id: 6,
        title: 'Withdraw',
        date: '06:24:45 AM',
        status: 'Canceled'
    },
    {
        id: 7,
        title: 'Withdraw',
        date: '06:24:45 AM',
        status: 'Pending'
    },
];

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20,
        [theme.breakpoints.down(768)]: {
            padding: 10,
        },
    },
    Title: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 20px 20px 20px',
        [theme.breakpoints.down(768)]: {
            padding: '0 10px 10px 10px',
        },
        '& $a': {
            textDecoration: 'none'
        }
    },
    Content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 20px 20px 20px',
        [theme.breakpoints.down(768)]: {
            padding: '0 10px 10px 10px',
        },
        '& $table': {
            minWidth: 0
        }
    },
    Conversation: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    Header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
        '&>div': {
            display: 'flex',
            alignItems: 'center'
        },
        // boxShadow: '0px 19px 22px -15px rgba(0, 0, 0, 1)',
    },
    Chat: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden'
    },
    Message: {
        display: 'flex',
        padding: '10px 20px 10px 20px',
        [theme.breakpoints.down(900)]: {
            padding: '10px 15px 10px 0',
        },
        margin: '0 -5px'
    },
    MyMessage: {
        flexDirection: 'row-reverse'
    },
    ChooseTicket: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    Image: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        overflow: 'hidden',
        margin: 5,
        '& $img': {
            width: '100%',
            height: '100%',
        }
    },
    TextBox: {
        position: 'relative',
        display: 'inline-flex',
        flexDirection: 'column',
        flex: '1',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        padding: 10,
        maxWidth: 300,
        margin: 5,
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            top: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '10px solid',
            borderTopColor: theme.palette.primary.main,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        }
    },
    PseudoRight: {
        '&::before': {
            right: -10
        }
    },
    PseudoLeft: {
        '&::before': {
            left: -10
        }
    },
    Text: {
        color: 'white'
    },
    TimeStamp: {
        color: 'rgba(255,255,255,.5)',
        fontSize: 13
    },
    Input: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0 0 20px',
        [theme.breakpoints.down(768)]: {
            padding: '0 10px',
        },
        '&>div:first-child': {
            flex: '1',
            padding: '10px 15px 10px 0'
        },
        '&>div:last-child': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        }
    }
}));

const SupportTickets = () => {
    const classes = useStyles();
    const theme = useTheme();
    // equals true if screen width is lower than 900 px
    const isMobileSize = useMediaQuery(theme.breakpoints.down(900));
    const [selectedTicket, setSelectedTicket] = useState(null);

    // object that controlls styles of status label
    const statusLabels = {
        Completed: theme.palette.success.main,
        Canceled: theme.palette.secondary.main,
        Pending: theme.palette.grey[500]
    }

    // function to transform given array to array that can be used as rows property of EnhancedTable
    function createData(id, title, date, status) {
        return { id: { data: id }, title: { data: title }, date: { data: date }, status: { data: <Text component='p' style={{ color: statusLabels[status].replace(';', '') }}>{status}</Text>, alignRight: true } };
    }

    const rows = tickets.map(({ id, title, date, status }) => createData(id, title, date, status));

    const handleClick = (e, id) => e.target.type !== "checkbox" && setSelectedTicket(id);

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item xs={12} className={classes.Title}>
                        <Text component='h4' variant='h5'>Support Tickets</Text>
                        <Link to="openticket">
                            <Button variant='contained' color='primary'>Open New Ticket</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12} className={classes.Content}>
                        <Grid container>
                            {(!isMobileSize || !selectedTicket) &&
                                <Grid item xs={isMobileSize ? 12 : 6}>
                                    <EnhancedTable
                                        rows={rows}
                                        handleClick={handleClick}
                                        defaultRowsPerPage={10}
                                        notCheckboxes
                                        selectedRow={selectedTicket}
                                        rowsPerPageOptions={[10]}
                                    />
                                </Grid>
                            }
                            {(!isMobileSize || selectedTicket) &&
                                <Grid item xs={isMobileSize ? 12 : 6}>
                                    <Box className={classes.Conversation}>
                                        {selectedTicket ?
                                            <>
                                                {isMobileSize &&
                                                    <Box className={classes.Header}>
                                                        <Box>
                                                            <Button variant='contained' color='primary' onClick={() => setSelectedTicket(null)}>back</Button>
                                                        </Box>
                                                        <Box>
                                                            <Text component='h5' variant='h6'>Topup</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text component='p' style={{ color: statusLabels['Completed'] }}>Completed</Text>
                                                        </Box>
                                                    </Box>
                                                }
                                                <PerfectScrollbar style={{ height: 558 }}>
                                                    <Box className={classes.Chat}>
                                                        <Box className={classes.Message}>
                                                            <Box className={classes.Image}>
                                                                <img src='https://zenix.dexignzone.com/xhtml/images/avatar/1.jpg' alt=''></img>
                                                            </Box>
                                                            <Box className={`${classes.TextBox} ${false ? classes.PseudoRight : classes.PseudoLeft}`}>
                                                                <Text component='p' className={classes.Text}>Hi, how are you samimblaablabla sefwefwe?</Text>
                                                                <Text component='p' className={classes.TimeStamp}>8:40 AM, Today</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box className={`${classes.Message} ${classes.MyMessage}`}>
                                                            <Box className={classes.Image}>
                                                                <img src='https://zenix.dexignzone.com/xhtml/images/avatar/2.jpg' alt=''></img>
                                                            </Box>
                                                            <Box className={`${classes.TextBox} ${true ? classes.PseudoRight : classes.PseudoLeft}`}>
                                                                <Text component='p' className={classes.Text}>Hi Khalid i am good tnx how about you?</Text>
                                                                <Text component='p' className={classes.TimeStamp}>8:40 AM, Today</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box className={classes.Message}>
                                                            <Box className={classes.Image}>
                                                                <img src='https://zenix.dexignzone.com/xhtml/images/avatar/1.jpg' alt=''></img>
                                                            </Box>
                                                            <Box className={`${classes.TextBox} ${false ? classes.PseudoRight : classes.PseudoLeft}`}>
                                                                <Text component='p' className={classes.Text}>I am good too, thank you for your chat template</Text>
                                                                <Text component='p' className={classes.TimeStamp}>8:40 AM, Today</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box className={`${classes.Message} ${classes.MyMessage}`}>
                                                            <Box className={classes.Image}>
                                                                <img src='https://zenix.dexignzone.com/xhtml/images/avatar/2.jpg' alt=''></img>
                                                            </Box>
                                                            <Box className={`${classes.TextBox} ${true ? classes.PseudoRight : classes.PseudoLeft}`}>
                                                                <Text component='p' className={classes.Text}>You are welcome</Text>
                                                                <Text component='p' className={classes.TimeStamp}>8:40 AM, Today</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box className={classes.Message}>
                                                            <Box className={classes.Image}>
                                                                <img src='https://zenix.dexignzone.com/xhtml/images/avatar/1.jpg' alt=''></img>
                                                            </Box>
                                                            <Box className={`${classes.TextBox} ${false ? classes.PseudoRight : classes.PseudoLeft}`}>
                                                                <Text component='p' className={classes.Text}>I am looking for your next templates</Text>
                                                                <Text component='p' className={classes.TimeStamp}>8:40 AM, Today</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box className={`${classes.Message} ${classes.MyMessage}`}>
                                                            <Box className={classes.Image}>
                                                                <img src='https://zenix.dexignzone.com/xhtml/images/avatar/2.jpg' alt=''></img>
                                                            </Box>
                                                            <Box className={`${classes.TextBox} ${true ? classes.PseudoRight : classes.PseudoLeft}`}>
                                                                <Text component='p' className={classes.Text}>Ok, thank you have a good day</Text>
                                                                <Text component='p' className={classes.TimeStamp}>8:40 AM, Today</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box className={classes.Message}>
                                                            <Box className={classes.Image}>
                                                                <img src='https://zenix.dexignzone.com/xhtml/images/avatar/1.jpg' alt=''></img>
                                                            </Box>
                                                            <Box className={`${classes.TextBox} ${false ? classes.PseudoRight : classes.PseudoLeft}`}>
                                                                <Text component='p' className={classes.Text}>Bye, see you</Text>
                                                                <Text component='p' className={classes.TimeStamp}>8:40 AM, Today</Text>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </PerfectScrollbar>
                                                <Box className={classes.Input}>
                                                    <InputBase
                                                        placeholder='Type your message'
                                                        inputProps={{ 'aria-label': 'naked' }}
                                                    />
                                                    <Box>
                                                        <SendRoundedIcon color="primary" fontSize='large' />
                                                    </Box>
                                                </Box>
                                            </>
                                            :
                                            <Box className={classes.ChooseTicket}>
                                                <Text component='p' align='center'>Choose Ticket</Text>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default SupportTickets;