import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withCookies } from 'react-cookie';
import orderAppServices from 'services/orderApp'

import { makeStyles } from '@material-ui/core';
import ColumnForm from 'components/molecules/ColumnForm/ColumnForm';
import FullContainer from 'components/atoms/FullContainer/FullContainer';
import BoxTitle from 'components/atoms/BoxTitle/BoxTitle';
import Text from 'components/atoms/Text/Text';
import iconSrc from 'assets/img/done.png'
import OrderAccessController from 'utils/OrderAccessController';
import OrderTvAccessController from 'utils/OrderTvAccessController';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 5),
    },
    marginTop: '50px',
  },
  chip: {
    fontSize: '20px',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: "17px",
  },
  doneImg: {
    width: "110px"
  }
}));

const OrderTvSuccess = () => {
  const classes = useStyles();
  const content = useSelector(state => state.language?.orderSuccess);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!content) {
      dispatch(orderAppServices.getContentWithTranslation());
    }
  },[])

  return (
    <>
      <OrderTvAccessController currentStep={6} />
      <FullContainer center>
        <ColumnForm className={classes.paper}>
          <BoxTitle variant="h4" component="h1" mb={2}>
            {content?.title}
          </BoxTitle>
          <img src={iconSrc} className={classes.doneImg}/>
          <Text mt={2} className={classes.text}>{content?.description}</Text>
        </ColumnForm>
      </FullContainer>
    </>
  );
};

export default OrderTvSuccess;
