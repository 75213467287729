import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import PageLoader from 'components/molecules/PageLoader/PageLoader';

import Footer from 'components/organisms/Footer/Footer';
import { promoLinks } from 'utils/promoLinks';

const EmptyFooter = () => <div style={{marginTop: 50}} />

const FooterRoutes = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="join" element={<Footer copyrightsOnly={true} />} />
        <Route path={"tvtrans/*"} element={<></>} />
        {
          //routes for promo versions of pages on which Footer should loock differently
          promoLinks.map((el) => <Route path={el} element={<EmptyFooter/>} />)
        }
        <Route path="*" element={<Footer copyrightsOnly={false}/>} />
      </Routes>
    </Suspense>
  )
};

export default FooterRoutes;
