import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import PageLoader from 'components/molecules/PageLoader/PageLoader';

import Header from 'components/organisms/Header/Header';
import HeaderPromo from 'components/organisms/Header/HeaderPromo';
import { promoLinks } from 'utils/promoLinks';

const HeaderRoutes = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/join" element={<HeaderPromo noSocialBar={true}/>} />
        <Route path={"tvtrans/*"} element={<></>} />
        {
          //routes for promo versions of pages on which Header should loock differently
          promoLinks.map((el) => <Route path={el} element={<HeaderPromo />} />)
        }
        <Route path="*" element={<Header />} />
      </Routes>
    </Suspense>
  )
};

export default HeaderRoutes;
