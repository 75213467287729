import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Chip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Text from 'components/atoms/Text/Text';
import { modeSwitch } from 'utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    color: modeSwitch(theme.palette.primary.main, 'white'),
  },
  unit: {
    fontSize: theme.spacing(3.75),
    alignSelf: 'flex-start',
  },
  price: {
    fontSize: ({ isSmall }) => (isSmall ? theme.spacing(6.5) : theme.spacing(8.5)),
    lineHeight: 1.3,
  },
  old: {
    alignSelf: 'flex-start',
    position: 'relative',
    color: theme.palette.grey[modeSwitch(600, 400)],
    // fontSize: '1.75em',
    fontSize: ({ isSmall }) => (isSmall ? '1.3rem' : '1.75rem'),
    '&:before': {
      content: '""',
      width: '110%',
      position: 'absolute',
      top: '50%',
      left: -1,
      borderBottom: 'solid 2px',
      borderBottomColor: theme.palette.grey[modeSwitch(700, 300)],
      transform: 'rotate(-16deg)',
    },
  },
  cycle: {
    fontWidth: 'bold',
  },
  chip: {
    minWidth: '50%',
    marginTop: ({ isSmall }) => (isSmall ? theme.spacing(1) : theme.spacing(2)),
    padding: ({ isSmall }) => (isSmall ? theme.spacing(0, 1) : theme.spacing(0, 2)),
    fontSize: ({ isSmall }) => (isSmall ? '0.7rem' : '1.1em'),
    fontWeight: 600,
    color: modeSwitch(theme.palette.primary.main, 'white'),
  },
}));

const Price = ({
  cycle,
  current,
  regular,
  withoutNowInPrice,
  small,
  discountChipText,
  emphasisOnSave,
}) => {
  const currency = useSelector((state) => state.general.currency);
  const { now, save } = useSelector((state) => state.language.components.price);
  const classes = useStyles({ isSmall: small });

  const advantagePrice = (regular - current).toFixed(2);
  const isPricesEqual = current !== regular;

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {regular && isPricesEqual && !small && !withoutNowInPrice && <Text>{now}</Text>}
        <Text className={classes.unit}>{currency}</Text>
        <Text className={classes.price}>
          {current === false ? <Skeleton variant="text" width={100} /> : current}
        </Text>
        {regular && isPricesEqual && (
          <Text className={classes.old}>
            {currency}
            {regular}
          </Text>
        )}
        {cycle && <Text className={classes.cycle}>{cycle}</Text>}
      </Box>
      {regular && isPricesEqual && (
        <Box style={{ minWidth: '50%', width: 'max-content' }}>
          <Chip
            className={classes.chip}
            label={`${discountChipText || save} ${currency}${advantagePrice}`}
            color="primary"
            variant="outlined"
            size={small ? 'small' : 'normal'}
          />
        </Box>
      )}
      {emphasisOnSave && (
        <Box style={{ minWidth: '50%', width: 'max-content' }}>
          <Chip
            className={classes.chip}
            label={`${save} ${currency}${advantagePrice}`}
            color="secondary"
            // variant="filled"
            size={small ? 'small' : 'normal'}
          />
        </Box>
      )}
    </Box>
  );
};

Price.defaultProps = {
  current: false,
  regular: false,
};

Price.propTypes = {
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  regular: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default Price;
