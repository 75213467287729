import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PaypalIcon = props => (
  <SvgIcon viewBox="0 0 21 24" {...props}>
    <path
      style={{ fill: 'rgb(14.509804%,23.137255%,50.196078%)' }}
      d="M 5.867188 23.324219 L 6.292969 20.664062 L 5.351562 20.644531 L 0.855469 20.644531 L 3.980469 1.035156 C 3.988281 0.972656 4.019531 0.917969 4.066406 0.878906 C 4.113281 0.839844 4.171875 0.820312 4.234375 0.820312 L 11.808594 0.820312 C 14.324219 0.820312 16.058594 1.335938 16.964844 2.359375 C 17.390625 2.839844 17.660156 3.34375 17.792969 3.894531 C 17.929688 4.472656 17.933594 5.164062 17.796875 6.007812 L 17.789062 6.070312 L 17.789062 6.613281 L 18.214844 6.851562 C 18.570312 7.039062 18.855469 7.253906 19.074219 7.5 C 19.4375 7.910156 19.671875 8.433594 19.773438 9.050781 C 19.875 9.6875 19.839844 10.441406 19.671875 11.300781 C 19.476562 12.285156 19.164062 13.144531 18.742188 13.847656 C 18.351562 14.492188 17.855469 15.03125 17.269531 15.445312 C 16.707031 15.839844 16.039062 16.140625 15.28125 16.332031 C 14.550781 16.523438 13.714844 16.617188 12.800781 16.617188 L 12.210938 16.617188 C 11.789062 16.617188 11.378906 16.769531 11.058594 17.039062 C 10.738281 17.3125 10.523438 17.6875 10.457031 18.101562 L 10.414062 18.339844 L 9.667969 23.023438 L 9.632812 23.195312 C 9.625 23.25 9.609375 23.277344 9.585938 23.296875 C 9.566406 23.3125 9.539062 23.324219 9.507812 23.324219 Z M 5.867188 23.324219 "
    />
    <path
      style={{ fill: 'rgb(9.019608%,60.784314%,84.313725%)' }}
      d="M 18.617188 6.132812 C 18.59375 6.277344 18.566406 6.421875 18.539062 6.574219 C 17.539062 11.65625 14.121094 13.410156 9.753906 13.410156 L 7.53125 13.410156 C 7 13.410156 6.546875 13.792969 6.464844 14.316406 L 5.328125 21.464844 L 5.003906 23.492188 C 4.949219 23.832031 5.21875 24.140625 5.566406 24.140625 L 9.507812 24.140625 C 9.976562 24.140625 10.371094 23.804688 10.445312 23.347656 L 10.484375 23.152344 L 11.226562 18.484375 L 11.273438 18.230469 C 11.347656 17.773438 11.746094 17.4375 12.210938 17.4375 L 12.800781 17.4375 C 16.621094 17.4375 19.609375 15.898438 20.484375 11.457031 C 20.851562 9.597656 20.660156 8.046875 19.695312 6.957031 C 19.402344 6.628906 19.039062 6.355469 18.617188 6.132812 Z M 18.617188 6.132812 "
    />
    <path
      style={{ fill: 'rgb(13.333333%,17.647059%,39.607843%)' }}
      d="M 17.570312 5.722656 C 17.417969 5.675781 17.261719 5.636719 17.097656 5.601562 C 16.9375 5.566406 16.769531 5.535156 16.597656 5.507812 C 16 5.410156 15.34375 5.367188 14.640625 5.367188 L 8.703125 5.367188 C 8.554688 5.367188 8.417969 5.398438 8.292969 5.457031 C 8.019531 5.589844 7.8125 5.84375 7.765625 6.160156 L 6.5 14.085938 L 6.464844 14.316406 C 6.546875 13.792969 7 13.410156 7.53125 13.410156 L 9.753906 13.410156 C 14.121094 13.410156 17.539062 11.652344 18.539062 6.574219 C 18.566406 6.421875 18.59375 6.277344 18.617188 6.132812 C 18.363281 6 18.089844 5.886719 17.792969 5.789062 C 17.722656 5.765625 17.644531 5.742188 17.570312 5.722656 Z M 17.570312 5.722656 "
    />
    <path
      style={{ fill: 'rgb(14.509804%,23.137255%,50.196078%)' }}
      d="M 7.765625 6.160156 C 7.8125 5.84375 8.019531 5.589844 8.292969 5.457031 C 8.417969 5.398438 8.554688 5.367188 8.703125 5.367188 L 14.640625 5.367188 C 15.34375 5.367188 16 5.410156 16.597656 5.507812 C 16.769531 5.535156 16.9375 5.566406 17.097656 5.601562 C 17.261719 5.636719 17.417969 5.675781 17.570312 5.722656 C 17.644531 5.742188 17.722656 5.765625 17.796875 5.789062 C 18.089844 5.886719 18.363281 6 18.617188 6.132812 C 18.914062 4.257812 18.613281 2.976562 17.589844 1.820312 C 16.460938 0.546875 14.417969 0 11.808594 0 L 4.234375 0 C 3.699219 0 3.246094 0.382812 3.164062 0.90625 L 0.0078125 20.71875 C -0.0546875 21.109375 0.25 21.464844 0.648438 21.464844 L 5.328125 21.464844 L 6.5 14.085938 Z M 7.765625 6.160156 "
    />
  </SvgIcon>
);

export default PaypalIcon;
