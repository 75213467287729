// @ts-nocheck
import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { List } from '@material-ui/core';
import StationItem from 'components/molecules/StationItem/StationItem';
import directoryServices from 'services/directory';

// list of radio stations
const DirectoryList = () => {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const stations = useSelector(state => state.directory.stations);
  const dispatch = useDispatch();

  // updates stations list when "sort" searchparam is updated
  useEffect(() => {
    const getStationList = async () => {
      await dispatch(directoryServices.getStationList({...params, sort: searchParams.get('sort')}));
    };

    getStationList();
    // eslint-disable-next-line
  }, [location]);

  return (
    <List>
      {stations.map(station => (
        <StationItem data={station} key={station.id} />
      ))}
    </List>
  );
};

export default DirectoryList;
