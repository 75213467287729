// returns true if app is on development server
export const isDev = () => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return false;
};

// returns true if app is on production server
export const isProd = () => {
  console.log(process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'production') {
    return true;
  }
  return false;
};

// returns true if app is localy tested
export const isTest = () => {
  if (process.env.NODE_ENV === 'test') {
    return true;
  }
  return false;
};

export default { isDev, isProd, isTest };
