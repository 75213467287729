import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';

// navigation button for header on /account*
const NavButton = styled(({ ...props }) => {
  const theme = useTheme();
  const matches = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  return (
    <Button
      color="primary"
      variant="contained"
      size={matches ? 'large' : 'small'}
      component={Link}
      {...props}
    />
  );
})`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  font-weight: 600;
`;
export default NavButton;
