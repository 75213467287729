import history from 'utils/history';

export const handleExternal = (navigate, url, lng, noSearch = false) => {
  const indexOfHash = (url.indexOf("#") === -1) ? url.length : url.indexOf("#");
  const pathname = url.slice(0, indexOfHash)
  const hash = url.slice(indexOfHash)
  if (url.startsWith('http')) {
    window.location.href = url;
  } else {
    navigate(`${lng}${pathname}${!noSearch ? history.location?.search : ''}${hash}`);
  }
};

export default { handleExternal };
