import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/atoms/Image/Image';

// image container for HeroSection
const Picture = ({ additionalImageSyles, mobile, desktop, alt, fullPathIsGiven, noWebp, isGif, ...props }) => (
  <picture {...props}>
    {desktop && (
      <>
        {!noWebp && <source type="image/webp" media="(min-width:768px)" srcSet={`${desktop}.webp`} />}
        <source type="image/png" media="(min-width:768px)" srcSet={`${desktop}${isGif ? '.gif' : fullPathIsGiven ? "" : '.png'}`} />
      </>
    )}
    {!noWebp && <source type="image/webp" srcSet={`${mobile}.webp`} />}
    <img
      style={{width: "100%", ...additionalImageSyles}}
      type="image/png"
      srcSet={`${mobile}${isGif ? '.gif' : '.png'}`}
      alt={alt}
    />
  </picture>
);

Picture.defaultProps = {
  desktop: '',
  alt: 'Fancy image',
};

Picture.propTypes = {
  mobile: PropTypes.string.isRequired,
  desktop: PropTypes.string,
  alt: PropTypes.string,
};

export default Picture;
