import { makeStyles } from '@material-ui/core';
import React from 'react'
import tvBorderImg from "../../../assets/img/apple-tv-border.png"
import tvScreenByDefaultImg from "../../../assets/img/apple-tv-default-screen.jpg"
import tvScreenOnHoverImg from "../../../assets/img/apple-tv-screen-on-hover.jpg"
import tvRCImg from "../../../assets/img/apple-tv-rc.png"
import tvImg from "../../../assets/img/apple-tv.png"

// @ts-ignore
const useStyles = makeStyles(theme => ({
    perspectiveWrapper: {
        perspective: "300px",
        width: "100%",
        position: "relative",
        paddingBottom: "50%",
        marginBottom: "15px",
        height: "0",
    },

    perspectiveWrapperForSection: {
        perspective: "300px",
        width: "100%",
        position: "relative",
        paddingBottom: "50%",
        marginBottom: "15px",
        height: "0",
    },

    container: {
        position: "absolute",
        width: "100%",
        height: "100%",
        transition: "0.2s",
        padding: "0 20px"
    },

    tvBorder: {
        position: "absolute",
        transition: "2s cubic-bezier(.2,1.14,.3,.95)",
        width: "74% !important",
        // left: "10.5%",
        top: "0",
        transformOrigin: "left bottom",
        height: "max-content",
        
        
        "&::before": {
            content: '""',
            width: "100%",
            position: "absolute",
            backgroundImage: `url(${tvBorderImg})`,
            paddingBottom: "60%",
            backgroundSize: "106%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "59% 63%",
        }
    },

    tvScreenContainer: {
        position: "absolute",
        background: "black",
        width: "98% !important",
        left: "1%",
        top: "3.5px",
        overflow: "hidden",
        paddingBottom: "56%",

        "&::after": {
            content: '""',
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "linear-gradient(120deg, transparent, rgb(255, 255, 255, 0.06) 40%, rgb(255, 255, 255, 0.2) 1%, transparent 1%)",
            backgroundSize: "200% auto",
            backgroundPositionX: "100%",
            transition: "0.1s ease",
            opacity: "0",
        }
    },

    tvScreenByDefault: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        objectFit: "contain",
        transition: "0.3s",
    },

    tvScreenOnHover: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        transform: "scale(1.2) translateY(100%)",
        objectFit: "contain",
        transition: "0.3s",
    },

    tvRC: {
        position: "absolute",
        width: "25px",
        height: "70px",
        right: 0,
        bottom: 0,
        transform: "scale(1.2)",
        objectFit: "contain",
        transition: "0.3s",
        zIndex: "3",
    },

    tv: {
        position: "absolute",
        width: "75px",
        height: "40px",
        right: "30px",
        bottom: "0",
        transform: "",
        objectFit: "contain",
        transition: "0.3s",
    },

    "@global": {
        "div:has(> $perspectiveWrapper)": {
            "&:hover > $perspectiveWrapper $tv": {
                transform: "scale(110%)",
            },
            "&:hover > $perspectiveWrapper $tvRC": {
                transform: "scale(1.1) rotateX(40deg)",
                right: "40px",
                bottom: "40px",
            },
            "&:hover > $perspectiveWrapper $container": {
                transform: "rotateY(-5deg)",
            },
    
            "&:hover > $perspectiveWrapper $tvScreenOnHover": {
                transform: "none",
            },
    
            "&:hover > $perspectiveWrapper $tvScreenByDefault": {
                transform: "scale(1.2) translateY(-100%)",
            },
    
            "&:hover > $perspectiveWrapper $tvScreenContainer": {
                "&::after": {
                    backgroundPosition: "20%",
                    opacity: "1",
                }
            }  
        },
        "section:has(> div $perspectiveWrapperForSection) div:has(> div $perspectiveWrapperForSection)": {
            "&:hover $perspectiveWrapper $tv": {
                transform: "scale(110%)",
            },
            "&:hover $perspectiveWrapperForSection $tvRC": {
                transform: "scale(1.1) rotateX(40deg)",
                right: "40px",
                bottom: "40px",
            },
            "&:hover $perspectiveWrapperForSection $container": {
                transform: "rotateY(-5deg)",
            },
    
            "&:hover $perspectiveWrapperForSection $tvScreenOnHover": {
                transform: "none",
            },
    
            "&:hover $perspectiveWrapperForSection $tvScreenByDefault": {
                transform: "scale(1.2) translateY(-100%)",
            },
    
            "&:hover $perspectiveWrapperForSection $tvScreenContainer": {
                "&::after": {
                    backgroundPosition: "20%",
                    opacity: "1",
                }
            }
        }
    },
}))

export default function AppleTvFeatureAnimation({isForSection}) {
    const classes = useStyles({isForSection: true});

    return (
        <div className={isForSection ? classes.perspectiveWrapperForSection : classes.perspectiveWrapper}>
            <div className={classes.container}>
                <div className={classes.tvBorder}>
                    <div className={classes.tvScreenContainer}>
                        <img className={classes.tvScreenByDefault} src={tvScreenByDefaultImg}></img>
                        <img className={classes.tvScreenOnHover} src={tvScreenOnHoverImg}></img>
                    </div>
                </div>
            </div>
            <img className={classes.tvRC} src={tvRCImg}></img>
            <img className={classes.tv} src={tvImg}></img>
        </div>
    )
}
