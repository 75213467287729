import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { StreamItem } from '../StreamItem/StreamItem';
import { TvTransStreamsSlider } from '../TvTransStreamsSlider/TvTransStreamsSlider'
import ShowMoreIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { TvTransSectionsList } from '../TvTransSectionsList/TvTransSectionsList';
import { TvTransCategoriesList } from '../TvTransCategoriesList/TvTransCategoriesList';
import { TvTransLeftPanel } from '../TvTransLeftPanel/TvTransLeftPanel';
import { TvTransGenreSection } from 'components/molecules/TvTransGenreSection/TvTransGenreSection';

const useStyles = makeStyles(theme => ({
    mainContent: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
    
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            background: theme.palette.background.paper
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.grey[500],
            borderRadius: "10px",
        },
    },
}))

export default function TvTransContentMain({content}) {
    const classes = useStyles();

    return (
        <>
            <TvTransLeftPanel/>
            <Box className={classes.content}>
                <Box className={classes.topRow}>
                    <TvTransStreamsSlider/>
                </Box>
                <Box className={classes.mainContent}>
                    <TvTransSectionsList directories={content.directories} order={1}/>
                    <TvTransCategoriesList indexesToDisplay={[0,3,5,8,10,12]} categoriesList={content.categoriesList} order={2}/>
                    {content.main.map(({title, content}, i) => (
                        <TvTransGenreSection itemsToShow={4} i={i} title={title} content={content} />
                    ))}
                </Box>
            </Box>
        </>
    )
}
