import React from 'react';
import { makeStyles, Grid, Chip, Box, Button, Divider } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import Text from 'components/atoms/Text/Text';
import EnhancedTable from 'components/organisms/EnhancedTable/EnhancedTable';
import { useNavigate } from 'react-router';
import { useTheme } from '@material-ui/styles';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    overflow: hidden;
  `}
`;

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: 20
    },
    content: {
        width: '100%',
        padding: '0 20px 20px 20px',
    },
}));

const Payments = () => {
	const navigate = useNavigate();
    const classes = useStyles();
    const theme = useTheme();

    // object that controlls styles of status label
    const statusLabels = {
        Paid: theme.palette.success.main,
        Unpaid: theme.palette.secondary.main,
        Cancelled:  theme.palette.grey[500]
    }
    
    // function to transform invoices array to array that can be used as rows property of EnhancedTable
    function createData(arr) {
        return arr.map((el) => ({ id: {data: el.id}, date: {data: el.date}, total: {data: el.total}, status: {data: <Text component='p' style={{color: statusLabels[el.status]}}>{el.status}</Text>, alignRight: true} }));
    }
    
    // const rows = []
    const rows = useSelector(state => createData(state.account.invoices))
    
    const columns = [
        { id: 'id', numeric: false, disablePadding: false, label: 'Invoice' },
        { id: 'date', numeric: false, disablePadding: false, label: 'Due Date' },
        { id: 'total', numeric: false, disablePadding: false, label: 'Amount' },
        { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    ];

    const handleClick = (e, row) => {
        if(e.target.type !== "checkbox") navigate(`132456`);
    }

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item className={classes.title}>
                        <Text component='h4' variant='h5'>Invoices</Text>
                        <Button variant='contained' color='primary'>Add Funds</Button>
                    </Grid>
                    <Grid item className={classes.content}>
                        <EnhancedTable
                            rows={rows}
                            columns={columns}
                            handleClick={handleClick}
                            defaultRowsPerPage={5}
                            showId
                            extraPaginationButton={<Button variant='contained' color='primary'>Mass Payment</Button>}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default Payments;