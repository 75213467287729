import { Avatar, Box, ListItemText, makeStyles } from '@material-ui/core'
import { ListItem, ListItemAvatar, ListItemButton } from '@mui/material'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    listTextPrimary: {
      fontSize: "13px",
      fontWeight: "bold",
    },
    listTextSecondary: {
      fontSize: "11px"
    },
    listButton: {
      position: "relative",
      padding: "0 5px !important",
      "&:hover": {
        backdropFilter: "invert(5%)",
      },
      "&:hover $usersCount": {
        background: theme.palette.primary.main
      },
      "&:hover $usersCount::before": {
        transform: "scale(1.1) translateY(-50%)",
        background: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.light}`
      },
      borderRadius: "10px !important",

      [theme.breakpoints.down('lg')]: {
        borderRadius: "0px !important",
        padding: "3px 8px !important",
      },
    },
    usersCount: {
      background: theme.palette.grey[500],
      padding: "2px 5px 2px 22px",
      position: "relative",
      borderRadius: "13px",
      color: theme.palette.primary.contrastText,
      transition: "0.1s ease",
      fontSize: "12px",
  
      "&::before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "7px",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        background: "#fff",
        transform: "translateY(-50%)",
        transformOrigin: "center",
        transition: "0.2s ease"
      },

      [theme.breakpoints.down('lg')]: {
        display: "none"
      }
    },
    showMoreSection: {
      display: "flex",
      alignItems: "center",
      "&::before": {
        height: "1px",
        background: "black",
        flex: 1,
        content: '""',
        opacity: 0.3
      },
      "&::after": {
        height: "1px",
        background: "black",
        flex: 1,
        content: '""',
        opacity: 0.3
      }
    },
    listText: {
        [theme.breakpoints.down('lg')]: {
            display: "none"
        }
    },
    avatar: {
        width: "30px",
        height: "30px",

        [theme.breakpoints.down('lg')]: {
            width: "40px",
            height: "40px",
        },
    },
    listItemAvatar: {
        minWidth: "0 !important",
        marginRight: "10px",

        [theme.breakpoints.down('lg')]: {
            marginRight: "0px",
            minWidth: "0 !important",
        }
    }
}))  

export const TvTransChannelsListElement = ({value}) => {
    const classes = useStyles();

    return (
        <ListItem
            key={value}
            disablePadding
        >
            <ListItemButton className={classes.listButton}>
                <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar
                        alt={`Avatar`}
                        src={`/static/images/avatar.jpg`}
                        className={classes.avatar}
                    />
                </ListItemAvatar>
                <ListItemText
                    id={"id"}
                    className={classes.listText}
                    classes={{primary: classes.listTextPrimary, secondary: classes.listTextSecondary}}
                    primary={`channel ${value + 1}`}
                    secondary="Gaming"
                />
                <Box className={classes.usersCount}>
                    12k
                </Box>
            </ListItemButton>
        </ListItem>
    )
}
