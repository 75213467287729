import TvTransContentMain from 'components/organisms/TvTransContent/TvTransContentMain';
import TvTransContentStream from 'components/organisms/TvTransContent/TvTransContentStream';
import React from 'react';
import { Route } from 'react-router-dom';
import PreviewImage from "../assets/preview.png"
import { TvTrans } from 'views/TvTrans/TvTrans';
import TvTransContentDirectory from 'components/organisms/TvTransContent/TvTransContentDirectory';
import TvTransContentCategory from 'components/organisms/TvTransContent/TvTransContentCategory';
import imgIrl1 from "../assets/categories/irl1.jpg"
import imgIrl2 from "../assets/categories/irl2.jpg"
import imgIrl3 from "../assets/categories/irl3.jpg"
import imgIrl4 from "../assets/categories/irl4.jpg"
import imgIrl5 from "../assets/categories/irl5.jpg"
import imgIrl6 from "../assets/categories/irl6.jpg"
import imgGames1 from "../assets/categories/games1.jpg"
import imgGames2 from "../assets/categories/games2.jpg"
import imgGames3 from "../assets/categories/games3.jpg"
import imgGames4 from "../assets/categories/games4.jpg"
import imgGames5 from "../assets/categories/games5.jpg"
import imgGames6 from "../assets/categories/games6.jpg"
import imgArt1 from "../assets/categories/art1.jpg"
import imgArt2 from "../assets/categories/art2.jpg"
import imgArt3 from "../assets/categories/art3.jpg"
import imgArt4 from "../assets/categories/art4.jpg"
import imgArt5 from "../assets/categories/art5.jpg"
import SportsEsportsIcon from '@mui/icons-material/SportsEsportsRounded';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoiceRounded';
import HeadphonesIcon from '@mui/icons-material/HeadphonesRounded';
import EmojiEventsIcon from '@mui/icons-material/EmojiEventsRounded';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjectsRounded';
import TvTransContentModeration from 'components/organisms/TvTransContent/TvTransContentModeration';

const videoExample = {
  title: "Lorem ipsum dolor sit amet.",
  description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit cum sapiente est aut. Magnam, officia!",
  src: "https://tv.fastcast4u.com:3238/stream/play.m3u8",
  previewSrc: PreviewImage,
  chips: [
      "bla 1",
      "bla 2",
      "bla 3"
  ],
  author: {
      name: "someGuy",
      avatarSrc: ""
  },
  directory: "irl",
  category: "special-events",
}

// const exampleArray = Array.from({length: 100}, (a, i) => (
//     {
//         title: "Lorem ipsum dolor sit amet.",
//         description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit cum sapiente est aut. Magnam, officia!",
//         src: `https://tv2.fastcast4u.com:3353/live/live${i + 1}live.m3u8`,
//         previewSrc: PreviewImage,
//         chips: [
//             "bla 1",
//             "bla 2",
//             "bla 3"
//         ],
//         author: {
//             name: "someGuy",
//             avatarSrc: ""
//         },
//         directory: "irl",
//         category: "special-events",
//       }
// ))

const exampleArray = [
    "srt://eu.srt.belabox.net:4001?streamid=VOmkD0Z6cEYFDGHOmLlc7r4REwn6oe&timeout=2000000",
    "srt://eu.srt.belabox.net:4001?streamid=snJEmMkZ06XxN9avqqg9q0OVqOFRB2&timeout=2000000",
    "srt://eu.srt.belabox.net:4001?streamid=zwS10vKyHxfCDmXYKMoKNd0Dyv1zRj&timeout=2000000",
    "srt://eu.srt.belabox.net:4001?streamid=dMfyeHPRWjI7wbNjh8KKwNnvpiz191&timeout=2000000",
    "https://tv2.fastcast4u.com:3353/live/live1live.m3u8",
    "https://tv2.fastcast4u.com:3836/live/live2live.m3u8",
    "https://tv2.fastcast4u.com:3881/live/live3live.m3u8",
    "https://tv2.fastcast4u.com:3008/live/live4live.m3u8",
    "https://tv2.fastcast4u.com:3384/live/live5live.m3u8",
    "https://tv2.fastcast4u.com:3421/live/live6live.m3u8",
    "https://tv2.fastcast4u.com:3134/live/live7live.m3u8",
    "https://tv2.fastcast4u.com:3118/live/live8live.m3u8",
    "https://tv2.fastcast4u.com:3102/live/live9live.m3u8",
    "https://tv2.fastcast4u.com:3210/live/live10live.m3u8",
    "https://tv2.fastcast4u.com:3580/live/live11live.m3u8",
    "https://tv2.fastcast4u.com:3533/live/live12live.m3u8",
    "https://tv2.fastcast4u.com:3947/live/live13live.m3u8",
    "https://tv2.fastcast4u.com:3786/live/live14live.m3u8",
    "https://tv2.fastcast4u.com:3209/live/live15live.m3u8",
    "https://tv2.fastcast4u.com:3222/live/live16live.m3u8",
    "https://tv2.fastcast4u.com:3915/live/live17live.m3u8",
    "https://tv2.fastcast4u.com:3845/live/live18live.m3u8",
    "https://tv2.fastcast4u.com:3192/live/live19live.m3u8",
    "https://tv2.fastcast4u.com:3220/live/live20live.m3u8",
    "https://tv2.fastcast4u.com:3614/live/live21live.m3u8",
    "https://tv2.fastcast4u.com:3830/live/live22live.m3u8",
    "https://tv2.fastcast4u.com:3952/live/live23live.m3u8",
    "https://tv2.fastcast4u.com:3976/live/live24live.m3u8",
    "https://tv2.fastcast4u.com:3543/live/live25live.m3u8",
    "https://tv2.fastcast4u.com:3157/live/live26live.m3u8",
    "https://tv2.fastcast4u.com:3112/live/live27live.m3u8",
    "https://tv2.fastcast4u.com:3114/live/live28live.m3u8",
    "https://tv2.fastcast4u.com:3746/live/live29live.m3u8",
    "https://tv2.fastcast4u.com:3255/live/live30live.m3u8",
    "https://tv2.fastcast4u.com:3823/live/live31live.m3u8",
    "https://tv2.fastcast4u.com:3320/live/live32live.m3u8",
    "https://tv2.fastcast4u.com:3446/live/live33live.m3u8",
    "https://tv2.fastcast4u.com:3568/live/live34live.m3u8",
    "https://tv2.fastcast4u.com:3488/live/live35live.m3u8",
    "https://tv2.fastcast4u.com:3104/live/live36live.m3u8",
    "https://tv2.fastcast4u.com:3281/live/live37live.m3u8",
    "https://tv2.fastcast4u.com:3660/live/live38live.m3u8",
    "https://tv2.fastcast4u.com:3428/live/live39live.m3u8",
    "https://tv2.fastcast4u.com:3398/live/live40live.m3u8",
    "https://tv2.fastcast4u.com:3049/live/live41live.m3u8",
    "https://tv2.fastcast4u.com:3949/live/live42live.m3u8",
    "https://tv2.fastcast4u.com:3212/live/live43live.m3u8",
    "https://tv2.fastcast4u.com:3981/live/live44live.m3u8",
    "https://tv2.fastcast4u.com:3079/live/live45live.m3u8",
    "https://tv2.fastcast4u.com:3659/live/live46live.m3u8",
    "https://tv2.fastcast4u.com:3957/live/live47live.m3u8",
    "https://tv2.fastcast4u.com:3119/live/live48live.m3u8",
    "https://tv2.fastcast4u.com:3585/live/live49live.m3u8",
    "https://tv2.fastcast4u.com:3485/live/live50live.m3u8",
    "https://tv2.fastcast4u.com:3615/live/live51live.m3u8",
    "https://tv2.fastcast4u.com:3152/live/live52live.m3u8",
    "https://tv2.fastcast4u.com:3741/live/live53live.m3u8",
    "https://tv2.fastcast4u.com:3285/live/live54live.m3u8",
    "https://tv2.fastcast4u.com:3671/live/live55live.m3u8",
    "https://tv2.fastcast4u.com:3153/live/live56live.m3u8",
    "https://tv2.fastcast4u.com:3919/live/live57live.m3u8",
    "https://tv2.fastcast4u.com:3587/live/live58live.m3u8",
    "https://tv2.fastcast4u.com:3999/live/live59live.m3u8",
    "https://tv2.fastcast4u.com:3517/live/live60live.m3u8",
    "https://tv2.fastcast4u.com:3809/live/live61live.m3u8",
    "https://tv2.fastcast4u.com:3252/live/live62live.m3u8",
    "https://tv2.fastcast4u.com:3055/live/live63live.m3u8",
    "https://tv2.fastcast4u.com:3767/live/live64live.m3u8",
    "https://tv2.fastcast4u.com:3474/live/live65live.m3u8",
    "https://tv2.fastcast4u.com:3571/live/live66live.m3u8",
    "https://tv2.fastcast4u.com:3692/live/live67live.m3u8",
    "https://tv2.fastcast4u.com:3326/live/live68live.m3u8",
    "https://tv2.fastcast4u.com:3332/live/live69live.m3u8",
    "https://tv2.fastcast4u.com:3778/live/live70live.m3u8",
    "https://tv2.fastcast4u.com:3871/live/live71live.m3u8",
    "https://tv2.fastcast4u.com:3032/live/live72live.m3u8",
    "https://tv2.fastcast4u.com:3423/live/live73live.m3u8",
    "https://tv2.fastcast4u.com:3640/live/live74live.m3u8",
    "https://tv2.fastcast4u.com:3181/live/live75live.m3u8",
    "https://tv2.fastcast4u.com:3410/live/live76live.m3u8",
    "https://tv2.fastcast4u.com:3618/live/live77live.m3u8",
    "https://tv2.fastcast4u.com:3382/live/live78live.m3u8",
    "https://tv2.fastcast4u.com:3029/live/live79live.m3u8",
    "https://tv2.fastcast4u.com:3082/live/live80live.m3u8",
    "https://tv2.fastcast4u.com:3948/live/live81live.m3u8",
    "https://tv2.fastcast4u.com:3219/live/live82live.m3u8",
    "https://tv2.fastcast4u.com:3297/live/live83live.m3u8",
    "https://tv2.fastcast4u.com:3663/live/live84live.m3u8",
    "https://tv2.fastcast4u.com:3497/live/live85live.m3u8",
    "https://tv2.fastcast4u.com:3689/live/live86live.m3u8",
    "https://tv2.fastcast4u.com:3101/live/live87live.m3u8",
    "https://tv2.fastcast4u.com:3024/live/live88live.m3u8",
    "https://tv2.fastcast4u.com:3509/live/live89live.m3u8",
    "https://tv2.fastcast4u.com:3470/live/live90live.m3u8",
    "https://tv2.fastcast4u.com:3084/live/live91live.m3u8",
    "https://tv2.fastcast4u.com:3040/live/live92live.m3u8",
    "https://tv2.fastcast4u.com:3006/live/live93live.m3u8",
    "https://tv2.fastcast4u.com:3654/live/live94live.m3u8",
    "https://tv2.fastcast4u.com:3892/live/live95live.m3u8",
    "https://tv2.fastcast4u.com:3524/live/live96live.m3u8",
    "https://tv2.fastcast4u.com:3107/live/live97live.m3u8",
    "https://tv2.fastcast4u.com:3463/live/live98live.m3u8",
    "https://tv2.fastcast4u.com:3833/live/live99live.m3u8",
    "https://tv2.fastcast4u.com:3487/live/live100live.m3u8",
].map((a, i) => (
    {
        title: `Live${i+1}`,
        description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit cum sapiente est aut. Magnam, officia!",
        src: a,
        previewSrc: PreviewImage,
        chips: [
            "bla 1",
            "bla 2",
            "bla 3"
        ],
        author: {
            name: "someGuy",
            avatarSrc: ""
        },
        directory: "irl",
        category: "special-events",
    }
))

const content = {
    top: [
        "https://tv.fastcast4u.com:3238/stream/play.m3u8",
        "https://tv.fastcast4u.com:3238/stream/play.m3u8",
        "https://tv.fastcast4u.com:3238/stream/play.m3u8",
        "https://tv.fastcast4u.com:3238/stream/play.m3u8",
        "https://tv.fastcast4u.com:3238/stream/play.m3u8",
        "https://tv.fastcast4u.com:3238/stream/play.m3u8",
        "https://tv.fastcast4u.com:3238/stream/play.m3u8",
        "https://tv.fastcast4u.com:3238/stream/play.m3u8",
    ],
    main: [
        {
            title: "Genre Lorem inpsum",
            content: exampleArray.slice(0,8),
        },
        {
            title: "Genre Lorem inpsum",
            content: exampleArray.slice(9, 17),
        },
        {
            title: "Genre Lorem inpsum",
            content: exampleArray.slice(17, 25),
        },
        {
            title: "Genre Lorem inpsum",
            content: exampleArray.slice(25, 33),
        },
        {
            title: "Genre Lorem inpsum",
            content: exampleArray.slice(33, 41),
        },
        {
            title: "Genre Lorem inpsum",
            content: exampleArray.slice(41,49),
        },
        {
            title: "Genre Lorem inpsum",
            content: exampleArray.slice(49,57),
        },
        {
            title: "Genre Lorem inpsum",
            content: exampleArray.slice(57, 65),
        }
    ],
    directory: [
        {
            content: exampleArray,
        }
    ],
    categoriesList: [
        {
            title: "MLB The Show 23",
            img: imgGames1,
            viewersCount: 3.8,
            slug: "mlb-the-show-23",
            directory: "games",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Demonologist",
            img: imgGames2,
            viewersCount: 4.8,
            slug: "demonologist",
            directory: "games",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Crab Champions",
            img: imgGames3,
            viewersCount: 3.5,
            isNew: true,
            slug: "crab-champions",
            directory: "games",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "WWE 2K23",
            img: imgGames4,
            viewersCount: 2.3,
            slug: "wwe-2k23",
            directory: "games",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Dredge",
            img: imgGames5,
            viewersCount: 10.2,
            isNew: true,
            slug: "dredge",
            directory: "games",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Counter Strike",
            img: imgGames6,
            viewersCount: 10.2,
            isNew: true,
            slug: "counter-strike",
            directory: "games",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Sports",
            img: imgIrl1,
            viewersCount: 3.8,
            slug: "sports",
            directory: "irl",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Travel",
            img: imgIrl2,
            viewersCount: 4.8,
            slug: "travel",
            directory: "irl",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Podcasts",
            img: imgIrl3,
            viewersCount: 3.5,
            isNew: true,
            slug: "podcasts",
            directory: "irl",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Special events",
            img: imgIrl4,
            viewersCount: 2.3,
            slug: "special-events",
            directory: "irl",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Just chatting",
            img: imgIrl5,
            viewersCount: 10.2,
            isNew: true,
            slug: "just-chatting",
            directory: "irl",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "ASMR",
            img: imgIrl6,
            viewersCount: 10.2,
            isNew: true,
            slug: "asmr",
            directory: "irl",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Art",
            img: imgArt1,
            viewersCount: 4.8,
            slug: "art",
            directory: "creative",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Software development",
            img: imgArt2,
            viewersCount: 3.5,
            isNew: true,
            slug: "software-development",
            directory: "creative",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Food & drink",
            img: imgArt3,
            viewersCount: 2.3,
            slug: "food-drink",
            directory: "creative",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "makers & crafting",
            img: imgArt4,
            viewersCount: 10.2,
            isNew: true,
            slug: "makers-crafting",
            directory: "creative",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        },
        {
            title: "Beaty & Body art",
            img: imgArt5,
            viewersCount: 10.2,
            isNew: true,
            slug: "beaty-body-art",
            directory: "creative",
            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore voluptas excepturi, itaque laudantium in eveniet commodi. Consectetur incidunt, rerum earum nesciunt iusto possimus excepturi cumque quibusdam voluptatibus vel. Aut, consequatur! Alias praesentium eveniet ab eius autem, delectus vero deserunt aperiam!"
        }
    ],
    directories: [
        {
            title: "Games",
            slug: "games",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum neque alias dolore rerum amet ipsum quas",
            Icon: SportsEsportsIcon,
        },
        {
            title: "IRL",
            slug: "irl",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum neque alias dolore rerum amet ipsum quas",
            Icon: KeyboardVoiceIcon,
        },
        {
            title: "Music",
            slug: "music",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum neque alias dolore rerum amet ipsum quas",
            Icon: HeadphonesIcon,
        },
        {
            title: "Esports",
            slug: "esports",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum neque alias dolore rerum amet ipsum quas",
            Icon: EmojiEventsIcon,
        },
        {
            title: "Creative",
            slug: "creative",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum neque alias dolore rerum amet ipsum quas",
            Icon: EmojiObjectsIcon,
        },
    ]

}

//children routes for /tvtrans page
const TvTransRoutes = [
  <Route index element={<TvTransContentMain content={content} />}/>,
  <Route path="adminpreview" element={<TvTransContentModeration content={content}/>}/>,
  <Route path="directory" element={<TvTransContentDirectory content={content}/>}/>,
  <Route path="adminpreview" element={<TvTransContentModeration content={content}/>}/>,
  <Route path="directory" element={<TvTransContentDirectory content={content}/>}/>,
  <Route path="directory/:directory" element={<TvTransContentDirectory content={content}/>}/>,
  <Route path="directory/:directory/:category" element={<TvTransContentCategory content={content}/>}/>,
  <Route path="stream" element={<TvTransContentStream video={videoExample}/>}/>,
  <Route path="directory/:directory/:category" element={<TvTransContentCategory content={content}/>}/>,
  <Route path="stream" element={<TvTransContentStream video={videoExample}/>}/>,
]

export default TvTransRoutes;
