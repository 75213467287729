import React from 'react';
import { SvgIcon, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    ArrowDown: {
        animation: `$down 1000ms ${theme.transitions.easing.easeInOut} infinite`
    },
    "@keyframes down": {
      "0%": {
        transform: "translateY(-100%)"
      },
      "100%": {
        transform: "translateY(120%)"
      }
    }
}));

const ArrowDown = props => {
    const classes = useStyles();

    return (
        <SvgIcon fontSize='large' viewBox="0 0 44 42" {...props}>
            <g className={classes.ArrowDown}>
                <path id="A1" d="M43.4088 0.986201C43.0515 0.83788 42.64 0.919394 42.3662 1.19281L21.9999 21.561L1.63377 1.19281C1.26073 0.818779 0.655069 0.818062 0.281039 1.19111C0.100705 1.37099 -0.000446874 1.61535 1.4948e-06 1.87003V19.0874C-0.000446874 19.3413 0.0999878 19.5849 0.279335 19.7646L21.3228 40.8081C21.6963 41.1815 22.3018 41.1815 22.6754 40.8081L43.7188 19.7646C43.8988 19.5852 44 19.3415 44.0001 19.0874V1.87003C43.9999 1.48299 43.7666 1.13407 43.4088 0.986201Z" fill="#2196F3"></path>
            </g>
        </SvgIcon>
    );
};

export default ArrowDown;
