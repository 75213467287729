// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import { CssBaseline, NoSsr, Box } from '@material-ui/core/';
import { StylesProvider } from '@material-ui/core/styles';
import GlobalStyle from 'theme/GlobalStyle';
import Alert from 'components/atoms/Alert/Alert';
import OnlineStatusBar from 'components/molecules/OnlineStatusBar/OnlineStatusBar';
import PromoNotification from 'components/molecules/PromoNotification/PromoNotification';
import Header from 'routes/HeaderRoutes';
import PageNavigation from 'components/organisms/PageNavigation/PageNavigation';
import Footer from 'routes/FooterRoutes';
import CookieConsent from 'components/organisms/CookieConsent/CookieConsent';
import MainThemeProvider from 'theme/MainThemeProvider';
import ErrorHandler from 'templates/ErrorHandler';
import ThirdPartyResources from 'utils/ThirdPartyResources';
import ServiceWorkerHandler from 'utils/ServiceWorkerHandler';

// component that plays the role of container for all pages and displays same elements on any page.
// this elements are: Header, Footer, PageNavigation. Also it has Alert, CookieConsent and PromoNotification in case it is needed to display
const MainTemplate = ({ children }) => (
  <ErrorHandler>
    <NoSsr>
      <MainThemeProvider>
        <StylesProvider injectFirst>
          <CssBaseline />
          <GlobalStyle />
          <Alert />
          <Header />
          <OnlineStatusBar />
          {/* pages views are displayed here */}
          <Box>{children}</Box>
          {!(window.location.pathname === "/join" || !(window.location.pathname === "/tv")) &&
            <PageNavigation />
          }
          <Footer/>
          {!(window.location.pathname === "/join") &&
            <CookieConsent />
          }
          <PromoNotification />
          <ThirdPartyResources />
          <ServiceWorkerHandler />
        </StylesProvider>
      </MainThemeProvider>
    </NoSsr>
  </ErrorHandler>
);

MainTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainTemplate;
