// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import RowContent from 'components/molecules/RowContent/RowContent';
import Picture from 'components/molecules/Picture/Picture';
import { Slide } from 'react-awesome-reveal';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('md')]: {
      minHeight: ({hasMediaContent}) => hasMediaContent ? 450 : 320,
    },
  },
  item: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: ({ reverse, long }) =>
      long ? (reverse ? 'flex-start' : 'flex-end') : reverse ? 'flex-end' : 'flex-start',
    textAlign: ({ reverse, long }) =>
      long ? (reverse ? 'left' : 'right') : reverse ? 'right' : 'left',
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
      textAlign: 'center !important',
      alignItems: 'center !important',
    },
    [theme.breakpoints.up(1000)]: {
      paddingRight: ({reverse, paddingRightNone}) => !paddingRightNone ? reverse ? 0 : '2% !important' : 0,
      paddingLeft: ({reverse}) => reverse ? '2% !important' : 0
    },
    [theme.breakpoints.up(1050)]: {
      paddingRight: ({reverse, paddingRightNone}) => !paddingRightNone ? reverse ? 0 : '4% !important' : 0,
      paddingLeft: ({reverse}) => reverse ? '4% !important' : 0
    },
    [theme.breakpoints.up(1100)]: {
      paddingRight: ({reverse, paddingRightNone}) => !paddingRightNone ? reverse ? 0 : '6% !important' : 0,
      paddingLeft: ({reverse}) => reverse ? '6% !important' : 0
    },
    [theme.breakpoints.up(1200)]: {
      paddingRight: ({reverse, paddingRightNone}) => !paddingRightNone ? reverse ? 0 : '8% !important' : 0,
      paddingLeft: ({reverse}) => reverse ? '8% !important' : 0,
    },
  },
  image: {
    minHeight: 200,
    padding: theme.spacing(0, 2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: "contain",
    width: "100%",

    '& div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },

    '& img': {
      margin: '0 auto',
      maxHeight: 365,
    },

    [theme.breakpoints.up('sm')]: {
      order: ({ reverse }) => (reverse ? 1 : 0),
    },
  },
  IframeWrapper: {
    width: '100%',
    height: 0,
    paddingBottom: ({videoHeight, videoWidth}) => `${videoHeight/videoWidth*100}%`,
    position: 'relative'
  },
  Iframe: {
    border: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  VideoWrapper: {
    width: '100%',
    height: "400px",
    maxHeight: "400px",
    overflow: "hidden",
    position: 'relative',
    postiion: "flex",
    alignItems: "center"
  },
  VideoWrapper: {
    width: '100%',
    height: "300px",
    maxHeight: "400px",
    overflow: "hidden",
    position: 'relative',
    postiion: "flex",
    alignItems: "center"
  },
  Video: {
    border: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

// component to make sections. It takes takes props for RowContent and img, gif or video for ilustration
const RowSection = ({ additionalImageSyles, element, contentElement, isElement, img, fullPathIsGiven, noWebp, isGif, videoPlayer, RadioPlayer, reverse, short, imgReplacement, paddingRightNone, heading, long, ...props }) => {
  const hasMediaContent =  videoPlayer || RadioPlayer || imgReplacement || img || element;
  const classes = useStyles({ reverse, paddingRightNone, long, videoWidth: videoPlayer?.width, videoHeight: videoPlayer?.height, contentElement: !contentElement });
  const player = useRef(null);


  // window.onresize = () => console.dir(player.current?.contentWindow?.document?.documentElement.clientHeight);

  return (
    <Grid className={classes.container} container>
      {hasMediaContent &&
        <Grid {...videoPlayer?.props} className={classes.image} item xs={12} sm={6} md={long ? 5 : short ? 6 : 7}>
          <Slide direction={reverse ? 'right' : 'left'} triggerOnce>
            {videoPlayer && !videoPlayer?.isLocal ?
              <div className={classes.IframeWrapper} > 
                <iframe ref={player} className={classes.Iframe} src={videoPlayer.src}></iframe>
              </div>
              :
              videoPlayer?.isLocal ? 
                <div className={classes.VideoWrapper} > 
                  <video className={classes.Video} loop autoPlay muted src={videoPlayer.src} style={videoPlayer.additionalStyles}/>
                </div>
              :
              element ?
                <div className={classes.ElementWrapper} >
                  {element}
                </div>
              :
              RadioPlayer ?
                <iframe border="0" style={{border: '0', margin: '0 15px', maxWidth: '100%', overflow: 'hidden'}}  width='400' height='370' ref={player} src={RadioPlayer.src} allow="autoplay"></iframe>
                : imgReplacement
                  ? imgReplacement
                  :
                    <div style={{overflow: "hidden"}}>{React.isValidElement(img) ? img : <Picture additionalImageSyles={additionalImageSyles} mobile={img} alt={heading} isGif={isGif} noWebp={noWebp}/>}</div>
            }
          </Slide>
        </Grid>
      }
      <Grid style={!hasMediaContent ? {alignItems: "center"} : {}} className={classes.item} item xs={12} sm={hasMediaContent ? 6 : 12} md={hasMediaContent ? (long ? 7 : short ? 6 : 5) : 12}>
        {contentElement ? contentElement :
          <RowContent Content hasMediaContent={hasMediaContent} contentCentred={!hasMediaContent} long={long} short={short} heading={heading} {...props} />
        }
      </Grid>
    </Grid>
  );
};

RowSection.defaultProps = {
  long: false,
};

RowSection.propTypes = {
  // img: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  element: PropTypes.any,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  reverse: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  long: PropTypes.bool,
};

export default RowSection;
