/* eslint-disable react/no-danger */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Container, Box, makeStyles, Typography } from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import FullContainer from 'components/atoms/FullContainer/FullContainer';
import HeroSection from 'components/organisms/HeroSection/HeroSection';
import RowSection from 'components/organisms/RowSection/RowSection';
import Accordion from 'components/organisms/Accordion/Accordion';
import { handleExternal } from 'utils/urls';
import { useCurrentLanguage } from 'utils/customHooks';
import FeatureSectionRadio from 'components/organisms/FeatureSectionRadio/FeatureSectionRadio';
import ScrollableAnchor from 'react-scrollable-anchor';
import { CheckMore } from 'components/molecules/CheckMore/CheckMore';
import { useNavigate } from 'react-router';
// import globeVid from "../../assets/globe.mov"
import AlexaFeatureAnimation from 'components/atoms/AlexaFeatureAnimation/AlexaFeatureAnimation';
import TelFeatureAnimation from 'components/atoms/TelFeatureAnimation/TelFeatureAnimation';
import AppleTvFeatureAnimation from 'components/atoms/AppleTvFeatureAnimation/AppleTvFeatureAnimation';
import CalendarAnimation from 'components/atoms/CalendarAnimation/CalendarAnimation';
import PlaneIcon from 'assets/svg/PlaneIcon';

const useStyles = makeStyles(theme => ({
  Wrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0',
      '& >*': {
        cursor: 'pointer',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }
  },
  list: {
    paddingLeft: 15,
    margin: 0,
    lineHeight: 1.5,

    
    [theme.breakpoints.only('xs')]: {
      listStyle: 'none',
      padding: 0,
      lineHeight: 1.2
    }
  }
}));

const Join = () => {
  const classes = useStyles();
  const content = useSelector(state => state.language.tv);
  const contentRadio = useSelector(state => state.language.join);
  const lng = useCurrentLanguage();
  const [categories, setCategories] = useState(contentRadio.faq.accordions);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if(window.Tawk_API?.hideWidget) {
        window.Tawk_API?.hideWidget();
        clearInterval(interval)
      }
    }, 500)
    return () => {
      clearInterval(interval)
      window.Tawk_API?.showWidget();
    };
    // eslint-disable-next-line
  }, [window.Tawk_API]);

  const heroData = useMemo(
    () => ({
      id: 'hero.id',
      heading: (
        <span dangerouslySetInnerHTML={{ __html: contentRadio.heroSection.heading }} />
      ),
      // image: 'https://img.fastcast4u.com/react/radio/backcover.png',
      image: "https://strapi.fastcast4u.com/uploads/Getty_Images_952843738_2200x880_b811f68c85.jpg",
      // video: 'https://img.fastcast4u.com/react/radio/radio.mp4',
      isNoStrapi: true,
      largerButtons: true,
      buttons: [
        {
          label: contentRadio.heroSection.buttons[0],
          onClick: () => handleExternal(navigate, 'https://account.fastcast4u.com/link.php?id=1198', lng),
          color: 'secondary',
        },
        {
          label: contentRadio.heroSection.buttons[1],
          onClick: () => handleExternal(navigate, 'https://account.fastcast4u.com/link.php?id=1199', lng),
          color: 'secondary',
          // iconElement: <PlaneIcon />
        },
        {
          label: contentRadio.heroSection.buttons[2],
          onClick: () => handleExternal(navigate, 'https://account.fastcast4u.com/link.php?id=1201', lng),
          color: 'secondary',
        }
      ]
    }),
    // eslint-disable-next-line
    [content, lng],
  );

  const sections = useMemo(
    () => [
      {
        // https://img.fastcast4u.com/react/home/device1
        videoPlayer: {
          // src: globeVid,
          src: "https://img.fastcast4u.com/react/radio/globe.mp4",
          isLocal: true,
          width: 1,
          height: 1,
          additionalStyles: {
            transform: "scale(130%) translate(70px, 40px)",
            // height: "100px"
          }
        },
        isGif: true,
        noWebp: true,
        additionalImageSyles: {
          transform: "scale(130%) translate(40px, 40px)",
        },
        // img: globeVideo,
        // fullPathIsGiven: true,
        // heading: contentRadio.rowSections[0].heading,
        content: (
          <ul className={classes.list}>
            <li>
              <span><b>Unlimited</b> Listeners & Transfer</span>
            </li>
            <li>
              <span><b>Live Stream</b> & <b>AutoDJ</b> mode</span>
            </li>
            <li>
              <span>Online Server <b>Control Panel</b></span>
            </li>
            <li>
              <span><b>Playlist</b> Scheduler & Management</span>
            </li>
            <li>
              <span>Detailed <b>Listeners Statistics</b></span>
            </li>
            <li>
              <span>Useful Radio <b>Server Addons</b></span>
            </li>
          </ul>
        ),
        contentReverse: true,
        // button: {
        //   label: contentRadio.rowSections[0].button,
        //   onClick: () => handleExternal(navigate, '/order/package', lng),
        //   color: 'primary'
        // },
      },
      {
        element: <CalendarAnimation/>,
        noWebp: true,
        heading: contentRadio.rowSections[1].heading,
        content: (
          <ul className={classes.list}>
            <li>
              <span><b>Unlimited disc</b> space for your audio library</span>
            </li>
            <li>
              <span>24/7 Radio Automation <b>Scheduler</b></span>
            </li>
            <li>
              <span>Smooth transition between <b>Live Broadcast</b></span>
            </li>
            <li>
              <span>Easy drag&drop <b>Playlist Management</b></span>
            </li>
          </ul>
        ),
        contentReverse: true,
      },
      {
        img: '',
        RadioPlayer: {
          src: 'https://fastcast4u.com/player/wydra',
          width: 400,
          height: 500,
          scrolling: "no"
        },
        heading: contentRadio.rowSections[2].heading,
        content:
          <ul className={classes.list}>
            <li>
              <b>Customizable</b> Layout, Logo and Themes
            </li>
            <li>
              <b>Automatic Setup</b> of your WebPlayer Page
            </li>
            <li>
              <b>Social Media & Players</b> buttons
            </li>
            <li>
              <b>Website Radio Player</b> embed widget
            </li>
            <li>
              <b>Dynamic</b> Cover Art & Metadata
            </li>
            <li>
              Place for <b>Ads</b> or <b>your own content</b>
            </li>
          </ul>,           
        contentReverse: true,
        buttonReverse: true,
        button: {
          label: contentRadio.rowSections[2].button,
          // opens popup webplayer widget
          onClick: () => {window.open('http://fastcast4u.com/player/wydra/', '_blank', "width=1000,height=610,left:50%,top: 50%")},
          color: 'primary',
          inNewWindow: true,
        },
      },
      // {
      //   heading: contentRadio.rowSections[3].heading,
      //   content: contentRadio.rowSections[3].content,
      //   button: {
      //     label: contentRadio.rowSections[3].button,
      //     onClick: () => handleExternal(navigate, '/contact?chat', lng),
      //   },
      // },
    ],
    [contentRadio, lng],
  );

  const contactUsSection = useMemo(
    () => (
      {
        heading: contentRadio.rowSections[3].heading,
        content: contentRadio.rowSections[3].content,
        button: {
          label: contentRadio.rowSections[3].button,
          onClick: () => handleExternal(navigate, '/contact?chat', lng),
        },
      }
    ),
    [contentRadio, lng],
  );

  const featureData = useMemo(
    () => [
      {
        heading: contentRadio.features[0].heading,
        desc: contentRadio.features[0].desc,
        cards:[
          {
            title: contentRadio.features[0].cards[0].title,
            element: <TelFeatureAnimation />,
            desc: contentRadio.features[0].cards[0].desc
          },
          {
            title: contentRadio.features[0].cards[1].title,
            element: <AlexaFeatureAnimation />,
            desc: contentRadio.features[0].cards[1].desc
          },
          {
            title: contentRadio.features[0].cards[2].title,
            element: <AppleTvFeatureAnimation />,
            desc: contentRadio.features[0].cards[2].desc
          }
        ],
        button: contentRadio.features[0].button
      },
    ], [contentRadio],
  );

  // useEffect(() => {
  //   faqServices.getCategories().then(({ articles }) => {
  //     setCategories(...articles);
  //   });
  // }, []);
  
  return (
    <>
      <HeroSection hasTextShadow headingSize={"45px"} largerButtons left={false} data={heroData} />
    </>
  );
};

export default Join;
