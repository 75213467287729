import { Box, makeStyles, Paper} from "@material-ui/core"
import TvTransAppBar from "components/organisms/TvTransAppBar/TvTransAppBar"
import TvTransAuthorization from "components/organisms/TvTransAuthorization/TvTransAuthorization";
import TvTransContentMain from "components/organisms/TvTransContent/TvTransContentMain";
import { TvTransLeftPanel } from "components/organisms/TvTransLeftPanel/TvTransLeftPanel";
import { Outlet } from "react-router";

// @ts-ignore
const useStyles = makeStyles(theme => ({
    mainContent: {
        flex: 1,
        height: "100%",
    },
    ganre: {
        margin: "40px 0",
        padding: "0 30px",
        width: "100%",
        boxSizing: "border-box",
    },
    genreTitle: {
        width: "100%",
        boxSizing: "border-box",
        padding: "15px 20px",
        borderRadius: "10px",
        fontSize: "20px",
        fontWeight: "bold",
        color: theme.palette.primary.contrastText,
        backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
        margin: "10px 0",
        marginBottom: "20px",
    },
    genreContent: {
        display: "flex",
        height: "300px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "20px",
        width: "100%",
        boxSizing: "border-box",
        marginRight: "-20px"
    },
}))

export const TvTrans = () => {
    const classes = useStyles();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
            }}
        >
            <TvTransAppBar/>
            <TvTransAuthorization/>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    overflow: "hidden",
                }}
            >
                <Outlet/>
            </Box>
        </Box>
    )
}