import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import OrderTvSuccess from 'views/OrderTv/OrderTvSuccess/OrderTvSuccess';

const OrderTvPackage = lazy(() => import('views/OrderTv/OrderTvPackage/OrderTvPackage'));
const OrderTvLogin = lazy(() => import('views/OrderTv/OrderTvLogin/OrderTvLogin'));
const OrderTvPayment = lazy(() => import('views/OrderTv/OrderTvPayment/OrderTvPayment'));
const OrderTvDetails = lazy(() => import('views/OrderTv/OrderTvDetails/OrderTvDetails'));
const OrderTvPending = lazy(() => import('views/OrderTv/OrderTvPending/OrderTvPending'));

// children routes displayed on /orderTv
const OrderTVRoutes = [
  <Route index element={<Navigate to="package" />}/>,
  <Route path={`package`} element={<OrderTvPackage/>}/>,
  <Route path={`login`} element={<OrderTvLogin/>}/>,
  <Route path={`payment`} element={<OrderTvPayment/>}/>,
  <Route path={`details`} element={<OrderTvDetails/>}/>,
  <Route path={`pending`} element={<OrderTvPending/>}/>,
  <Route path={`success`} element={<OrderTvSuccess/>}/>,
];

export default OrderTVRoutes;
