/* eslint-disable react/no-danger */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Container } from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import FullContainer from 'components/atoms/FullContainer/FullContainer';
import HeroSection from 'components/organisms/HeroSection/HeroSection';
import RowSection from 'components/organisms/RowSection/RowSection';
import Accordion from 'components/organisms/Accordion/Accordion';
import { handleExternal } from 'utils/urls';
import { useCurrentLanguage } from 'utils/customHooks';
import faqServices from 'services/orderTv';
import Modal from 'components/organisms/Modal/Modal';
import ReactPlayer from 'react-player';
import ScrollableAnchor from "react-scrollable-anchor";
import { CheckMore } from 'components/molecules/CheckMore/CheckMore';
import { useNavigate } from 'react-router';
import { calcTimeDelta } from 'react-countdown';
import FeatureSectionTv from 'components/organisms/FeatureSectionTv/FeatureSectionTv';
import OnlineTvHostingAnimation from 'components/atoms/OnlineTvHostingAnimation/OnlineTvHostingAnimation';

const TV = () => {
  const content = useSelector(state => state.language.tv);
  const lng = useCurrentLanguage();
  const [modalActive, setModalActive] = useState(false);
  const [categories, setCategories] = useState(null);
  const navigate = useNavigate()

  const showVideo = () => setModalActive(true);

  const heroData = useMemo(
    () => ({
      id: 'hero.id',
      heading: (
        <span dangerouslySetInnerHTML={{ __html: 'Create Your Own Online TV Channel' }} />
      ),
      image: 'https://img.fastcast4u.com/react/tv/4.png',
      video: 'https://img.fastcast4u.com/react/tv/tv.mp4',
      isNoStrapi: true,
      buttons: [{
          label: 'Start now',
          onClick: () => handleExternal(navigate, '/orderTv/package', lng),
          color: 'secondary',
        },
        {
          label: 'Watch video',
          onClick: showVideo,
          color: 'primary',
      }]
    }),
    // eslint-disable-next-line
    [content, lng],
  );

  const sections = useMemo(
    () => [
      {
        // img: 'https://img.fastcast4u.com/react/tv/2',
        // noWebp: true,
        element: <OnlineTvHostingAnimation isForSection={true}/>,
        heading: content.rowSections[0].heading,
        content: content.rowSections[0].content,
        button: {
          label: content.rowSections[0].button,
          onClick: () => handleExternal(navigate, '/orderTv/package', lng),
        },
        showVideoButton: {
          label: 'watch video',
          onClick: showVideo
        }
      },
      {
        img: 'https://img.fastcast4u.com/react/tv/1',
        noWebp: true,
        heading: content.rowSections[1].heading,
        content: content.rowSections[1].content,
        injectHTML: true,
        contentReverse: true,
        button: {
          label: content.rowSections[1].button,
          onClick: () => handleExternal(navigate, '/orderTv/package', lng),
        },
      },
      {
        img: '',
        videoPlayer: {
          src: "https://tv.fastcast4u.com/server-stream-video-widget/app",
          width: 16,
          height: 9,
        },
        heading: content.rowSections[2].heading,
        content: content.rowSections[2].content,
        style: {height: "calc(100% - 50px)",},
        button: {
          label: content.rowSections[2].button,
          // onClick: () => handleExternal(navigate, '/orderTv/package', lng),
          href: "https://tv.fastcast4u.com/server-stream-video-widget/app",
        },
      },
      {
        img: '',
        videoPlayer: {
          src: "https://tv.fastcast4u.com/embed-playlist/app/PlaylistDemo",
          width: 16,
          height: 9,

          props: {
            style: {
              padding: "0 60px"
            }
          }
        },
        heading: content.rowSections[3].heading,
        content: content.rowSections[3].content,
        style: {height: "calc(100% - 50px)",},
        button: {
          label: content.rowSections[3].button,
          // onClick: () => handleExternal(navigate, '/orderTv/package', lng),
          href: "https://tv.fastcast4u.com/embed-playlist/app/PlaylistDemo"
        },
      },
      {
        img: 'https://img.fastcast4u.com/react/tv/3',
        noWebp: true,
        heading: content.rowSections[4].heading,
        content: content.rowSections[4].content,
        button: {
          label: content.rowSections[4].button,
          onClick: () => handleExternal(navigate, '/orderTv/package', lng),
        },
      },
      {
        heading: content.rowSections[5].heading,
        content: content.rowSections[5].content,
        button: {
          label: content.rowSections[5].button,
          onClick: () => handleExternal(navigate, '/contact?chat', lng),
        },
      },
    ],
    [content, lng],
  );
  

  useEffect(() => {
    faqServices.getCategories().then(({ articles }) => {
      setCategories(...articles);
    });
  }, []);
  
  return (
    <>
      <HeroSection left={false} data={heroData} />
      <Modal active={modalActive} setActive={setModalActive}>
        {modalActive && <ReactPlayer playing={true} width='90vw' height='90vh' url={'https://img.fastcast4u.com/react/tv/promo.mp4'} controls/>}
      </Modal>
      <CheckMore />
      
      <FullContainer id="more" maxWidth="xl" overflowHidden>
        {sections.map((props, i) => (
          <React.Fragment key={i}>
            <RowSection {...props} long paddingRightNone reverse={i % 2 === 0} />
            {/* {i + 1 < sections.length && <Divider variant="middle" />} */}
            <Divider variant="middle" />
          </React.Fragment>
        ))}
      </FullContainer>
      <Container maxWidth="xl">
        <Text component="h2" variant="h4" mt={4} mb={6} align="center" fontWeight={400}>
          {content.faq.title}
        </Text>
        {categories && <Accordion data={categories} />}
      </Container>
    </>
  );
};

export default TV;
