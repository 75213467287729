// @ts-nocheck
import { store } from 'redux/store';

// link generator for /radio-directory page. Used to search radio directories on page.
// when user searches directory by title, title applies it as url prop (/radio-directory/:title/:page)
// when the page is changed is applyed as url prop the same way
// when the directoryList is sorted 
const generateLink = ({ page, sort, title, lng = '' }) => {
  const state = store.getState().directory;

  return `${lng}/radio-directory${
    title || state.title ? `/search/${title || state.title}` : ''
  }/${page || state.page}${
    sort === '?sort=popular' || (!sort && state.sort === '?sort=popular') ? '' : `?sort=${sort || state.sort}`
  }`;
};

export default generateLink;
