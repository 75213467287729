/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cmsActions from 'redux/actions/cmsActions';
import generalServices from 'services/general';
import cmsServices from 'services/cms';
import { Box, makeStyles } from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import { isNowBetween } from 'utils/date';

const useStyles = makeStyles({
  text: {
    textAlign: 'center',
    color: 'white',
  },
});

const ItemsLeftTopBar = () => {
  const [items, setItems] = useState('...');
  const currency = useSelector(state => state.general.currency);
  const data = useSelector(state => state.cms.topBar);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    cmsServices.getTopBarData().then(res => {
      dispatch(cmsActions.setTopBarData(res));
      res.bar.promocode &&
        generalServices.getItemsLeft(res.bar.promocode).then(res => setItems(res.uses));
    });
  }, [dispatch]);

  return (
    isNowBetween(data.from, data.to) && (
      <Box py={0.5} bgcolor="primary.main">
        <Text className={classes.text} variant="h6">
          {data.bar.content.replace('{items}', items).replace('{currency}', currency)}
        </Text>
      </Box>
    )
  );
};

export default ItemsLeftTopBar;
