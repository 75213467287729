import { Checkbox, Avatar, ListItemAvatar, ListItemText, makeStyles, Box, Button } from '@material-ui/core';
import { List, ListItem, ListItemButton } from '@mui/material';
import React from 'react'
import { TvTransChannelsListElement } from '../TvTransChannelsListElement/TvTransChannelsListElement';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  showMoreSection: {
    display: "flex",
    alignItems: "center",
    "&::before": {
      height: "1px",
      background: "black",
      flex: 1,
      content: '""',
      opacity: 0.3
    },
    "&::after": {
      height: "1px",
      background: "black",
      flex: 1,
      content: '""',
      opacity: 0.3
    },
    [theme.breakpoints.down('lg')]: {
      display: "none"
    }
  }
}))

export const TvTransChannelsList = () => {
  const classes = useStyles();
  const list = [0, 1, 2, 3, 2, 3, 0, 1, 2, 3, 2, 3];
  const [isShowUp, setShowUp] = useState(false);

  return (
    <List dense sx={{ width: '100%', maxWidth: 360 }}>
      {(!isShowUp ? list.slice(0, 7) : list).map((value) => {
        return (
          <TvTransChannelsListElement value={value}/>
        );
      })}

      {!isShowUp &&
        <Box className={classes.showMoreSection}>
            <Button
              onClick={() => {
                setShowUp(true)
              }}
              size="small"
            >
              Show more
            </Button>
        </Box>
      }
    </List>
  )
}
