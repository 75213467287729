import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import PageLoader from 'components/molecules/PageLoader/PageLoader';
import lng from 'utils/languageRoute';

// const Dashboard = lazy(() => import('views/Account/Dashboard'));
// const Payments = lazy(() => import('views/Account/Payments'));
// const Payment = lazy(() => import('views/Account/Payment'));
// const AccountDetails = lazy(() => import('views/Account/AccountDetails'));
// const ContactDetails = lazy(() => import('views/Account/ContactDetails'));
// const AddContact = lazy(() => import('views/Account/AddContact'));
// const ResetPassword = lazy(() => import('views/Account/ResetPassword'));
// const SupportTickets = lazy(() => import('views/Account/SupportTickets'));
// const OpenTicket = lazy(() => import('views/Account/OpenTicket'));
// const Emails = lazy(() => import('views/Account/Emails'));
// const Email = lazy(() => import('views/Account/Email'));
// const DashboardDetails = lazy(() => import('views/Account/DashboardDetails'));
// const NotFound = lazy(() => import('views/NotFound/NotFound'));

import Dashboard from 'views/Account/Dashboard';
import Payments from 'views/Account/Payments';
import Payment from 'views/Account/Payment';
import AccountDetails from 'views/Account/AccountDetails';
import ContactDetails from 'views/Account/ContactDetails';
import AddContact from 'views/Account/AddContact';
import ResetPassword from 'views/Account/ResetPassword';
import SupportTickets from 'views/Account/SupportTickets';
import OpenTicket from 'views/Account/OpenTicket';
import Emails from 'views/Account/Emails';
import Email from 'views/Account/Email';
import DashboardDetails from 'views/Account/DashboardDetails';
 
//chilren routes displayed on /account
const AccountRoutes = [
  <Route index element={<Dashboard/>} />,
  <Route path={`payments`} element={<Payments/>} />,
  <Route path={`payments/:id`} element={<Payment/>} />,
  <Route path={`details`} element={<AccountDetails/>} />,
  <Route path={`contactdetails`} element={<ContactDetails/>} />,
  <Route path={`addcontact`} element={<AddContact/>} />,
  <Route path={`changepassword`} element={<ResetPassword/>} />,
  <Route path={`supporttickets`} element={<SupportTickets/>} />,
  <Route path={`openticket`} element={<OpenTicket/>} />,
  <Route path={`emails/:id`} element={<Email/>} />,
  <Route path={`emails`} element={<Emails/>} />,
  //displayed once one product is active
  <Route path={`:id`} element={<DashboardDetails/>} />,
];

export default AccountRoutes;
