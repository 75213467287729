import React from 'react';
import { makeStyles, Grid, Chip, Box, Button, Divider, TextField, FormControlLabel, Checkbox, withStyles } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Text from 'components/atoms/Text/Text';
import { green } from '@material-ui/core/colors';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
  `}
`;

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20
    },
    Title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 20px 20px 20px',
    },
    ContentWrapper: {
        width: '100%',
        padding: '0 10px 10px 10px',
        '&>div': {
            '&>div': {
                display: 'flex',
                padding: 10,
                [theme.breakpoints.down(768)]: {
                    minWidth: '100%',
                },
                '&>div': {
                    width: '100%',
                },
                '&> *': {
                    alignSelf: 'center',
                }
            }
        }
    },
    Checkboxes: {
        display: 'flex',
        alignItems: 'center',
        '&>div': {
            [theme.breakpoints.down(768)]: {
                minWidth: '100%',
            },
        }
    },
}));

// page to update contact detail such as phone number
const ContactDetails = () => {
    const classes = useStyles();

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item className={classes.Title}>
                        <Text component='h4' variant='h5'>Contact  Details</Text>
                    </Grid>
                    <Grid item className={classes.ContentWrapper}>
                        <Grid container>
                            <Grid item xs={5}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Phone Number"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Button variant='contained' color='primary'>Update Number</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container className={classes.Checkboxes}>
                                    <Grid item xs={5}>
                                        <Text component='p' fontSize={18}>SMS Notifications and Phone Contact</Text>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={true} onChange={() => { }} name="checkedG" />}
                                            label="Enabled"
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Text component='p' fontSize={18}>Receive Email Updates and Offers</Text>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={true} onChange={() => { }} name="checkedG" />}
                                            label="Enabled"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default ContactDetails;