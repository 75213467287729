import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MicrophoneIcon = props => (
  <SvgIcon {...props}>
    <path d="M 19.902344 9.6875 L 19.4375 9.6875 L 19.4375 7.5 C 19.4375 3.453125 16.226562 0.0742188 12.210938 0 C 12.101562 0 11.882812 0 11.773438 0 C 7.761719 0.0742188 4.550781 3.453125 4.550781 7.5 L 4.550781 9.6875 L 4.082031 9.6875 C 3.363281 9.6875 2.773438 10.429688 2.773438 11.347656 L 2.773438 22.320312 C 2.773438 23.238281 3.363281 23.988281 4.082031 23.988281 L 19.902344 23.988281 C 20.625 23.988281 21.210938 23.238281 21.210938 22.320312 L 21.210938 11.347656 C 21.210938 10.429688 20.625 9.6875 19.902344 9.6875 Z M 13.476562 16.824219 L 13.476562 20.136719 C 13.476562 20.519531 13.15625 20.839844 12.777344 20.839844 L 11.210938 20.839844 C 10.828125 20.839844 10.511719 20.519531 10.511719 20.136719 L 10.511719 16.824219 C 10.144531 16.460938 9.929688 15.957031 9.929688 15.402344 C 9.929688 14.351562 10.742188 13.449219 11.773438 13.40625 C 11.882812 13.402344 12.101562 13.402344 12.210938 13.40625 C 13.246094 13.449219 14.058594 14.351562 14.058594 15.402344 C 14.058594 15.957031 13.84375 16.460938 13.476562 16.824219 Z M 16.355469 9.6875 L 7.632812 9.6875 L 7.632812 7.5 C 7.632812 5.089844 9.589844 3.097656 11.992188 3.097656 C 14.398438 3.097656 16.355469 5.089844 16.355469 7.5 Z M 16.355469 9.6875 " />
  </SvgIcon>
);

export default MicrophoneIcon;
