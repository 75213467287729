export const SET_PARAMS = 'DIRECTORY/SET_PARAMS';

export const SET_LOADING = 'DIRECTORY/SET_LOADING';

export const SET_PAGES = 'DIRECTORY/SET_PAGES';

export const SET_STATIONS = 'DIRECTORY/SET_STATIONS';

export const SET_STATIONS_PLACEHOLDER = 'DIRECTORY/SET_STATIONS_PLACEHOLDER';

export const SET_PLAYER_DATA = 'DIRECTORY/SET_PLAYER_DATA';

export const SET_SONG_METADATA = 'DIRECTORY/SET_SONG_METADATA';

export const SET_SOURCE = 'DIRECTORY/SET_SOURCE';
