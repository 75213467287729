import { Avatar, Box, ButtonBase, Chip, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import ReactHlsPlayer from 'react-hls-player/dist';
import LiveIcon from '@mui/icons-material/PodcastsRounded';
import { useNavigate } from 'react-router';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';

// @ts-ignore
const useStyles = makeStyles(theme => ({
    "@keyframes outlineOnHover": {
        "0%": {
            transform: "translate(-50%, -50%)",
            top: 0,
            left: 0,
            opacity: 1,
        },

        "25%": {
            transform: "translate(-50%, -50%)",
            top: "100%",
            left: 0,
        },

        "50%": {
            transform: "translate(-50%, -50%)",
            top: "100%",
            left: "100%",
        },

        "75%": {
            transform: "translate(-50%, -50%)",
            top: 0,
            left: "100%",
        },

        "80%": {
            opacity: 1,
        },

        "100%": {
            transform: "translate(-50%, -50%)",
            top: 0,
            left: 0,
            opacity: 0,
        },
    },

    videoChip: {
        marginLeft: 2,
    },

    videoPlayerBorder: ({
        // position: "absolute",
        position: "relative",
        borderRadius: "5px",
        padding: "3px",
        boxShadow: `0 0 5px ${theme.palette.grey[1000]}`,
        overflow: "hidden",
        transition: "0.3s ease",
        cursor: "pointer",
        boxSizing: "content-box",
        // boxSizing: "border-box",
        width: "100%",
        maxWidth: "350px",

        "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            width: "170px",
            height: "170px",
            background: `radial-gradient(${theme.palette.primary.main}, ${theme.palette.primary.light})`,
            transform: "translateX(-50%)",
            zIndex: "1",
            opacity: 0,
        },

        "&:hover": {
            "& $videoChip": {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
            "&::before": {
                animation: "1s $outlineOnHover linear"
            },
            padding: "3px",
            background: theme.palette.grey[800],
            boxShadow: `7px 7px 1px 0 ${theme.palette.primary.dark}`,
            transform: "scale(1.05) translate(-7px, -7px)",
            zIndex: "10",
        }
    }),
    videoPlayerBorderModeration: ({index}) => ({
        "&:hover": {
            transformOrigin: (index % 5) === 4 ? "right" : !(index % 5) ? "left" : "center",
            transform: "scale(2.8)",
            boxShadow: `none`,
            "& .name, & .title": {
                fontSize: "9px",
            },
            "& .chip": {
                fontSize: "7px !important",
                height: "12px !important",

                "& > *": {
                    padding: "3px"
                } 
            },
            "& .videoAuthor": {
                padding: "2px",
            },
            "& .avatar": {
                height: "15px",
                width: "15px",
            }
        },

    }),
    videoPlayer: {
        borderRadius: "4px",
        borderRadius: "4px",
        overflow: "hidden",
        zIndex: "2",
        position: "relative",
        boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.5)"
    },
    videoContent: {
        display: "flex",
        flexDirection: "column",
        padding: "5px 10px",
        background: theme.palette.background.paper,
        border: `solid 3px transparent`,
        borderTop: "none",
        zIndex: "4",
    },
    videoLiveChip: {
        position: "absolute",
        top: "5px",
        left: "5px",
        background: theme.palette.error.main,
        color: theme.palette.primary.contrastText,
        zIndex: 3
    },
    videoContainer: {
        width: "100%",
        paddingBottom: "50%",
        position: "relative",
        zIndex: "2",
        background: theme.palette.background.default,
    },
    videoTitle: {
        fontSize: "15px",
        marginBottom: "3px",
    },
    videoAuthorAvatar: {
        height: "23px",
        width: "23px",
        marginRight: "5px",
        background: theme.palette.primary.main
    },
    videoAuthorName: {
        fontSize: "14px"
    },
    videoAuthor: {
        display: "flex",
        alignItems: "center",
        borderRadius: "6px",
        padding: "6px",
        marginBottom: "7px",
        background: theme.palette.background.default,
        width: "max-content",
    },
    unmuteBlock: ({canBePlayedWithSound}) => ({
        display: "flex",
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        position: "absolute",
        background: "rgba(0,0,0,0.7)",
        pointerEvents: canBePlayedWithSound ? "none" : "",
        opacity: 0,

        "&:hover": {
            opacity: canBePlayedWithSound ? 0 : 1,
        }
    }),
    unmuteBlockContainer: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    unmuteBlockIcon: {
        width: "50px",
        height: "50px",
    },
    unmuteBlock: ({canBePlayedWithSound}) => ({
        display: "flex",
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        position: "absolute",
        background: "rgba(0,0,0,0.7)",
        pointerEvents: canBePlayedWithSound ? "none" : "",
        opacity: 0,

        "&:hover": {
            opacity: canBePlayedWithSound ? 0 : 1,
        }
    }),
    unmuteBlockContainer: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    unmuteBlockIcon: {
        width: "50px",
        height: "50px",
    }
}));

export const StreamItem = ({order, index, src, title, previewSrc, description, chips, author, canBePlayedWithSound, setCanBePlayedWithSound, isForModeration}) => {
    const playerRef = useRef(null);
    const ref = useRef(null);
    const [isHovered, setHovered] = useState(false);
    const [isVideoReady, setVideoReady] = useState(true);
    const [isVisibleOnScreen, setVisibleOnScreen] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles({order, canBePlayedWithSound, index});

    useEffect(() => {
        if(isMobile) {
            const observerCallback = (entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setVisibleOnScreen(true);
                    playerRef.current?.load();
                    setVisibleOnScreen(true);
                    playerRef.current?.load();
                    playerRef.current?.play();
                } else {
                    setVisibleOnScreen(false)
                    playerRef.current?.pause();
                }
              });
            };
        
            const observerOptions = {
              root: null,
              rootMargin: '0px',
              threshold: 0.5,
            };
        
            const observer = new IntersectionObserver(observerCallback, observerOptions);
    
            if (ref.current) {
              observer.observe(ref.current);
            }
        
            return () => {
              if (ref.current) {
                observer.unobserve(ref.current);
              }
            };
        }
    }, [playerRef, isMobile]);

    return (
        <div
            style={{
                width: "100%",
                height: "max-content",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                marginRight: "25px",
            }}
            ref={ref}
        >
            <div
                onMouseEnter={() => {
                    setHovered(true);
                    if(!isForModeration) {
                        playerRef.current?.load();
                        playerRef.current?.play();
                    }
                }}
                onMouseLeave={() => {
                    setHovered(false)
                    if(!isForModeration) {
                        setVideoReady(false)
                    }
                }}
                className={[classes.videoPlayerBorder, isForModeration && classes.videoPlayerBorderModeration].join(" ")}
                className={[classes.videoPlayerBorder, isForModeration && classes.videoPlayerBorderModeration].join(" ")}
            >
                <div
                    className={classes.videoPlayer}
                >
                    <div className={classes.videoContainer}>
                        {(isHovered || isVisibleOnScreen || isForModeration) &&
                            <ReactHlsPlayer
                                src={src}
                                autoPlay={isForModeration}
                                muted={!isForModeration || !canBePlayedWithSound || (isForModeration && !isHovered)}
                                controls={false}
                                playerRef={playerRef}
                                width="100%"
                                height="100%"
                                preload={"none"}
                                style={{
                                    position: "absolute",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                    background: "black",
                                    display: isVideoReady ? "block" : "none"
                                }}
                                onCanPlay={() => {
                                    setVideoReady(true)
                                }}
                                onCanPlayThrough={async () => {
                                    await playerRef.current.play();
                                }}
                            />
                        }
                        <img
                            src={previewSrc}
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                objectFit: "cover",
                                transition: "0.2s ease",
                                opacity: (isHovered || isVisibleOnScreen || isForModeration) ? "0" : "1"
                            }}
                        />
                        {isForModeration &&
                            <ButtonBase
                                className={classes.unmuteBlock}
                                onClick={() => {
                                    setCanBePlayedWithSound(true);
                                }}
                            >
                                <div className={classes.unmuteBlockContainer}>
                                    <VolumeOffRoundedIcon className={classes.unmuteBlockIcon}/>
                                    <div>
                                        click to unmute
                                    </div>
                                </div>
                            </ButtonBase>
                        }
                    </div>
                    <Chip
                        size="small"
                        label={"LIVE"}
                        icon={<LiveIcon style={{color: "#fff", width: "15px"}}/>}
                        className={classes.videoLiveChip}
                    />
                    <div className={classes.videoContent}>
                        <Typography variant="h6" className={[classes.videoTitle, "title"].join(" ")}>
                            {title}
                        </Typography>
                        {/* <Box className={[classes.videoAuthor, "videoAuthor"].join(" ")}>
                            <Avatar
                                className={[classes.videoAuthorAvatar, "avatar"].join(" ")}
                                className={[classes.videoAuthorAvatar, "avatar"].join(" ")}
                                size="small"
                                alt={author.name}
                            />
                            <Typography variant="p" className={[classes.videoAuthorName, "name"].join(" ")}>
                            <Typography variant="p" className={[classes.videoAuthorName, "name"].join(" ")}>
                                {author.name}
                            </Typography>
                        </Box> */}
{/*                         
                        <Box sx={{display: "flex", justifyContent: "end"}}>
                            {chips?.map((text) => (
                                <Chip
                                    size="small"
                                    label={text}
                                    className={[classes.videoChip, "chip"].join(" ")}
                                    className={[classes.videoChip, "chip"].join(" ")}
                                />
                            ))}
                        </Box> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
