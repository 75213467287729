import React from 'react';
import { makeStyles, Grid, Box, Button } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Text from 'components/atoms/Text/Text';
import { Link } from 'react-router-dom';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
  `}
`;

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20
    },
    Title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 20px 20px 20px',
        '& $a': {
            textDecoration: 'none'
        },
        '&>div': {
            display: 'flex',
            alignItems: 'center',
        }
    },
    Content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0 20px 20px 20px',
    },
}));

// page to view selected email
const Email = () => {
    const classes = useStyles();

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item className={classes.Title}>
                        <Link to={"/account/emails"}>
                            <Button variant='contained' color='primary'>Back</Button>
                        </Link>
                        <Box>
                            <Text component='h4' variant='h5'>Email</Text>
                        </Box>
                        <Box>
                            <Text component='p'>07:23 AM</Text>
                        </Box>
                    </Grid>
                    <Grid item className={classes.Content}>
                        <Text component='h5' variant='h6'>A collection of textile samples lay spread</Text>
                        <Text component='p'>To: Me, info@example.com</Text><br/>
                        <Text component='p' style={{whiteSpace: 'pre-line'}}>
                            Hi,Ingredia,{'\n\n'}
                            Ingredia Nutrisha, A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture{'\n\n'}
                            Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.{'\n\n'}
                            Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar.{'\n\n'}
                            Kind Regards{'\n'}
                            Mr Smith{'\n\n'}
                        </Text>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default Email;