import { RESET_ACCOUNT_STORE, SET_ACCOUNT_DATA, SET_ACTIVE_PRODUCT, SET_CURRENT_PAGE, SET_PRODUCT_GROUPS, SET_TOKEN } from 'redux/actionTypes/accountTypes';
import produce from 'immer';

const initialState = {
  //#region stab to use while /account page doesn't work properly
  products: [
    {
      id: 2,
      groupname: 'Centova Cast Radio Hosting',
      name: '',
      status: 'Terminated',
      type: 'radio',
      addons: [{
          title: 'Extras / Premium Addons',
          list: [
            'Mobile App Bundle',
            'Alexa Radio Skill',
            'Radio Promotion Package',
            'Social Streaming Package',
            'App Monetization',
            'App Push Notifications',
          ]
        },
        {
          title: 'Service Addons',
          list: [
            'WebPlayer Page',
          ]
        },
      ]
    },
    {
      id: 3,
      groupname: '100 viewers - HD',
      name: 'Live & Web TV',
      status: 'Suspended',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 4,
      groupname: '100 viewers - Full HD',
      name: 'Live & Web TV',
      status: 'Pending',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 5,
      groupname: '1000 viewers - HD',
      name: 'Live & Web TV',
      status: 'Canceled',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 6,
      groupname: '1000 viewers - Full HD',
      name: 'Live & Web TV',
      status: 'Active',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 7,
      groupname: '100 viewers - HD',
      name: 'Live & Web TV + Social Media Streaming',
      status: 'Suspended',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 8,
      groupname: '100 viewers - Full HD',
      name: 'Live & Web TV + Social Media Streaming',
      status: 'Pending',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 9,
      groupname: '1000 viewers - HD',
      name: 'Live & Web TV + Social Media Streaming',
      status: 'Canceled',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 10,
      groupname: '1000 viewers - Full HD',
      name: 'Live & Web TV + Social Media Streaming',
      status: 'Active',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 11,
      groupname: '100 viewers - HD',
      name: 'Live TV + Social Media Streaming',
      status: 'Suspended',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 12,
      groupname: '100 viewers - Full HD',
      name: 'Live TV + Social Media Streaming',
      status: 'Terminated',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 13,
      groupname: '1000 viewers - HD',
      name: 'Live TV + Social Media Streaming',
      status: 'Canceled',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 14,
      groupname: '1000 viewers - Full HD',
      name: 'Live TV + Social Media Streaming',
      status: 'Active',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 15,
      groupname: '100 viewers - HD',
      name: 'Live TV',
      status: 'Suspended',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 16,
      groupname: '100 viewers - Full HD',
      name: 'Live TV',
      status: 'Terminated',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 17,
      groupname: '1000 viewers - HD',
      name: 'Live TV',
      status: 'Canceled',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 18,
      groupname: '1000 viewers - Full HD',
      name: 'Live TV',
      status: 'Active',
      type: 'tv',
      addons: [{
        title: 'Extras / Premium Addons',
        list: [
          'Mobile App Bundle',
        ]
      }, ]
    },
    {
      id: 19,
      groupname: 'Mobile App Bundle',
      name: '',
      status: 'Active',
      type: 'mobile',
      addons: [{
        title: 'App Addons',
        list: [
          'App Monetization',
          'App Push Notifications',
        ]
      }, ]
    },
    {
      id: 20,
      groupname: 'Android App',
      name: '',
      status: 'Active',
      type: 'mobile',
      addons: [{
        title: 'App Addons',
        list: [
          'App Monetization',
          'App Push Notifications',
        ]
      }, ]
    },
    {
      id: 21,
      groupname: 'IOS App',
      name: '',
      status: 'Active',
      type: 'mobile',
      addons: [{
        title: 'App Addons',
        list: [
          'App Monetization',
          'App Push Notifications',
        ]
      }, ]
    },
    {
      id: 22,
      groupname: 'Alexa Radio Skill',
      name: '',
      status: 'Active',
      type: 'alexa',
      alexaFormState: '0'
    },
    {
      id: 23,
      groupname: 'Alexa Radio Skill',
      name: '',
      status: 'Canceled',
      type: 'alexa',
      alexaFormState: '1'
    },
    {
      id: 24,
      groupname: 'Alexa Radio Skill',
      name: '',
      status: 'Terminated',
      type: 'alexa',
      alexaFormState: '2'
    },
    {
      id: 25,
      groupname: 'Social Media Live Streaming',
      name: '',
      status: 'Active',
      type: 'mobile',
      addons: []
    },
  ],
  invoices: [{
    id: 1,
    date: `21.02.2023`,
    total: "$2",
    status: "Paid",
  }],
  //#endregion
  //product to display when any product is chosen
  activeProduct: null,
  productGroups: {},
  // data about user
  accountData: {products: []},
  token: '',
  currentPage: 1,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PRODUCT:
      return produce(state, draftState => {
        draftState.activeProduct = action.payload;
      });
    // action type to change page on /account page
    case SET_CURRENT_PAGE:
      return produce(state, draftState => {
        draftState.currentPage = action.payload;
      });
    // action type to set data about user on login or after it changes
    case SET_ACCOUNT_DATA:
      return produce(state, draftState => {
        draftState.accountData = action.payload;
      });
    // action type to set product groups. Based on them product will be rendered differentsly 
    case SET_PRODUCT_GROUPS:
      return produce(state, draftState => {
        const result = Object.entries(action.payload).map((el) => el[1].map((props) => ({...props, type: el[0]})));
        draftState.productGroups = result.reduce((prev, cur) => ([...prev, ...cur]), [])
      });
    // action type to change token after every action
    case SET_TOKEN:
      return produce(state, draftState => {
        draftState.token = action.payload;
      });
    case RESET_ACCOUNT_STORE:
      return initialState;
    default:
      return state;
  }
};

export default accountReducer;