import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const OrderSuccess = lazy(() => import('views/Order/OrderSuccess/OrderSuccess'));
const OrderPackage = lazy(() => import('views/Order/OrderPackage/OrderPackage'));
const OrderLogin = lazy(() => import('views/Order/OrderLogin/OrderLogin'));
const OrderPayment = lazy(() => import('views/Order/OrderPayment/OrderPayment'));
const OrderDetails = lazy(() => import('views/Order/OrderDetails/OrderDetails'));
const OrderPending = lazy(() => import('views/Order/OrderPending/OrderPending'));

//children routes displayed on /order
const OrderRoutes = [
  <Route index element={<Navigate to="package" />} />,
  <Route path={`package`} element={<OrderPackage />} />,
  <Route path={`login`} element={<OrderLogin />} />,
  <Route path={`payment`} element={<OrderPayment />} />,
  <Route path={`details`} element={<OrderDetails />} />,
  <Route path={`pending`} element={<OrderPending />} />,
  <Route path={`success`} element={<OrderSuccess />} />,
];

export default OrderRoutes;
