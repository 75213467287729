import React, { useMemo } from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Text from 'components/atoms/Text/Text';
import FooterLinks from 'components/molecules/FooterLinks/FooterLinks';
import CyberDealsCounterDown from 'components/organisms/CyberDealsCounterDown/CyberDealsCounterDown';
import PackageBox from 'components/molecules/PackageBox/Base';
import PricingTable from 'components/organisms/PricingTable/PricingTable';
import PricingGrid from 'templates/PricingGrid';

import christmasmp4 from "../../assets/christmas.mp4";

const useStyles = makeStyles(theme => ({
    Wrapper: {
        width: '100%',
        height: '100%',
        overflowX: "hidden",
        backgroundColor: '#010a00',
        color: 'white',
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/3px-tile.png")',
        '&>div': {
            maxWidth: '1200px',
            margin: '0 auto',
            padding: 20,
            [theme.breakpoints.down(700)]: {
                padding: 10,
            },
        }
    },
    Logo: {
        display: 'flex',
        justifyContent: 'center',
        '& $button': {
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        '& $img': {
            maxWidth: '100%',
        }
    },
    CyberDealsTitle: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 40,
        '& $p': {
            fontWeight: 700,
            textAlign: 'center',
            textShadow: '0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #2e8ece, 0 0 80px #2e8ece, 0 0 90px #2e8ece, 0 0 100px #2e8ece, 0 0 150px #2e8ece',
            fontSize: '4.5rem',
            margin: '0 20px',
            letterSpacing: '15px',
            maxWidth: '100%',
            [theme.breakpoints.down(500)]: {
                fontSize: '4rem',
            },
        }
    },
    CyberDealsDesc: {
        '& $p': {
            fontSize: '2.3rem',
            [theme.breakpoints.down(500)]: {
                fontSize: '1.7rem',
            },
        },
    },
    BlinkingText: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 60,
        '& $p': {
            fontWeight: 700,
            textAlign: 'center',
            fontSize: '3.3rem',
            margin: '0 5px',
            maxWidth: '100%',
            [theme.breakpoints.down(500)]: {
                fontSize: '2.7rem',
            },
        }
    },
    Blinking1: {
        animation: '$blinkingText1 2400ms infinite'
    },
    Blinking2: {
        animation: '$blinkingText2 2400ms infinite'
    },
    "@keyframes blinkingText1": {
        '0%': { color: 'red' },
        '15%': { color: 'red' },
        '35%': { color: 'white' },
        '50%': { color: 'white' },
        '65%': { color: 'white' },
        '85%': { color: 'red' },
        '100%': { color: 'red' },
    },
    "@keyframes blinkingText2": {
        '0%': { color: 'white' },
        '15%': { color: 'white' },
        '35%': { color: 'red' },
        '50%': { color: 'red' },
        '65%': { color: 'red' },
        '85%': { color: 'white' },
        '100%': { color: 'white' },
    },
    Timer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 30
    },
    Content: {
        justifyContent: 'space-evenly',
        marginBottom: 20
    },
    Card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 15,
        backgroundColor: 'rgba(255,255,255,.1)',
        borderColor: 'rgba(255,255,255,.08)',
        margin: '20px 10px',
        borderRadius: 4,
        [theme.breakpoints.down(768)]: {
            width: '70%',
            margin: '10px',
        },
        [theme.breakpoints.down(530)]: {
            width: '100%',
        },
        '& $img': {
            maxWidth: '100%'
        },
        '&>*': {
            display: 'flex',
            justifyContent: 'center',
            margin: '15px 5px',
        },
    },
    Footer: {
        textAlign: 'center',
        '& $a': {
            textDecoration: 'none',
            color: 'rgb(46, 142, 206)'
        },
    },
    Regulations: {
        marginBottom: 30
    },
    CopyRights: {
        marginBottom: 20,
        '&>div': {
            color: 'rgba(255,255,255,.25)',
        }
    },
    Payment: {
        display: 'flex',
        justifyContent: 'center',
        color: 'rgba(255,255,255,.25)',
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '&>div': {
                display: 'flex',
                alignItems: 'center',
                '& $img:first-child': {
                    height: 16
                },
                '& $img:last-child': {
                    height: 14
                },
            },
            '& $p': {
                fontSize: 14,
            },
        },
    }
}));

const cards = [
    {
        img: 'https://img.fastcast4u.com/promo/cm2020/7.png',
        link: 'https://fastcast4u.com/account/clientarea.php?action=addfunds&amount=100'
    },
    {
        img: 'https://img.fastcast4u.com/promo/cm2020/6.png',
        link: 'https://fastcast4u.com/account/clientarea.php?action=addfunds&amount=150'
    },
    {
        img: 'https://img.fastcast4u.com/promo/cm2020/4.png',
        link: 'https://fastcast4u.com/account/clientarea.php?action=addfunds&amount=200'
    },
    {
        img: 'https://img.fastcast4u.com/promo/cm2020/3.png',
        link: 'https://fastcast4u.com/account/clientarea.php?action=addfunds&amount=250'
    },
    {
        img: 'https://img.fastcast4u.com/promo/cm2020/2.png',
        link: 'https://fastcast4u.com/account/clientarea.php?action=addfunds'
    },
];

const pricingData = [
      {
        title: "SMALL GIFT BOX",
        link: `https://account.fastcast4u.com/link.php?id=1231`,
        regular: 200,
        current: 99,
        gift: true,
      },
      {
        title: "MEDIUM GIFT BOX",
        link: `https://account.fastcast4u.com/link.php?id=1232`,
        regular: 400,
        current: 199,
        gift: true,
      },
      {
        title: "BIG GIFT BOX",
        link: `https://account.fastcast4u.com/link.php?id=1233`,
        regular: 800,
        current: 399,
        gift: true,
      },
    ]

// promotion page for cyberdeals
const BoxPage = () => {
    const classes = useStyles();

    return (
        <Box container className={classes.Wrapper}>
            <Grid container>
                <Grid  style={{position: "relative"}} xs={12} item>
                    <video style={{position: "absolute", left: "calc((100% - 100vw)/2)", width: "100vw", height: "100%", objectFit: "cover"}} autoPlay muted loop id="myVideo">
                        <source src={christmasmp4} type="video/mp4"/>
                    </video>
                    <Grid style={{zIndex: 10, position: "relative"}} container>
                        <Grid xs={12} item className={classes.CyberDealsTitle}>
                            <p>GIFT BOX PROMO</p>
                        </Grid>
                        <Grid xs={12} item className={classes.BlinkingText}>
                            <p className={classes.Blinking1}>Hurry Up!</p>
                            <p className={classes.Blinking2}>This promotion won't last long!</p>
                        </Grid>
                        <Grid xs={12} item className={classes.Timer}>
                            {/* Warsaw time is UTC - 2 hours */}
                            <CyberDealsCounterDown date={Date.UTC(2023, 11, 31, 22, 0, 0)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} item>
                    <PricingGrid id="pricing">
                        {pricingData.map((props, i) => (
                            <PricingTable emphasisOnSave={true} discountChipText={"TOTAL ITEMS VALUE "} withoutNowInPrice key={i} {...props} />
                        ))}
                    </PricingGrid>
                </Grid>
                <Grid xs={12} item>
                    <Grid container className={classes.Footer}>
                        <Grid xs={12} item className={classes.Regulations}>
                            <Text component='p'>By adding funds you agree with <Link to='/help/507'>the Promotion Regulations</Link></Text>
                        </Grid>
                        <Grid xs={12} item className={classes.CopyRights}>
                            <FooterLinks />
                        </Grid>
                        <Grid xs={12} item className={classes.Payment}>
                            <Box>
                                <Box>
                                    <img src="https://fastcast4u.com/images/paypal.png" alt="Paypal Logo" />&nbsp;|&nbsp;
                                    <img src="https://fastcast4u.com/images/paymentwall.png" alt="Paymentwall Logo" />
                                </Box>
                                <Text component='p'>&nbsp; and 120+ more trusted payment methods</Text>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BoxPage;