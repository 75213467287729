// @ts-nocheck
import { SET_AUTH_MODE, SET_AUTH_OPENED, SET_CHAT_OPENED } from 'redux/actionTypes/tvtrans';
import produce from 'immer';

const initialState = {
  auth: {
    mode: "login",
    isOpened: false,
  },
  stream: {
    isChatOpened: true,
  }
};

const tvtransReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_OPENED:
      return produce(state, draftState => ({
        ...draftState,
        auth: {
          ...draftState.auth,
          isOpened: action.payload,
        }
      }));
    case SET_AUTH_MODE:
      return produce(state, draftState => ({
        ...draftState,
        auth: {
          ...draftState.auth,
          mode: action.payload,
        }
      }));
    case SET_CHAT_OPENED:
      return produce(state, draftState => ({
        ...draftState,
        stream: {
          ...draftState.stream,
          isChatOpened: action.payload,
        }
      }));
    default:
      return state;
  }
};

export default tvtransReducer;
