import { Box, Button, Dialog, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { StreamItem } from '../StreamItem/StreamItem';
import { TvTransStreamsSlider } from '../TvTransStreamsSlider/TvTransStreamsSlider'
import ShowMoreIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { TvTransSectionsList } from '../TvTransSectionsList/TvTransSectionsList';
import { TvTransCategoriesList } from '../TvTransCategoriesList/TvTransCategoriesList';
import { TvTransLeftPanel } from '../TvTransLeftPanel/TvTransLeftPanel';
import { useParams } from 'react-router';
import { TvTransCategoryHero } from '../TvTransCategoryHero/TvTransCategoryHero';
import { TvTransGenreSection } from 'components/molecules/TvTransGenreSection/TvTransGenreSection';

const useStyles = makeStyles(theme => ({
    mainContent: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
    
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            background: theme.palette.background.paper
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.grey[500],
            borderRadius: "10px",
        },
    },
}))

export default function TvTransContentCategory({content}) {
    const classes = useStyles();
    const {category, directory} = useParams();

    return (
        <>
            <TvTransLeftPanel/>
            <Box className={classes.content}>
                <Box className={classes.mainContent}>
                    <TvTransCategoryHero
                        selectedCategory={content.categoriesList.find(a => a.slug === category)}
                        selectedDirectory={content.directories.find(a => a.slug === directory)}
                    />
                    {content.directory.map(({title, content}, i) => (
                        <TvTransGenreSection i={i} title={title} content={content} />
                    ))}
                </Box>
            </Box>
        </>
    )
}
