import { Box, ButtonBase, Icon, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { useNavigate } from 'react-router';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

// @ts-ignore
const useStyles = makeStyles(theme => ({
    section: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gap: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "30px 0",

        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: "1fr 1fr",
        },

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "1fr",
        }
    },
    sectionContainer: ({order, hero}) => ({
        order: order,
        padding: hero ? "40px 30px" : "0 30px",
        margin: hero ? "0px 0px 20px 0px" : "10px",
        position: "relative",
        height: "max-content",

        "&::after": !hero && {
            content: '""',
            height: "1px",
            background: "black",
            display: "flex",
            width: "100%",
            opacity: 0.2,
        },

        background: hero && `linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
        color: hero && "#fff",
    }),
    categoryBlock: {
        width: "100%",
        height: "70px",
        padding: "10px 15px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        transition: "0.2s ease",
        position: "relative",
        zIndex: 2,

        // border: isSelected && "solid 10px #fff",

        "&:hover": {
            transform: "scale(1.05) translate(-2.5px, -2.5px)",
        },

        "&:hover $categoryBorder::before": {
            opacity: 1,
        },

        "&:hover $categoryBorder": {
            boxShadow: "5px 5px 5px rgba(0,0,0,0.3)",
        },

        "&:hover $categoryBackground::before": {
            opacity: 1,
        },

        "&:hover $categoryIcon": {
            transform: "scale(1.2) rotateZ(-8deg) translate(0%, -7%)",
            filter: "drop-shadow(7px 5px 1px rgba(0,0,0,0.3))"
        },

        "&:hover $categoryTitle": {
            transform: "scale(1.2) translate(6px, -7%)",
            filter: "drop-shadow(7px 5px 1px rgba(0,0,0,0.3))"
        }
    },
    categoryBlockSelected: {
        "& $categoryBackground": {
            backgroundColor: theme.palette.primary.dark,
            boxShadow: "inset 0 0 2px black",
        },
        "& $categoryIcon": {
            transform: "scale(1.2)",
            filter: "none",
        },
        "& $categoryTitle": {
            filter: "none",
        },
        "& $categoryBorder": {
            boxShadow: "none",
        },

        "&:hover": {
            transform: "none",
        },

        "&:hover $categoryBorder::before": {
            opacity: 0,
        },

        "&:hover $categoryBorder": {
            boxShadow: "none",
        },

        "&:hover $categoryBackground::before": {
            opacity: 0,
        },

        "&:hover $categoryIcon": {
            transform: "scale(1.2)",
            filter: "none"
        },

        "&:hover $categoryTitle": {
            transform: "none",
            filter: "none"
        }
    },
    categoryBorder: {
        top: "0",
        left: "0",
        // background: theme.palette.primary.dark,
        background: theme.palette.primary.dark,
        padding: "1px",
        borderRadius: "20px",
        boxShadow: "0 0 4px rgba(0,0,0,0.6)",
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: "-1",
        overflow: "hidden",
        transition: "0.2s ease",
        "&::before": {
            content: '""',
            position: "absolute",
            width: "200px",
            height: "300px",
            opacity: 0,
            top: "var(--mouse-move-y)",
            left: "var(--mouse-move-x)",
            transform: "translate(-50%, -50%)",
            background: "radial-gradient(rgb(255,255,255), rgba(255,255,255, 0.2), transparent)",
        },
    },
    categoryBackground: {
        // background: theme.palette.primary.dark,
        borderRadius: "19px",
        zIndex: 3,
        background: theme.palette.primary.main,
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",

        "&::before": {
            content: '""',
            position: "absolute",
            width: "300px",
            height: "300px",
            opacity: 0,
            top: "var(--mouse-move-y)",
            left: "var(--mouse-move-x)",
            transform: "translate(-50%, -50%)",
            background: "radial-gradient(rgba(255,255,255,0.2), rgba(255,255,255,0.02), transparent)",
        },
    },
    categoryIcon: {
        display: "flex",
        width: "100px",
        height: "100px",
        fontSize: "40px",
        color: "#fff",
        transition: "0.3s ease",
        filter: "drop-shadow(2px 2px 4px rgba(0,0,0,0.4))"
    },
    categoryTitle: {
        color: "#fff",
        fontSize: "20px",
        fontWeight: "bold",
        marginLeft: "10px",
        transition: "0.3s ease",
        filter: "drop-shadow(2px 2px 4px rgba(0,0,0,0.4))"
    },
    title: {
        width :"100%",
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    heroTitle: {
        width :"max-content",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    "@keyframes levitate": {
        "0%": {
            transform: "translateY(0%)"
        },
        "20%": {
            transform: "translateY(5%)"
        },
        "80%": {
            transform: "translateY(-5%)"
        },
        "100%": {
            transform: "translateY(0%)"
        }
    },

    bgIconContainer: {
        animation: `$levitate 7000ms linear infinite`,
        position: "absolute",
    },
    bgIconContainer1: {
        bottom: "0",
        right: "0",
        animationDuration: "9s",
    },
    bgIconContainer2: {
        bottom: "50%",
        right: "50%",
        animationDuration: "6s",
    },
    bgIconContainer3: {
        bottom: "5%",
        left: 0,
        animationDuration: "8s",
    },
    bgIconBrowseContainer1: {
        left: "50%",
        top: "5%",
        animationDuration: "9s",
    },
    bgIconBrowseContainer2: {
        top: "5%",
        right: "50%",
        animationDuration: "6s",
    },
    bgIconBrowseContainer3: {
        left: "50%",
        top: "5%",
        animationDuration: "8s",
    },
    bgIcon1: {
        display: "flex !important",
        width: "400px !important",
        height: "400px !important",
        opacity: "0.25",
        transform: "translate(20%, 20%) rotateZ(-30deg)",
    },
    bgIcon2: {
        display: "flex !important",
        width: "300px !important",
        height: "300px !important",
        opacity: "0.1",
        transform: "translate(20%, 20%) rotateZ(40deg)"
    },
    bgIcon3: {
        display: "flex !important",
        width: "350px !important",
        height: "350px !important",
        opacity: "0.15",
        transform: "translate(-30%, 20%) rotateZ(10deg)"
    },
    bgIconBrowse1: {
        display: "flex !important",
        width: "400px !important",
        height: "400px !important",
        opacity: "0.25",
        transform: "translateX(-50%) rotateZ(45deg)",
    },
    bgIconBrowse2: {
        display: "flex !important",
        width: "350px !important",
        height: "350px !important",
        opacity: "0.1",
        transform: "translateX(-10%) rotate(10deg)"
    },
    bgIconBrowse3: {
        display: "flex !important",
        width: "350px !important",
        height: "350px !important",
        opacity: "0.15",
        transform: "translateX(10%) rotateZ(80deg)"
    },
    bgIconsContainer: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "absolute",
        left: 0,
        top: 0,
    }
}))

const CategoryBlock = ({title, Icon, slug, selectedDirectorySlug}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    console.log("slug", slug, selectedDirectorySlug)

    return (
        <div
            className={[classes.categoryBlock, selectedDirectorySlug === slug && classes.categoryBlockSelected ].join(" ")}
            onMouseMove={event => {
                const rect = event.currentTarget.getBoundingClientRect();
                const left = event.clientX - rect.left;
                const top = event.clientY - rect.top;
                event.currentTarget.style.setProperty("--mouse-move-x", `${left}px`)
                event.currentTarget.style.setProperty("--mouse-move-y", `${top}px`)
            }}
            onClick={() => {
                navigate(`/tvtrans/directory/${slug}`)
            }}
        >
            <Box className={classes.categoryBorder}>
                <Box className={classes.categoryBackground}/>
            </Box>
            <Icon className={classes.categoryIcon} sx={{height: "100%", width: "auto", transition: "0.2s ease",}}/>
            <Typography variant="h5" className={classes.categoryTitle}>
                {title}
            </Typography>
        </div>
    )
}

export const TvTransSectionsList = ({order, hero, selectedDirectorySlug, directories}) => {
    const classes = useStyles({order, hero});
    const selectedDirectory = directories.find(a => a.slug === selectedDirectorySlug);
        
    return (
        <div className={classes.sectionContainer}>
            {!hero ?
                <Typography variant='h5' className={classes.title}>
                    Sections
                </Typography>
            :
            selectedDirectorySlug ?
                <>
                    <div className={classes.bgIconsContainer}>
                        <Box className={[classes.bgIconContainer, classes.bgIconContainer1]}>
                            <selectedDirectory.Icon className={classes.bgIcon1}/>
                        </Box>
                        <Box className={[classes.bgIconContainer, classes.bgIconContainer2]}>
                            <selectedDirectory.Icon className={classes.bgIcon2}/>
                        </Box>
                        <Box className={[classes.bgIconContainer, classes.bgIconContainer3]}>
                            <selectedDirectory.Icon className={classes.bgIcon3}/>
                        </Box>
                    </div>
                    <Typography variant="h3" className={classes.heroTitle}>{selectedDirectory.title}</Typography>
                    <Typography variant="h6" className={classes.description}>{selectedDirectory.description}</Typography>
                </>
            :
                <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <div className={classes.bgIconsContainer}>
                        <Box className={[classes.bgIconContainer, classes.bgIconBrowseContainer1]}>
                            <SearchRoundedIcon className={classes.bgIconBrowse1}/>
                        </Box>
                        <Box className={[classes.bgIconContainer, classes.bgIconBrowseContainer2]}>
                            <SearchRoundedIcon className={classes.bgIconBrowse2}/>
                        </Box>
                        <Box className={[classes.bgIconContainer, classes.bgIconBrowseContainer3]}>
                            <SearchRoundedIcon className={classes.bgIconBrowse3}/>
                        </Box>
                    </div>
                    <Typography variant="h3" className={classes.heroTitle}>Browse</Typography>
                    <Typography variant="h6" className={classes.description}>select category you would like to watch</Typography>
                </div>
            }
            <div
                className={classes.section}
            >
                {directories.map((props) => (
                    <CategoryBlock {...props} selectedDirectorySlug={selectedDirectorySlug}/>
                ))}
            </div>
        </div>
    )
}
