import React, { useEffect, useMemo } from 'react';
import { makeStyles, Grid, Chip, Box, Button, Divider, Tooltip, withStyles, Zoom } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import Text from 'components/atoms/Text/Text';
import Donut from 'components/organisms/Donut/Donut';
import AlexaForm1 from 'components/organisms/AlexaForm1/AlexaForm1';
import AlexaForm2 from 'components/organisms/AlexaForm2/AlexaForm2';
import { useMatch, useNavigate } from 'react-router';
import { useCurrentLanguage } from 'utils/customHooks';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    overflow: hidden;
  `}
`;

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 220,
      padding: 0
    },
  }))(Tooltip);

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20,
        '&>div': {
            '&>*': {
                width: '100%'
            }
        }
    },
    Content: {
        marginBottom: '15px !important',
        '&>div': {
            width: '100%'
        }
    },
    Header: {
        margin: 15,
        marginBottom: 10,
        '&>div': {
            marginBottom: 5,
            [theme.breakpoints.down(670)]: {
                minWidth: '100%',
                textAlign: 'center',
                justifyContent: 'center',
            },
        }
    },
    HeaderButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '&>*:not(:last-child)': {
            marginRight: '15px'
        }
    },
    Badge: {
        paddingLeft: '10px',
        '&>div': {
            width: 'max-content',
            color: 'white',
        }
    },
    Active: {
        backgroundColor: theme.palette.success.main
    },
    Terminated: {
        backgroundColor: theme.palette.text.secondary,
        color: ({ background }) => background === 'dark' ? 'black !important' : 'inherit'
    },
    Suspended: {
        backgroundColor: theme.palette.error.main
    },
    Pending: {
        backgroundColor: theme.palette.warning.dark
    },
    Info: {
        margin: 10,
        justifyContent: 'space-between',
        '&>div:first-child': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            [theme.breakpoints.down(1000)]: {
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                flexBasis: '100%',
            },
        },
        '&>div': {
            padding: 5,
            maxWidth: '49%',
            flexBasis: '49%',
            [theme.breakpoints.down(1000)]: {
                justifyContent: 'center',
                maxWidth: '100%',
                flexBasis: '100%',
            },
        },
        [theme.breakpoints.down(600)]: {
            textAlign: 'center'
        },
    },
    StatisticsWrapper: {
        '&>div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 5,
            '&>div': {
                display: 'flex',
                flexDirection: 'column',
            },
            [theme.breakpoints.down(600)]: {
                maxWidth: '100%',
                flexBasis: '100%',
            },
        }
    },
    DonutWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        '&>small': {
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -55%)',
            fontSize: '17px'
        }
    },
    Extra: {
        maxWidth: '1250px',
        margin: '0 auto',
        [theme.breakpoints.down(900)]: {
            '&>div': {
                maxWidth: '100%',
                flexBasis: '100%',
                padding: '0 !important'
            }
        },
    },
    AddonsWrapper: {
        paddingRight: '7.5px'
    },
    AdvertWrapper: {
        paddingLeft: '7.5px'
    },
    Addons: {
        padding: 15,
        '& $h4': {
            margin: '10px 0',
            [theme.breakpoints.down(450)]: {
                textAlign: 'center'
            },
        },
        '& $button': {
            margin: 5
        }
    },
    Addon: {
        display: 'flex',
        flexWrap: 'wrap',
        '&>div': {
            flex: '.5',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down(450)]: {
                maxWidth: '100%',
                flexBasis: '100%',
                justifyContent: 'center !important',
            },
        },
        '&>div:last-child': {
            justifyContent: 'flex-end',
        },
        '& $p': {
            margin: '5px 0'
        },
    },
}));

const addonsFunctions = {
    'Mobile App Bundle': [
        <Button variant='contained' color='primary'>Details</Button>,
        <Button variant='contained' color='primary'>Setup</Button>
    ],
    'Alexa Radio Skill': [
        <Button variant='contained' color='primary'>Details</Button>,
        <Button variant='contained' color='primary'>Setup</Button>
    ],
    'Radio Promotion Package': [
        <Button variant='contained' color='primary' style={{ backgroundColor: '#FF4C41' }}>Activate/Buy</Button>
    ],
    'Social Streaming Package': [
        <Button variant='contained' color='primary'>Setup</Button>
    ],
    'App Monetization': [
        <Button variant='contained' color='primary' style={{ backgroundColor: '#FF4C41' }}>Activate/Buy</Button>
    ],
    'App Push Notifications': [
        <Button variant='contained' color='primary' style={{ backgroundColor: '#FF4C41' }}>Activate/Buy</Button>
    ],
    'WebPlayer Page': [
        <Button variant='contained' color='primary'>Open</Button>,
        <Button variant='contained' color='primary'>Setup</Button>
    ],
    'TwitterCast': [
        <Button variant='contained' color='primary'>Setup</Button>
    ],
    'Tunein API': [
        <Button variant='contained' color='primary'>Setup</Button>
    ],
};

const alexaForms = [<AlexaForm1 />, <AlexaForm2 />, <AlexaForm2 withWebsiteVisit/>];

// page to show active product details
const DashboardDetails = () => {
    const background = useSelector(state => state.general.theme);
    const classes = useStyles({ background });
    const activeProduct = useSelector(state => state.account.activeProduct);
    const lng = useCurrentLanguage();
    const match = useMatch("/account/:id");
    const product = useSelector(state => state.account.products.find(({id}) => +id === +match.params.id));
    const isActive = useMemo(() => product.status === 'Active', [])
    const navigate = useNavigate();

    // if there is no active product, page automaticaly redirects to dashboard (/accont) page 
    useEffect(() => {
        if(!activeProduct) {
            navigate('/account')
        }
    }, [activeProduct])

    return activeProduct && (
        <Box className={classes.Wrapper}>
            <Grid container>
                <Grid item>
                    <Card className={classes.Content}>
                        <Box>
                            <Alert onClose={() => { }}>This is a success alert — check it out!</Alert>
                        </Box>
                    </Card>
                </Grid>
                <Grid item>
                    <Card className={classes.Content}>
                        <Grid container className={classes.Header}>
                            <Grid item xs={6}>
                                <Text component='h4' variant='h5'>{activeProduct.groupname}</Text>
                                <Text component='h6'>{activeProduct.name}</Text>
                            </Grid>
                            <Grid item xs={6} className={classes.HeaderButtons}>
                                <Grid>
                                    <div className={classes.Badge}><Chip className={classes[activeProduct.status]} label={activeProduct.status} /></div>
                                </Grid>
                                <HtmlTooltip 
                                    TransitionComponent={Zoom} 
                                    title={!isActive && <Button variant='contained' color='primary' style={{ backgroundColor: '#FF4C41' }}>Suspended</Button>}
                                >
                                    <span>
                                        <Button href={`${lng}/login`} disabled={!isActive} variant='contained' color='primary'>Login</Button>
                                    </span>
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item>
                    <Card className={classes.Content}>
                        <Grid container className={classes.Info}>
                            <Grid item>
                                <Box>
                                    <Text component='p'>Username: {product.username}</Text>
                                    <Text component='p'>Next Due Date: {product.nextduedate}</Text>
                                    <Text component='p'>Amount: {product.firstpaymentamount}</Text>
                                    <Text component='p'>Billing Cycle: {product.billingcycle}</Text>
                                    <Text component='p'>Payment Method: {product.paymentmethodname}</Text>
                                    <Text component='p'>Subscription: {product.subscriptionid}</Text>
                                </Box>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item>
                                <Grid container className={classes.StatisticsWrapper}>
                                    <Grid item xs={6} className={classes.StatisticsLeft}>
                                        <Box>
                                            <Text component='span'>Server Status: {product.status}</Text>
                                            <Text component='span'>Server Type: blablabla</Text>
                                            <Text component='span'>Listeners: blablabla</Text>
                                            <Box className={classes.DonutWrapper}>
                                                <Donut value={66} backgroundColor="#FF4C41" backgroundColor2="#F0F0F0" />
                                                <small>66%</small>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} className={classes.StatisticsRight}>
                                        <Box>
                                            <Text component='span'>Source Connected: blablabla</Text>
                                            <Text component='span'>Bitrate: blablabla</Text>
                                            <Text component='span'>Disk Space: blablabla</Text>
                                            <Box className={classes.DonutWrapper}>
                                                <Donut value={66} backgroundColor="#FF4C41" backgroundColor2="#F0F0F0" />
                                                <small>66%</small>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item>
                    <Grid container className={classes.Extra}>
                        <Grid item xs={activeProduct.alexaFormState ? 8 : 6} className={classes.AddonsWrapper}>
                            <Card className={classes.Content}>
                                <Grid container className={classes.Addons}>
                                    {activeProduct.addons ? 
                                        activeProduct.addons.map((addonsGroup, i) => (<>
                                            <Grid key={i} item xs={12}>
                                                <Text component='h4' variant='h5'>{addonsGroup.title}</Text>
                                            </Grid>
                                            {addonsGroup.list.map((addon, i) => (
                                                <Grid key={i} item xs={12} className={classes.Addon}>
                                                    <Box>
                                                        <Text component='p'>{addon}</Text>
                                                    </Box>
                                                    <Box>
                                                        {addonsFunctions[addon].map(func => func)}
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </>))
                                        : 
                                        alexaForms[+activeProduct.alexaFormState]
                                    }
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={activeProduct.alexaFormState ? 4 : 6} className={classes.AdvertWrapper}>
                            <Card className={classes.Content}>
                                <Grid container className={classes.StatisticsWrapper}>
                                    <Grid item>
                                        <Box m='15px' className="text-white bg-info">
                                            <Box>
                                                <Box className="text-white">Info card title</Box>
                                            </Box>
                                            <Box className=" mb-0">
                                                <Box>
                                                    Some quick example text to build on the card title and make up
                                                    the bulk of the card's content.
                                                </Box>
                                                <Box
                                                    as="a"
                                                    variant="info "
                                                    href="#"
                                                    className="btn-card mt-3"
                                                >
                                                    Go somewhere
                                                </Box>
                                            </Box>
                                            <Box className=" bg-transparent border-0 text-white">
                                                Last updateed 3 min ago
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
};

export default DashboardDetails;