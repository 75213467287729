import React from 'react';
import { Route } from 'react-router-dom';
import App from 'views/App/App';
import AppPromo from 'views/App/AppPromo';

//children routes for /app page. Used to divide /app page and its promo version /app/promo
const AppRoutes = [
  <Route index element={<App/>} />,
  <Route path={`promo`} element={<AppPromo/>} />
];

export default AppRoutes;
