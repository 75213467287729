import axios from 'axios';
import { isProd } from 'utils/nodeEnv';

const baseUrl = `${isProd() ? 'https://fastcast4u.com/api' : '/api'}`;
// const baseUrl = 'http://eu10.fastcast4u.com:8082/api';
let source = axios.CancelToken.source();

//service to redirect on TEMPORARY login page
const getRedirectUrl = async () => {
  const response = await axios.get(`${baseUrl}/login/redirect`, {
    headers: {
      'Access-Control-Allow-Credentials': true
    }
  });

  return response.data;
};

// action to set new tocken for next requests
const setToken = (token) => async dispatch =>  {
  return dispatch({type: 'SET_TOKEN', payload: token})
}

// service to request user details and set it as state property
const getUserDetails = (token) => async dispatch =>  {
  const response = await axios.get(`http://eu10.fastcast4u.com:8082/api/client/details`, {
    headers: {
        Authorization: 'Bearer ' + token
    }
  })
  dispatch(setToken(response.data.token.value))
  dispatch({type: 'SET_ACCOUNT_DATA', payload: response.data.data})
  return response.data
}

// service to get ticket
const getTicket = (token, ticketId) => async dispatch =>  {
  const response = await axios.get(`http://eu10.fastcast4u.com:8082/api/ticket/${ticketId}`, {
    headers: {
        Authorization: 'Bearer ' + token
    }
  })
  dispatch(setToken(response.data.token.value))
  console.log('ticket', response)
}

// service to login with data that is TEMPORATY located in url
const login = (queryStr) => async dispatch =>  {
  const response = await axios.get(`http://eu10.fastcast4u.com:8082/api/login/callback${queryStr}`, {
    headers: {
        'Access-Control-Allow-Credentials': true,
    }
  })
  .then((res) => {
    return (async () => {
      const token = res.data.data.token.value;
      await dispatch(setToken(token))
      const result = await dispatch(getUserDetails(token))
      return result
    })()
  })
  .catch((error) => {
    return (async () => {
      // console.log('login', {...error})
      return await getRedirectUrl()
    })()
  })
  return response
}

// service to get product groups do render them differently depending on group ('tv', 'radio' etc)
const getProductGroups = (token) => async dispatch => {
  const response = await axios.get(`${baseUrl}/products/groups`, {
    headers: {
      'Access-Control-Allow-Credentials': true,
      Authorization: 'Bearer ' + token
    }
  });
  dispatch({type: 'SET_PRODUCT_GROUPS', payload: response.data.data})
  dispatch(setToken(response.data.token.value))
  return response.data;
}

// old action for login
const loginUser = async data => {
  const response = await axios.post(`${baseUrl}/login/login.php`, data, {
    cancelToken: source.token,
  });

  return response.data;
};

// old action
const forgotPassword = async data => {
  const response = await axios.post(`${baseUrl}/login/oneforgotpassword/index.php`, data, {
    cancelToken: source.token,
  });

  return response.data;
};

// cancel the requests
const cancel = () => {
  source.cancel();
  source = axios.CancelToken.source();
};

export default { loginUser, forgotPassword, cancel, login, getUserDetails, getRedirectUrl, getTicket, getProductGroups };
