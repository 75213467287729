// @ts-nocheck
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import generalActions from 'redux/actions/generalActions';

import styled, { css } from 'styled-components';
import { AppBar, Toolbar, Tooltip, IconButton, useTheme, useMediaQuery, Menu, MenuItem, Button, Fade } from '@material-ui/core';
import Logo from 'components/atoms/Logo/Logo';
import SocialBar from 'components/molecules/SocialBar/SocialBar';
import ItemsLeftTopBar from 'components/molecules/ItemsLeftTopBar/ItemsLeftTopBar';
import { useCurrentLanguage } from 'utils/customHooks';
import { useNavigate } from 'react-router-dom';

import LightIcon from '@material-ui/icons/Brightness4';
import DarkIcon from '@material-ui/icons/Brightness7';
import Navbar from '../Navbar/Navbar';
import NavButton from 'components/atoms/NavButton/NavButton';
import { isDev } from 'utils/nodeEnv';

const StyledAppBar = styled(AppBar)`
  ${({ theme, hidden }) => css`
    padding: 0;
    background-color: ${theme.palette.background.paper};
    display: ${hidden && 'none'};
  `}
`;

const StyledToolbar = styled(Toolbar)`
  ${({ theme }) => css`
    justify-content: flex-end;

    ${theme.breakpoints.up('md')} {
      min-height: ${theme.spacing(10)}px;
    }
  `}
`;

const MenuItems = [
  {
    title: 'Account Details',
    to: '/account/details'
  }, 
  {
    title: 'Contact Details',
    to: '/account/contactdetails'
  }, 
  {
    title: 'Sub-accounts',
    to: '/account/addcontact'
  }, 
  {
    title: 'Reset Password',
    to: '/account/changepassword'
  }, 
  {
    title: 'Emails',
    to: '/account/emails'
  }, 
  {
    title: 'Logout',
    to: '/account'
  }
];

const Header = ({noSocialBar}) => {
  const muitheme = useTheme();
  const navbarEnabled = useMediaQuery(muitheme.breakpoints.up(768));
  const content = useSelector(state => state.language.components.header);
  const theme = useSelector(state => state.general.theme);
  const lng = useCurrentLanguage();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isHidden = pathname.startsWith(`${lng}/login`) || pathname.startsWith(`${lng}/account/payments/`);
  const landingHidden = pathname.includes('promo');
  const showNavbar = window.location.pathname.split('/')[1] === 'account';
  const isAuth = pathname.startsWith(`${lng}/account`);
  const [menuElement, setMenuElement] = useState(null);
	const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    dispatch(generalActions.toggleTheme());
  };
  const openMenu = e => {
    setMenuElement(e.currentTarget);
  }
  
  const closeMenu = (link) => {
    setMenuElement(null);
    navigate(link);
  }

  return (
    <>
      <StyledAppBar color="default" position="static" hidden={isHidden}>
        {!noSocialBar &&
          <SocialBar />
        }
        <StyledToolbar>
          <Logo mr="auto" to={{pathname: `/`, search: location.search, hash: location.hash}} />
          {/* {isDev() && <LanguageButton />} */}
          <Tooltip title={content.toggle}>
            <IconButton color="primary" aria-label="toggle dark theme" onClick={handleClick}>
              {theme === 'light' ? <LightIcon /> : <DarkIcon />}
            </IconButton>
          </Tooltip>
          {!landingHidden &&
            isAuth && isDev() ?
            <>
              <Button 
                variant='contained' 
                color='primary'
                aria-controls="simple-menu" 
                aria-haspopup="true" 
                onClick={openMenu}
              >
                My Account
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={menuElement}
                keepMounted
                open={!!menuElement}
                onClose={closeMenu}
                TransitionComponent={Fade}
              >
                {MenuItems.map((item, i) => <MenuItem key={i} onClick={() => closeMenu(item.to)}>{item.title}</MenuItem>)}
              </Menu>
            </>
            :
            // <></>
            <NavButton to={`${lng}/login`}>{content.login}</NavButton>
            // <Button onClick={() => {loginServices.getRedirectUrl().then(({data}) => {window.location.replace(data.oauth_url);})}}>{content.login}</Button>
          }
        </StyledToolbar>
        {showNavbar && navbarEnabled && <Navbar />}
      </StyledAppBar>
      <ItemsLeftTopBar />
    </>
  );
};

export default Header;
