import React from 'react';
import { makeStyles, Grid, Box, Button, TextField } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Text from 'components/atoms/Text/Text';
import PhoneInput from 'components/atoms/PhoneInput/PhoneInput';

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 1.375rem;
    box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
  `}
`;

const useStyles = makeStyles(theme => ({
    Wrapper: {
        padding: 20
    },
    Title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 20px 20px 20px',
    },
    ContentWrapper: {
        width: '100%',
        padding: '0 10px 10px 10px',
        '&>div': {
            '&>div': {
                padding: 10,
                [theme.breakpoints.down(768)]: {
                    minWidth: '100%',
                },
                '&>div': {
                    width: '100%',
                }
            },
            '&>div:last-child': {
                padding: 0,
                '&>div': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    '&>div': {
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 10,
                        [theme.breakpoints.down(768)]: {
                            minWidth: '40%',
                        },
                        [theme.breakpoints.down(570)]: {
                            minWidth: '100%',
                        },
                        '&>div': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }
                    },
                    '&>div:last-child': {
                        [theme.breakpoints.down(768)]: {
                            minWidth: '100%',
                        }
                    },
                }
            }
        }
    },
}));

const OpenTicket = () => {
    const classes = useStyles();

    const attachFiles = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.click();
    }

    return (
        <Box className={classes.Wrapper}>
            <Card>
                <Grid container>
                    <Grid item className={classes.Title}>
                        <Text component='h4' variant='h5'>Open New Support Ticket</Text>
                    </Grid>
                    <Grid item className={classes.ContentWrapper}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Subject"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Message"
                                    variant="outlined"
                                    multiline
                                    minRows={3}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item>
                                        <Box>
                                            <Text component='p' fontSize='14px'>Attachments(Optional)</Text>
                                            <Button 
                                                variant='contained' 
                                                color='primary' 
                                                onClick={attachFiles}
                                            >
                                                Add Files
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box>
                                            <PhoneInput
                                                name="phone"
                                            // onChange={number => formik.setFieldValue('phone', number)}
                                            // value={formik.values.phone}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item style={{ marginLeft: 'auto' }}>
                                        <Box>
                                            <Button variant='contained' color='primary'>Open Ticket</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default OpenTicket;