import React from 'react'
import { Box, ButtonBase, Chip, Dialog, DialogTitle, IconButton, Typography, makeStyles } from '@material-ui/core'
import PeopleIcon from '@mui/icons-material/PeopleRounded';
import { fontWeight } from '@material-ui/system'
import CTAButton from 'components/atoms/CTAButton/CTAButton'
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/CloseRounded';

const useStyles = makeStyles(theme => ({
    categoryHero: {
        display: "flex",
        padding: "40px 40px",
        background: `linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
        overflow: "hidden",
        position: "relative",
        display: "flex",
        minHeight: "350px",
        marginBottom: "20px",
    },
    poster: {
        boxShadow: theme.shadows[18],
        borderRadius: "10px",
        zIndex: 2,
        height: "100%",
        width: "auto",
    },
    fullDescriptionPoster: {
        boxShadow: theme.shadows[10],
        borderRadius: "10px",
        zIndex: 2,
        height: "200px",
        width: "min-content",
        marginBottom: "10px",
        marginRight: "10px",
    },
    bgIconContainer: {
        animation: `$levitate 7000ms linear infinite`,
        position: "absolute",
        zIndex: 1,
        color: "#fff",
    },
    bgIconContainer1: {
        bottom: "0",
        right: "0",
        animationDuration: "9s",
    },
    bgIconContainer2: {
        bottom: "50%",
        right: "50%",
        animationDuration: "6s",
    },
    bgIconContainer3: {
        bottom: "5%",
        left: 0,
        animationDuration: "8s",
    },
    bgIcon1: {
        display: "flex !important",
        width: "400px !important",
        height: "400px !important",
        opacity: "0.25",
        transform: "translate(20%, 20%) rotateZ(-30deg)",
    },
    bgIcon2: {
        display: "flex !important",
        width: "300px !important",
        height: "300px !important",
        opacity: "0.1",
        transform: "translate(20%, 20%) rotateZ(40deg)"
    },
    bgIcon3: {
        display: "flex !important",
        width: "350px !important",
        height: "350px !important",
        opacity: "0.15",
        transform: "translate(-30%, 20%) rotateZ(10deg)"
    },

    categoryDescription: {
        display: "block",
        maxWidth: "550px",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        cursor: "pointer",
        transition: "0.1s ease",
        padding: "5px",
        zIndex: 5,
        position: "relative",
        borderRadius: "10px",
        fontSize: "15px",
        textAlign: "left",
        "&:hover": {
            background: "rgba(255,255,255,0.2)"
        }
    },
    categoryData: {
        color: "#fff",
        padding: "5px 0 15px 0",
        paddingLeft: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    categoryTitle: {
        fontWeight: "bold"
    },
    categoryTags: {
        "& > *": {
            marginRight: "5px",
            fontWeight: "bold",
        }
    },
    categoryDataStatistics: {
        display: "flex",
        alignItems: "center",
        margin: "10px 5px",
    },
    fullDescriptionCategoryDataStatistics: {
        color: theme.palette.primary.contrastText,
        display: "flex",
        width: "max-content",
        background: theme.palette.primary.main,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
        padding: "5px 20px",
        borderRadius: "20px",
    },
    categoryDataStatisticsDivider: {
        width: "2px",
        height: "15px",
        borderRadius: "2px",
        background: "white",
        margin: "0 10px",
    },
    fullDescriptionContainer: {
        display: "flex",
        flexDirection: "row",
        padding: "25px",
        paddingTop: 0,
        
    },
    fullDescriptionContent: {
        paddingLeft: "10px",
        borderLeft: `solid 3px ${theme.palette.primary.dark}`
    },
    
    "@keyframes levitate": {
        "0%": {
            transform: "translateY(0%)"
        },
        "20%": {
            transform: "translateY(5%)"
        },
        "80%": {
            transform: "translateY(-5%)"
        },
        "100%": {
            transform: "translateY(0%)"
        }
    },

    fullDescriptionDialogCloseButton: {
        fontSize: "20px",
        padding: "5px",
    },
    fullDescriptionDialogTitle: {
        paddingBottom: 0,
        display: "flex",
        flexDirection: "row-reverse",
    }
}))

const FullDescription = ({isDescriptionOpened, closeDescription, selectedCategory}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={isDescriptionOpened}
            onClose={closeDescription}
            style={{maxWidth: "none"}}
            maxWidth="md"
        >
            <DialogTitle disableTypography className={classes.fullDescriptionDialogTitle}>
                <IconButton onClick={closeDescription} className={classes.fullDescriptionDialogCloseButton}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <div className={classes.fullDescriptionContainer}>
                <img src={selectedCategory.img} className={classes.fullDescriptionPoster}/>
                <div className={classes.fullDescriptionContent}>
                    <Typography variant="h3">{selectedCategory.title}</Typography>
                    <div className={classes.fullDescriptionCategoryDataStatistics}>
                        <Typography>80K viewers</Typography>
                        <div className={classes.categoryDataStatisticsDivider}/>
                        <Typography>150K followers</Typography>
                        <div className={classes.categoryDataStatisticsDivider}/>
                        <div className={classes.categoryTags}>
                            <Chip size="small" label="lorem"></Chip>
                            <Chip size="small" label="ipsum"></Chip>
                            <Chip size="small" label="dolor"></Chip>
                        </div>
                    </div>
                    <Typography
                        className={classes.fullDescription}
                    >
                        {selectedCategory.description}
                    </Typography>
                </div>
            </div>
        </Dialog>
    )
}

export const TvTransCategoryHero = ({order, selectedCategory, selectedDirectory}) => {
    const maxDescriptionLength = 200;
    const [isDescriptionOpened, setDescriptionOpened] = useState(false); 
    const classes = useStyles({order});

    const openDescription = () => setDescriptionOpened(true);
    const closeDescription = () => setDescriptionOpened(false);


    return (
        <div className={`${classes.categoryHero}`}>
            <FullDescription
                isDescriptionOpened={isDescriptionOpened}
                closeDescription={closeDescription}
                selectedCategory={selectedCategory}
            />
            <Box className={[classes.bgIconContainer, classes.bgIconContainer1]}>
                <selectedDirectory.Icon className={classes.bgIcon1}/>
            </Box>
            <Box className={[classes.bgIconContainer, classes.bgIconContainer2]}>
                <selectedDirectory.Icon className={classes.bgIcon2}/>
            </Box>
            <Box className={[classes.bgIconContainer, classes.bgIconContainer3]}>
                <selectedDirectory.Icon className={classes.bgIcon3}/>
            </Box>
            <img src={selectedCategory.img} className={classes.poster}/>
            <div className={classes.categoryData}>
                <div>
                    <Typography className={classes.categoryTitle} variant="h3">{selectedCategory.title}</Typography>
                    <div className={classes.categoryDataStatistics}>
                        <Typography>80K viewers</Typography>
                        <div className={classes.categoryDataStatisticsDivider}/>
                        <Typography>150K followers</Typography>
                        <div className={classes.categoryDataStatisticsDivider}/>
                        <div className={classes.categoryTags}>
                            <Chip size="small" label="lorem"></Chip>
                            <Chip size="small" label="ipsum"></Chip>
                            <Chip size="small" label="dolor"></Chip>
                        </div>
                    </div>
                    <ButtonBase
                        onClick={openDescription}
                        className={classes.categoryDescription}
                    >
                        {
                            selectedCategory.description.length > maxDescriptionLength
                                ? selectedCategory.description.slice(0, maxDescriptionLength) + "..."
                                : selectedCategory.description
                        }
                    </ButtonBase>
                </div>
                <CTAButton
                    style={{
                        position: "relative",
                        zIndex: 2,
                    }}
                >
                    Follow
                </CTAButton>
            </div>
        </div>
    )
}
