import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import PeopleIcon from '@mui/icons-material/PeopleRounded';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => ({
    categoryContainer: ({img, isNew}) => ({
        background: `url(${img})`,
        paddingBottom: "130%",
        backgroundSize: "100%",
        backgroundPosition: "center",
        transition: "1s ease",
        borderRadius: "7px",
        position: "relative",
        overflow: "hidden",
        boxShadow: "2px 2px 9px 1px rgba(0, 0, 0, 0.5), inset 3px -2px 8px rgba(0,0,0,0.2)",
        border: `1px solid ${theme.palette.primary.light}`,
        cursor: "pointer",

        "&:hover": {
            transition: "3s cubic-bezier(.08,1.14,0,.85)",
            backgroundSize: "120%",
        },

        "&::before": {
            content: '""',
            background: "rgba(0,0,0,0.4)",
            backdropFilter: "blur(1px)",
            width: "100%",
            height: "100%",
            position: "absolute",
            transition: "1s ease",
        },

        "&:hover::before": {
            background: "rgba(0,0,0,0)",
            backdropFilter: "none",
        },

        "&::after": {
            content: '"New"',
            display: isNew ? "block" : "none",
            color: "white",
            fontWeight: "bold",
            fontSize: "17px",
            backgroundColor: theme.palette.primary.main,
            background: "radial-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.1), transparent)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 300%",
            backgroundPosition: "-50% 50%",
            padding: "2px 70px",
            right: "0",
            transformOrigin: "center",
            transform: "rotateZ(45deg) translate(30%, -100%)",
            position: "absolute",
            transition: "background-position 0s linear, background-color 0.5s ease",
            border: `1px solid ${theme.palette.primary.dark}`
        },

        "&:hover::after": {
            backgroundColor: theme.palette.primary.light,
            opacity: "0.95",

            backgroundPosition: "150% 50%",
            transition: "background-position 0.3s linear, background-color 0.5s ease",
        },
    }),
    categoryBox: {
        "&:hover $categoryTitle::before": {
            transform: "translateX(0%)"
        },
        "&:hover $viewersCount::before": {
            transform: "translateY(0%)"
        },
    },
    categoriesList: {
        display: "grid",
        padding: "10px 0",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
        gap: "20px",
        margin: "10px 0",
        
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: "1fr 1fr 1fr",
        },

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "1fr 1fr",
        }
    },
    categoryTitle: {
        color: "#fff",
        fontSize: "15px",
        filter: "drop-shadow(2px 2px 2px black)",
        position: "absolute",
        bottom: "10px",
        right: "0",
        overflow: "hidden",
        borderRadius: "20px 0 0 20px",
        padding: "2px 10px 2px 20px",
        transition: "0.2s ease",

        "&::before": {
            content: '""',
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: -1,
            background: theme.palette.primary.light,
            right : 0,
            top: 0,
            transition: "0.5s ease",
            transform: "translateX(100%)",
            opacity: 0.8,
            borderRadius: "6px 0 0 6px",
        }
    },
    viewersCount: {
        color: "#fff",
        fontSize: "15px",
        filter: "drop-shadow(2px 2px 2px black)",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        top: "0px",
        left: "0",
        overflow: "hidden",
        borderRadius: "0 0 15px 0",
        padding: "6px 6px 10px 10px",
        transition: "0.2s ease",

        "&::before": {
            content: '""',
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: -1,
            background: theme.palette.primary.light,
            right : 0,
            top: 0,
            transition: "0.5s ease",
            transform: "translateY(-100%)",
            opacity: 0.8,
            borderRadius: "0 0 6px 0",
        }
    },
    categoriesListContainer: ({order}) => ({
        order: order,
        padding: "0 30px",
        
        "&::after": {
            content: '""',
            height: "1px",
            background: "black",
            display: "flex",
            width: "100%",
            opacity: 0.2,
        }
    }),
    title: {
        width :"100%",
        textAlign: "center",
        fontWeight: "bold",
    }
}))

const Category = ({title, img, viewersCount, isNew, directory, slug}) => {
    const classes = useStyles({img, isNew});
    const navigate = useNavigate();

    return (
        <div
            className={`${classes.categoryContainer} ${classes.categoryBox}`}
            onClick={() => {
                navigate(`/tvtrans/directory/${directory}/${slug}`)
            }}
        >
            <div className={classes.viewersCount}>
                {viewersCount}K <PeopleIcon sx={{marginLeft: "10px"}}/>
            </div>
            <Typography className={classes.categoryTitle}>
                {title}
            </Typography>
        </div>
    )
}

export const TvTransCategoriesList = ({order, categoriesList, indexesToDisplay, directoryToDisplay}) => {
    const classes = useStyles({order});
    const visibleCategoriesList = indexesToDisplay ?
            indexesToDisplay.map(a => categoriesList[a])
        : directoryToDisplay ?
            categoriesList.filter(a => a.directory === directoryToDisplay)
        : categoriesList;

    return (
        <div className={`${classes.categoriesListContainer}`}>
            <Typography className={`${classes.title}`} variant="h5">Categories</Typography>
            <div className={`${classes.categoriesList}`}>
                {visibleCategoriesList.map((a) => <Category key={a.slug} {...a}/>)}
            </div>
        </div>
    )
}
