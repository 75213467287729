/* eslint-disable react/prop-types */
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    display: 'inherit',
    flexDirection: 'inherit',
    justifyContent: 'inherit',
    alignItems: 'inherit',
    textAlign: 'inherit',
  },
  heading:{
    width: '100%',
    // fontSize: 30,
    // fontSize: "3rem",
    paddingBottom: ({hasMediaContent}) => hasMediaContent ? 15 : 25,
    textAlign: ({contentReverse, hasMediaContent}) => contentReverse ? 'left' : !hasMediaContent && 'center',
    [theme.breakpoints.up('lg')]: {
      fontSize: ({long, hasMediaContent}) => hasMediaContent ? (long ? 27 : 30) : (long ? 40 : 43),
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center !important'
    },
  },
  content: {
    width: '100%',
    whiteSpace: 'pre-line',
    // display: "block",
    paddingBottom: ({hasMediaContent}) => !hasMediaContent && 20,
    fontSize: ({long, short, hasMediaContent}) => hasMediaContent ? (long ? 16 : short ? 18 : 17) : (long ? 18 : short ? 25 : 20),
    textAlign: ({contentReverse, hasMediaContent}) => contentReverse ? 'left' : !hasMediaContent && 'center',
    [theme.breakpoints.up('lg')]: {
      fontSize: ({long, short, hasMediaContent}) => hasMediaContent ? (long ? 18 : short ? 20 : 19) : (long ? 20 : short ? 30 : 25),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center !important'
    },
    '& b': {
      color: '#007db8',
      fontWeight: 600
    },
    '& >*:not(:first-child)': {
      display: 'block',
      marginTop: '8px'
    }
  },
  item: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: "4px 0",
      background: "red"
    },
  },
  listIcon: {
    minWidth: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: 18,
  },
  itemText: {
    fontSize: '1rem',
  },
  btn: {
    '&:not(:last-child)': {
      marginRight: '30px'
    }
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: ({buttonReverse}) => buttonReverse ? 'flex-start' : '',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center !important'
    },
    marginTop: theme.spacing(3),
  }
}));

// component for RowSection that is responsible for the view of main section content (the part with text)
const RowContent = ({ heading, hasMediaContent, content, contentReverse, contentCentred, buttonReverse, showVideoButton, button, buttons, list, long, short, iconeList, injectHTML }) => {
  const classes = useStyles({long, hasMediaContent, short, contentReverse, buttonReverse, contentCentred});

  return (
    <Box className={classes.box}>
      <Text component="h3" variant={"h4"} mb={0} className={classes.heading}>
        {heading}
      </Text>
      {content && (
        <Text component="p" variant="h5" mt={0} className={classes.content} {...(injectHTML ? {dangerouslySetInnerHTML: {__html: content}} : {children: content})}/>
      )}
      {!!list.length && (
        <List>
          {list.map(content => (
            <ListItem key={content} disableGutters className={classes.item}>
              <ListItemIcon className={classes.listIcon}>
                <CheckCircleIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.itemText} primary={content} />
            </ListItem>
          ))}
        </List>
      )}
      <Box className={classes.buttonsContainer}>
        {button.label && (
          <Button
            className={classes.btn}
            component={button.onClick ? 'button' : button.href ? 'a' : Link}
            onClick={button.onClick}
            size="large"
            variant="contained"
            color={button.color || "secondary"}
            target={button.href && '_blank'}
            {...button}
          >
            {button.label}
          </Button>
        )}
        {buttons && (
          buttons.map(data => (
            <Button
              className={classes.btn}
              component={data.onClick ? 'button' : data.href ? 'a' : Link}
              onClick={data.onClick}
              size="large"
              variant="contained"
              color={data.color || "secondary"}
              target={data.href && '_blank'}
              {...data}
            >
              {data.label}
            </Button>
          ))
        )}
        {showVideoButton && (
          <Button
            className={classes.btn}
            onClick={showVideoButton.onClick}
            size="large"
            variant="contained"
            color="primary"
          >
            {showVideoButton.label}
          </Button>
        )}
      </Box>
    </Box>
  );
};

RowContent.defaultProps = {
  content: '',
  list: [],
  button: {},
  long: false,
  short: false,
};

RowContent.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  list: PropTypes.arrayOf(PropTypes.string),
  button: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }),
  long: PropTypes.bool,
  short: PropTypes.bool,
};

export default RowContent;
