import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Text from 'components/atoms/Text/Text';
import AlexaFormVisitWebsite from 'components/molecules/AlexaFormVisitWebsite/AlexaFormVisitWebsite';
import React, { useState } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles(theme => ({
    Inputs: {
        '&>*': {
            padding: 10,
            [theme.breakpoints.down(1100)]: {
                minWidth: '100%',
            },
        },
        '& $button': {
            marginLeft: '0 !important'
        },
    },
    TextField: {
        width: '100%',
    },
    Attachment: {
        '& $img': {
            maxWidth: '80%'
        },
        '&>div': {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down(500)]: {
                minWidth: '100%',
            },
        },
    },
    ParamContainer: {
        margin: '10px 0'
    },
    Parametr: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        '& $button': {
            minWidth: 0,
            padding: 0
        },
    },
    ParametrTextField: {
        flex: 1,
        marginRight: 10
    },
    ParamTitle: {
        fontWeight: 600
    },
    ParamDesc: {
        color: theme.palette.text.secondary
    },
    ParametrAddButton: {
        marginTop: 10
    }
}));

const defaultImg = {
    img: "https://fastcast4u.com/images/logo.webp",
    name: 'example.webp'
}

const parametresText = [
    {
        title: 'Launch message',
        desc: 'An introduction message read when your Skill is launched'
    },
    {
        title: 'Now Playing',
        desc: 'Information (metadata) about a currently played track'
    },
    {
        title: 'Current track title',
        desc: 'A message about information (metadata) about a currently played track'
    },
    {
        title: 'Error message',
        desc: ''
    },
    {
        title: 'Goodbye message',
        desc: ''
    },
];

const AlexaForm1 = () => {
    const classes = useStyles();
    const [artImage, setArtImage] = useState(defaultImg);
    const [bgImage, setBgImage] = useState(defaultImg);
    const [parametresFields, setParametresFields] = useState({});

    const attachFiles = (variant) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = "image/jpeg,image/png";
        input.onchange = e => {
            const reader = new FileReader();
            const file = e.target.files[0];
            reader.onloadend = () => {
                if (variant === 0) {
                    setArtImage({ img: reader.result, name: file.name });
                } else if (variant === 1) {
                    setBgImage({ img: reader.result, name: file.name });
                }
                input.remove();
            }
            reader.readAsDataURL(file);
        }
        input.click();
    }

    const setParametresFieldsAddHadler = i => {
        setParametresFields({
            ...parametresFields,
            [i]: parametresFields[i] ? [...parametresFields[i], ''] : ['']
        });
    }

    const setParametresFieldsChangeHadler = (e, i, j) => {
        setParametresFields({
            ...parametresFields,
            [i]: [
                ...parametresFields[i].slice(0, j),
                e.target.value,
                ...parametresFields[i].slice(j + 1)
            ]
        });
    }

    const setParametresFieldsRemoveHadler = (i, j) => {
        setParametresFields({
            ...parametresFields,
            [i]: [
                ...parametresFields[i].slice(0, j),
                ...parametresFields[i].slice(j + 1)
            ]
        });
    }

    return (
        <Grid item xs={12}>
            <AlexaFormVisitWebsite />
            <Grid container className={classes.Inputs}>
                <Grid item xs={12}>
                    <Text component='h5' variant='h5'>Choose username</Text>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className={classes.TextField}
                        required
                        id="outlined-required"
                        label='Stream URL (HTTPS required)'
                        variant="outlined"
                        size='small'
                        placeholder='Stream URL'
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className={classes.TextField}
                        required
                        id="outlined-required"
                        label='Title'
                        variant="outlined"
                        size='small'
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.TextField}
                        required
                        id="outlined-required"
                        label='Subtitle'
                        variant="outlined"
                        size='small'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="info">Images should not contain other logos and advertisement</Alert>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={classes.Attachment}>
                        <Grid item xs={6}>
                            <Text component='p'>Art</Text>
                            <Box>
                                <img src={artImage.img} alt="" />
                            </Box>
                            <Box mt='auto'>
                                <Text component='p'>{artImage.name}</Text>
                                <Button variant='outlined' onClick={() => attachFiles(0)}>Choose file</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Text component='p'>Background Image</Text>
                            <Box>
                                <img src={bgImage.img} alt="" />
                            </Box>
                            <Box mt='auto'>
                                <Text component='p'>{bgImage.name}</Text>
                                <Button variant='outlined' onClick={() => attachFiles(1)}>Choose file</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {parametresText.map((parametr, i) => (
                        <Grid container className={classes.ParamContainer}>
                            <Grid item xs={12}>
                                <Text component='p' className={classes.ParamTitle}>{parametr.title}</Text>
                            </Grid>
                            <Grid item xs={12}>
                                <Text component='p' className={classes.ParamDesc}>{parametr.desc}</Text>
                            </Grid>
                            {parametresFields[i]?.map((parametrField, j) => (
                                <Grid item xs={12} className={classes.Parametr}>
                                    <TextField
                                        className={classes.ParametrTextField}
                                        variant="outlined"
                                        size='small'
                                        autoFocus
                                        value={parametrField}
                                        onChange={e => setParametresFieldsChangeHadler(e, i, j)}
                                    />
                                    <Button variant='text' onClick={() => setParametresFieldsRemoveHadler(i, j)}><CloseRoundedIcon color='secondary' fontSize='large' /></Button>
                                </Grid>
                            ))}
                            <Grid item xs={12} className={classes.ParametrAddButton}>
                                <Button variant='outlined' onClick={() => setParametresFieldsAddHadler(i)}>Add</Button>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Button variant='contained' color='primary'>Submit</Button>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default AlexaForm1;