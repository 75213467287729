// @ts-nocheck
import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Divider,
  makeStyles,
} from '@material-ui/core';
import Text from 'components/atoms/Text/Text';
import { modeSwitch } from 'utils/theme';

const useStyles = makeStyles(theme => ({
  root: ({ isActive }) => ({
    height: '100%',
    boxShadow: isActive ? `0px 0 0px 2px ${theme.palette.primary.main}` : 'unset',
    border: `solid 1px ${
      isActive ? theme.palette.primary.main : theme.palette.grey[modeSwitch(400, 800)]
    }`,
    transition: 'unset',
  }),
  actionArea: {
    height: '100%',
  },
  content: {
    height: '100%',
    padding: `${theme.spacing(1, 1.5)} !important`,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(2, 2.5)} !important`,
      paddingBottom: ({ showPrice }) => showPrice && `${theme.spacing(1.5)}px !important`,
    },
  },
  checkbox: { position: 'absolute', right: 0, top: 0 },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  desc: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  priceContainer: {
    marginTop: 'auto',
    paddingTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
  },
  price: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  priceOld: {
    marginRight: theme.spacing(0.5),
    alignSelf: 'flex-start',
    color: theme.palette.grey[modeSwitch(600, 400)],
    textDecoration: 'line-through',
  },
  cycle: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.grey[modeSwitch(700, 300)],
    fontSize: theme.typography.pxToRem(14),
  },
}));

// component that controlls the view of all order radio products, adoons and extra addons on /order/package page
const ProductBox = ({
  data,
  cycle,
  cycleLabel,
  isActive,
  priceProp,
  priceBasicProp,
  priceBasicOldProp,
  onetimePriceProp,
  onetimePriceBasicProp,
  onetimePriceBasicOldProp,
  showPrice,
  hideCheckbox,
  disabled,
  onClick,
  onetime
}) => {
  const currency = useSelector(state => state.general.currency);
  const { name, description } = data;
  const cyclePrice = priceProp || Number(data[cycle]);
  const cyclePriceBasic = priceBasicProp || Number(data[`${cycle}Basic`]);
  const cyclePriceBasicOld = priceBasicOldProp || Number(data[`${cycle}BasicOld`]);
  const onetimePrice = onetimePriceProp || onetime ? Number(data.onetime) : null;
  const onetimePriceBasic = onetimePriceBasicProp || onetime ? Number(data.onetimeBasic) : null;
  const onetimePriceBasicOld = onetimePriceBasicOldProp || onetime ? Number(data.onetimeBasicOld) : null;
  const price = onetimePrice || cyclePrice;
  const priceBasic = onetimePriceBasic || cyclePriceBasic;
  const priceBasicOld = onetimePriceBasicOld || cyclePriceBasicOld;
  const classes = useStyles({ isActive, showPrice });

  const recurfor = useSelector(state => state.order.recurfor || {})

  const curCycleLabel = useMemo(() => {
    if(!cycleLabel) {
      return 'one-time';
    }
    if(cycleLabel === 'monthly') {
      if(recurfor.monthly && priceBasicOld !== priceBasic) {
        return recurfor.monthly === 1 ? 'first month' : `next ${recurfor.monthly} months`
      }  
      return 'monthly'
    }
    return cycle
  }, [cycleLabel, recurfor])
  
  return (
    <Card className={classes.root} onClick={onClick}>
      <CardActionArea className={classes.actionArea} disabled={disabled}>
        {!hideCheckbox && (
          <Checkbox checked={isActive} color="primary" className={classes.checkbox} />
        )}
        <CardContent className={classes.content}>
          <Text
            className={classes.heading}
            gutterBottom
            variant="subtitle1"
            component="h4"
            fontWeight={600}
            pr={3}
          >
            {name}
          </Text>
          <Divider />
          <Text className={classes.desc} color="textSecondary" component="p" mt={1}>
            {description}
          </Text>
          {showPrice && (
            <Box className={classes.priceContainer}>
              {!!priceBasicOld && +priceBasicOld > +price && ( // old price (displayed if there is discount)
                <Text className={classes.priceOld}>
                  {!!priceBasicOld && currency}
                  {priceBasicOld ? priceBasicOld.toFixed(2) : 'FREE'}
                </Text>
              )}
              <Text className={classes.price}>
                {!!price && currency}
                {price ? Math.min(price, priceBasic).toFixed(2) : 'FREE'}
              </Text>
              {/* {!!price && ( */}
              <Text className={classes.cycle} component="span">
                {curCycleLabel}
              </Text>
              {/* )} */}
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ProductBox.propTypes = {
  showPrice: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  cycle: PropTypes.string,
  cycleLabel: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  priceProp: PropTypes.number,
  priceBasicProp: PropTypes.number,
  hideCheckbox: PropTypes.bool,
  disabled: PropTypes.bool,
  onetime: PropTypes.bool,
};

ProductBox.defaultProps = {
  cycle: '',
  cycleLabel: '',
  isActive: false,
  onClick: () => {},
  priceProp: 0,
  priceBasicProp: 0,
  hideCheckbox: false,
  disabled: false,
  onetime: false,
};

export default ProductBox;
